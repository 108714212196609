<template>
  <div class="column">
    <woot-modal-header :header-title="$t('SKILLS_MGMT.AGENT_MODAL.TITLE')">
      <p>{{ $t('SKILLS_MGMT.AGENT_MODAL.SUBTITLE') }}</p>
    </woot-modal-header>
    <div class="row modal-content">
      <div class="medium-12 columns skills-wrap">
        <agent-skills-input
          v-for="(skill, i) in skillsToUpdate"
          :key="skill.id"
          :index="i"
          :options="skillTypeOptions"
          :skill="skill"
          :skills-to-update="skillsToUpdate"
          @changeSkill="onChangeSkill"
          @removeSkill="onRemoveSkill"
          @submit="onSubmit"
        />
        <div class="skill-actions">
          <woot-button
            v-if="skillsArePresent"
            icon="add"
            color-scheme="success"
            variant="smooth"
            size="small"
            data-test-id="agent-add-skill-button"
            :disabled="!canAddMoreSkills()"
            @click="appendNewSkill"
          >
            {{ $t('SKILLS_MGMT.AGENT_MODAL.ADD_SKILL') }}
          </woot-button>
          <p v-else class="no-skills">
            {{ $t('SKILLS_MGMT.AGENT_MODAL.NO_SKILL') }}
          </p>
        </div>
      </div>
      <div class="medium-12 columns">
        <div class="modal-footer justify-content-end w-full">
          <woot-button class="button clear" @click.prevent="closeModal">
            {{ $t('SKILLS_MGMT.AGENT_MODAL.CANCEL_BUTTON_LABEL') }}
          </woot-button>
          <woot-button
            data-test-id="agent-save-skills-button"
            :disabled="!skillsArePresent"
            @click.prevent="onSubmit"
          >
            {{ $t('SKILLS_MGMT.AGENT_MODAL.SUBMIT_BUTTON_LABEL') }}
          </woot-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import AgentSkillsInput from './AgentSkillsInput.vue';
import skillsMixin from '../../../../mixins/skillsMixin';

export default {
  components: { AgentSkillsInput },
  mixins: [alertMixin, skillsMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    initialFilterTypes: {
      type: Array,
      default: () => [],
    },
    conversationInbox: {
      type: [String, Number],
      default: 0,
    },
    agentId: { type: Number, default: null },
    agentSkills: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: true,
      skillsToUpdate: [],
    };
  },
  computed: {
    skillsArePresent() {
      return this.records.length > 0;
    },
  },
  mounted() {
    this.skillsToUpdate = [...this.agentSkills];
  },
  methods: {
    closeModal() {
      this.skillsToUpdate = [];
      this.onClose();
    },
    canAddMoreSkills() {
      return (
        this.skillsToUpdate.length < this.records.length &&
        this.skillsToUpdate.length < 10
      );
    },
    appendNewSkill() {
      const newSkill = this.records.find(
        skill =>
          !this.skillsToUpdate.some(
            existingSkill => existingSkill.id === skill.id
          )
      );

      if (newSkill) {
        this.skillsToUpdate.push(newSkill);
      }
    },
    onChangeSkill(skill) {
      const { index, skill: newSkill } = skill;
      const isNameUnique = !this.skillsToUpdate.some(
        (existingSkill, existingIndex) =>
          existingIndex !== index && existingSkill.name === newSkill.name
      );

      if (isNameUnique) {
        this.skillsToUpdate = this.skillsToUpdate.map((existingSkill, i) =>
          i === index ? newSkill : existingSkill
        );
      }
    },
    onRemoveSkill(index) {
      this.skillsToUpdate.splice(index, 1);
    },
    onSubmit() {
      this.$store.dispatch('agents/addSkillToAgent', {
        skills: this.skillsToUpdate
          .filter(skill => skill.id)
          .map(skill => skill.id),
        agentId: this.agentId,
      });
      this.onClose();
    },
  },
};
</script>
<style lang="scss" scoped>
.skills-wrap {
  padding: var(--space-normal);
  border-radius: var(--border-radius-large);
  border: 1px solid var(--color-border);
  background: var(--color-background-light);
  margin-bottom: var(--space-normal);
}

.skill-actions {
  margin-top: var(--space-normal);
}

.no-skills {
  color: #cc0000;
}
</style>
