import { frontendURL } from '../../../../helper/URLHelper';

const featureDisableReports = window.chatwootConfig.featureDisableReports;

const primaryMenuItems = accountId => [
  {
    icon: 'chat',
    key: 'conversations',
    label: 'CONVERSATIONS',
    toState: frontendURL(`accounts/${accountId}/dashboard`),
    toStateName: 'home',
    roles: ['administrator', 'agent', 'supervisor'],
  },
  // {
  //   icon: 'book-contacts',
  //   key: 'contacts',
  //   label: 'CONTACTS',
  //   toState: frontendURL(`accounts/${accountId}/contacts`),
  //   toStateName: 'contacts_dashboard',
  //   roles: ['administrator', 'agent'],
  // },
  {
    icon: 'arrow-trending-lines',
    key: 'reports',
    label: 'REPORTS',
    toState: frontendURL(`accounts/${accountId}/reports`),
    toStateName: 'settings_account_reports',
    roles: featureDisableReports
      ? ['supervisor']
      : ['administrator', 'supervisor'],
  },
  {
    icon: 'settings',
    key: 'settings',
    label: 'SETTINGS',
    toState: frontendURL(`accounts/${accountId}/settings`),
    toStateName: 'settings_home',
    roles: ['administrator', 'agent', 'supervisor'],
  },
];

export default primaryMenuItems;
