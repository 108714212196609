<template>
  <woot-modal :show="show" :on-close="closeModal">
    <woot-modal-header
      :header-title="$t('CONVERSATION.REPLYBOX.INSERT_LINK_MODAL_TITLE')"
    />
    <form @submit.prevent="onAddLink">
      <label>{{ $t('CONVERSATION.REPLYBOX.INSERT_LINK_MODAL_NAME') }}</label>
      <woot-input
        v-model="name"
        type="text"
        :class="{ error: $v.name.$error }"
        data-test-id="insert_link_name_input"
        :placeholder="
          $t('CONVERSATION.REPLYBOX.INSERT_LINK_MODAL_NAME_PLACEHOLDER')
        "
        @blur="$v.name.$touch"
      />
      <label>{{ $t('CONVERSATION.REPLYBOX.INSERT_LINK_MODAL_URL') }}</label>
      <woot-input
        v-model="url"
        type="text"
        placeholder="https://www.example.com"
        :class="{ error: $v.url.$error }"
        data-test-id="insert_link_url_input"
        @blur="$v.url.$touch"
      />
      <div class="button-wrapper">
        <woot-button
          :is-disabled="$v.name.$invalid || $v.url.$invalid"
          data-test-id="insert_link_button"
        >
          {{ $t('CONVERSATION.REPLYBOX.INSERT_LINK_MODAL_INSERT_LINK_BUTTON') }}
        </woot-button>
        <woot-button class="clear" @click.prevent="closeModal">
          {{ $t('CONVERSATION.REPLYBOX.INSERT_LINK_MODAL_CANCEL_BUTTON') }}
        </woot-button>
      </div>
    </form>
  </woot-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { shouldBeUrl } from 'shared/helpers/Validators';
import WootModal from 'components/Modal.vue';
import WootModalHeader from 'components/ModalHeader.vue';
import WootButton from 'components/ui/WootButton.vue';
import WootInput from 'components/widgets/forms/Input.vue';

export default {
  components: { WootInput, WootButton, WootModalHeader, WootModal },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      name: '',
      url: '',
    };
  },
  validations: {
    name: {
      required,
    },
    url: {
      required,
      shouldBeUrl,
    },
  },
  methods: {
    closeModal() {
      this.name = '';
      this.url = '';
      this.$emit('on-close');
    },
    onAddLink() {
      this.$emit('on-add-link', { name: this.name, url: this.url });
      this.closeModal();
    },
  },
};
</script>
