import { mapGetters } from 'vuex';
import { formatTime } from '@chatwoot/utils';

export default {
  computed: {
    ...mapGetters({
      accountSummary: 'getAccountSummary',
      accountReport: 'getAccountReports',
    }),
    calculateTrend() {
      return metric_key => {
        if (!this.accountSummary.previous[metric_key]) return 0;
        return Math.round(
          ((this.accountSummary[metric_key] -
            this.accountSummary.previous[metric_key]) /
            this.accountSummary.previous[metric_key]) *
            100
        );
      };
    },
    displayMetric() {
      return metric_key => {
        if (this.isAverageMetricType(metric_key)) {
          return formatTime(this.accountSummary[metric_key]);
        }
        return this.accountSummary[metric_key];
      };
    },
    displayInfoText() {
      return metric_key => {
        if (this.metrics[this.currentSelection].KEY !== metric_key) {
          return '';
        }
        if (this.isAverageMetricType(metric_key)) {
          const total = this.accountReport.data
            .map(item => item.count)
            .reduce((prev, curr) => prev + curr, 0);
          return `${this.metrics[this.currentSelection].INFO_TEXT} ${total}`;
        }
        return '';
      };
    },
    isAverageMetricType() {
      return metric_key => {
        return ['avg_first_response_time', 'avg_resolution_time'].includes(
          metric_key
        );
      };
    },
  },
  methods: {
    formatSecondsByChunk(initialSeconds, chunkSize) {
      if (initialSeconds > 0) {
        const numberOfChunks = Math.floor(initialSeconds / chunkSize);
        const remainingSeconds = Math.round(initialSeconds % chunkSize);
        return { numberOfChunks, remainingSeconds };
      }
      return { numberOfChunks: 0, remainingSeconds: 0 };
    },
    formatSecondsField(initialSeconds) {
      const {
        numberOfChunks: numberOfDays,
        remainingSeconds: remainingSecondsForDays,
      } = this.formatSecondsByChunk(initialSeconds, 86400);

      const {
        numberOfChunks: numberOfHours,
        remainingSeconds: remainingSecondsForHours,
      } = this.formatSecondsByChunk(remainingSecondsForDays, 3600);

      const {
        numberOfChunks: numberOfMins,
        remainingSeconds: numberOfSeconds,
      } = this.formatSecondsByChunk(remainingSecondsForHours, 60);

      const daysTranslation = this.$t(
        'OVERVIEW_REPORTS.AGENT_CONVERSATIONS.DAYS'
      );

      const hoursTranslation = this.$t(
        'OVERVIEW_REPORTS.AGENT_CONVERSATIONS.HOURS'
      );

      const minutesTranslation = this.$t(
        'OVERVIEW_REPORTS.AGENT_CONVERSATIONS.MINUTES'
      );

      const secondsTranslation = this.$t(
        'OVERVIEW_REPORTS.AGENT_CONVERSATIONS.SECONDS'
      );

      let msg = '';

      if (numberOfDays > 0) {
        msg += `${numberOfDays}${daysTranslation}`;
      }

      if (numberOfHours > 0) {
        const hoursMsg =
          msg !== ''
            ? ` ${numberOfHours}${hoursTranslation}`
            : `${numberOfHours}${hoursTranslation}`;
        msg += hoursMsg;
      }

      if (numberOfMins > 0) {
        const minsMsg =
          msg !== ''
            ? ` ${numberOfMins} ${minutesTranslation}`
            : `${numberOfMins} ${minutesTranslation}`;
        msg += minsMsg;
      }

      if (numberOfSeconds > 0 || msg === '') {
        const secondsMsg =
          msg !== ''
            ? ` ${numberOfSeconds} ${secondsTranslation}`
            : `${numberOfSeconds} ${secondsTranslation}`;
        msg += secondsMsg;
      }

      return msg;
    },
  },
};
