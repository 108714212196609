import { SocketClient } from '@cognigy/socket-client';

export const getAssistSocketConnection = (
  { endpoint_url },
  { agentId },
  sessionId,
  assistClientId
) => {
  if (!endpoint_url) {
    return null;
  }

  const AssistClients = JSON.parse(
    window.localStorage.getItem('AssistClients')
  );

  if (AssistClients[assistClientId]) {
    return AssistClients[assistClientId];
  }

  const lastIndex = endpoint_url.lastIndexOf('/');

  const baseUrl = endpoint_url.slice(0, lastIndex);

  const socketToken = endpoint_url.slice(lastIndex + 1);

  const newAssistClient = { baseUrl, socketToken, userId: agentId, sessionId };

  AssistClients[assistClientId] = {
    assistClient: newAssistClient,
    chatHistory: [],
  };

  window.localStorage.setItem('AssistClients', JSON.stringify(AssistClients));

  return AssistClients[assistClientId];
};

export const resetSocketConnection = (newSessionId, assistClientId) => {
  const AssistClients = JSON.parse(
    window.localStorage.getItem('AssistClients')
  );
  AssistClients[assistClientId] = {
    assistClient: {
      ...AssistClients[assistClientId].assistClient,
      sessionId: newSessionId,
    },
    chatHistory: [],
  };

  window.localStorage.setItem('AssistClients', JSON.stringify(AssistClients));

  return AssistClients[assistClientId];
};

export const establishSocketConnection = ({
  baseUrl,
  socketToken,
  userId,
  sessionId,
}) => {
  const AssistClient = new SocketClient(baseUrl, socketToken, {
    forceWebsockets: true,
    userId: userId,
    sessionId: sessionId,
  });

  return AssistClient;
};
