export default {
  methods: {
    fileName(attachment) {
      let fileName;
      const url = attachment.url;

      if (attachment.file_name) {
        fileName = attachment.file_name;
      } else if (url) {
        fileName = url.substring(url.lastIndexOf('/') + 1);
      }

      return fileName || this.$t('ATTACHMENTS_MGMT.LIST.UNKNOWN_FILE_TYPE');
    },
  },
};
