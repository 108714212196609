/* eslint-disable no-underscore-dangle */
import {
  isAdaptiveCard,
  isAttachment,
  isAudio,
  isButtonWithText,
  isDatePicker,
  isGallery,
  isImage,
  isList,
  isQuickReply,
  isTemplate,
  isVideo,
} from '../../dashboard/components/widgets/conversation/helpers/getStructuredMessageType';

/** Helper function for getting the message type of a Cognigy message. */
export default data => {
  const firstContentAttrItem = data.content_attributes.items[0];
  const legacyMessage = firstContentAttrItem?.data?._cognigy?._webchat?.message;
  const message =
    firstContentAttrItem?.data?._cognigy?._defaultPreview?.message;

  if (isButtonWithText(message, legacyMessage)) {
    return 'button_with_text';
  }

  if (isQuickReply(message, legacyMessage)) {
    return 'quick_replies';
  }

  if (isGallery(message, legacyMessage)) {
    return 'generic';
  }

  if (isList(message, legacyMessage)) {
    return 'list';
  }

  if (isAudio(message, legacyMessage)) {
    return 'audio';
  }

  if (isImage(message, legacyMessage)) {
    return 'image';
  }

  if (isVideo(message, legacyMessage)) {
    return 'video';
  }

  if (isTemplate(message, legacyMessage)) {
    return (
      legacyMessage?.attachment.payload.template_type ||
      message?.attachment.payload.template_type
    );
  }
  if (isDatePicker(firstContentAttrItem)) {
    return 'date_picker';
  }

  if (isAdaptiveCard(message, legacyMessage, firstContentAttrItem)) {
    return 'adaptivecards';
  }
  const isSimpleMessage = data.content;

  if (isSimpleMessage) {
    return 'text';
  }

  if (isAttachment(message, legacyMessage)) {
    return 'attachment';
  }

  return 'unknown';
};
