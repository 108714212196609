/* eslint-disable no-underscore-dangle */

const addPostbackValueEventListener = (
  component,
  item,
  onForwardAssistMessagePostback
) => {
  component.addEventListener('click', e => {
    onForwardAssistMessagePostback(item.payload);
    e.stopPropagation();
  });
};

const addURLNavigationEventListener = (component, item) => {
  component.addEventListener('click', e => {
    window.open(item.url, item.target);
    e.stopPropagation();
  });
};

const addEventListeners = (elements, data, onForwardAssistMessagePostback) => {
  data.forEach((item, index) => {
    if (item.type === 'postback') {
      addPostbackValueEventListener(
        elements[index],
        item,
        onForwardAssistMessagePostback
      );
    } else if (item.type === 'web_url') {
      addURLNavigationEventListener(elements[index], item);
    }
  });
};

const addEventListenersToQuickReplies = (
  elements,
  data,
  onForwardAssistMessagePostback
) => {
  data.forEach((item, index) => {
    if (elements[index]) {
      addPostbackValueEventListener(
        elements[index],
        item,
        onForwardAssistMessagePostback
      );
    }
  });
};

const addEventListenersToGallery = (
  elements,
  data,
  onForwardAssistMessagePostback
) => {
  // This horrible thing takes care of mapping postback values and urls to the buttons inside each different card in a gallery
  // will need refactoring eventually
  data.forEach((card, cardIndex) => {
    card.buttons.forEach((item, index) => {
      const component = Array.from(
        elements[cardIndex].children[2].children
      ).filter(el => el.childNodes.length > 0)[index];

      if (item.type === 'postback') {
        addPostbackValueEventListener(
          component,
          item,
          onForwardAssistMessagePostback
        );
      } else if (item.type === 'web_url') {
        addURLNavigationEventListener(component, item);
      }
    });
  });
};

export const addEventListenersForActionElements = ({
  elementId,
  onForwardAssistMessagePostback,
  messageData,
  type,
}) => {
  const contentAttributesFirstCognigyItem =
    messageData.content_attributes.items[0].data?._cognigy;
  let data;

  if (contentAttributesFirstCognigyItem?._defaultPreview?.message) {
    data = contentAttributesFirstCognigyItem?._defaultPreview?.message;
  } else {
    data = contentAttributesFirstCognigyItem?._webchat?.message;
  }

  switch (type) {
    case 'button_with_text':
      {
        const buttonsData = data?.attachment?.payload?.buttons;
        const buttonsElements = [
          // Text with buttons
          ...document.querySelectorAll(
            `#${elementId} .webchat-buttons-template-button`
          ),
        ];

        addEventListeners(
          buttonsElements,
          buttonsData,
          onForwardAssistMessagePostback
        );
      }
      break;
    case 'quick_replies':
      {
        const quickRepliesData = data.quick_replies;
        const quickRepliesElements = [
          // Text with quick replies
          ...document.querySelectorAll(
            `#${elementId} .webchat-quick-reply-template-reply`
          ),
        ];

        addEventListenersToQuickReplies(
          quickRepliesElements,
          quickRepliesData,
          onForwardAssistMessagePostback
        );
      }
      break;
    case 'generic':
      {
        const galleryData = data?.attachment.payload.elements;
        const galleryElements = [
          // Gallery
          ...document.querySelectorAll(
            `#${elementId} .webchat-carousel-template-frame`
          ),
        ];

        addEventListenersToGallery(
          galleryElements,
          galleryData,
          onForwardAssistMessagePostback
        );
      }
      break;
    case 'list':
      {
        const listData = data?.attachment.payload.buttons;
        const listElements = [
          // List
          ...document.querySelectorAll(
            `#${elementId} .webchat-list-template-global-button`
          ),
        ];

        addEventListeners(
          listElements,
          listData,
          onForwardAssistMessagePostback
        );
      }
      break;

    default:
      break;
  }
};

export const addEventListenersToStopGalleryArrowPropagation = (
  elementId,
  emitStopForwarding
) => {
  const elements = document.querySelectorAll(`#${elementId} .control-arrow`);

  elements.forEach(element => {
    element.addEventListener('click', () => {
      emitStopForwarding();
    });
  });
};

export const addEventListenerToOpenImageModal = (elementId, openImageModal) => {
  const element = document.querySelectorAll(
    `#${elementId} .webchat-media-template-image > div`
  );

  element[0].addEventListener('click', e => {
    const imageStyle =
      element[0].currentStyle || window.getComputedStyle(element[0], false);

    const url = imageStyle.backgroundImage.slice(4, -1).replace(/['"]/g, '');

    openImageModal(url);
    e.stopPropagation();
  });
};

export const addEventListenerForArrowButtons = (
  elementId,
  onGalleryArrowClick
) => {
  const nextArrow = document.querySelectorAll(
    `#${elementId} .control-arrow.control-next`
  );

  const prevArrow = document.querySelectorAll(
    `#${elementId} .control-arrow.control-prev`
  );

  if (nextArrow[0] || prevArrow[0]) {
    nextArrow[0].addEventListener('click', () => {
      onGalleryArrowClick('next');
    });

    prevArrow[0].addEventListener('click', () => {
      onGalleryArrowClick('prev');
    });
  }
};
