<template>
  <button
    class="back-button"
    data-test-id="header_back_button"
    @click.capture="goBack"
  >
    <fluent-icon icon="chevron-left" />
    {{ buttonLabel || $t('GENERAL_SETTINGS.BACK') }}
  </button>
</template>
<script>
export default {
  props: {
    backUrl: {
      type: [String, Object],
      default: '',
    },
    buttonLabel: {
      type: String,
      default: '',
    },
  },
  methods: {
    goBack() {
      if (this.backUrl !== '') {
        this.$router.push(this.backUrl);
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>
<style scoped></style>
