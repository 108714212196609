export const updateSocketConnection = (copilotBotId, endpointUrl) => {
  const AssistClients = JSON.parse(
    window.localStorage.getItem('AssistClients')
  );

  Object.keys(AssistClients).forEach(key => {
    if (key.indexOf('-' + copilotBotId) > 0) {
      const lastIndex = endpointUrl.lastIndexOf('/');

      const baseUrl = endpointUrl.slice(0, lastIndex);

      const socketToken = endpointUrl.slice(lastIndex + 1);

      AssistClients[key] = {
        assistClient: {
          ...AssistClients[key].assistClient,
          baseUrl: baseUrl,
          socketToken: socketToken,
        },
        chatHistory: AssistClients[key].chatHistory,
      };

      window.localStorage.setItem(
        'AssistClients',
        JSON.stringify(AssistClients)
      );
    }
  });
};
