import { INotification } from '../../../../../store/modules/notifications/types';

/**
 * Returns the title of the notification
 * @param notification
 * @param $t
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const notificationTitle = (notification: INotification, $t: any) => {
  if (notification.primary_actor_type === 'Conversation') {
    return notification.primary_actor_display_id;
  }

  if (notification.secondary_actor_type === 'Conversation') {
    return notification.secondary_actor_display_id;
  }

  return $t(`NOTIFICATIONS_PAGE.DELETE_TITLE`);
};
