<template>
  <mention-box :items="items" @mention-select="handleMentionClick" />
</template>

<script>
import { mapGetters } from 'vuex';
import MentionBox from '../mentions/MentionBox.vue';
import { filterAgentsForAssignability } from 'shared/filters/agents';

export default {
  components: { MentionBox },
  props: {
    searchKey: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
    }),
    assignableAgents: {
      get() {
        const agents = this.$store.getters[
          'inboxAssignableAgents/getAssignableAgents'
        ](this.currentChat.inbox_id);
        return filterAgentsForAssignability(agents, this.selectedAgent, false);
      },
    },
    items() {
      if (!this.searchKey) {
        return this.assignableAgents.map(agent => ({
          label: agent.name,
          key: agent.id,
          description: agent.email,
        }));
      }

      return this.assignableAgents
        .filter(agent =>
          agent.name
            .toLocaleLowerCase()
            .includes(this.searchKey.toLocaleLowerCase())
        )
        .map(agent => ({
          label: agent.name,
          key: agent.id,
          description: agent.email,
        }));
    },
  },
  methods: {
    handleMentionClick(item = {}) {
      this.$emit('click', item);
    },
  },
};
</script>
