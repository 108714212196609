import types from '../../mutation-types';
import DraftManager from './draftManager';
import { REPLY_EDITOR_MODES } from '../../../components/widgets/WootWriter/constants';
import {
  CreateDraftMessagePayload,
  DeleteDraftMessagePayload,
  DraftContent,
  DraftMessages,
  ReplyType,
  State,
} from './draftStore.types';
import { ActionContext } from 'vuex';

export const state: State = {
  records: DraftManager.getDrafts(),
  replyEditorMode: REPLY_EDITOR_MODES.REPLY,
};

export const getters = {
  get:
    (_state: State) =>
    (id: string, replyType: ReplyType): DraftContent | null => {
      const key: string = DraftManager.buildKey(id, replyType);
      return _state.records[key] ?? null;
    },
  getReplyEditorMode: (_state: State) => _state.replyEditorMode,
};

export const actions = {
  set: async (
    { commit }: Pick<ActionContext<State, State>, 'commit'>,
    { id, replyType, message }: CreateDraftMessagePayload
  ): Promise<void> => {
    DraftManager.saveDraft(id, replyType, message);
    const drafts: DraftMessages = DraftManager.getDrafts();
    commit(types.SET_DRAFT_MESSAGES, drafts);
  },
  delete: (
    { commit }: Pick<ActionContext<State, State>, 'commit'>,
    { id, replyType }: DeleteDraftMessagePayload
  ): void => {
    DraftManager.deleteDraft(id, replyType);
    const drafts: DraftMessages = DraftManager.getDrafts();
    commit(types.REMOVE_DRAFT_MESSAGES, drafts);
  },
  deleteAll: ({
    commit,
  }: Pick<ActionContext<State, State>, 'commit'>): void => {
    DraftManager.deleteAllDrafts();
    commit(types.REMOVE_ALL_DRAFT_MESSAGES);
  },
  clearDraftsByTTL: ({
    commit,
  }: Pick<ActionContext<State, State>, 'commit'>): void => {
    DraftManager.deleteDraftsByTTL();
    const drafts: DraftMessages = DraftManager.getDrafts();
    commit(types.REMOVE_DRAFTS_BY_TTL, drafts);
  },
  setReplyEditorMode: (
    { commit }: Pick<ActionContext<State, State>, 'commit'>,
    { mode }: { mode: ReplyType }
  ): void => {
    commit(types.SET_REPLY_EDITOR_MODE, mode);
  },
};

export const mutations = {
  [types.SET_DRAFT_MESSAGES]($state: State, drafts: DraftMessages): void {
    $state.records = drafts;
  },
  [types.REMOVE_DRAFT_MESSAGES]($state: State, drafts: DraftMessages): void {
    $state.records = drafts;
  },
  [types.SET_REPLY_EDITOR_MODE]($state: State, mode: ReplyType): void {
    $state.replyEditorMode = mode;
  },
  [types.REMOVE_DRAFTS_BY_TTL]($state: State, drafts: DraftMessages): void {
    $state.records = drafts;
  },
  [types.REMOVE_ALL_DRAFT_MESSAGES]($state: State): void {
    $state.records = {};
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
