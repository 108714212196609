import ApiClient, { axiosWithAuth } from './ApiClient';
import { INotificationClient } from './types/notificationsClient.types';

class NotificationsAPI extends ApiClient implements INotificationClient {
  constructor() {
    super('notifications', { accountScoped: true });
  }

  /**
   * Overloaded method for get
   * @param page
   */
  get(page?: number) {
    return axiosWithAuth.get(`${this.url}?page=${page}`);
  }

  getNotifications(contactId: number) {
    return axiosWithAuth.get(`${this.url}/${contactId}/notifications`);
  }

  getUnreadNotifications(page?: number) {
    return axiosWithAuth.get(`${this.url}/unread?page=${page}`);
  }

  getUnreadCount() {
    return axiosWithAuth.get(`${this.url}/unread_count`);
  }

  read(primaryActorType: string, primaryActorId: string) {
    return axiosWithAuth.post(`${this.url}/read_all`, {
      primary_actor_type: primaryActorType,
      primary_actor_id: primaryActorId,
    });
  }

  readAll() {
    return axiosWithAuth.post(`${this.url}/read_all`);
  }
}

export default new NotificationsAPI();
