<template>
  <div class="settings columns container">
    <setting-intro-banner
      :header-image="inbox.avatarUrl"
      :header-title="inboxName"
    >
      <woot-tabs :index="selectedTabIndex" @change="onTabChange">
        <woot-tabs-item
          v-for="tab in tabs"
          :key="tab.key"
          :name="tab.name"
          :show-badge="false"
          :data-test-id="`${tab.name}_tab`"
        />
      </woot-tabs>
    </setting-intro-banner>
    <div v-if="selectedTabKey === 'inbox_settings'" class="settings--content">
      <settings-section
        :title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_UPDATE_TITLE')"
        :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_UPDATE_SUB_TEXT')"
      >
        <woot-avatar-uploader
          :key="currentInboxId"
          ref="avatarUploader"
          :label="$t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_AVATAR.LABEL')"
          :src="avatarUrl"
          class="settings-item"
          data-test-id="inbox_settings_avatar_uploader"
          :delete-avatar="isAdministrator"
          @change="handleImageUpload"
          @onAvatarDelete="handleAvatarDelete"
        />
        <woot-input
          v-model.trim="selectedInboxName"
          :class="{ error: $v.selectedInboxName.$error }"
          class="medium-9 columns settings-item"
          :data-test-id="'inbox_settings_inbox_name_input'"
          :label="inboxNameLabel"
          :placeholder="inboxNamePlaceHolder"
          :error="
            $v.selectedInboxName.$error
              ? $t('INBOX_MGMT.ADD.API_CHANNEL.CHANNEL_NAME.VALIDATION_ERROR')
              : ''
          "
          @blur="$v.selectedInboxName.$touch"
        />
        <woot-input
          v-model.trim="projectId"
          class="medium-9 columns settings-item"
          :data-test-id="'inbox_settings_project_id_input'"
          :label="inboxProjectIdLabel"
          :placeholder="inboxProjectIdPlaceHolder"
        />
        <woot-input
          v-model.trim="webhookUrl"
          :data-test-id="'inbox_settings_webhook_input'"
          class="medium-9 columns settings-item"
          :class="{ error: $v.webhookUrl.$error }"
          :label="
            $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WEBHOOK_URL.LABEL')
          "
          :placeholder="
            $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WEBHOOK_URL.PLACEHOLDER')
          "
          :error="
            $v.webhookUrl.$error
              ? $t('INBOX_MGMT.ADD.WEBSITE_CHANNEL.CHANNEL_WEBHOOK_URL.ERROR')
              : ''
          "
          @blur="$v.webhookUrl.$touch"
        />

        <woot-submit-button
          type="submit"
          :disabled="$v.webhookUrl.$invalid"
          :button-text="$t('INBOX_MGMT.SETTINGS_POPUP.UPDATE')"
          :loading="uiFlags.isUpdatingInbox"
          :data-test-id="'inbox_settings_submit_button'"
          @click="updateInbox"
        />
      </settings-section>
      <settings-section
        :title="$t('INBOX_MGMT.COPILOT_BOT.INBOX_COPILOT_BOT_TITLE')"
        :sub-title="$t('INBOX_MGMT.COPILOT_BOT.INBOX_COPILOT_BOT_SUB_TEXT')"
      >
        <copilot-bots-input />
      </settings-section>
    </div>
    <div v-if="selectedTabKey === 'collaborators'" class="settings--content">
      <collaborators-page :inbox="inbox" />
    </div>
    <div v-if="selectedTabKey === 'configuration'">
      <configuration-page :inbox="inbox" />
    </div>
    <!--    <div v-if="selectedTabKey === 'businesshours'">-->
    <!--      <weekly-availability :inbox="inbox" />-->
    <!--    </div>-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { shouldBeUrl, isValidImage } from 'shared/helpers/Validators';
import configMixin from 'shared/mixins/configMixin';
import alertMixin from 'shared/mixins/alertMixin';
import SettingIntroBanner from 'dashboard/components/widgets/SettingIntroBanner.vue';
import SettingsSection from '../../../../components/SettingsSection.vue';
import inboxMixin from 'shared/mixins/inboxMixin';
import CopilotBotsInput from './components/CopilotBotsInput.vue';
import ConfigurationPage from './settingsPage/ConfigurationPage.vue';
import CollaboratorsPage from './settingsPage/CollaboratorsPage.vue';
import wootConstants from '../../../../constants';
import WootUIKit from 'components';

const validNameIsSafe = (value = '') => !/^=/.test(value);

export default {
  name: 'SettingsRoute',
  components: {
    WootSubmitButton: WootUIKit.SubmitButton,
    WootTabsItem: WootUIKit.TabsItem,
    WootInput: WootUIKit.Input,
    SettingIntroBanner,
    SettingsSection,
    // WeeklyAvailability,
    CopilotBotsInput,
    ConfigurationPage,
    CollaboratorsPage,
  },
  mixins: [alertMixin, configMixin, inboxMixin],
  data() {
    return {
      avatarFile: null,
      avatarUrl: '',
      hmacMandatory: null,
      emailCollectEnabled: false,
      isAgentListUpdating: false,
      allowMessagesAfterResolved: true,
      continuityViaEmail: true,
      selectedInboxName: '',
      channelWebsiteUrl: '',
      webhookUrl: '',
      channelWelcomeTitle: '',
      channelWelcomeTagline: '',
      selectedFeatureFlags: [],
      replyTime: '',
      selectedTabIndex: 0,
      projectId: '',
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'inboxes/getUIFlags',
      globalConfig: 'globalConfig/get',
      currentRoles: 'getCurrentRoles',
    }),
    selectedTabKey() {
      return this.tabs[this.selectedTabIndex]?.key;
    },
    tabs() {
      const visibleToAllChannelTabs = [
        {
          key: 'inbox_settings',
          name: this.$t('INBOX_MGMT.TABS.SETTINGS'),
        },
        {
          key: 'collaborators',
          name: this.$t('INBOX_MGMT.TABS.COLLABORATORS'),
        },
      ];

      return [
        ...visibleToAllChannelTabs,
        {
          key: 'configuration',
          name: this.$t('INBOX_MGMT.TABS.CONFIGURATION'),
        },
      ];
    },
    currentInboxId() {
      return this.$route.params.inboxId;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.currentInboxId);
    },
    inboxName() {
      return this.inbox.name;
    },
    inboxNameLabel() {
      return this.$t('INBOX_MGMT.ADD.CHANNEL_NAME.LABEL');
    },
    inboxNamePlaceHolder() {
      return this.$t('INBOX_MGMT.ADD.CHANNEL_NAME.PLACEHOLDER');
    },
    inboxProjectIdLabel() {
      return this.$t('INBOX_MGMT.ADD.PROJECT_ID.LABEL');
    },
    inboxProjectIdPlaceHolder() {
      return this.$t('INBOX_MGMT.ADD.PROJECT_ID.PLACEHOLDER');
    },
    isAdministrator() {
      return this.currentRoles.includes('administrator');
    },
  },
  watch: {
    $route(to) {
      if (to.name === 'settings_inbox_show') {
        this.fetchInboxSettings();
      }
    },
  },
  mounted() {
    this.fetchInboxSettings();
  },
  methods: {
    toggleInput(selected, current) {
      if (selected.includes(current)) {
        return selected.filter(flag => flag !== current);
      }
      return [...selected, current];
    },
    onTabChange(selectedTabIndex) {
      this.selectedTabIndex = selectedTabIndex;
    },
    fetchInboxSettings() {
      this.selectedTabIndex = 0;
      this.selectedAgents = [];
      this.$store.dispatch('teams/get');
      this.$store.dispatch('labels/get');
      this.$store.dispatch('inboxes/get').then(() => {
        this.avatarUrl = this.inbox.avatar_url;
        this.selectedInboxName = this.inbox.name;
        this.webhookUrl = this.inbox.webhook_url;
        this.projectId = this.inbox.cognigy_project_id;
      });
    },
    async updateInbox() {
      try {
        const payload = {
          id: this.currentInboxId,
          name: this.selectedInboxName,
          channel: {
            webhook_url: this.webhookUrl,
          },
          cognigy_project_id: this.projectId,
        };
        if (this.avatarFile) {
          payload.avatar = this.avatarFile;
        }
        await this.$store.dispatch('inboxes/updateInbox', payload);
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'), {
          status: wootConstants.SNACKBAR_TYPE.SUCCESS,
        });
      } catch (error) {
        this.showAlert(this.$t('INBOX_MGMT.EDIT.API.SUCCESS_MESSAGE'), {
          status: wootConstants.SNACKBAR_TYPE.ERROR,
        });
      }
    },
    handleImageUpload({ file, url }) {
      if (isValidImage(file)) {
        this.avatarFile = file;
        this.avatarUrl = url;
        return;
      }

      this.showAlert(this.$t('PROFILE_SETTINGS.AVATAR_TYPE_ERROR'), {
        status: wootConstants.SNACKBAR_TYPE.ERROR,
      });
      this.$refs.avatarUploader.clearFileInput();
    },
    async handleAvatarDelete() {
      try {
        await this.$store.dispatch(
          'inboxes/deleteInboxAvatar',
          this.currentInboxId
        );
        this.avatarFile = null;
        this.avatarUrl = '';
        this.showAlert(
          this.$t('INBOX_MGMT.DELETE.API.AVATAR_SUCCESS_MESSAGE'),
          {
            status: wootConstants.SNACKBAR_TYPE.SUCCESS,
          }
        );
      } catch (error) {
        this.showAlert(
          error.message
            ? error.message
            : this.$t('INBOX_MGMT.DELETE.API.AVATAR_ERROR_MESSAGE'),
          {
            status: wootConstants.SNACKBAR_TYPE.ERROR,
          }
        );
      }
    },
  },
  validations: {
    webhookUrl: {
      shouldBeUrl,
    },
    selectedInboxName: {
      validNameIsSafe,
    },
  },
};
</script>

<style scoped lang="scss">
@import 'dashboard/assets/scss/variables';
@import 'dashboard/assets/scss/mixins';

.settings {
  background: $color-white;

  .settings--content {
    div:last-child {
      border-bottom: 0;
    }
  }

  .tabs {
    padding: 0;
    margin-bottom: -1px;
  }
}

.widget--feature-flag {
  padding-top: var(--space-small);
}

.settings-item {
  padding-bottom: var(--space-normal);

  .help-text {
    font-style: normal;
    color: var(--b-500);
    padding-bottom: var(--space-smaller);
  }
}
</style>
