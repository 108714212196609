import capitalize from './capitalize';

export default (key, value) => {
  const mapKeyValueToFilter = {
    age: () => (value === 0 ? '' : value),
    gender: () => capitalize(value),
    accepted_gdpr: () => (value ? 'Yes' : 'No'),
    // eslint-disable-next-line
    privacy_policy: () => (value['accepted'] ? 'Yes' : 'No'),
    goals: () =>
      Array.isArray(value) && value.length === 0 ? '' : value.toString(),
  };

  if (key === 'privacy_policy') {
    return mapKeyValueToFilter[key]();
  }

  if (typeof value === 'object' && !Array.isArray(value)) {
    return value.value ? value.value : '';
  }

  return mapKeyValueToFilter[key] ? mapKeyValueToFilter[key]() : value;
};
