<template>
  <div class="column content-box">
    <div class="attachment-filter-row">
      <sort-by-selector
        class="attachment-sort-by-selector"
        :selected-sort-by-filter="selectedSortByFilter"
        :sort-by-filter-items="sortByFilterItems"
        @filter-change="onSortByFilterChange"
      />
      <woot-button
        v-if="hasFilters"
        v-tooltip.top-end="$t('FILTER.CLEAR_BUTTON_LABEL')"
        size="large"
        variant="clear"
        color-scheme="alert"
        icon="dismiss-circle"
        @click="removeFilters"
      />
      <woot-button
        v-tooltip.top-end="$t('FILTER.ATTACHMENTS.TOOLTIP_TITLE')"
        variant="clear"
        color-scheme="secondary"
        icon="filter"
        size="large"
        class="btn-filter"
        @click="onToggleAdvanceFiltersModal"
      />
    </div>

    <div class="row">
      <div class="flex-auto max-width-large-8 columns with-right-space">
        <woot-loading-state
          v-if="uiFlags.isFetching"
          :message="$t('ATTACHMENTS_MGMT.LOADING')"
        />

        <div v-else>
          <p v-if="!attachmentsTotal" data-testid="attachments-not-found">
            {{ $t('ATTACHMENTS_MGMT.LIST.404') }}
          </p>
          <div v-else>
            <table class="woot-table attachments">
              <thead>
                <tr>
                  <th>{{ $t('ATTACHMENTS_MGMT.LIST.HEADER.NAME') }}</th>
                  <th>{{ $t('ATTACHMENTS_MGMT.LIST.HEADER.INBOX') }}</th>
                  <th>
                    {{ $t('ATTACHMENTS_MGMT.LIST.HEADER.CONVERSATION') }}
                  </th>
                  <th>
                    {{ $t('ATTACHMENTS_MGMT.LIST.HEADER.COGNIGY_SESSION_ID') }}
                  </th>
                  <th>{{ $t('ATTACHMENTS_MGMT.LIST.HEADER.CREATED') }}</th>
                  <th>{{ $t('ATTACHMENTS_MGMT.LIST.HEADER.ACTIONS') }}</th>
                </tr>
              </thead>
              <tbody>
                <attachment-row
                  v-for="attachment in attachmentsList"
                  :key="attachment.key"
                  :attachment="attachment"
                  :open-delete-popup="openDeletePopup"
                  :loading="Boolean(loading[attachment.id])"
                />
              </tbody>
            </table>
            <div class="attachments-pagination small-12 columns">
              <ve-pagination
                :total="attachmentsTotal"
                :page-index="page"
                :page-size="25"
                :page-size-option="[25]"
                @on-page-number-change="onPageNumberChange"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="small-4 columns">
        <span v-dompurify-html="$t('ATTACHMENTS_MGMT.SIDEBAR_TXT')" />
      </div>
    </div>

    <woot-modal
      :show.sync="showAdvancedFilters"
      :on-close="onToggleAdvanceFiltersModal"
      size="medium"
    >
      <attachments-advanced-filter
        v-if="showAdvancedFilters"
        :initial-filter-types="initialAttachmentFilters"
        :on-close="onToggleAdvanceFiltersModal"
        @applyFilter="onApplyFilter"
      />
    </woot-modal>

    <woot-delete-modal
      :show.sync="showDeletePopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('ATTACHMENTS_MGMT.DELETE.CONFIRM.TITLE')"
      :message="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { VePagination } from 'vue-easytable';
import SortBySelector from './components/SortBySelector.vue';
import AttachmentRow from './components/AttachmentRow.vue';
import AttachmentsAdvancedFilter from './components/AdvancedFilter.vue';
import alertMixin from 'shared/mixins/alertMixin';
import attachmentsMixin from './mixins/attachmentsMixin';
import wootConstants from '../../../../constants';
import WootUIKit from 'components';

const SORT_BY_FILTERS = ['date', 'inbox'];

export default {
  name: 'SettingsAttachments',
  components: {
    WootLoadingState: WootUIKit.LoadingState,
    WootDeleteModal: WootUIKit.DeleteModal,
    WootModal: WootUIKit.Modal,
    WootButton: WootUIKit.Button,
    AttachmentsAdvancedFilter,
    AttachmentRow,
    SortBySelector,
    VePagination,
  },
  mixins: [alertMixin, attachmentsMixin],
  data() {
    return {
      page: 1,
      loading: {},
      showDeletePopup: false,
      attachmentAPI: {
        message: '',
      },
      currentAttachment: {},
      selectedSortByFilter: this.createSortByFilterItem(SORT_BY_FILTERS[0]),
      showAdvancedFilters: false,
    };
  },
  computed: {
    ...mapGetters({
      attachmentsList: 'attachments/getAttachments',
      attachmentsTotal: 'attachments/getAttachmentsTotal',
      attachmentsFilters: 'attachments/getAttachmentFilters',
      uiFlags: 'attachments/getUIFlags',
    }),
    hasFilters() {
      return this.initialAttachmentFilters.length > 0;
    },
    initialAttachmentFilters() {
      const keys = Object.keys(this.attachmentsFilters);
      const values = Object.values(this.attachmentsFilters);

      return keys.map((key, index) => {
        const value = values[index];
        const operator = key === 'filename' ? 'contains' : 'equal_to';
        return {
          attribute_key: key,
          filter_operator: operator,
          values: value,
        };
      });
    },
    deleteConfirmText() {
      return `${this.$t('ATTACHMENTS_MGMT.DELETE.CONFIRM.YES')}`;
    },
    deleteRejectText() {
      return `${this.$t('ATTACHMENTS_MGMT.DELETE.CONFIRM.NO')}`;
    },
    deleteMessage() {
      return `${this.$t('ATTACHMENTS_MGMT.DELETE.CONFIRM.MESSAGE')} ${
        this.currentAttachmentName
      } ?`;
    },
    sortByFilterItems() {
      return SORT_BY_FILTERS.map(item => this.createSortByFilterItem(item));
    },
    currentAttachmentName() {
      return this.fileName(this.currentAttachment);
    },
  },
  mounted() {
    this.$store.dispatch('attachments/get', {
      page: this.page,
      sortBy: this.selectedSortByFilter.id,
    });
  },
  methods: {
    openDeletePopup(attachment) {
      this.showDeletePopup = true;
      this.currentAttachment = attachment;
    },
    closeDeletePopup() {
      this.showDeletePopup = false;
    },
    confirmDeletion() {
      this.loading[this.currentAttachment.id] = true;
      this.closeDeletePopup();
      this.deleteAttachment(this.currentAttachment.key);
    },
    async deleteAttachment(key) {
      try {
        await this.$store.dispatch('attachments/delete', key);
        this.showAlert(this.$t('ATTACHMENTS_MGMT.DELETE.API.SUCCESS_MESSAGE'), {
          status: wootConstants.SNACKBAR_TYPE.SUCCESS,
        });
      } catch (error) {
        this.showAlert(this.$t('ATTACHMENTS_MGMT.DELETE.API.ERROR_MESSAGE'), {
          status: wootConstants.SNACKBAR_TYPE.ERROR,
        });
      }
    },
    onSortByFilterChange(payload) {
      this.selectedSortByFilter = payload;
      this.$store.dispatch('attachments/get', {
        page: this.page,
        sortBy: payload.id,
      });
    },
    onPageNumberChange(pageIndex) {
      this.page = pageIndex;
      this.$store.dispatch('attachments/get', {
        page: pageIndex,
        sortBy: this.selectedSortByFilter.id,
      });
    },
    createSortByFilterItem(id) {
      return {
        id,
        name: this.$t(`ATTACHMENTS_MGMT.SORT_BY.${id.toUpperCase()}`),
      };
    },
    onToggleAdvanceFiltersModal() {
      this.showAdvancedFilters = !this.showAdvancedFilters;
    },
    removeFilters() {
      this.$store.dispatch('attachments/updateFilters', {});
    },
    onApplyFilter(payload) {
      const convertedFilters = {};
      payload.forEach(el => {
        convertedFilters[el.attribute_key] = el.values;
      });
      this.$store.dispatch('attachments/updateFilters', convertedFilters);
    },
  },
};
</script>
<style lang="scss" scoped>
.attachments-pagination {
  &::v-deep .ve-pagination {
    background-color: transparent;
  }

  &::v-deep .ve-pagination-select {
    display: none;
  }
  text-align: right;
}

.flex-auto {
  flex-basis: auto;
}

.max-width-large-8 {
  max-width: 130em;
}

.attachments {
  th {
    padding-top: 0;
  }
}

.attachment-filter-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: var(--space-normal);
  .attachment-sort-by-selector {
    flex: 0.2;
  }
}
</style>
