import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import AgentAPI from '../../api/agents';
import Vue from 'vue';

export const state = {
  records: [],
  total: 0,
  unmaskedRecords: [],
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
  },
  sortingPreferences: {
    page: 1,
  },
};

export const getters = {
  getAgents($state) {
    return $state.records;
  },
  getUnmaskedAgents($state) {
    return $state.unmaskedRecords;
  },
  getAgentsTotal($state) {
    return $state.total;
  },
  getVerifiedAgents($state) {
    return $state.records.filter(record => record.confirmed);
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getAgentStatus($state) {
    return {
      online: $state.records.filter(
        agent => agent.availability_status === 'online'
      ).length,
      busy: $state.records.filter(agent => agent.availability_status === 'busy')
        .length,
      offline: $state.records.filter(
        agent => agent.availability_status === 'offline'
      ).length,
      away: $state.records.filter(agent => agent.availability_status === 'away')
        .length,
    };
  },
  getAgentsCount($state) {
    return $state.records.length;
  },
};

export const actions = {
  get: async ({ commit }, params = {}) => {
    commit(types.default.SET_AGENT_FETCHING_STATUS, true);
    try {
      const response = await AgentAPI.get({
        page: params.page,
        query: params.query,
        minified: params.minified,
      });
      commit(types.default.SET_AGENT_FETCHING_STATUS, false);
      commit(types.default.SET_AGENTS, response.data.payload);
    } catch (error) {
      commit(types.default.SET_AGENT_FETCHING_STATUS, false);
    }
  },
  getUnmasked: async ({ commit }, params = {}) => {
    commit(types.default.SET_AGENT_FETCHING_STATUS, true);
    try {
      const response = await AgentAPI.getUnmaskedAgents({
        page: params.page,
        query: params.query,
        minified: params.minified,
      });
      commit(types.default.SET_AGENT_FETCHING_STATUS, false);
      commit(types.default.SET_UNMASKED_AGENTS, response.data.payload);
      commit(types.default.SET_AGENTS_TOTAL, response.data.total);
    } catch (error) {
      commit(types.default.SET_AGENT_FETCHING_STATUS, false);
    }
  },
  updatePresence: async ({ commit, dispatch }, data) => {
    commit(types.default.SET_AGENT_UPDATING_STATUS, true);
    commit(types.default.UPDATE_AGENT_PRESENCE, data);
    dispatch('updateSingleReportAgentStatus', data, { root: true });
    commit(types.default.SET_AGENT_UPDATING_STATUS, false);
  },

  updatePresenceForAll: async ({ commit, dispatch }, data) => {
    commit(types.default.SET_AGENT_UPDATING_STATUS, true);
    commit(types.default.UPDATE_ALL_AGENTS_PRESENCE, data);
    dispatch('updateAllReportAgentStatuses', data, { root: true });
    commit(types.default.SET_AGENT_UPDATING_STATUS, false);
  },

  addSkillToAgent: async ({ commit }, { agentId, skills }) => {
    commit(types.default.SET_AGENT_UPDATING_STATUS, true);
    try {
      const response = await AgentAPI.updateAgentSkills({ agentId, skills });
      commit(types.default.UPDATE_AGENT_SKILLS, {
        agentId,
        skills: response.data,
      });
      commit(types.default.SET_AGENT_UPDATING_STATUS, false);
    } catch (error) {
      commit(types.default.SET_AGENT_UPDATING_STATUS, false);
    }
  },
};

export const mutations = {
  [types.default.SET_AGENT_FETCHING_STATUS]($state, status) {
    $state.uiFlags.isFetching = status;
  },
  [types.default.SET_AGENTS_TOTAL]($state, total) {
    $state.total = total;
  },
  [types.default.SET_AGENT_CREATING_STATUS]($state, status) {
    $state.uiFlags.isCreating = status;
  },
  [types.default.SET_AGENT_UPDATING_STATUS]($state, status) {
    $state.uiFlags.isUpdating = status;
  },
  [types.default.SET_AGENT_DELETING_STATUS]($state, status) {
    $state.uiFlags.isDeleting = status;
  },

  [types.default.SET_AGENTS]: MutationHelpers.set,
  [types.default.SET_UNMASKED_AGENTS]($state, agents) {
    $state.unmaskedRecords = agents;
  },
  [types.default.UPDATE_ALL_AGENTS_PRESENCE]: MutationHelpers.updatePresence,
  [types.default.UPDATE_AGENT_PRESENCE](_state, data) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < _state.records.length; i++) {
      const currentAgentMetric = _state.records[i];
      if (currentAgentMetric.id === data.id) {
        Vue.set(_state.records[i], 'availability', data.status);
        break;
      }
    }
  },
  [types.default.UPDATE_AGENT_SKILLS]($state, data) {
    const { skills, agentId } = data;
    const foundAgent = $state.unmaskedRecords.find(
      agent => agent.id === agentId
    );

    if (foundAgent) {
      if (!foundAgent.skills) {
        foundAgent.skills = [];
      }
      foundAgent.skills = skills;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
