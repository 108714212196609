<template>
  <div class="location message-text__wrap">
    <GmapMap
      :center="{ lat: latitude, lng: longitude }"
      :zoom="7"
      map-type-id="terrain"
      style="width: 500px; height: 300px"
      class="location-message"
    >
      <GmapMarker
        v-for="(m, index) in markers"
        :key="index"
        :position="m.position"
        :clickable="true"
        :draggable="true"
        @click="center = m.position"
      />
    </GmapMap>
    <p class="name">{{ name }}</p>
    <p class="address">{{ address }}</p>
  </div>
</template>

<script>
export default {
  name: 'BubbleLocation',
  props: {
    latitude: {
      type: Number,
      required: true,
    },
    longitude: {
      type: Number,
      required: true,
    },
    address: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    markers() {
      return [
        {
          position: {
            lat: this.latitude,
            lng: this.longitude,
          },
        },
      ];
    },
  },
  methods: {
    onClose() {
      this.show = false;
    },
    onClick() {
      this.show = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.location-message {
  max-width: 35rem;
}

.location {
  display: inline-block;
}

.address {
  width: 300px;
  margin-top: 10px;
}

.name {
  width: 300px;
  margin-top: 20px;
  font-weight: bold;
}
</style>
