import ApiClient from './ApiClient';
import {
  ContactSearchParams,
  GetContactParams,
  IContactClient,
} from './types/contactsClient.types';
import { ContactFilterParams } from '../store/modules/contacts/types/contactStore.types';

export const buildContactParams = (
  page?: number | undefined,
  sortAttr?: string,
  label?: string,
  search?: string
) => {
  let params = `include_contact_inboxes=false&page=${page}&sort=${sortAttr}`;
  if (search) {
    params = `${params}&q=${search}`;
  }
  if (label) {
    params = `${params}&labels[]=${label}`;
  }
  return params;
};

export class ContactAPI extends ApiClient implements IContactClient {
  constructor() {
    super('contacts', { accountScoped: true });
  }

  /**
   * Overloaded method
   * It overloads the get method from the IApiClient interface
   * Due to the overloading in TypeScript, the method signature must be optional.
   *
   * @param page
   * @param sortAttr
   * @param label
   */
  get(page?: number, sortAttr: string = 'name', label: string = '') {
    const requestURL = `${this.url}?${buildContactParams(
      page,
      sortAttr,
      label,
      ''
    )}`;
    return this.client.get(requestURL);
  }

  getConversations(contactId: number) {
    return this.client.get(`${this.url}/${contactId}/conversations`);
  }

  getContactableInboxes(contactId: number) {
    return this.client.get(`${this.url}/${contactId}/contactable_inboxes`);
  }

  getContactLabels(contactId: number) {
    return this.client.get(`${this.url}/${contactId}/labels`);
  }

  updateContactLabels(contactId: number, labels: string[]) {
    return this.client.post(`${this.url}/${contactId}/labels`, { labels });
  }

  search({
    search = '',
    page = 1,
    sortAttr = 'name',
    label = '',
  }: ContactSearchParams) {
    const requestURL = `${this.url}/search?${buildContactParams(
      page,
      sortAttr,
      label,
      search
    )}`;
    return this.client.get(requestURL);
  }

  filter(
    page?: number | undefined,
    sortAttr?: string | undefined,
    queryPayload?: ContactFilterParams | undefined
  ) {
    const requestURL = `${this.url}/filter?${buildContactParams(
      page,
      sortAttr
    )}`;
    return this.client.post(requestURL, queryPayload);
  }

  importContacts(file: File): Promise<unknown> {
    const formData = new FormData();
    formData.append('import_file', file);
    return this.client.post(`${this.url}/import`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
  }

  destroyCustomAttributes(
    contactId: number,
    customAttributes: string[] | Record<string, unknown>
  ) {
    return this.client.post(
      `${this.url}/${contactId}/destroy_custom_attributes`,
      {
        custom_attributes: customAttributes,
      }
    );
  }

  getContact({ id, projectId }: GetContactParams) {
    return this.client.get(`${this.url}/${id}`, {
      params: {
        cognigy_project_id: projectId,
      },
    });
  }

  update(id?: number | string, projectId?: string, data?: unknown) {
    return this.client.patch(
      `${this.url}/${id}?cognigy_project_id=${projectId}`,
      data
    );
  }
}

export default new ContactAPI();
