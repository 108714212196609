<template>
  <iframe
    v-if="modifiedAICopilotWorkspaceUrl"
    :src="modifiedAICopilotWorkspaceUrl"
    class="iframe"
    :class="{
      'expanded-frame': !isConversationsPanelOpen,
    }"
    data-test-id="ai-copilot-workspace-frame"
    allow="clipboard-write"
  />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    aiCopilotWorkspaceUrl: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      isConversationsPanelOpen: 'ui/isConversationsPanelOpen',
    }),
    modifiedAICopilotWorkspaceUrl() {
      if (!this.aiCopilotWorkspaceUrl) return null;

      const url = new URL(this.aiCopilotWorkspaceUrl);
      url.searchParams.append('platform', 'liveAgent');
      return url.href;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'dashboard/assets/scss/woot';
.iframe {
  height: 100%;
  border: none;

  @include breakpoint(large up) {
    min-width: 250px;
  }

  @include breakpoint(xlarge up) {
    min-width: 400px;
  }

  @include breakpoint(xxlarge up) {
    min-width: 450px;
  }

  @include breakpoint(xxxlarge up) {
    min-width: 700px;
  }
}

.iframe.expanded-frame {
  height: 100%;
  border: none;

  @include breakpoint(large up) {
    min-width: 500px;
  }

  @include breakpoint(xlarge up) {
    min-width: 650px;
  }

  @include breakpoint(xxlarge up) {
    min-width: 700px;
  }

  @include breakpoint(xxxlarge up) {
    min-width: 950px;
  }
}
</style>
