<template>
  <div class="agent-table-container">
    <ve-table
      max-height="calc(100vh - 15rem)"
      :fixed-header="true"
      :columns="columns"
      :table-data="tableData"
      :sort-option="sortOption"
      :event-custom-option="eventCustomOption"
    />
    <div v-if="isLoading" class="agents-loader">
      <spinner />
      <span>{{
        $t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.LOADING_MESSAGE')
      }}</span>
    </div>
    <empty-state
      v-else-if="!isLoading && !agentMetrics.length"
      :title="$t('OVERVIEW_REPORTS.AGENT_CONVERSATIONS.NO_AGENTS')"
    />
    <div v-if="agentMetrics.length > 0" class="table-pagination">
      <ve-pagination
        :total="totalAgents"
        :page-index="pageIndex"
        :page-size="25"
        :page-size-option="[25]"
        @on-page-number-change="onPageNumberChange"
      />
    </div>
    <woot-modal
      :show="showStatusModal"
      :on-close="toggleStatusModal"
      class="status-modal"
    >
      <woot-modal-header
        :header-title="agentDataName"
        :header-content="agentDataEmail"
      />
      <div class="status-modal-container">
        <availability-status
          :agent-id="agentDataId"
          :agent-status="agentDataStatus"
        />
      </div>
    </woot-modal>
  </div>
</template>

<script lang="jsx">
import { VeTable, VePagination } from 'vue-easytable';
import Spinner from 'shared/components/Spinner.vue';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import WootThumbnail from 'dashboard/components/widgets/Thumbnail.vue';
import reportMixin from 'dashboard/mixins/reportMixin';
import { mapGetters } from 'vuex';
import AvailabilityStatus from '../../../../../../components/layout/AvailabilityStatus.vue';
import { BUS_EVENTS } from '../../../../../../../shared/constants/busEvents';
import WootModalHeader from '../../../../../../components/ModalHeader.vue';
import WootModal from '../../../../../../components/Modal.vue';

export default {
  name: 'AgentTable',
  components: {
    WootModal,
    WootModalHeader,
    EmptyState,
    Spinner,
    VeTable,
    VePagination,
    AvailabilityStatus,
  },
  mixins: [reportMixin],
  props: {
    totalAgents: {
      type: Number,
      default: 0,
    },
    agentMetrics: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    pageIndex: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      currentSort: {
        fieldName: null,
        type: null,
      },
      sortOption: {
        sortChange: params => {
          const paramKeys = Object.keys(params);
          paramKeys.forEach(key => {
            if (params[key] === 'asc' || params[key] === 'desc') {
              this.currentSort = {
                fieldName: key,
                type: params[key],
              };
            }
          });
        },
      },
      eventCustomOption: {
        bodyRowEvents: ({ row }) => {
          return {
            click: () => {
              this.toggleStatusModal();
              this.agentData = row;
            },
          };
        },
      },
      showStatusModal: false,
      agentData: null,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    tableData() {
      const data = this.agentMetrics.map(agent => {
        return {
          agent: agent.name,
          agentId: agent.id,
          email: agent.email,
          thumbnail: agent.thumbnail,
          open: agent.metric.open || 0,
          queued: agent.metric.queued || 0,
          status: agent.availability,
          avg_first_response_time: this.formatSecondsField(
            agent.metric.avg_first_response_time
          ),
          avg_resolution_time: this.formatSecondsField(
            agent.metric.avg_resolution_time
          ),
          resolved: agent.metric.resolved,
        };
      });

      if (
        this.currentSort.fieldName === null ||
        this.currentSort.type === null
      ) {
        return data;
      }
      return data.sort((daton1, daton2) => {
        if (this.currentSort.type === 'asc') {
          return (
            daton1[this.currentSort.fieldName] <
            daton2[this.currentSort.fieldName]
          );
        }
        return (
          daton1[this.currentSort.fieldName] >
          daton2[this.currentSort.fieldName]
        );
      });
    },
    columns() {
      return [
        {
          field: 'agent',
          key: 'agentName',
          title: this.$t(
            'OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.AGENT'
          ),
          fixed: 'left',
          align: 'left',
          width: 25,
          renderBodyCell: ({ row }) => (
            <div class="row-user-block">
              <WootThumbnail
                src={row.thumbnail}
                size="32px"
                username={row.agent}
                status={row.status}
              />
              <div class="user-block">
                <h6 class="title text-truncate">{row.agent}</h6>
                <span class="sub-title">{row.email}</span>
              </div>
            </div>
          ),
        },
        {
          field: 'status',
          key: 'availability',
          title: this.$t(
            'OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.STATUS'
          ),
          align: 'left',
          width: 10,
          renderBodyCell: ({ row }) => {
            const className = `agent-table-status--${row.status}`;
            const translatedStatus = this.$t(
              `OVERVIEW_REPORTS.AGENT_STATUS.${row.status.toUpperCase()}`
            );
            return <div className={className}>{translatedStatus}</div>;
          },
        },
        {
          field: 'open',
          key: 'open',
          title: this.$t(
            'OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.OPEN'
          ),
          align: 'left',
          width: 10,
          renderBodyCell: ({ row }) => {
            if (row.open > 0) {
              const link = `/app/accounts/${this.accountId}/dashboard?status=open&assignee_id=${row.agentId}`;
              return <a href={link}>{row.open}</a>;
            }
            return row.open;
          },
        },
        {
          field: 'queued',
          key: 'queued',
          title: this.$t(
            'OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.QUEUED'
          ),
          align: 'left',
          width: 10,
          renderBodyCell: ({ row }) => {
            if (row.queued > 0) {
              const link = `/app/accounts/${this.accountId}/dashboard?status=open&assignee_id=${row.agentId}&not_present=first_reply_created_at`;
              return <a href={link}>{row.queued}</a>;
            }
            return row.queued;
          },
        },
        {
          field: 'avg_first_response_time',
          key: 'avg_first_response_time',
          title: this.$t(
            'OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.AVG_FIRST_RESPONSE_TIME'
          ),
          align: 'left',
          width: 10,
        },
        {
          field: 'avg_resolution_time',
          key: 'avg_resolution_time',
          title: this.$t(
            'OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.AVG_RESOLUTION_TIME'
          ),
          align: 'left',
          width: 10,
        },
        {
          field: 'resolved',
          key: 'resolved',
          title: this.$t(
            'OVERVIEW_REPORTS.AGENT_CONVERSATIONS.TABLE_HEADER.RESOLVED'
          ),
          align: 'left',
          width: 10,
        },
      ];
    },
    agentDataId() {
      return this.agentData?.agentId;
    },
    agentDataName() {
      return this.agentData?.agent;
    },
    agentDataEmail() {
      return this.agentData?.email;
    },
    agentDataStatus() {
      return this.agentData?.status;
    },
  },
  mounted() {
    bus.$on(BUS_EVENTS.CLOSE_STATUS_MODAL, () => {
      this.showStatusModal = false;
    });
  },

  methods: {
    onPageNumberChange(pageIndex) {
      this.$emit('page-change', pageIndex);
    },
    toggleStatusModal() {
      this.showStatusModal = !this.showStatusModal;
    },
  },
};
</script>

<style lang="scss" scoped>
.agent-table-container {
  display: flex;
  flex-direction: column;
  flex: 1;

  .ve-table {
    &::v-deep {
      th.ve-table-header-th {
        font-size: var(--font-size-mini) !important;
        padding: var(--space-small) var(--space-two) !important;
      }

      td.ve-table-body-td {
        padding: var(--space-one) var(--space-two) !important;
      }
    }
  }

  &::v-deep .ve-pagination {
    background-color: transparent;
  }

  &::v-deep .ve-pagination-select {
    display: none;
  }

  .table-pagination {
    margin-top: var(--space-normal);
    text-align: right;
  }
}

.agents-loader {
  align-items: center;
  display: flex;
  font-size: var(--font-size-default);
  justify-content: center;
  padding: var(--space-large);
}

.agent-table-status--online {
  color: var(--g-600);
}

.agent-table-status--busy {
  color: var(--r-500);
}

.agent-table-status--offline {
  color: var(--b-600);
}

.agent-table-status--away {
  color: var(--y-700);
}

.status-modal-container {
  padding: 20px;
}
</style>
<style lang="scss">
.status-modal {
  .modal-container {
    width: 40rem;
  }
}
.row-user-block {
  align-items: center;
  display: flex;
  text-align: left;

  .user-block {
    display: flex;
    flex-direction: column;
    min-width: 0;
    .title {
      font-size: var(--font-size-small);
      margin: var(--zero);
      line-height: 1.3;
    }
    .sub-title {
      font-size: var(--font-size-mini);
    }
  }

  .user-thumbnail-box {
    margin-right: var(--space-small);
  }
}
</style>
