import AlertMethods from 'shared/mixins/alertMixin';
import { ApiErrors } from 'shared/constants/errors';
import wootConstants from '../../dashboard/constants';

export default {
  methods: {
    assignAgent(conversationId, agentId) {
      this.$store
        .dispatch('assignAgent', {
          conversationId,
          agentId,
        })
        .then(() => {
          AlertMethods.methods.showAlert(this.$t('CONVERSATION.CHANGE_AGENT'), {
            status: wootConstants.SNACKBAR_TYPE.SUCCESS,
          });
        })
        .catch(error => {
          if (error?.response?.status === 304) {
            AlertMethods.methods.showAlert(
              this.$t('CONVERSATION.CHANGE_AGENT_CONVERSATION_LIMIT_ERROR'),
              {
                status: wootConstants.SNACKBAR_TYPE.ERROR,
              }
            );
          } else if (
            error?.response?.data?.error ===
            ApiErrors.ASSIGNMENT_RESOLVED_CONVERSATION_ERROR
          ) {
            AlertMethods.methods.showAlert(
              this.$t('CONVERSATION.ASSIGNMENT_RESOLVED_CONVERSATION_ERROR'),
              {
                status: wootConstants.SNACKBAR_TYPE.ERROR,
              }
            );
          } else {
            // eslint-disable-next-line no-console
            console.error(error);
            AlertMethods.methods.showAlert(this.$t('ERRORS.UNKNOWN'), {
              status: wootConstants.SNACKBAR_TYPE.ERROR,
            });
          }
        });
    },
  },
};
