<template>
  <div class="CopilotHeader" @click="collapseGroup">
    <p class="aiCopilotTitle">{{ $t('CONVERSATION.ASSIST_TITLE') }}</p>
    <fluent-icon v-if="isCollapsed" icon="chevron-up" class="arrowIcon" />
    <fluent-icon v-else icon="chevron-down" class="arrowIcon" />
  </div>
</template>

<script>
export default {
  props: {
    isCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    collapseGroup() {
      this.$parent.$emit('collapseGroup');
    },
  },
};
</script>

<style lang="scss">
.CopilotHeader {
  display: flex;
  justify-content: space-between;
  transition: background-color 0.3s ease-out;
  &:hover {
    cursor: pointer;
    background-color: #dbe3ec;
    transition: background-color 0.3s ease-out;
  }

  button {
    margin-right: 5px;
  }
}

.aiCopilotTitle {
  margin-top: 10px;
  margin-left: 10px;
  color: #92a3c7;
  transition: height 0.15s ease-out;
}

.arrowIcon {
  margin-right: 10px;
  margin-top: 10px;
}
</style>
