export const filterTypes = [
  {
    attributeKey: 'cognigy_session_id',
    attributeI18nKey: 'COGNIGY_SESSION_ID',
    inputType: 'plain_text',
    dataType: 'text',
    filterOperators: ['equal_to'],
  },
  {
    attributeKey: 'cognigy_user_id',
    attributeI18nKey: 'COGNIGY_USER_ID',
    inputType: 'plain_text',
    dataType: 'text',
    filterOperators: ['equal_to'],
  },
  {
    attributeKey: 'filename',
    attributeI18nKey: 'FILENAME',
    inputType: 'plain_text',
    dataType: 'text',
    filterOperators: ['contains'],
  },
];
