<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('CANNED_MGMT.IMPORT.TITLE')"
        :header-content="$t('CANNED_MGMT.IMPORT.DESC')"
      />
      <form @submit.prevent="importCanned()">
        <div class="import-form">
          <file-upload
            v-if="!hasAttachments"
            ref="upload"
            v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
            :size="4096 * 4096"
            :accept="allowedFileTypes"
            :drop="true"
            :drop-directory="false"
            :data="{
              direct_upload_url: '/rails/active_storage/direct_uploads',
              direct_upload: true,
            }"
            @input-file="onFileUpload"
          >
            <woot-button
              class-names="button--upload"
              color-scheme="secondary"
              variant="smooth"
            >
              {{ $t('CANNED_MGMT.IMPORT.SELECT') }}
            </woot-button>
          </file-upload>
          <div v-if="hasAttachments" class="attachment-box">
            <attachment-preview
              :attachments="attachedFiles"
              :remove-attachment="removeAttachment"
            />
          </div>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="!hasAttachments"
              :button-text="$t('CANNED_MGMT.IMPORT.FORM.SUBMIT')"
              :loading="importCanned.showLoading"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('CANNED_MGMT.IMPORT.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';

import WootSubmitButton from '../../../../components/buttons/FormSubmitButton.vue';
import Modal from '../../../../components/Modal.vue';
import alertMixin from 'shared/mixins/alertMixin';
import wootConstants from '../../../../constants';
import FileUpload from 'vue-upload-component';
import { mapGetters } from 'vuex';
import AttachmentPreview from 'dashboard/components/widgets/AttachmentsPreview.vue';
import { checkFileSizeLimit } from 'shared/helpers/FileHelper';
import { MAXIMUM_FILE_UPLOAD_SIZE } from 'shared/constants/messages';
import { DirectUpload } from 'activestorage';

export default {
  components: {
    WootSubmitButton,
    Modal,
    FileUpload,
    AttachmentPreview,
  },
  mixins: [alertMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      shortCode: '',
      content: '',

      vertical: 'bottom',
      horizontal: 'center',
      addCanned: {
        showLoading: false,
        message: '',
      },
      show: true,
      attachedFiles: [],
    };
  },
  validations: {
    shortCode: {
      required,
      minLength: minLength(2),
    },
    content: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      cannedMessages: 'getCannedResponses',
    }),
    allowedFileTypes() {
      return 'text/csv';
    },
    hasAttachments() {
      return this.attachedFiles.length;
    },
  },
  methods: {
    resetForm() {
      this.shortCode = '';
      this.content = '';
      this.$v.shortCode.$reset();
      this.$v.content.$reset();
    },
    async importCanned() {
      // Show loading on button
      this.importCanned.showLoading = true;
      // Make API Calls

      try {
        await this.$store.dispatch(
          'uploadCannedResponse',
          this.attachedFiles[0]
        );
        this.importCanned.showLoading = false;
        this.showAlert(this.$t('CANNED_MGMT.IMPORT.API.SUCCESS_MESSAGE'), {
          status: wootConstants.SNACKBAR_TYPE.SUCCESS,
        });
        this.resetForm();
        this.$store.dispatch('getCannedResponse');
        this.onClose();
      } catch (error) {
        this.importCanned.showLoading = false;
        this.onClose();
        this.showAlert(this.$t('CANNED_MGMT.IMPORT.API.ERROR_MESSAGE'), {
          status: wootConstants.SNACKBAR_TYPE.ERROR,
        });
      }
    },
    isValidFileType(file) {
      return file.type === 'text/csv';
    },
    onFileUpload(file) {
      const fileIsValid = this.isValidFileType(file);
      if (!fileIsValid) {
        this.showAlert(this.$t('CONVERSATION.UNSUPORTED_FILE_TYPE'), {
          status: wootConstants.SNACKBAR_TYPE.ERROR,
        });
        return;
      }
      this.file = file;
      if (this.globalConfig.directUploadsEnabled) {
        this.onDirectFileUpload(file);
      } else {
        this.onIndirectFileUpload(file);
      }
    },
    onDirectFileUpload(file) {
      if (!file) {
        return;
      }
      if (checkFileSizeLimit(file, MAXIMUM_FILE_UPLOAD_SIZE)) {
        const upload = new DirectUpload(
          file.file,
          `/api/v1/accounts/${this.accountId}/canned_responses/upload`,
          {
            directUploadWillCreateBlobWithXHR: xhr => {
              xhr.setRequestHeader(
                'api_access_token',
                this.currentUser.access_token
              );
            },
          }
        );
        upload.create((error, blob) => {
          if (error) {
            this.showAlert(error, {
              status: wootConstants.SNACKBAR_TYPE.ERROR,
            });
          } else {
            this.attachFile({ file, blob });
          }
        });
      } else {
        this.showAlert(
          this.$t('CONVERSATION.FILE_SIZE_LIMIT', {
            MAXIMUM_FILE_UPLOAD_SIZE,
          }),
          {
            status: wootConstants.SNACKBAR_TYPE.ERROR,
          }
        );
      }
    },
    onIndirectFileUpload(file) {
      if (!file) {
        return;
      }
      if (checkFileSizeLimit(file, MAXIMUM_FILE_UPLOAD_SIZE)) {
        this.attachFile({ file });
      } else {
        this.showAlert(
          this.$t('CONVERSATION.FILE_SIZE_LIMIT', {
            MAXIMUM_FILE_UPLOAD_SIZE,
          }),
          {
            status: wootConstants.SNACKBAR_TYPE.ERROR,
          }
        );
      }
    },
    attachFile({ blob, file }) {
      const reader = new FileReader();
      reader.readAsDataURL(file.file);
      reader.onloadend = () => {
        this.attachedFiles.push({
          resource: blob || file,
          isPrivate: this.isPrivate,
          thumb: reader.result,
          blobSignedId: blob ? blob.signed_id : undefined,
        });
      };
    },
    removeAttachment(itemIndex) {
      this.attachedFiles = this.attachedFiles.filter(
        (item, index) => itemIndex !== index
      );
    },
  },
};
</script>
<style scoped lang="scss">
.import-form {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
</style>
<style lang="scss">
.attachment-box {
  width: 100%;

  .preview-item__wrap {
    .preview-item {
      width: 100%;
    }
  }
}

.file-uploads {
  :hover {
    cursor: pointer;
  }
}
</style>
