<template>
  <div>
    <p aria-hidden="true" class="hide">
      {{ $t('ATTACHMENTS_MGMT.SORT_BY.LABEL') }}
    </p>
    <multi-select
      v-model="currentSelectedSorting"
      class="multiselect-wrapper"
      :placeholder="$t('ATTACHMENTS_MGMT.SORT_BY.LABEL')"
      track-by="id"
      label="name"
      data-testid="sort-by-attachment"
      :options="sortByFilterItems"
      :show-labels="false"
      :allow-empty="false"
      @input="changeSortBySelection"
    />
  </div>
</template>

<script>
import MultiSelect from 'vue-multiselect';

export default {
  components: { MultiSelect },
  props: {
    selectedSortByFilter: {
      type: Object,
      default: () => {},
    },
    sortByFilterItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentSelectedSorting: this.selectedSortByFilter,
    };
  },
  methods: {
    changeSortBySelection() {
      this.$emit('filter-change', this.currentSelectedSorting);
    },
  },
};
</script>

<style>
.multiselect-wrapper {
  margin-bottom: 0;
}
</style>
