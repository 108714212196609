// import groupBy from 'lodash.groupby';

export default {
  methods: {
    setFilterAttributes() {
      const attributeGroups = this.conversationInbox
        ? this.filterAttributeGroups.map(group => {
            return {
              ...group,
              attributes: group.attributes.filter(
                attr => attr.key !== 'inbox_id'
              ),
            };
          })
        : this.filterAttributeGroups;

      const allFilterGroups = attributeGroups.map(group => {
        return {
          name: this.$t(`${this.filtersFori18n}.GROUPS.${group.i18nGroup}`),
          attributes: group.attributes.map(attribute => {
            return {
              key: attribute.key,
              name: this.$t(
                `${this.filtersFori18n}.ATTRIBUTES.${attribute.i18nKey}`
              ),
            };
          }),
        };
      });
      this.filterGroups = allFilterGroups;
    },
  },
};
