<template>
  <form class="contact--form" @submit.prevent="handleSubmit">
    <div class="row">
      <div class="columns">
        <label :class="{ error: $v.email.$error }">
          {{ $t('CONTACT_FORM.FORM.EMAIL_ADDRESS.LABEL') }}
          <input
            v-model.trim="email"
            type="text"
            :placeholder="$t('CONTACT_FORM.FORM.EMAIL_ADDRESS.PLACEHOLDER')"
            data-test-id="contact_form_email_input"
            @input="$v.email.$touch"
          />
        </label>
      </div>
    </div>
    <div class="medium-12 columns">
      <div v-for="(profileValue, profileKey) in profile" :key="profileKey">
        <label>
          {{
            typeof profileValue === 'object' && profileValue.field
              ? profileValue.field
              : $t(mapProfileKeyToHumanString(profileKey, profileValue))
          }}
          <input
            v-if="typeof profileValue === 'object' && profileValue.field"
            v-model="profile[profileKey].value"
            type="text"
            :placeholder="mapProfileKeyToPlaceholder(profileKey)"
            :data-test-id="`contact_form_${profileKey}_input`"
          />
          <input
            v-else-if="profileKey === 'privacy_policy'"
            v-model="profile[profileKey].accepted"
            type="text"
            :placeholder="mapProfileKeyToPlaceholder(profileKey)"
            :data-test-id="`contact_form_${profileKey}_input`"
          />
          <input
            v-else
            v-model="profile[profileKey]"
            type="text"
            :placeholder="mapProfileKeyToPlaceholder(profileKey)"
            :data-test-id="`contact_form_${profileKey}_input`"
          />
        </label>
      </div>
    </div>
    <div class="modal-footer">
      <div class="medium-12 columns">
        <woot-submit-button
          data-test-id="contact_form_submit_button"
          :disabled="isUpdating"
          :loading="inProgress"
          :button-text="$t('CONTACT_FORM.FORM.SUBMIT')"
        />
        <button
          class="button clear"
          :disabled="isUpdating"
          @click.prevent="onCancel"
        >
          {{ $t('CONTACT_FORM.FORM.CANCEL') }}
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import {
  DuplicateContactException,
  ExceptionWithMessage,
} from 'shared/helpers/CustomErrors';
import mapProfileKeyToHumanString from 'shared/helpers/mapProfileKeyToHumanString';
import mapProfileKeyToPlaceholder from 'shared/helpers/mapProfileKeyToPlaceholder';
import capitalize from 'shared/helpers/capitalize';
import wootConstants from '../../../../constants';

export default {
  mixins: [alertMixin],
  props: {
    contact: {
      type: Object,
      default: () => ({}),
    },
    inProgress: {
      type: Boolean,
      default: false,
    },
    isUpdating: {
      type: Boolean,
      default: false,
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      email: '',
      profile: {},
    };
  },
  validations: {
    email: {},
  },

  watch: {
    contact() {
      this.setContactObject();
    },
  },
  mounted() {
    this.setContactObject();
  },
  methods: {
    mapProfileKeyToHumanString,
    mapProfileKeyToPlaceholder,
    onCancel() {
      this.$emit('cancel');
    },
    onSuccess() {
      this.$emit('success');
    },
    toggleIsUpdating(isUpdating) {
      this.$emit('toggleIsUpdating', isUpdating);
    },
    setContactObject() {
      const { email: email, profile = [] } = this.contact;
      this.email = email || '';
      this.profile = {
        ...profile,
        accepted_gdpr: profile.accepted_gdpr ? 'Yes' : 'No',
        privacy_policy: this.setPrivacyPolicy(profile.privacy_policy),
        gender: capitalize(profile.gender),
        goals:
          Array.isArray(profile?.goals) && profile?.goals.length > 0
            ? profile.goals.join(',')
            : profile.goals,
      };
    },
    setPrivacyPolicy(privacyPolicy) {
      if (privacyPolicy.accepted) {
        return {
          accepted: 'Yes',
          meta: {
            liveAgent: {
              timestamp: Date.now(),
            },
          },
        };
      }
      return {
        accepted: 'No',
      };
    },
    getContactObject() {
      return {
        id: this.contact.id,
        profile: {
          ...this.profile,
          email: this.email,
          gender: this.profile.gender.toLowerCase(),
          accepted_gdpr: this.profile.accepted_gdpr.toLowerCase() === 'yes',
          privacy_policy: {
            ...this.profile.privacy_policy,
            accepted:
              this.profile.privacy_policy?.accepted.toLowerCase() === 'yes',
          },
          goals: this.getGoals(),
        },
      };
    },
    getGoals() {
      const goalsInput = this.profile?.goals;

      const goals = Array.isArray(goalsInput)
        ? goalsInput
        : goalsInput.split(',');

      return goals.length === 1 && goals[0] === '' ? [] : goals;
    },
    async handleSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.toggleIsUpdating(true);

      try {
        await this.onSubmit(this.getContactObject());
        this.onSuccess();
        this.showAlert(this.$t('CONTACT_FORM.SUCCESS_MESSAGE'), {
          status: wootConstants.SNACKBAR_TYPE.SUCCESS,
        });
      } catch (error) {
        if (error instanceof DuplicateContactException) {
          if (error.data?.includes('email')) {
            this.showAlert(
              this.$t('CONTACT_FORM.FORM.EMAIL_ADDRESS.DUPLICATE'),
              {
                status: wootConstants.SNACKBAR_TYPE.ERROR,
              }
            );
          } else if (error.data?.includes('phone_number')) {
            this.showAlert(
              this.$t('CONTACT_FORM.FORM.PHONE_NUMBER.DUPLICATE'),
              {
                status: wootConstants.SNACKBAR_TYPE.ERROR,
              }
            );
          }
        } else if (error instanceof ExceptionWithMessage) {
          this.showAlert(error.data, {
            status: wootConstants.SNACKBAR_TYPE.ERROR,
          });
        } else {
          this.showAlert(this.$t('CONTACT_FORM.ERROR_MESSAGE'), {
            status: wootConstants.SNACKBAR_TYPE.ERROR,
          });
        }
      }

      this.toggleIsUpdating(false);
    },
  },
};
</script>

<style scoped lang="scss">
.contact--form {
  padding: var(--space-normal) var(--space-large) var(--space-large);

  .columns {
    padding: 0 var(--space-smaller);
  }
}

.input-group-label {
  font-size: var(--font-size-small);
}
</style>
