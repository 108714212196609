export const channelMappings = {
  'abstract-rest': 'Abstract Rest',
  adminconsole: 'Admin Console',
  'admin-webchat': 'Admin Webchat',
  alexa: 'Alexa',
  avaya: 'Avaya CPaas',
  microsoftBotFramework: 'Azure Bot Services',
  dialogflow: 'Dialogflow',
  facebook: 'Facebook',
  generic: 'Generic',
  google: 'Google Actions',
  inject: 'Inject',
  intercom: 'Intercom',
  line: 'Line',
  microsoftTeams: 'Microsoft Teams',
  nonConversational: 'Non-conversational',
  realtime: 'Realtime',
  rest: 'Rest',
  ringCentralEngage: 'RingCentral Engage',
  slack: 'Slack',
  socket: 'Socket',
  sunshineConversations: 'Sunshine Conversations',
  twilio: 'Twilio',
  'twilio-autopilot': 'Twilio Autopilot',
  'twilio-sms': 'Twilio SMS',
  userlike: 'Userlike',
  audioCodes: 'VoiceGateway',
  webchat: 'Webchat',
  webchat2: 'Webchat 2',
  webhook: 'Webhook',
  whatsapp: 'WhatsApp',
  workplace: 'Workplace',
};

/**
 *
 * @param channelId: string
 * @returns string
 */
export const mapChannelsToHumanReadable = channelId => {
  const humanReadableChannelOption = channelMappings[channelId];

  if (humanReadableChannelOption) {
    return humanReadableChannelOption;
  }
  return channelId;
};
