import ApiClient, { axiosWithAuth } from './ApiClient';

class UserNotificationSettings extends ApiClient {
  constructor() {
    super('notification_settings', { accountScoped: true });
  }

  update(params) {
    return axiosWithAuth.patch(`${this.url}`, params);
  }
}

export default new UserNotificationSettings();
