<template>
  <div class="video message-text__wrap">
    <video
      class="video-message"
      :src="url"
      muted
      playsInline
      crossorigin="anonymous"
      @click="onClick"
    />
    <div class="button-container" @click="onClick">
      <div class="play-button">
        <fluent-icon icon="arrow-chevron-right" />
      </div>
    </div>
    <woot-modal :show.sync="show" :on-close="onClose">
      <video
        crossorigin="anonymous"
        :src="url"
        controls
        playsInline
        class="modal-video"
      />
    </woot-modal>
  </div>
</template>

<script>
import WootModal from 'components/Modal.vue';

export default {
  name: 'BubbleVideo',
  components: { WootModal },
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    onClose() {
      this.show = false;
    },
    onClick() {
      this.show = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.video-message {
  max-width: 35rem;
}

.play-button {
  display: flex;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #c8e4ff;
  background-color: white;
  justify-content: center;
  align-items: center;
  transition: border 0.5s ease;
  svg {
    color: #c8e4ff;
    transition: color 0.5s ease;
  }
}

.button-container {
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 5;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  &:hover {
    svg {
      color: #154194;
    }
    .play-button {
      border: 1px solid #154194;
    }
  }
}
</style>
