<template>
  <div class="cognigy-content-container">
    <div class="message-time-container">
      <div :class="messageData.private && 'is-private-cognigy'">
        <div
          :id="getCognigyTestId"
          :data-test-id="'cognigy_' + cognigyMessageType"
        />
      </div>
      <bubble-actions
        :id="messageData.id"
        :sender="messageData.sender"
        :story-sender="storySender"
        :story-id="storyId"
        :is-private="messageData.private"
        :message-type="messageData.message_type"
        :readable-time="readableTime"
        :source-id="messageData.source_id"
        :inbox-id="messageData.inbox_id"
        :message-read="false"
      />
    </div>
    <div v-if="showStructuredContentAvatar">
      <woot-thumbnail
        v-tooltip="tooltipForSender"
        size="16px"
        :src="sender.thumbnail"
        :username="senderNameForAvatar"
        :class-name="'thumbnail-structured-content'"
        :sent-by-bot="isSentByBot"
      />
    </div>
  </div>
</template>

<script>
import { renderCognigyMessage } from './helpers/renderCognigyMessage.js';
import { MESSAGE_STATUS } from 'shared/constants/messages';
import cognigyStructuredContentMixin from 'shared/mixins/cognigyStructuredContentMixin';
import BubbleActions from './bubble/Actions.vue';

export default {
  components: {
    BubbleActions,
  },
  mixins: [cognigyStructuredContentMixin],
  props: {
    messageData: {
      type: Object,
      required: true,
    },
    readableTime: {
      type: String,
      default: '',
    },
    isSentByBot: {
      type: Boolean,
    },
    isSentByUser: {
      type: Boolean,
    },
    tooltipForSender: {
      type: Object,
      default: null,
    },
    sender: {
      type: Object,
      default: null,
    },
    contentType: {
      type: String,
      default: '',
    },
    senderNameForAvatar: {
      type: String,
      default: '',
    },
    isIncoming: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showStructuredContentAvatar() {
      return this.isSentByBot || this.isSentByUser;
    },
    messageColor() {
      return this.isSentByUser ? '#0070E0' : '#0b3694';
    },
    messageTimeClass() {
      return {
        'message-time-container': true,
        'is-private': this.messageData.private,
      };
    },
    storySender() {
      return this.contentAttributes.story_sender || null;
    },
    storyId() {
      return this.contentAttributes.story_id || null;
    },
  },
  mounted() {
    const isIdAvailable =
      !!this.messageData.inbox_id &&
      !!this.messageData.conversation_id &&
      !!this.messageData.id;

    const isCognigyMessage = isIdAvailable && this.contentType === 'cognigy';
    const messageStatusIsNotProgress =
      this.messageData.status !== MESSAGE_STATUS.PROGRESS;

    if (isCognigyMessage && messageStatusIsNotProgress) {
      this.renderCognigyMessage();
    }
  },
  methods: {
    renderCognigyMessage() {
      renderCognigyMessage(
        this.getCognigyTestId,
        this.contentAttributes,
        this.messageColor,
        'outgoing'
      );
    },
  },
};
</script>

<style lang="scss">
@import 'foundation-sites/scss/foundation';

.cognigy-content-container {
  display: flex;
}

.message-time-container {
  margin-right: 23px;
}

.thumbnail-structured-content {
  position: absolute !important;
  right: 16px;
  bottom: 27px;
}

.sender--info {
  align-items: flex-end;
  color: var(--b-700);
  display: inline-flex;
  padding-left: 0.8rem;
  .sender--available-name {
    font-size: var(--font-size-mini);
    margin-left: var(--space-smaller);
  }
}

.sender-structured-content {
  align-items: flex-end;
  color: var(--b-700);
  display: inline-flex;
  padding: var(--space-smaller) 0;

  .sender--available-name {
    font-size: var(--font-size-mini);
    margin-left: var(--space-smaller);
  }
}

div[data-test-id='cognigy_generic'] .slider {
  background-color: transparent !important;
  height: 100% !important;
}

div[data-test-id='cognigy_generic'] {
  width: 400px;
  @include breakpoint(1400 down) {
    width: 310px;
  }
}

div[data-test-id='cognigy_adaptivecards'] .adaptivecard-wrapper {
  max-width: 400px;
}

.webchat-quick-reply-template-header-message {
  font-size: 1.4rem;
  color: white !important;
  font-family: 'Inter' !important;
}

.webchat-buttons-template-header {
  font-size: 1.4rem;
  color: white !important;
  font-family: 'Inter' !important;
}
.is-private-cognigy {
  background: #fff4da;
  border: 1px solid #ffd984;
  border-radius: 16px 16px 0px;
  padding-top: 7px;
  margin-right: 19px;
}
</style>
