<template>
  <div class="column page-top-bar">
    <img v-if="headerImage" :src="headerImage" alt="No image" />
    <h2 class="page-sub-title" data-test-id="modal-header-title">
      {{ headerTitle }}
    </h2>
    <p v-if="headerContent" class="small-12 column">
      {{ headerContent }}
    </p>
    <p
      v-if="warningMessage"
      class="small-12 column warning"
      data-test-id="cognigy_inbox_warning_message"
    >
      {{ warningMessage }}
    </p>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'WootModalHeader',
  props: {
    headerTitle: {
      type: String,
      default: '',
    },
    headerContent: {
      type: String,
      default: '',
    },
    warningMessage: {
      type: String,
      default: '',
    },
    headerImage: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped>
.warning {
  color: #cc0000;
}
</style>
