import { INotificationsState } from './types';

export const getters = {
  getNotifications($state: INotificationsState) {
    return $state.records;
  },
  getUIFlags($state: { uiFlags: unknown }) {
    return $state.uiFlags;
  },
  getNotification: ($state: INotificationsState) => (id: string | number) => {
    const notification = $state.records[id];
    return notification || {};
  },
  getMeta: ($state: { meta: unknown }) => {
    return $state.meta;
  },
};
