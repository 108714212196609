<template>
  <div
    :id="getCognigyTestId"
    :data-test-id="'agent_assist_' + cognigyMessageType"
  />
</template>

<script>
import { mapGetters } from 'vuex';
import {
  addEventListenersForActionElements,
  addEventListenersToStopGalleryArrowPropagation,
  addEventListenerToOpenImageModal,
  addEventListenerForArrowButtons,
} from './helpers/addEventListeners.js';
import { removeCognigyGalleryEventListeners } from './helpers/removeEventListeners.js';
import { addScalingMarginFix } from './helpers/addScalingMarginFix.js';
import {
  addClassToCognigyGalleryInCopilot,
  addClassToGalleryNextArrowButton,
  addSideBarOpenClass,
} from './helpers/addClassToCognigyGalleryInCopilot.js';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import { renderCognigyMessage } from './helpers/renderCognigyMessage.js';
import cognigyStructuredContentMixin from 'shared/mixins/cognigyStructuredContentMixin';

export default {
  mixins: [cognigyStructuredContentMixin],
  props: {
    messageData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      MessageRenderer: window.MessageRenderer,
      galleryNextArrowClicks: 0,
    };
  },
  computed: {
    ...mapGetters({
      isSideMenuOpen: 'ui/isSideMenuOpen',
    }),
  },
  watch: {
    isSideMenuOpen: {
      immediate: true,
      handler() {
        if (this.cognigyMessageType === 'generic') {
          addSideBarOpenClass({
            isSideMenuOpen: this.isSideMenuOpen,
            elementId: this.getCognigyTestId,
            messageData: this.messageData,
            galleryNextArrowClicks: this.galleryNextArrowClicks,
          });
          addClassToGalleryNextArrowButton(
            this.getCognigyTestId,
            this.galleryNextArrowClicks,
            this.messageData,
            this.isSideMenuOpen
          );
        }
      },
    },
  },
  mounted() {
    this.renderCognigyMessage();
    // In order to get the current Width for adaptive cards margin ratio relationship
    // we use setTimeout, nexttick does not yield the current rendered width after using the "message-renderer"
    setTimeout(() => {
      addScalingMarginFix(
        this.getCognigyTestId,
        this.messageData,
        this.cognigyMessageType
      );
      if (this.cognigyMessageType === 'generic') {
        addSideBarOpenClass({
          isSideMenuOpen: this.isSideMenuOpen,
          elementId: this.getCognigyTestId,
          messageData: this.messageData,
          galleryNextArrowClicks: this.galleryNextArrowClicks,
        });
      }
    }, 0);
  },
  methods: {
    openImageModal(imageUrl) {
      this.$emit('openImageModal', imageUrl);
    },
    renderCognigyMessage() {
      const elementId = this.getCognigyTestId;

      renderCognigyMessage(
        elementId,
        this.contentAttributes,
        '#0b3694',
        'outgoing'
      );

      if (this.cognigyMessageType === 'generic') {
        addClassToCognigyGalleryInCopilot(elementId, this.messageData);
        removeCognigyGalleryEventListeners(elementId);
      }

      this.addEventListeners(elementId);
    },
    addEventListeners(elementId) {
      addEventListenersForActionElements({
        elementId,
        onForwardAssistMessagePostback: this.onForwardAssistMessagePostback,
        messageData: this.messageData,
        type: this.cognigyMessageType,
      });

      if (this.cognigyMessageType === 'generic') {
        addEventListenersToStopGalleryArrowPropagation(
          elementId,
          this.emitStopForwarding
        );
        addEventListenerForArrowButtons(elementId, this.onGalleryArrowClick);
      }
      if (this.cognigyMessageType === 'image') {
        addEventListenerToOpenImageModal(elementId, this.openImageModal);
      }
    },
    onForwardAssistMessagePostback(payload) {
      this.$parent.$parent.$parent.$emit(
        'forwardAssistMessagePostback',
        payload
      );
    },
    emitStopForwarding() {
      bus.$emit(BUS_EVENTS.STOP_FORWARDING);
    },
    onGalleryArrowClick(direction) {
      if (direction === 'next') {
        this.galleryNextArrowClicks += 1;
      } else {
        this.galleryNextArrowClicks -= 1;
      }

      addClassToGalleryNextArrowButton(
        this.getCognigyTestId,
        this.galleryNextArrowClicks,
        this.messageData,
        this.isSideMenuOpen
      );
      addSideBarOpenClass({
        isSideMenuOpen: this.isSideMenuOpen,
        elementId: this.getCognigyTestId,
        messageData: this.messageData,
        galleryNextArrowClicks: this.galleryNextArrowClicks,
      });
    },
  },
};
</script>

<style lang="scss">
@import 'foundation-sites/scss/foundation';
@import 'dashboard/assets/scss/variables';

// Instead of using the zoom property we re-create it with scale for every type
// of structured content
div[data-test-id^='agent_assist_'] .webchat-message-row {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

div[data-test-id='agent_assist_list'] {
  transform: scale(70%);
  margin: 5px 0px -10px 0px;
  transition: transform 0.1s ease-out;

  @include breakpoint(1300 up) {
    :hover > & {
      transform: scale(100%) !important;
      margin: 0px !important;
      transition: transform 0.3s ease-out;
    }
  }
}

div[data-test-id='agent_assist_quick_replies'] {
  transform: scale(70%);
  margin: -10px 0px -20px 0px;
  transition: transform 0.3s ease-out;

  @include breakpoint(1300 up) {
    :hover > & {
      transform: scale(100%) !important;
      margin: 0px !important;
      transition: transform 0.3s ease-out;
    }
  }
}

div[data-test-id='agent_assist_audio'] {
  transform: scale(70%);
  margin: 0px 0px -10px 0px;
  transition: transform 0.3s ease-out;

  @include breakpoint(1300 up) {
    :hover > & {
      transform: scale(100%) !important;
      margin: 0px !important;
      transition: transform 0.3s ease-out;
    }
  }
}

div[data-test-id='agent_assist_generic'] {
  transform: scale(70%);
  transition: transform 0.3s ease-out;
}

div[data-test-id='agent_assist_button_with_text'] {
  transform: scale(70%);
  margin: 0px 0px -20px 0px;
  transition: transform 0.3s ease-out;

  @include breakpoint(1300 up) {
    :hover > & {
      transform: scale(100%) !important;
      margin: 0px !important;
      transition: transform 0.3s ease-out;
    }
  }
}

div[data-test-id='agent_assist_image'] {
  transform: scale(70%);
  margin: -20px 0px -30px 0px;
  transition: transform 0.3s ease-out;

  @include breakpoint(1300 up) {
    :hover > & {
      transform: scale(100%) !important;
      margin: 0px !important;
      transition: transform 0.3s ease-out;
    }
  }
}

div[data-test-id='agent_assist_video'] {
  transform: scale(70%);
  margin: -20px 0px -30px 0px;
  transition: transform 0.3s ease-out;

  @include breakpoint(1300 up) {
    :hover > & {
      transform: scale(100%) !important;
      margin: 0px !important;
      transition: transform 0.3s ease-out;
    }
  }
}

div[data-test-id='agent_assist_adaptivecards'] {
  transform: scale(70%);
  transition: transform 0.3s ease-out;

  @include breakpoint(1300 up) {
    :hover > & {
      transform: scale(100%) !important;
      margin: 0px !important;
      transition: transform 0.3s ease-out;
    }
  }
}
div[data-test-id='agent_assist_adaptivecards'] .adaptivecard-wrapper {
  max-width: 400px;
}

.non-forwardable-adaptive-cards {
  transform: none !important;
  margin: -0px 0px -10px 0px !important;
  width: none !important;
}

// ---------------AGENT ASSIST GALLERIES FIXES----------------- //

// Remove grey background and expand vertical view so we can see the galleries
div[data-test-id='agent_assist_generic'] .slider {
  background-color: transparent !important;
  height: 100% !important;
}

// EXPANDING MECHANISM FOR GALLERIES

// - One card galleries
div[data-test-id='agent_assist_generic'].one-card-ai-copilot-gallery-root {
  @include breakpoint(1300 up) {
    :hover > & {
      transform: scale(90%) !important;

      transition: transform 0.3s ease-out;
    }
  }
}

div[data-test-id='agent_assist_generic'].one-card-gallery-side-menu-open-root {
  @include breakpoint(1300 up) {
    :hover > & {
      transform: scale(90%) !important;

      transition: transform 0.3s ease-out;
    }
  }
}

// - Two cards galleries
div[data-test-id='agent_assist_generic'].two-cards-ai-copilot-gallery-root {
  @include breakpoint(1500 up) {
    :hover > & {
      transform: scale(90%) !important;

      transition: transform 0.3s ease-out;
    }
  }
}

// - Three cards galleries
div[data-test-id='agent_assist_generic'].three-cards-ai-copilot-gallery-root {
  @include breakpoint(1600 up) {
    :hover > & {
      transform: scale(80%) !important;

      transition: transform 0.3s ease-out;
    }
  }
}

// - Multiple cards galleries
// This will make galleries with more than 3 cards not to grow big when small screens,
// this allow us to be able to press the scroll arrows
div[data-test-id='agent_assist_generic'].multiple-cards-gallery-root {
  @include breakpoint(1450 down) {
    :hover > & {
      transform: scale(70%) !important;

      transition: transform 0.3s ease-out;
    }
  }
  @include breakpoint(1600 up) {
    :hover > & {
      transform: scale(80%) !important;

      transition: transform 0.3s ease-out;
    }
  }
}

div[data-test-id='agent_assist_generic']
  [data-cognigy-webchat-root]
  .carousel
  .slider-wrapper.axis-horizontal
  .slider {
  width: 270px;
}

/////// SIZE FIXES FOR EACH TYPE OF GALLERIES DEPENDING ON SCREEN AND NUMBER OF CARDS

// - Two cards galleries
.two-cards-ai-copilot-gallery {
  // We resize and center, expanding the view of the scroller to fit 2 cards
  padding-left: 345px;
  .carousel-root {
    width: 550px;
  }
  // Remove the scrolling arrows since we do not need them
  .carousel-slider > button {
    display: none;
  }
  // By resizing the slider, we increase the size of the 2 cards
  .slider-wrapper.axis-horizontal .slider {
    width: 350px !important;
  }
}

// - Two cards galleries with side menu open
.two-cards-ai-copilot-gallery-side-menu-open {
  // We use the width to center the element when the side menu is open
  width: 550px;

  @include breakpoint(1600 down) {
    // we reduce the width to make it fit in a smaller area and center it
    width: 280px;

    // Since it is not shown complete, we need to show the scroll arrow again
    .carousel-slider > button {
      display: block;
    }

    // Adjust the positioning of the arrows
    .control-next.control-arrow {
      right: 330px !important;
    }

    .control-previous.control-arrow {
      left: 20px !important;
    }
  }

  @include breakpoint(1300 down) {
    // We use the width to center the element when the side menu is open
    width: 270px;

    // Since it is not shown complete, we need to show the scroll arrow again
    .carousel-slider > button {
      display: block;
    }

    // Adjust the positioning of the arrows
    .control-next.control-arrow {
      right: 340px !important;
    }

    .control-previous.control-arrow {
      left: 20px !important;
    }

    // We reduce size and separation of the cards so they can be completely seen when scrolled
    .slider-wrapper.axis-horizontal .slider {
      width: 310px !important;
    }
  }
}

// - Three cards galleries
.three-cards-ai-copilot-gallery {
  // We center the view of the scroller to fit 3 cards
  padding-left: 255px;

  // Remove the scrolling arrows since we do not need them
  .carousel-slider > button {
    display: none;
  }
}

// - Three cards galleries with side menu open
.three-cards-ai-copilot-gallery-side-menu-open {
  padding-left: 0 !important;

  .carousel-slider > button {
    display: none;
  }

  @include breakpoint(1600 down) {
    // We use the width to center the element when the side menu is open
    width: 270px !important;

    // Since it is not shown complete, we need to show the scroll arrow again
    .carousel-slider > button {
      display: block !important;
    }

    // Adjust the positioning of the arrows
    .control-next.control-arrow {
      right: 310px !important;
    }

    .control-previous.control-arrow {
      left: 20px !important;
    }
  }

  @include breakpoint(1300 down) {
    // Adjust the positioning of the arrows
    .control-next.control-arrow {
      right: 340px !important;
    }
  }
}

// - Multiple cards galleries
.multiple-cards-ai-copilot-gallery {
  // We center the view of the scroller to be aligned with the other galleries
  padding-left: 205px;
}

// - Multiple cards galleries with side menu open
.multiple-cards-ai-copilot-gallery-side-menu-open {
  @include breakpoint(1600 down) {
    // We use the width to center the element when the side menu is open
    width: 270px !important;

    // Since it is not shown complete, we need to show the scroll arrow again
    .carousel-slider > button {
      display: block !important;
    }

    // Adjust the positioning of the arrows
    .control-next.control-arrow {
      right: 310px !important;
    }

    .control-previous.control-arrow {
      left: 20px !important;
    }
  }

  @include breakpoint(1300 down) {
    // Adjust the positioning of the arrows
    .control-next.control-arrow {
      right: 340px !important;
    }
  }
}

.hide-control-arrow {
  display: none;
}
.arrow-side-menu-open {
  display: block !important;
  @include breakpoint(xlargeHD up) {
    display: none !important;
  }
}
div[data-test-id='agent_assist_generic']
  .three-cards-ai-copilot-gallery
  > div
  > div {
  width: 640px;
}

div[data-test-id='agent_assist_generic']
  .multiple-cards-ai-copilot-gallery
  > div
  > div {
  width: 680px;
}

div[data-test-id='agent_assist_generic'] .gallery-side-menu-open > div > div {
  width: 380px !important;
  @include breakpoint(xlargeHD up) {
    width: 680px !important;
  }
}

.ac-pushButton {
  background-color: transparent;
  border: 1px solid #2c6caf;
  color: #2c6caf;
  cursor: pointer;
  height: 40px;
  padding: 8px 16px;
  border-radius: 16px;
  font-size: 1.5em;
}
</style>
