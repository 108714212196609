const state = {
  isSideMenuOpen: false,
  isConversationScrollbarAtBottom: true,
  isConversationsPanelOpen: true,
};

export const getters = {
  isSideMenuOpen: $state => {
    return $state.isSideMenuOpen;
  },

  isConversationScrollBarAtBottom: $state => {
    return $state.isConversationScrollBarAtBottom;
  },

  isConversationsPanelOpen: $state => {
    return $state.isConversationsPanelOpen;
  },
};

export const types = {
  TOGGLE_SIDEMENU_STATUS: 'TOGGLE_SIDEMENU_STATUS',
  SET_SCROLLBAR_IS_IN_BOTTOM: 'SET_SCROLLBAR_IS_IN_BOTTOM',
  CLOSE_PANELS_STATUS: 'CLOSE_PANELS_STATUS',
  OPEN_PANELS_STATUS: 'OPEN_PANELS_STATUS',
};

export const actions = {
  toggleSideMenuStatus({ commit }, { status }) {
    commit(types.TOGGLE_SIDEMENU_STATUS, status);
  },
  closeSidePanels({ commit }) {
    commit(types.CLOSE_PANELS_STATUS);
  },
  openSidePanels({ commit }) {
    commit(types.OPEN_PANELS_STATUS);
  },
  setConversationScrollbarAtBottomStatus({ commit }, status) {
    commit(types.SET_SCROLLBAR_IS_IN_BOTTOM, status);
  },
};

export const mutations = {
  [types.TOGGLE_SIDEMENU_STATUS]($state, status) {
    $state.isSideMenuOpen = status;
  },
  [types.CLOSE_PANELS_STATUS]($state) {
    $state.isSideMenuOpen = false;
    $state.isConversationsPanelOpen = false;
  },
  [types.OPEN_PANELS_STATUS]($state) {
    $state.isSideMenuOpen = true;
    $state.isConversationsPanelOpen = true;
  },
  [types.SET_SCROLLBAR_IS_IN_BOTTOM]($state, status) {
    $state.isConversationScrollbarAtBottom = status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
