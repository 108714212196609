<template>
  <div
    id="aiCopilotMessage"
    class="container"
    :class="{ display: !showPopover }"
  />
</template>

<script>
import { renderCognigyMessage } from './helpers/renderCognigyMessage.js';

export default {
  props: {
    showPopover: {
      type: Boolean,
      default: false,
    },
    messageData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return { MessageRenderer: window.MessageRenderer };
  },
  computed: {
    contentAttributes() {
      return this.messageData.content_attributes || {};
    },
  },
  watch: {
    messageData() {
      this.renderCognigyMessage();
    },
  },
  mounted() {
    this.renderCognigyMessage();
  },
  methods: {
    renderCognigyMessage() {
      renderCognigyMessage(
        'aiCopilotMessage',
        this.contentAttributes,
        '#0070E0',
        'incoming'
      );
    },
  },
};
</script>

<style scoped>
.container {
  background-color: #f1f5f8;
  border: 1px solid #ebf0f5;
  border-radius: 8px;
  position: absolute;
  bottom: 100px;
  left: 20px;
  padding: 30px 0 30px 30px;
  max-width: 700px;
  z-index: 2;
}

.display {
  display: none;
}
</style>
<style lang="scss">
#aiCopilotMessage .slider {
  background-color: transparent !important;
  height: 100% !important;

  .slide {
    min-width: 40% !important;
  }
}

#aiCopilotMessage .adaptivecard-wrapper {
  max-width: 400px;
}
</style>
