// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { ExceptionWithMessage } from 'shared/helpers/CustomErrors';
import types from '../../mutation-types';
import ContactAPI from '../../../api/contacts';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import AccountActionsAPI from '../../../api/accountActions';
import { ActionContext } from 'vuex';
import {
  ContactSearchPayload,
  ContactQueryPayload,
  ContactStoreState,
  ContactDeleteCustomAttributesPayload,
  ContactMergePayload,
  ContactFilter,
  ContactUpdatePayload,
} from './types/contactStore.types';
import { isAxiosError } from '../../utils/error.helper';
import { Contact } from './types/contact.types';
import {
  ContactableInboxResponse,
  ContactSearchParams,
} from '../../../api/types/contactsClient.types';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { DuplicateContactException } from '../../../../shared/helpers/CustomErrors';

export const actions = {
  search: async (
    { commit }: ActionContext<ContactStoreState, ContactStoreState>,
    { search, page, sortAttr, label }: ContactSearchParams
  ) => {
    commit(types.SET_CONTACT_UI_FLAG, { isFetching: true });
    try {
      const {
        data: { payload, meta },
      } = await ContactAPI.search({ search, page, sortAttr, label });
      commit(types.CLEAR_CONTACTS);
      commit(types.SET_CONTACTS, payload);
      commit(types.SET_CONTACT_META, meta);
      commit(types.SET_CONTACT_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_CONTACT_UI_FLAG, { isFetching: false });
    }
  },

  get: async (
    { commit }: ActionContext<ContactStoreState, ContactStoreState>,
    { page = 1, sortAttr, label }: ContactSearchParams = {}
  ) => {
    commit(types.SET_CONTACT_UI_FLAG, { isFetching: true });
    try {
      const {
        data: { payload, meta },
      } = await ContactAPI.get(page, sortAttr, label);
      commit(types.CLEAR_CONTACTS);
      commit(types.SET_CONTACTS, payload);
      commit(types.SET_CONTACT_META, meta);
      commit(types.SET_CONTACT_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_CONTACT_UI_FLAG, { isFetching: false });
    }
  },

  show: async (
    { commit }: ActionContext<ContactStoreState, ContactStoreState>,
    { id, projectId }: ContactQueryPayload
  ) => {
    commit(types.SET_CONTACT_UI_FLAG, { isFetchingItem: true });
    try {
      const response = await ContactAPI.getContact({ id, projectId });
      commit(types.SET_CONTACT_ITEM, response.data.payload);
      commit(types.SET_CONTACT_UI_FLAG, {
        isFetchingItem: false,
      });
    } catch (error) {
      commit(types.SET_CONTACT_UI_FLAG, {
        isFetchingItem: false,
      });
    }
  },

  update: async (
    { commit }: ActionContext<ContactStoreState, ContactStoreState>,
    { id, projectId, ...updateObj }: ContactUpdatePayload
  ) => {
    commit(types.SET_CONTACT_UI_FLAG, { isUpdating: true });
    try {
      const response = await ContactAPI.update(id, projectId, updateObj);
      commit(types.EDIT_CONTACT, response.data.payload);
      commit(types.SET_CONTACT_UI_FLAG, { isUpdating: false });
    } catch (error) {
      commit(types.SET_CONTACT_UI_FLAG, { isUpdating: false });
      if (!isAxiosError(error)) {
        throw new Error(`${error}`);
      }

      if (error.response?.status === 422) {
        throw new DuplicateContactException(error.response.data.attributes);
      } else {
        throw new ExceptionWithMessage(error.response?.data.message);
      }
    }
  },

  create: async (
    { commit }: ActionContext<ContactStoreState, ContactStoreState>,
    userObject: Contact
  ) => {
    commit(types.SET_CONTACT_UI_FLAG, { isCreating: true });
    try {
      const response = await ContactAPI.create(userObject);
      commit(types.SET_CONTACT_ITEM, response.data.payload.contact);
      commit(types.SET_CONTACT_UI_FLAG, { isCreating: false });
    } catch (error) {
      commit(types.SET_CONTACT_UI_FLAG, { isCreating: false });
      if (isAxiosError(error)) {
        throw new ExceptionWithMessage(error.response?.data.message);
      } else {
        throw new Error(error as string);
      }
    }
  },
  import: async (
    { commit }: ActionContext<ContactStoreState, ContactStoreState>,
    file: File
  ) => {
    commit(types.SET_CONTACT_UI_FLAG, { isCreating: true });
    try {
      await ContactAPI.importContacts(file);
      commit(types.SET_CONTACT_UI_FLAG, { isCreating: false });
    } catch (error) {
      commit(types.SET_CONTACT_UI_FLAG, { isCreating: false });
      if (isAxiosError(error)) {
        throw new ExceptionWithMessage(error.response?.data.message);
      }
    }
  },
  delete: async (
    { commit }: ActionContext<ContactStoreState, ContactStoreState>,
    id: number
  ) => {
    commit(types.SET_CONTACT_UI_FLAG, { isDeleting: true });
    try {
      await ContactAPI.delete(id);
      commit(types.SET_CONTACT_UI_FLAG, { isDeleting: false });
    } catch (error) {
      commit(types.SET_CONTACT_UI_FLAG, { isDeleting: false });
      if (isAxiosError(error)) {
        throw new Error(error.response?.data.message);
      } else {
        throw new Error(error as string);
      }
    }
  },

  deleteCustomAttributes: async (
    { commit }: ActionContext<ContactStoreState, ContactStoreState>,
    { id, customAttributes }: ContactDeleteCustomAttributesPayload
  ) => {
    try {
      const response = await ContactAPI.destroyCustomAttributes(
        id,
        customAttributes
      );
      commit(types.EDIT_CONTACT, response.data.payload);
    } catch (error) {
      throw new Error(error as string);
    }
  },

  fetchContactableInbox: async (
    { commit }: ActionContext<ContactStoreState, ContactStoreState>,
    id: number
  ) => {
    commit(types.SET_CONTACT_UI_FLAG, { isFetchingInboxes: true });
    try {
      const response: ContactableInboxResponse =
        await ContactAPI.getContactableInboxes(id);
      const contact: Contact = {
        id,
        contactableInboxes: response.data.payload,
      };
      commit(types.SET_CONTACT_ITEM, contact);
    } catch (error) {
      if (isAxiosError(error)) {
        throw new ExceptionWithMessage(error.response?.data.message);
      } else {
        throw new Error(error as string);
      }
    } finally {
      commit(types.SET_CONTACT_UI_FLAG, { isFetchingInboxes: false });
    }
  },

  updatePresence: (
    { commit }: ActionContext<ContactStoreState, ContactStoreState>,
    data: Record<number, string>
  ) => {
    commit(types.UPDATE_CONTACTS_PRESENCE, data);
  },

  setContact(
    { commit }: ActionContext<ContactStoreState, ContactStoreState>,
    data: Contact
  ) {
    commit(types.SET_CONTACT_ITEM, data);
  },

  merge: async (
    { commit }: ActionContext<ContactStoreState, ContactStoreState>,
    { childId, parentId }: ContactMergePayload
  ) => {
    commit(types.SET_CONTACT_UI_FLAG, { isMerging: true });
    try {
      const response = await AccountActionsAPI.merge(parentId, childId);
      commit(types.SET_CONTACT_ITEM, response.data);
    } catch (error) {
      throw new Error(error as string);
    } finally {
      commit(types.SET_CONTACT_UI_FLAG, { isMerging: false });
    }
  },

  deleteContactThroughConversations: (
    { commit }: ActionContext<ContactStoreState, ContactStoreState>,
    id: number
  ) => {
    commit(types.DELETE_CONTACT, id);
    commit(types.CLEAR_CONTACT_CONVERSATIONS, id, { root: true });
    commit(`contactConversations/${types.DELETE_CONTACT_CONVERSATION}`, id, {
      root: true,
    });
  },

  updateContact: async (
    { commit }: ActionContext<ContactStoreState, ContactStoreState>,
    updateObj: Contact
  ) => {
    commit(types.SET_CONTACT_UI_FLAG, { isUpdating: true });
    try {
      commit(types.EDIT_CONTACT, updateObj);
      commit(types.SET_CONTACT_UI_FLAG, { isUpdating: false });
    } catch (error) {
      commit(types.SET_CONTACT_UI_FLAG, { isUpdating: false });
    }
  },

  filter: async (
    { commit }: ActionContext<ContactStoreState, ContactStoreState>,
    { page = 1, sortAttr, queryPayload }: ContactSearchPayload = {}
  ) => {
    commit(types.SET_CONTACT_UI_FLAG, { isFetching: true });
    try {
      const {
        data: { payload, meta },
      } = await ContactAPI.filter(page, sortAttr, queryPayload);
      commit(types.CLEAR_CONTACTS);
      commit(types.SET_CONTACTS, payload);
      commit(types.SET_CONTACT_META, meta);
      commit(types.SET_CONTACT_UI_FLAG, { isFetching: false });
    } catch (error) {
      commit(types.SET_CONTACT_UI_FLAG, { isFetching: false });
    }
  },

  setContactFilters(
    { commit }: ActionContext<ContactStoreState, ContactStoreState>,
    data: ContactFilter[]
  ) {
    commit(types.SET_CONTACT_FILTERS, data);
  },

  clearContactFilters({
    commit,
  }: ActionContext<ContactStoreState, ContactStoreState>) {
    commit(types.CLEAR_CONTACT_FILTERS);
  },
};
