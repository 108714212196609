<template>
  <div class="column content-box">
    <woot-modal-header
      :header-title="$t('LABEL_MGMT.ADD.TITLE')"
      :header-content="$t('LABEL_MGMT.ADD.DESC')"
    />
    <form class="row" @submit.prevent="addLabel">
      <woot-input
        v-model.trim="title"
        :class="{ error: $v.title.$error }"
        class="medium-12 columns"
        :label="$t('LABEL_MGMT.FORM.NAME.LABEL')"
        :placeholder="$t('LABEL_MGMT.FORM.NAME.PLACEHOLDER')"
        :error="getLabelTitleErrorMessage"
        :data-test-id="'label_title_input'"
        @input="$v.title.$touch"
      />

      <woot-input
        v-model.trim="description"
        :class="{ error: $v.description.$error }"
        class="medium-12 columns"
        :label="$t('LABEL_MGMT.FORM.DESCRIPTION.LABEL')"
        :placeholder="$t('LABEL_MGMT.FORM.DESCRIPTION.PLACEHOLDER')"
        :data-test-id="'label_description_input'"
        @input="$v.description.$touch"
      />

      <div class="medium-12 columns">
        <label :class="{ error: $v.ownerType.$error }" for="scope">{{
          $t('LABEL_MGMT.FORM.LEVEL.LABEL')
        }}</label>
        <select
          id="scope"
          v-model.trim="ownerType"
          :class="{ error: $v.ownerType.$error }"
          :data-test-id="'label_level_select'"
        >
          <option value="Account">
            {{ $t('LABEL_MGMT.FORM.LEVEL.OPTIONS.ACCOUNT') }}
          </option>
          <option value="Team">
            {{ $t('LABEL_MGMT.FORM.LEVEL.OPTIONS.TEAM') }}
          </option>
          <option value="Inbox">
            {{ $t('LABEL_MGMT.FORM.LEVEL.OPTIONS.INBOX') }}
          </option>
        </select>
      </div>

      <div v-if="isTeamOwner" class="medium-12 columns">
        <label for="team">{{ $t('LABEL_MGMT.FORM.TEAM.LABEL') }}</label>
        <select id="team" v-model.trim="ownerId" :data-test-id="'select_team'">
          <option
            v-for="(team, index) in teamsList"
            :key="index"
            :value="team.id"
            :data-test-id="'team_option_' + team.id"
          >
            {{ team.name }}
          </option>
        </select>
      </div>

      <div v-if="isInboxOwner" class="medium-12 columns">
        <label for="inbox">{{ $t('LABEL_MGMT.FORM.INBOX.LABEL') }}</label>
        <select
          id="inbox"
          v-model.trim="ownerId"
          :data-test-id="'select_inbox'"
        >
          <option
            v-for="(inbox, index) in inboxesList"
            :key="index"
            :value="inbox.id"
            :data-test-id="'inbox_option_' + inbox.id"
          >
            {{ inbox.name }}
          </option>
        </select>
      </div>

      <div class="medium-12">
        <label>
          {{ $t('LABEL_MGMT.FORM.COLOR.LABEL') }}
          <woot-color-picker v-model="color" />
        </label>
      </div>
      <div class="medium-12">
        <input v-model="showOnSidebar" type="checkbox" :value="true" />
        <label for="conversation_creation">
          {{ $t('LABEL_MGMT.FORM.SHOW_ON_SIDEBAR.LABEL') }}
        </label>
      </div>
      <div class="modal-footer">
        <div class="medium-12 columns">
          <woot-button
            :is-disabled="$v.title.$invalid || uiFlags.isCreating"
            :is-loading="uiFlags.isCreating"
            :data-test-id="'create_label'"
          >
            {{ $t('LABEL_MGMT.FORM.CREATE') }}
          </woot-button>
          <woot-button class="button clear" @click.prevent="onClose">
            {{ $t('LABEL_MGMT.FORM.CANCEL') }}
          </woot-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import validationMixin from './validationMixin';
import { mapGetters } from 'vuex';
import validations from './validations';
import nameValidations from '../helpers/nameValidations';
import wootConstants from '../../../../constants';
import WootButton from '../../../../../shared/components/Button.vue';
import WootModalHeader from '../../../../components/ModalHeader.vue';
import WootInput from '../../../../components/widgets/forms/Input.vue';

export default {
  components: { WootInput, WootModalHeader, WootButton },
  mixins: [alertMixin, validationMixin],
  data() {
    return {
      color: '#000',
      description: '',
      title: '',
      showOnSidebar: true,
      ownerType: 'Inbox',
      ownerId: null,
    };
  },
  validations,
  nameValidations,
  computed: {
    ...mapGetters({
      uiFlags: 'labels/getUIFlags',
      teamsList: 'teams/getTeams',
      inboxesList: 'inboxes/getInboxes',
    }),
    isTeamOwner() {
      return this.ownerType === 'Team';
    },
    isInboxOwner() {
      return this.ownerType === 'Inbox';
    },
  },
  mounted() {
    this.color = this.getRandomColor();
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    getRandomColor() {
      const letters = '0123456789ABCDEF';
      let color = '#';
      for (let i = 0; i < 6; i += 1) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    async addLabel() {
      try {
        await this.$store.dispatch('labels/create', {
          color: this.color,
          description: this.description,
          title: this.title,
          show_on_sidebar: this.showOnSidebar,
          owner_type: this.ownerType,
          owner_id: this.ownerId,
        });
        this.showAlert(this.$t('LABEL_MGMT.ADD.API.SUCCESS_MESSAGE'), {
          status: wootConstants.SNACKBAR_TYPE.SUCCESS,
        });
        this.onClose();
      } catch (error) {
        let errorMessage = '';
        let snackbarType = wootConstants.SNACKBAR_TYPE.ERROR;

        switch (error.message) {
          case 'Title must be unique':
            errorMessage = this.$t(
              'LABEL_MGMT.ADD.API.ERROR_MESSAGE_TITLE_TAKEN'
            );
            break;
          case 'Inbox ID is required':
            errorMessage = this.$t('LABEL_MGMT.ADD.API.INBOX_ID_REQUIRED');
            break;
          default:
            errorMessage = this.$t('LABEL_MGMT.ADD.API.ERROR_MESSAGE');
        }

        this.showAlert(errorMessage, { status: snackbarType });
      }
    },
  },
};
</script>
