<template>
  <div class="conversation-details-wrap">
    <conversation-header
      v-if="currentChat.id"
      :chat="currentChat"
      :is-contact-panel-open="isContactPanelOpen"
      @contact-panel-toggle="onToggleContactPanel"
    />
    <woot-tabs
      v-if="dashboardApps.length && currentChat.id"
      :index="activeIndex"
      class="dashboard-app--tabs"
      @change="onDashboardAppTabChange"
    >
      <woot-tabs-item
        v-for="tab in dashboardAppTabs"
        :key="tab.key"
        :name="tab.name"
        :show-badge="false"
      />
    </woot-tabs>
    <div class="conversations-toggle__wrap">
      <woot-button
        variant="smooth"
        size="tiny"
        color-scheme="secondary"
        class="sidebar-toggle--button"
        :icon="arrowPosition"
        data-test-id="conversation_sidebar_toggle_button"
        @click="onToggleConversationPanel"
      />
    </div>
    <div v-if="!activeIndex" class="messages-and-sidebar">
      <messages-view
        v-if="currentChat.id"
        :inbox-id="inboxId"
        :is-contact-panel-open="isContactPanelOpen"
        :is-conversation-panel-open="isConversationsPanelOpen"
        :show-forward-context-menu-option="showForwardContextMenuOption"
        @contact-panel-toggle="onToggleContactPanel"
      />
      <empty-state v-else />
      <div v-if="showAICopilotWorkspace" class="iframe-container">
        <CopilotWorkspaceIframe
          :ai-copilot-workspace-url="cognigy_agent_assist_workspace_url"
        />
      </div>
      <div v-show="showContactPanel" class="conversation-sidebar-wrap">
        <div class="tab--chat-type">
          <woot-tabs :index="activeTabIndex" @change="onTabChange">
            <woot-tabs-item
              v-for="item in sidePanelTabItems"
              :key="item.key"
              :name="item.name"
              :count="messagesCount"
              :show-badge="item.key === 'assist'"
              :data-test-id="`sidebar_tabs_${item.name}`"
            />
          </woot-tabs>
        </div>
        <agent-copilot-bot-container
          v-if="showContactPanel && activeTabIndex === 1"
          :inbox-id="currentChat.inbox_id"
          :selected-assist-flow="selectedAssistFlow"
          :session-id="sessionId"
          @setSelectedAssistFlow="setSelectedAssistFlow"
          @setSessionId="setSessionId"
          @setMessagesCount="setMessagesCount"
        />
        <contact-panel
          v-if="showContactPanel && activeTabIndex === 0"
          :conversation-id="currentChat.id"
          :cognigy-session-id="currentChat.cognigy_session_id"
          :cognigy-user-id="cognigy_user_id"
          :cognigy-channel-type="cognigy_channel_type"
          :inbox-id="currentChat.inbox_id"
        />
      </div>
    </div>
    <dashboard-app-frame
      v-else
      :key="currentChat.id"
      :config="dashboardApps[activeIndex - 1].content"
      :current-chat="currentChat"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import ContactPanel from 'dashboard/routes/dashboard/conversation/ContactPanel.vue';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import ConversationHeader from './ConversationHeader.vue';
import DashboardAppFrame from '../DashboardApp/Frame.vue';
import EmptyState from './EmptyState.vue';
import MessagesView from './MessagesView.vue';
import CopilotWorkspaceIframe from './CopilotWorkspaceIframe.vue';
import wootConstants from '../../../constants.js';
import AgentCopilotBotContainer from '../../agentCopilotBot/AgentCopilotBotContainer.vue';

export default {
  components: {
    AgentCopilotBotContainer,
    CopilotWorkspaceIframe,
    ContactPanel,
    ConversationHeader,
    DashboardAppFrame,
    EmptyState,
    MessagesView,
  },
  mixins: [uiSettingsMixin],
  props: {
    inboxId: {
      type: [Number, String],
      default: '',
      required: false,
    },
    isContactPanelOpen: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      activeIndex: 0,
      activeTab: wootConstants.SIDE_PANEL_TAB.DETAILS,
      selectedAssistFlow: {},
      sessionId: '',
      messagesCount: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      globalConfig: 'globalConfig/get',
      dashboardApps: 'dashboardApps/getRecords',
      isConversationsPanelOpen: 'ui/isConversationsPanelOpen',
    }),
    dashboardAppTabs() {
      return [
        {
          key: 'messages',
          name: this.$t('CONVERSATION.DASHBOARD_APP_TAB_MESSAGES'),
        },
        ...this.dashboardApps.map(dashboardApp => ({
          key: `dashboard-${dashboardApp.id}`,
          name: dashboardApp.title,
        })),
      ];
    },
    showContactPanel() {
      return this.isContactPanelOpen && this.currentChat.id;
    },
    sidePanelTabItems() {
      const tabsArray = [...this.$t('SIDE_PANEL_TABS')];

      if (this.showAICopilotWorkspace) {
        const count = 0;
        const tab = {
          key: tabsArray[0].KEY,
          name: tabsArray[0].NAME,
          count,
        };
        return [tab];
      }
      return tabsArray.map(item => {
        const count = 0;
        const tab = {
          key: item.KEY,
          name: item.NAME,
          count,
        };
        return tab;
      });
    },
    activeTabIndex() {
      return this.sidePanelTabItems.findIndex(
        item => item.key === this.activeTab
      );
    },
    showForwardContextMenuOption() {
      return this.isContactPanelOpen && this.activeTab === 'assist';
    },
    cognigy_channel_type() {
      return this.currentChat.custom_attributes?.cognigy_channel_type || null;
    },

    cognigy_user_id() {
      return this.currentChat.meta?.sender?.cognigy_user_id || null;
    },
    arrowPosition() {
      if (this.isConversationsPanelOpen) {
        return 'arrow-chevron-left';
      }
      return 'arrow-chevron-right';
    },
  },
  watch: {
    'currentChat.inbox_id'(inboxId) {
      if (inboxId) {
        this.$store.dispatch('inboxAssignableAgents/fetch', [inboxId]);
      }
    },
    'currentChat.id'() {
      this.fetchLabels();
    },
    $route() {
      this.selectedAssistFlow = {};
      this.sessionId = '';
    },
  },
  mounted() {
    this.fetchLabels();
  },
  methods: {
    fetchLabels() {
      if (!this.currentChat.id) {
        return;
      }
      this.$store.dispatch('conversationLabels/get', this.currentChat.id);
    },
    onToggleContactPanel() {
      this.$emit('contact-panel-toggle');
    },
    onTabChange(selectedTabIndex) {
      if (this.sidePanelTabItems[selectedTabIndex].key !== this.activeTab) {
        this.activeTab = this.sidePanelTabItems[selectedTabIndex].key;
      }
    },
    setSelectedAssistFlow(flow) {
      this.selectedAssistFlow = flow;
      this.setSessionId();
    },
    setSessionId() {
      this.sessionId = uuidv4();
    },
    setMessagesCount(count) {
      this.messagesCount = count;
    },
    onDashboardAppTabChange(index) {
      this.activeIndex = index;
    },
    onToggleConversationPanel() {
      if (this.isConversationsPanelOpen) {
        this.$store.dispatch('ui/closeSidePanels');
      } else {
        this.$store.dispatch('ui/openSidePanels');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'dashboard/assets/scss/woot';

.conversation-details-wrap {
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 100%;
  border-left: 1px solid var(--color-border);
  background: var(--color-background-light);
}

.dashboard-app--tabs {
  background: var(--white);
  margin-top: -1px;
}

.messages-and-sidebar {
  display: flex;
  background: var(--color-background-light);
  margin: 0;
  height: 100%;
  min-height: 0;
}

.conversation-sidebar-wrap {
  height: auto;
  flex: 0 0;
  overflow: hidden;
  overflow: auto;
  background: white;
  flex-basis: 28rem;
  padding-top: 17px;
  display: flex;
  flex-direction: column;

  @include breakpoint(large up) {
    flex-basis: 30em;
  }

  @include breakpoint(xlarge up) {
    flex-basis: 31em;
  }

  @include breakpoint(xxlarge up) {
    flex-basis: 33rem;
  }

  @include breakpoint(xxxlarge up) {
    flex-basis: 40rem;
  }

  &::v-deep .contact--panel {
    width: 100%;
    max-width: 100%;
    flex: 1;
  }
}

.conversations-toggle__wrap {
  display: flex;
  justify-content: flex-start;

  .sidebar-toggle--button {
    position: fixed;

    top: var(--space-mega);
    z-index: var(--z-index-low);

    background: var(--white);

    padding: inherit 0;
    border-top-right-radius: calc(
      var(--space-medium) + 1px
    ); /* 100px of height + 10px of border */
    border-bottom-right-radius: calc(
      var(--space-medium) + 1px
    ); /* 100px of height + 10px of border */
    border: 1px solid var(--color-border-light);
    border-left: 0;
    box-sizing: border-box;
  }
}
</style>
