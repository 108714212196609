import { frontendURL } from '../../../helper/URLHelper';
import account from './account/account.routes';
import agent from './agents/agent.routes';
import canned from './canned/canned.routes';
import inbox from './inbox/inbox.routes';
import labels from './labels/labels.routes';
import profile from './profile/profile.routes';
import reports from './reports/reports.routes';
import skills from './skills/skills.routes';
import teams from './teams/teams.routes';
import attributes from './attributes/attributes.routes';
import automation from './automation/automation.routes';
import auditlogs from './auditlogs/audit.routes';
import attachment from './attachments/attachments.routes';

export default store => ({
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings'),
      name: 'settings_home',
      roles: ['administrator', 'agent', 'supervisor'],
      redirect: () => {
        if (store.getters.getCurrentRoles.includes('administrator')) {
          return frontendURL('accounts/:accountId/settings/agents');
        }
        return frontendURL('accounts/:accountId/settings/canned-response');
      },
    },
    ...account.routes,
    ...agent.routes,
    ...canned.routes,
    ...inbox(store).routes,
    ...skills.routes,
    ...labels.routes,
    ...profile.routes,
    ...reports(store).routes,
    ...teams.routes,
    ...attributes.routes,
    ...automation.routes,
    ...auditlogs(store).routes,
    ...attachment.routes,
  ],
});
