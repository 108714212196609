import types from '../../mutation-types';
import BulkActionsAPI from '../../../api/bulkActions';
import {
  BulkActionGetters,
  BulkActionMutations,
  BulkActionPayload,
  BulkActionState,
  BulkActionStore,
} from './bulkAction.types';
import { ActionContext } from 'vuex';
import { UiFlags } from '../../types/ui.types';

export const state: BulkActionState = {
  uiFlags: {
    isUpdating: false,
  },
};

export const getters: BulkActionGetters = {
  getUIFlags(_state: BulkActionState): UiFlags {
    return _state.uiFlags;
  },
};

export const actions = {
  process: async function processAction(
    { commit }: Pick<ActionContext<BulkActionState, BulkActionState>, 'commit'>,
    payload: BulkActionPayload
  ) {
    commit(types.SET_BULK_ACTIONS_FLAG, { isUpdating: true });
    try {
      await BulkActionsAPI.create(payload);
    } catch (error) {
      throw new Error(error as string);
    } finally {
      commit(types.SET_BULK_ACTIONS_FLAG, { isUpdating: false });
    }
  },
};

export const mutations: BulkActionMutations = {
  [types.SET_BULK_ACTIONS_FLAG](
    _state: BulkActionState,
    data: BulkActionPayload
  ) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
};

const bulkActions: BulkActionStore = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default bulkActions;
