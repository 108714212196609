export const afterSanitizeAttributes = currentNode => {
  if ('target' in currentNode) {
    currentNode.setAttribute('target', '_blank');
  }
};

export const domPurifyConfig = {
  default: {
    ADD_TAGS: ['iframe'], // this one whitelists iframe videos
    FORBID_TAGS: ['input'],
  },
  hooks: {
    afterSanitizeAttributes,
  },
};
