import { v4 as uuidv4 } from 'uuid';
import { isEmptyObject } from '../../helper/commons';

const formatCognigySimpleMessage = message => {
  return {
    content: message.text,
    content_type: 'text',
    sender: { type: 'agent_bot' },
  };
};

const formatCognigyStructuredMessage = (message, inboxId, conversationId) => {
  return {
    content: typeof message?.text === 'string' ? message.text : null,
    content_type: 'cognigy',
    inbox_id: inboxId,
    conversation_id: conversationId,
    id: uuidv4(),
    content_attributes: {
      items: [
        {
          data: message.data,
        },
      ],
    },
    sender: { type: 'agent_bot' },
  };
};

const formatUserMessage = message => {
  return {
    content: message,
    sender: { type: 'user' },
  };
};

const isMessageStructured = message => {
  if (message?.data !== undefined && typeof message?.data === 'object') {
    return !isEmptyObject(message?.data);
  }
  return false;
};

export const formatMessage = ({
  messageToAdd,
  inboxId,
  conversationId,
  copilotBotId,
  userId,
  error,
}) => {
  let message = '';

  if (isMessageStructured(messageToAdd)) {
    message = formatCognigyStructuredMessage(
      messageToAdd,
      inboxId,
      conversationId
    );
  } else if (messageToAdd?.text !== undefined) {
    message = formatCognigySimpleMessage(messageToAdd);
  } else {
    message = formatUserMessage(messageToAdd);
  }
  return {
    message: message,
    inbox_id: inboxId,
    conversation_id: conversationId,
    copilot_bot_id: copilotBotId,
    id: uuidv4(),
    created_at: Date.now(),
    user_id: userId,
    error,
  };
};
