<template>
  <div class="container" data-test-id="not_found_page">
    <div>
      <h1>404</h1>
      <p>{{ $t('INBOX_MGMT.RESOURCE_NOT_FOUND') }}</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
