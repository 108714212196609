import SettingsContent from '../Wrapper.vue';
import ProfileSettings from './Index.vue';
import { frontendURL } from 'dashboard/helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/profile'),
      name: 'profile_settings',
      roles: ['administrator', 'agent', 'supervisor'],
      component: SettingsContent,
      props: {
        headerTitle: 'PROFILE_SETTINGS.TITLE',
        icon: 'edit',
        showNewButton: false,
      },
      children: [
        {
          path: 'settings',
          name: 'profile_settings_index',
          component: ProfileSettings,
          roles: ['administrator', 'agent', 'supervisor'],
        },
      ],
    },
  ],
};
