import { throttle } from 'lodash';
import Vue from 'vue';
import types from '../mutation-types';
import ConversationApi from '../../api/inbox/conversation';

const initialState = {
  mineCount: 0,
  unAssignedCount: 0,
  allCount: 0,
  isFetching: false,
};

export const getters = {
  getStats: $state => $state,
};

const throttledGet = throttle(async ({ commit, state }, params) => {
  if (state.isFetching) {
    return;
  }

  commit('setFetching', true);

  try {
    const response = await ConversationApi.meta(params);
    const {
      data: { meta },
    } = response;
    commit(types.SET_CONV_TAB_META, meta);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  } finally {
    commit('setFetching', false);
  }
}, 3000);

export const actions = {
  get: throttledGet,
  set({ commit }, meta) {
    commit(types.SET_CONV_TAB_META, meta);
  },
};

export const mutations = {
  setFetching($state, isFetching) {
    Vue.set($state, 'isFetching', isFetching);
  },
  [types.SET_CONV_TAB_META](
    $state,
    {
      mine_count: mineCount,
      unassigned_count: unAssignedCount,
      all_count: allCount,
    } = {}
  ) {
    Vue.set($state, 'mineCount', mineCount);
    Vue.set($state, 'allCount', allCount);
    Vue.set($state, 'unAssignedCount', unAssignedCount);
  },
};

export default {
  namespaced: true,
  state: initialState,
  getters,
  actions,
  mutations,
};
