<template>
  <div class="context-menu">
    <woot-button
      icon="more-vertical"
      class="button--delete-message"
      color-scheme="secondary"
      variant="link"
      @click="handleContextMenuClick"
    />
    <div
      v-if="isOpen"
      v-on-clickaway="handleContextMenuClick"
      class="dropdown-pane dropdown-pane--open"
      :class="`dropdown-pane--${menuPosition} ${forwardContextOptionLeft} ${forwardContextOptionRight}`"
    >
      <woot-dropdown-menu>
        <woot-dropdown-item v-if="showCopy">
          <woot-button
            variant="clear"
            size="small"
            icon="clipboard"
            :data-test-id="'message_context_menu_copy_button'"
            @click="handleCopy"
          >
            {{ $t('CONVERSATION.CONTEXT_MENU.COPY') }}
          </woot-button>
        </woot-dropdown-item>
        <woot-dropdown-item v-if="showRawData">
          <woot-button
            variant="clear"
            size="small"
            icon="code"
            :data-test-id="'message_context_menu_raw_data_button'"
            @click="handleRawData"
          >
            {{ $t('CONVERSATION.CONTEXT_MENU.RAW_DATA') }}
          </woot-button>
        </woot-dropdown-item>
        <woot-dropdown-item v-if="isPrivate">
          <woot-button
            variant="clear"
            color-scheme="alert"
            size="small"
            icon="delete"
            :data-test-id="'message_context_menu_delete_button'"
            @click="handleDelete"
          >
            {{ $t('CONVERSATION.CONTEXT_MENU.DELETE') }}
          </woot-button>
        </woot-dropdown-item>
        <woot-dropdown-item v-if="showForwardContextMenuOption">
          <woot-button
            variant="clear"
            size="small"
            icon="clipboard"
            @click="handleSendMessageToAgentCopilotBot"
          >
            {{ $t('CONVERSATION.CONTEXT_MENU.SEND_MESSAGE_TO_COPILOT_BOT') }}
          </woot-button>
        </woot-dropdown-item>
      </woot-dropdown-menu>
    </div>
  </div>
</template>
<script>
import { mixin as clickaway } from 'vue-clickaway';

import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem.vue';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu.vue';

export default {
  components: {
    WootDropdownMenu,
    WootDropdownItem,
  },
  mixins: [clickaway],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    showCopy: {
      type: Boolean,
      default: false,
    },
    menuPosition: {
      type: String,
      default: 'left',
    },
    isPrivate: {
      type: Boolean,
      default: false,
    },
    showForwardContextMenuOption: {
      type: Boolean,
      default: false,
    },
    showRawData: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    forwardContextOptionLeft() {
      return this.showForwardContextMenuOption && this.menuPosition === 'left'
        ? 'forward-context-option-left'
        : '';
    },
    forwardContextOptionRight() {
      return this.showForwardContextMenuOption && this.menuPosition === 'right'
        ? 'forward-context-option-right'
        : '';
    },
  },
  methods: {
    handleContextMenuClick() {
      this.$emit('toggle', !this.isOpen);
    },
    handleCopy() {
      this.$emit('copy');
    },
    handleRawData() {
      this.$emit('showRawData');
    },
    handleDelete() {
      this.$emit('delete');
    },
    handleSendMessageToAgentCopilotBot() {
      this.$emit('handleSendMessageToAgentCopilotBot');
    },
  },
};
</script>
<style lang="scss" scoped>
/* TDOD: Remove once MenuComponent supports postions */
.dropdown-pane {
  bottom: var(--space-medium);
}
.dropdown-pane--left {
  right: -65px;
}
.dropdown-pane--right {
  left: -65px;
}

.forward-context-option-right {
  left: -240px;
}

.forward-context-option-left {
  right: -230px;
}
</style>
