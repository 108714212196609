import types from '../../mutation-types';
import NotificationsAPI from '../../../api/notifications';
import { ActionContext } from 'vuex';
import { INotificationsState } from './types';

export const state: INotificationsState = {
  notifications: [],
  meta: {},
  unreadCount: 0,
  uiFlags: {
    isFetching: false,
    isUpdating: false,
    isUpdatingUnreadCount: false,
  },
  records: {},
};

export const actions = {
  get: async (
    {
      commit,
    }: Pick<ActionContext<INotificationsState, INotificationsState>, 'commit'>,
    { page = 1 } = {}
  ) => {
    commit(types.SET_NOTIFICATIONS_UI_FLAG, { isFetching: true });
    try {
      const {
        data: {
          data: { payload, meta },
        },
      } = await NotificationsAPI.get(page);
      commit(types.CLEAR_NOTIFICATIONS);
      commit(types.SET_NOTIFICATIONS, payload);
      commit(types.SET_NOTIFICATIONS_META, meta);
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isFetching: false });
    } catch (error: unknown) {
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isFetching: false });
      if (error instanceof Error) {
        throw new Error(error.message || 'Failed to fetch notifications');
      } else {
        throw new Error('Failed to fetch notifications');
      }
    }
  },
  getUnread: async (
    {
      commit,
    }: Pick<ActionContext<INotificationsState, INotificationsState>, 'commit'>,
    { page = 1 } = {}
  ) => {
    try {
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isFetching: true });
      const {
        data: {
          data: { payload, meta },
        },
      } = await NotificationsAPI.getUnreadNotifications(page);
      commit(types.SET_NOTIFICATIONS, payload);
      commit(types.SET_NOTIFICATIONS_META, meta);
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isFetching: false });
    } catch (error: unknown) {
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isFetching: false });
      if (error instanceof Error) {
        throw new Error(
          error.message || 'Failed to fetch unread notifications'
        );
      } else {
        throw new Error('Failed to fetch unread notifications');
      }
    }
  },
  unReadCount: async ({
    commit,
  }: Pick<
    ActionContext<INotificationsState, INotificationsState>,
    'commit'
  >) => {
    commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdatingUnreadCount: true });
    try {
      const { data } = await NotificationsAPI.getUnreadCount();
      commit(types.SET_NOTIFICATIONS_UNREAD_COUNT, data);
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdatingUnreadCount: false });
    } catch (error: unknown) {
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdatingUnreadCount: false });
      if (error instanceof Error) {
        throw new Error(
          error.message || 'Failed to fetch unread notifications count'
        );
      } else {
        throw new Error('Failed to fetch unread notifications count');
      }
    }
  },
  read: async (
    {
      commit,
    }: Pick<ActionContext<INotificationsState, INotificationsState>, 'commit'>,
    {
      primaryActorType,
      primaryActorId,
      unreadCount,
    }: {
      primaryActorType: string;
      primaryActorId: string;
      unreadCount: number;
    }
  ) => {
    try {
      await NotificationsAPI.read(primaryActorType, primaryActorId);
      commit(types.SET_NOTIFICATIONS_UNREAD_COUNT, unreadCount - 1);
      commit(types.UPDATE_NOTIFICATION, primaryActorId);
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error(error.message || 'Failed to mark notification as read');
      } else {
        throw new Error('Failed to mark notification as read');
      }
    }
  },
  readAll: async ({
    commit,
  }: Pick<
    ActionContext<INotificationsState, INotificationsState>,
    'commit'
  >) => {
    commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdating: true });
    try {
      await NotificationsAPI.readAll();
      commit(types.SET_NOTIFICATIONS_UNREAD_COUNT, 0);
      commit(types.UPDATE_ALL_NOTIFICATIONS);
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdating: false });
    } catch (error: unknown) {
      commit(types.SET_NOTIFICATIONS_UI_FLAG, { isUpdating: false });
      if (error instanceof Error) {
        throw new Error(
          error.message || 'Failed to mark all notifications as read'
        );
      } else {
        throw new Error('Failed to mark all notifications as read');
      }
    }
  },

  addNotification(
    {
      commit,
    }: Pick<ActionContext<INotificationsState, INotificationsState>, 'commit'>,
    data: unknown
  ) {
    commit(types.ADD_NOTIFICATION, data);
  },

  getNewsOnPage: async (
    {
      commit,
    }: Pick<ActionContext<INotificationsState, INotificationsState>, 'commit'>,
    { page = 1 } = {}
  ) => {
    try {
      const {
        data: {
          data: { payload, meta },
        },
      } = await NotificationsAPI.getUnreadNotifications(page);
      commit(types.SET_NOTIFICATIONS, payload);
      commit(types.SET_NOTIFICATIONS_META, meta);
    } catch (error: unknown) {
      if (error instanceof Error) {
        throw new Error(error.message || 'Failed to fetch news notifications');
      } else {
        throw new Error('Failed to fetch news notifications');
      }
    }
  },
};
