<template>
  <button data-test-id="sidebar-sandwich-button" @click="onMenuItemClick">
    <fluent-icon class="hamburger--menu" icon="list" />
  </button>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      isSidebarOpen: 'ui/isSideMenuOpen',
    }),
  },
  methods: {
    onMenuItemClick() {
      this.$store.dispatch('ui/toggleSideMenuStatus', {
        status: !this.isSidebarOpen,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.hamburger--menu {
  cursor: pointer;
  display: block;
  margin-right: var(--space-normal);

  @media screen and (max-width: 1200px) {
    display: block;
  }
}
</style>
