export const isValidFileType = file => {
  const types = [
    'application/pdf',
    'application/jpg',
    'application/jpeg',
    'application/png',
    'image/png',
    'image/jpeg',
    'image/jpg',
    'audio/x-wav',
    'audio/ogg',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];

  return types.includes(file.type);
};
