import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import AttachmentAPI from '../../api/attachments';

export const state = {
  records: [],
  total: 0,
  uiFlags: {
    isFetching: false,
    isDeleting: false,
  },
  filters: {},
  sortingPreferences: {
    page: 1,
    sortBy: 'date',
  },
};

export const getters = {
  getAttachments($state) {
    return $state.records;
  },
  getAttachmentsTotal($state) {
    return $state.total;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getAttachmentFilters($state) {
    return $state.filters;
  },
};

export const actions = {
  get: async ({ commit, state: currentState }, { sortBy, page }) => {
    commit(types.default.SET_ATTACHMENTS_FETCHING_STATUS, true);
    try {
      const response = await AttachmentAPI.get({
        ...currentState.filters,
        sortBy,
        page,
      });
      commit(types.default.SET_ATTACHMENTS_FETCHING_STATUS, false);
      commit(types.default.SET_ATTACHMENTS, response.data.payload);
      commit(types.default.SET_ATTACHMENTS_TOTAL, response.data.total);
    } catch (error) {
      commit(types.default.SET_ATTACHMENTS_FETCHING_STATUS, false);
    }
  },

  delete: async ({ commit, state: currentState }, attachmentKey) => {
    commit(types.default.SET_ATTACHMENTS_DELETING_STATUS, true);
    try {
      await AttachmentAPI.delete(attachmentKey);
      commit(types.default.DELETE_ATTACHMENTS, attachmentKey);
      commit(types.default.SET_ATTACHMENTS_DELETING_STATUS, false);
      commit(types.default.SET_ATTACHMENTS_TOTAL, currentState.total - 1);
    } catch (error) {
      commit(types.default.SET_ATTACHMENTS_DELETING_STATUS, false);
      throw new Error(error);
    }
  },

  updateFilters: (
    { commit, dispatch },
    { cognigy_session_id, cognigy_user_id, filename }
  ) => {
    const filters = {
      ...(cognigy_session_id ? { cognigy_session_id } : {}),
      ...(cognigy_user_id ? { cognigy_user_id } : {}),
      ...(filename ? { filename } : {}),
    };

    commit(types.default.SET_ATTACHMENT_FILTERS, filters);
    dispatch('get', { sortBy: 'date', page: 1 });
  },
};

export const mutations = {
  [types.default.SET_ATTACHMENTS_FETCHING_STATUS]($state, status) {
    $state.uiFlags.isFetching = status;
  },
  [types.default.SET_ATTACHMENTS_DELETING_STATUS]($state, status) {
    $state.uiFlags.isDeleting = status;
  },
  [types.default.SET_ATTACHMENT_FILTERS]($state, filters) {
    $state.filters = filters;
  },
  [types.default.SET_ATTACHMENTS_TOTAL]($state, total) {
    $state.total = total;
  },
  [types.default.SET_ATTACHMENTS]: MutationHelpers.set,
  [types.default.DELETE_ATTACHMENTS]($state, attachmentKey) {
    $state.records = $state.records.filter(
      record => record.key !== attachmentKey
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
