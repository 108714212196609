<template>
  <div class="wizard-body small-9 columns">
    <page-header
      :header-title="$t('TEAMS_SETTINGS.CREATE_FLOW.CREATE.TITLE')"
      :header-content="$t('TEAMS_SETTINGS.CREATE_FLOW.CREATE.DESC')"
      data-test-id="create_teams_header"
    />
    <div class="row channels">
      <team-form
        :on-submit="createTeam"
        :submit-in-progress="false"
        :submit-button-text="$t('TEAMS_SETTINGS.FORM.SUBMIT_CREATE')"
      />
    </div>
  </div>
</template>

<script>
import TeamForm from '../TeamForm.vue';
import PageHeader from '../../SettingsSubPageHeader.vue';
import alertMixin from 'shared/mixins/alertMixin';
import wootConstants from '../../../../../constants';

export default {
  components: {
    TeamForm,
    PageHeader,
  },
  mixins: [alertMixin],
  data() {
    return {
      enabledFeatures: {},
    };
  },
  methods: {
    async createTeam(data) {
      try {
        const team = await this.$store.dispatch('teams/create', {
          ...data,
        });

        this.$router.replace({
          name: 'settings_teams_add_agents',
          params: {
            page: 'new',
            teamId: team.id,
          },
        });
      } catch (error) {
        if (error.message === 'Name has already been taken') {
          this.showAlert(
            this.$t('TEAMS_SETTINGS.TEAM_FORM.ERROR_NAME_MESSAGE'),
            {
              status: wootConstants.SNACKBAR_TYPE.ERROR,
            }
          );
        } else {
          this.showAlert(this.$t('TEAMS_SETTINGS.TEAM_FORM.ERROR_MESSAGE'), {
            status: wootConstants.SNACKBAR_TYPE.ERROR,
          });
        }
      }
    },
  },
};
</script>
