<template>
  <div class="copilot-bot-container">
    <agent-copilot-bot-header
      :flows="flows"
      :selected-assist-flow="selectedAssistFlow"
      :inbox-id="inboxId"
      @resetSession="resetSession"
    />
    <agent-copilot-bot-chat
      :session-id="sessionId"
      :chat-history="chatHistory"
      @onClickRawDataButton="toggleRawData"
    />

    <agent-copilot-bot-footer
      :selected-assist-flow="selectedAssistFlow"
      :session-id="sessionId"
      @sendMessage="onSend"
    />
    <raw-data-modal
      :show="showRawDataModal"
      :raw-data="rawData"
      @close="toggleRawData"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AgentCopilotBotChat from './AgentCopilotBotChat.vue';
import AgentCopilotBotFooter from './AgentCopilotBotFooter.vue';
import AgentCopilotBotHeader from './AgentCopilotBotHeader.vue';
import { formatMessage } from './formatMessage.js';
import {
  getAssistSocketConnection,
  establishSocketConnection,
  resetSocketConnection,
} from './socketClient.js';
import RawDataModal from '../widgets/modal/RawDataModal.vue';

export default {
  components: {
    AgentCopilotBotHeader,
    AgentCopilotBotFooter,
    AgentCopilotBotChat,
    RawDataModal,
  },
  props: {
    inboxId: {
      type: Number,
      default: null,
    },
    selectedAssistFlow: {
      type: Object,
      default: () => {},
    },
    sessionId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      clientConnection: {},
      chatHistory: [],
      showRawDataModal: false,
      rawData: {},
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      currentUser: 'getCurrentUser',
    }),
    flows() {
      return this.$store.getters['inboxCopilotBots/getCopilotBots'](
        this.inboxId
      );
    },
    currentInboxId() {
      return this.currentChat.inbox_id;
    },
    assistClientId() {
      return this.currentChat.id + '-' + this.selectedAssistFlow.id;
    },
  },
  watch: {
    selectedAssistFlow: {
      handler() {
        this.removePreviousConnection();
        this.getAssistSocketClient();
        this.proofLocalStorageChatHistory();

        if (this.assistClient) {
          this.establishSocketConnection();
        }
      },
    },
  },
  mounted() {
    if (this.flows?.length === 0) {
      this.$store.dispatch('inboxCopilotBots/fetch', {
        inboxId: this.inboxId,
      });
    }

    this.proofLocalStorage();
    this.proofLocalStorageChatHistory();
    this.getAssistSocketClient();

    if (this.assistClient) {
      this.establishSocketConnection();
    }
  },
  methods: {
    async onSend(message) {
      try {
        this.clientConnection.sendMessage(message);
        this.addMessageToChatHistory(message, false);
      } catch (error) {
        const errorMessage = this.$t(
          'INBOX_MGMT.COPILOT_BOT.COPILOT_BOT_ERROR_MESSAGE'
        );
        this.addMessageToChatHistory(errorMessage, true);
      }
    },
    addMessageToChatHistory(message, error) {
      const formattedMessage = formatMessage({
        messageToAdd: message,
        inboxId: this.currentInboxId,
        conversationId: this.currentChat.id,
        copilotBotId: this.selectedAssistFlow.id,
        userId: this.currentUser.id,
        error: error,
      });
      const newChat = [...this.chatHistory, formattedMessage];

      const AssistClients = JSON.parse(
        window.localStorage.getItem('AssistClients')
      );

      AssistClients[this.assistClientId] = {
        ...AssistClients[this.assistClientId],
        chatHistory: newChat,
      };

      this.chatHistory = newChat;
      window.localStorage.setItem(
        'AssistClients',
        JSON.stringify(AssistClients)
      );
    },
    proofLocalStorage() {
      const AssistClients = JSON.parse(
        window.localStorage.getItem('AssistClients')
      );

      if (!AssistClients) {
        window.localStorage.setItem('AssistClients', JSON.stringify({}));
      }
    },
    proofLocalStorageChatHistory() {
      const AssistClients = JSON.parse(
        window.localStorage.getItem('AssistClients')
      );

      if (AssistClients && AssistClients[this.assistClientId]) {
        this.chatHistory = AssistClients[this.assistClientId].chatHistory;
      } else {
        this.chatHistory = [];
      }
    },
    getAssistSocketClient() {
      const assistClient = getAssistSocketConnection(
        this.selectedAssistFlow,
        { agentId: this.currentUser.id },
        this.sessionId,
        this.assistClientId
      );
      this.assistClient = assistClient;
    },
    async establishSocketConnection() {
      const connection = establishSocketConnection(
        this.assistClient?.assistClient
      );

      await connection.connect();

      connection.on('output', output => {
        this.addMessageToChatHistory(output);
      });
      this.clientConnection = connection;
    },
    removePreviousConnection() {
      if (this.clientConnection.socketUrl) {
        this.clientConnection.removeAllListeners();
        this.clientConnection.disconnect();
      }
    },
    resetSession() {
      this.$emit('setSessionId');

      this.$nextTick(() => {
        this.removePreviousConnection();
        this.assistClient = resetSocketConnection(
          this.sessionId,
          this.assistClientId
        );
        this.proofLocalStorageChatHistory();

        if (this.assistClient) {
          this.establishSocketConnection();
        }
      });
    },
    toggleRawData(data) {
      this.rawData = data;
      this.showRawDataModal = !this.showRawDataModal;
    },
  },
};
</script>

<style lang="scss">
.copilot-bot-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}
</style>
