/* eslint no-console: 0 */
/* eslint no-undef: "error" */
/* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */

export default {
  methods: {
    lastMessage(m) {
      const messages = m.messages;
      return messages[messages.length - 1];
    },
    unreadMessagesCount(m) {
      // if chat has more than one message and agent_last_seen_at is 0
      // (e.g. the chat was newly created and assignee hasn't seen the chat yet)
      if (m.messages.length >= 1 && m.agent_last_seen_at === 0) {
        return 1;
      }

      // if chat only has one message and that message is newer than agent_last_seen_at
      // (e.g. the chat update come with last message when assignee changed)
      if (
        m.messages.length === 1 &&
        m.messages[0].createdAt * 1000 > m.agent_last_seen_at * 1000
      ) {
        return 1;
      }

      return m.messages.filter(
        chat =>
          chat.created_at * 1000 > m.agent_last_seen_at * 1000 &&
          chat.message_type === 0 &&
          chat.private !== true
      ).length;
    },
    hasUserReadMessage(createdAt, contactLastSeen) {
      return !(contactLastSeen - createdAt < 0);
    },
    readMessages(m) {
      return m.messages.filter(
        chat => chat.created_at * 1000 <= m.agent_last_seen_at * 1000
      );
    },
    unReadMessages(m) {
      return m.messages.filter(
        chat => chat.created_at * 1000 > m.agent_last_seen_at * 1000
      );
    },
  },
};
