import Vue from 'vue';
import InboxCopilotBotsAPI from '../../api/InboxCopilotBots';

const state = {
  records: {},
  uiFlags: {
    isFetching: false,
  },
};

export const getters = {
  getCopilotBots: $state => inboxId => {
    return $state.records[inboxId] || [];
  },
};

export const types = {
  SET_INBOX_COPILOT_BOTS: 'SET_INBOX_COPILOT_BOTS',
  ADD_INBOX_COPILOT_BOTS: 'ADD_INBOX_COPILOT_BOTS',
  EDIT_INBOX_COPILOT_BOTS: 'EDIT_INBOX_COPILOT_BOTS',
  REMOVE_INBOX_COPILOT_BOTS: 'REMOVE_INBOX_COPILOT_BOTS',
  SET_INBOX_COPILOT_BOTS_UI_FLAG: 'SET_INBOX_COPILOT_BOTS_UI_FLAG',
};

export const actions = {
  async fetch({ commit }, { inboxId }) {
    commit(types.SET_INBOX_COPILOT_BOTS_UI_FLAG, { isFetching: true });
    try {
      const { data } = await InboxCopilotBotsAPI.getCopilotBots(inboxId);
      commit(types.SET_INBOX_COPILOT_BOTS, {
        inboxId,
        copilotBots: data,
      });
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.SET_INBOX_COPILOT_BOTS_UI_FLAG, { isFetching: false });
    }
  },
  async addCopilotBot({ commit }, { inboxId, copilotBot }) {
    try {
      const { data } = await InboxCopilotBotsAPI.addCopilotBot(
        inboxId,
        copilotBot
      );
      commit(types.ADD_INBOX_COPILOT_BOTS, {
        inboxId,
        copilotBot: data,
      });
    } catch (error) {
      throw new Error(error);
    }
  },
  async editCopilotBot({ commit }, { inboxId, copilotBot }) {
    try {
      const { data } = await InboxCopilotBotsAPI.editCopilotBot(
        inboxId,
        copilotBot
      );
      commit(types.EDIT_INBOX_COPILOT_BOTS, {
        inboxId,
        copilotBot: data,
      });
    } catch (error) {
      throw new Error(error);
    }
  },
  async removeCopilotBot({ commit }, { inboxId, copilotBotId }) {
    try {
      await InboxCopilotBotsAPI.removeCopilotBot(inboxId, copilotBotId);
      commit(types.REMOVE_INBOX_COPILOT_BOTS, {
        inboxId,
        copilotBotId,
      });
    } catch (error) {
      throw new Error(error);
    }
  },
};

export const mutations = {
  [types.SET_INBOX_COPILOT_BOTS_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [types.SET_INBOX_COPILOT_BOTS]: ($state, { inboxId, copilotBots }) => {
    Vue.set($state.records, inboxId, copilotBots);
  },
  [types.ADD_INBOX_COPILOT_BOTS]: ($state, { inboxId, copilotBot }) => {
    $state.records[inboxId] = [...$state.records[inboxId], copilotBot];
  },
  [types.EDIT_INBOX_COPILOT_BOTS]: ($state, { inboxId, copilotBot }) => {
    const index = $state.records[inboxId].findIndex(
      bot => bot.id === copilotBot.id
    );
    Vue.set($state.records[inboxId], index, copilotBot);
  },
  [types.REMOVE_INBOX_COPILOT_BOTS]: ($state, { inboxId, copilotBotId }) => {
    const index = $state.records[inboxId].findIndex(
      bot => bot.id === copilotBotId
    );
    $state.records[inboxId].splice(index, 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
