export const renderCognigyMessage = (
  elementId,
  contentAttributes,
  messageColor,
  directionMapping
) => {
  const data =
    Object.keys(contentAttributes?.items[0]?.data || {}).length > 0
      ? { data: contentAttributes?.items[0]?.data }
      : { text: contentAttributes?.items[0]?.text };

  window.MessageRenderer.renderMessage(
    {
      ...data,
      source: 'bot',
    },
    document.getElementById(elementId),
    {
      settings: {
        colorScheme: messageColor,
        sourceColorMapping: {
          bot: 'primary',
        },
        sourceDirectionMapping: {
          bot: directionMapping,
        },
        enableDefaultPreview: true,
      },
    }
  );
};
