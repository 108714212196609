/* eslint-disable no-underscore-dangle */

const isMessageType = ({ message, legacyMessage, isMessageCorrectType }) => {
  if (message !== undefined) {
    return isMessageCorrectType(message);
  }
  if (legacyMessage !== undefined) {
    return isMessageCorrectType(legacyMessage);
  }
  return false;
};

export const isGallery = (message, legacyMessage) => {
  return isMessageType({
    message,
    legacyMessage,
    isMessageCorrectType: messageBody =>
      messageBody?.attachment?.payload?.template_type === 'generic',
  });
};

export const isButtonWithText = (message, legacyMessage) => {
  return isMessageType({
    message,
    legacyMessage,
    isMessageCorrectType: messageBody =>
      messageBody?.attachment?.payload?.template_type === 'button',
  });
};

export const isQuickReply = (message, legacyMessage) => {
  return isMessageType({
    message,
    legacyMessage,
    isMessageCorrectType: messageBody => messageBody?.quick_replies?.length > 0,
  });
};

export const isList = (message, legacyMessage) => {
  return isMessageType({
    message,
    legacyMessage,
    isMessageCorrectType: messageBody =>
      messageBody?.attachment?.payload?.template_type === 'list',
  });
};

export const isAudio = (message, legacyMessage) => {
  return isMessageType({
    message,
    legacyMessage,
    isMessageCorrectType: messageBody =>
      messageBody?.attachment?.type === 'audio',
  });
};

export const isImage = (message, legacyMessage) => {
  return isMessageType({
    message,
    legacyMessage,
    isMessageCorrectType: messageBody =>
      messageBody?.attachment?.type === 'image',
  });
};

export const isVideo = (message, legacyMessage) => {
  return isMessageType({
    message,
    legacyMessage,
    isMessageCorrectType: messageBody =>
      messageBody?.attachment?.type === 'video',
  });
};

export const isTemplate = (message, legacyMessage) => {
  return isMessageType({
    message,
    legacyMessage,
    isMessageCorrectType: messageBody =>
      messageBody?.attachment?.type === 'template',
  });
};

export const isDatePicker = firstContentAttrItem => {
  return firstContentAttrItem?.data?._plugin?.type === 'date-picker';
};

export const isAdaptiveCard = (
  defaultPreview,
  webchat,
  firstContentAttrItem
) => {
  const isCurrentAdaptiveCard = isMessageType({
    message: defaultPreview,
    legacyMessage: webchat,
    isMessageCorrectType: messageBody => messageBody?.type === 'adaptiveCard',
  });

  const isLegacyAdaptiveCard =
    firstContentAttrItem?.data?._plugin?.type === 'adaptivecards';
  return isCurrentAdaptiveCard || isLegacyAdaptiveCard;
};

export const isAttachment = (message, legacyMessage) =>
  legacyMessage?.attachment?.type || message?.attachment?.type;

export const isCognigyAttachment = firstContentAttrItem =>
  firstContentAttrItem?.data?._cognigy?._defaultPreview?.attachments[0]?.type;

const getMessageTypeDescription = messageTypeDescription => ({
  messageTypeDescription,
});

const getTextFromMessageProperty = text => ({ text });

/**
 * TODO: refactor and simplify this method
 * getStructuredMessageType
 * This method return the text contained inside structure messages or its description based on the properties
 * that are passed. Descriptions are provided for displaying the message preview in the ConversationCard and
 * the text needs to be obtained for forwarding only the text on certain type of structured messages
 * (whisper assist and copilot bot)
 * @param data
 * @param buttonWithTextDescription string | undefined
 * @param quickReplyDescription string | undefined
 * @param galleryDescription string
 * @param listDescription string
 * @param audioDescription string
 * @param imageDescription string
 * @param videoDescription string
 * @param adaptiveCardDescription string
 * @param templateDescription string | undefined
 * @param attachmentsDescription string
 * @param unknownDescription
 * @returns {string|undefined|*}
 */

export default ({
  data,
  buttonWithTextDescription,
  quickReplyDescription,
  galleryDescription,
  listDescription,
  audioDescription,
  imageDescription,
  videoDescription,
  adaptiveCardDescription,
  templateDescription,
  attachmentsDescription,
  unknownDescription,
}) => {
  const firstContentAttrItem = data.content_attributes.items[0];
  const webchat = firstContentAttrItem?.data?._cognigy?._webchat;
  const defaultPreview = firstContentAttrItem?.data?._cognigy?._defaultPreview;
  const legacyMessage = webchat?.message;
  const message = defaultPreview?.message;

  if (isButtonWithText(message, legacyMessage)) {
    if (buttonWithTextDescription !== undefined) {
      return getMessageTypeDescription(buttonWithTextDescription);
    }

    const payloadText =
      message?.attachment.payload.text ||
      legacyMessage?.attachment.payload.text;
    return getTextFromMessageProperty(payloadText);
  }

  if (isQuickReply(message, legacyMessage)) {
    if (quickReplyDescription !== undefined) {
      return getMessageTypeDescription(quickReplyDescription);
    }
    return getTextFromMessageProperty(legacyMessage?.text || message?.text);
  }

  if (isGallery(message, legacyMessage)) {
    return getMessageTypeDescription(galleryDescription);
  }

  if (isList(message, legacyMessage)) {
    return getMessageTypeDescription(listDescription);
  }

  if (isAudio(message, legacyMessage)) {
    return getMessageTypeDescription(audioDescription);
  }

  if (isImage(message, legacyMessage)) {
    return getMessageTypeDescription(imageDescription);
  }

  if (isVideo(message, legacyMessage)) {
    return getMessageTypeDescription(videoDescription);
  }

  if (isTemplate(message, legacyMessage)) {
    if (templateDescription !== undefined) {
      return getMessageTypeDescription(templateDescription);
    }

    const description =
      message?.attachment.payload.template_type ||
      legacyMessage?.attachment.payload.template_type;
    return getMessageTypeDescription(description);
  }

  if (isDatePicker(firstContentAttrItem)) {
    return getTextFromMessageProperty(
      firstContentAttrItem?.data?._plugin?.data?.openPickerButtonText
    );
  }

  if (isAdaptiveCard(defaultPreview, webchat, firstContentAttrItem)) {
    return getMessageTypeDescription(adaptiveCardDescription);
  }

  if (isCognigyAttachment(firstContentAttrItem)) {
    const description =
      firstContentAttrItem?.data?._cognigy?._defaultPreview?.attachments
        .length > 1
        ? attachmentsDescription
        : firstContentAttrItem?.data?._cognigy?._defaultPreview?.attachments[0]
            ?.type;
    return getMessageTypeDescription(description);
  }

  if (firstContentAttrItem?.text !== undefined) {
    return getTextFromMessageProperty(firstContentAttrItem.text);
  }

  if (message?.text !== undefined) {
    return getTextFromMessageProperty(message?.text);
  }

  if (isAttachment(message, legacyMessage)) {
    const description =
      message?.attachment.type || legacyMessage?.attachment.type;
    return getMessageTypeDescription(description);
  }

  return getMessageTypeDescription(unknownDescription);
};
