<template>
  <div class="bottom-box" :class="wrapClass">
    <div class="left-wrap">
      <woot-button
        v-if="!isOnReplyBot"
        v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_EMOJI_ICON')"
        :title="$t('CONVERSATION.REPLYBOX.TIP_EMOJI_ICON')"
        icon="emoji"
        emoji="😊"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        @click="toggleEmojiPicker"
      />
      <file-upload
        ref="upload"
        v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
        :size="4096 * 4096"
        :accept="allowedFileTypes"
        :multiple="enableMultipleFileUpload"
        :drop="true"
        :drop-directory="false"
        :data="{
          direct_upload_url: '/rails/active_storage/direct_uploads',
          direct_upload: true,
        }"
        @input-file="onFileUpload"
      >
        <woot-button
          v-if="showAttachButton"
          class-names="button--upload"
          :title="$t('CONVERSATION.REPLYBOX.TIP_ATTACH_ICON')"
          icon="attach"
          emoji="📎"
          color-scheme="secondary"
          variant="smooth"
          size="small"
        />
      </file-upload>
      <woot-button
        v-if="showAudioRecorderButton"
        v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_AUDIORECORDER_ICON')"
        :icon="!isRecordingAudio ? 'microphone' : 'microphone-off'"
        emoji="🎤"
        :color-scheme="!isRecordingAudio ? 'secondary' : 'alert'"
        variant="smooth"
        size="small"
        :title="$t('CONVERSATION.REPLYBOX.TIP_AUDIORECORDER_ICON')"
        data-test-id="reply_bottom_panel_audio_recorder_button"
        @click="toggleAudioRecorder"
      />
      <woot-button
        v-if="showAudioPlayStopButton"
        :icon="audioRecorderPlayStopIcon"
        emoji="🎤"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        data-test-id="reply_bottom_panel_audio_recorder_play_stop_button"
        @click="toggleAudioRecorderPlayPause"
      >
        <span>{{ recordingAudioDurationText }}</span>
      </woot-button>
      <woot-button
        v-if="showMessageSignatureButton"
        v-tooltip.top-end="signatureToggleTooltip"
        icon="signature"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        :title="signatureToggleTooltip"
        @click="toggleMessageSignature"
      />
      <transition name="modal-fade">
        <div
          v-show="$refs.upload && $refs.upload.dropActive"
          class="modal-mask"
        >
          <fluent-icon icon="cloud-backup" />
          <h4 class="page-sub-title">
            {{ $t('CONVERSATION.REPLYBOX.DRAG_DROP') }}
          </h4>
        </div>
      </transition>
      <woot-button
        v-if="!isOnReplyBot"
        v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_REQUEST_FILE')"
        class-names="button--upload"
        :title="$t('CONVERSATION.REPLYBOX.TIP_REQUEST_FILE')"
        icon="document"
        :icon-size="16"
        emoji="📄"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        @click="openFileRequestModal"
      />
      <woot-button
        v-if="!isOnReplyBot"
        v-tooltip.top-end="$t('CONVERSATION.REPLYBOX.TIP_INSERT_LINK')"
        class-names="button--insert-link"
        :title="$t('CONVERSATION.REPLYBOX.TIP_INSERT_LINK')"
        icon="link"
        :icon-size="16"
        data-test-id="reply_bottom_panel_insert_link_button"
        emoji="🔗"
        color-scheme="secondary"
        variant="smooth"
        size="small"
        @click="openLinkModal"
      />
      <transition name="modal-fade">
        <div
          v-show="$refs.upload && $refs.upload.dropActive"
          class="modal-mask"
        >
          <fluent-icon icon="cloud-backup" />
          <h4 class="page-sub-title">
            {{ $t('CONVERSATION.REPLYBOX.DRAG_DROP') }}
          </h4>
        </div>
      </transition>
    </div>
    <div class="right-wrap">
      <woot-button
        v-if="globalConfig.enableUiRequestFile"
        size="small"
        :class-names="buttonClass"
        :is-disabled="isSendDisabled"
        :data-test-id="'send_message_button'"
        @click="onSend"
      >
        {{ sendButtonText }}
      </woot-button>
    </div>
    <basic-modal
      v-if="showFileRequestModal"
      :show="showFileRequestModal"
      :header-title="$t('CONVERSATION.REPLYBOX.REQUEST_FILE_MODAL_TITLE')"
      :header-content="$t('CONVERSATION.REPLYBOX.REQUEST_FILE_MODAL_CONTENT')"
      :primary-button-text="
        $t('CONVERSATION.REPLYBOX.REQUEST_FILE_MODAL_SEND_BUTTON')
      "
      :secondary-button-text="
        $t('CONVERSATION.REPLYBOX.REQUEST_FILE_MODAL_CANCEL_BUTTON')
      "
      @cancel="closeFileRequestModal"
      @submit="onSendFileRequest"
    />
    <insert-link-modal
      v-if="showLinkModal"
      :show.sync="showLinkModal"
      @on-add-link="onAddLink"
      @on-close="closeLinkModal"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FileUpload from 'vue-upload-component';
import * as ActiveStorage from 'activestorage';
import {
  hasPressedAltAndWKey,
  hasPressedAltAndAKey,
} from 'shared/helpers/KeyboardHelpers';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import inboxMixin from 'shared/mixins/inboxMixin';

import { ALLOWED_FILE_TYPES } from 'shared/constants/messages';
import BasicModal from '../modal/BasicModal.vue';
import InsertLinkModal from '../modal/InsertLinkModal.vue';

import { REPLY_EDITOR_MODES } from './constants';
import WootButton from 'components/ui/WootButton.vue';
import FluentIcon from 'shared/components/FluentIcon/DashboardIcon.vue';

export default {
  name: 'ReplyBottomPanel',
  components: {
    FluentIcon,
    WootButton,
    FileUpload,
    BasicModal,
    InsertLinkModal,
  },
  mixins: [eventListenerMixins, uiSettingsMixin, inboxMixin],
  props: {
    mode: {
      type: String,
      default: REPLY_EDITOR_MODES.REPLY,
    },
    onSend: {
      type: Function,
      default: () => {},
    },
    onAddLink: {
      type: Function,
      default: () => {},
    },
    sendButtonText: {
      type: String,
      default: '',
    },
    recordingAudioDurationText: {
      type: String,
      default: '',
    },
    inbox: {
      type: Object,
      default: () => ({}),
    },
    showFileUpload: {
      type: Boolean,
      default: false,
    },
    showAudioRecorder: {
      type: Boolean,
      default: false,
    },
    onFileUpload: {
      type: Function,
      default: () => {},
    },
    showEmojiPicker: {
      type: Boolean,
      default: false,
    },
    isOnReplyBot: {
      type: Boolean,
      default: false,
    },
    toggleEmojiPicker: {
      type: Function,
      default: () => {},
    },
    toggleAudioRecorder: {
      type: Function,
      default: () => {},
    },
    toggleAudioRecorderPlayPause: {
      type: Function,
      default: () => {},
    },
    isRecordingAudio: {
      type: Boolean,
      default: false,
    },
    recordingAudioState: {
      type: String,
      default: '',
    },
    isSendDisabled: {
      type: Boolean,
      default: false,
    },
    setFormatMode: {
      type: Function,
      default: () => {},
    },
    isFormatMode: {
      type: Boolean,
      default: false,
    },
    isOnPrivateNote: {
      type: Boolean,
      default: false,
    },
    enableRichEditor: {
      type: Boolean,
      default: false,
    },
    enableMultipleFileUpload: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showFileRequestModal: false,
      showLinkModal: false,
    };
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    }),
    isNote() {
      return this.mode === REPLY_EDITOR_MODES.NOTE;
    },
    wrapClass() {
      return {
        'is-note-mode': this.isNote,
      };
    },
    buttonClass() {
      return {
        warning: this.isNote,
      };
    },
    showAttachButton() {
      return this.showFileUpload || this.isNote;
    },
    showAudioRecorderButton() {
      return this.showAudioRecorder;
    },
    showAudioPlayStopButton() {
      return this.showAudioRecorder && this.isRecordingAudio;
    },
    allowedFileTypes() {
      return ALLOWED_FILE_TYPES;
    },
    audioRecorderPlayStopIcon() {
      switch (this.recordingAudioState) {
        // playing paused recording stopped inactive destroyed
        case 'playing':
          return 'microphone-pause';
        case 'paused':
          return 'microphone-play';
        case 'stopped':
          return 'microphone-play';
        default:
          return 'microphone-stop';
      }
    },
    showMessageSignatureButton() {
      return !this.isPrivate && this.isAnEmailChannel;
    },
    sendWithSignature() {
      const { send_with_signature: isEnabled } = this.uiSettings;
      return isEnabled;
    },
    signatureToggleTooltip() {
      return this.sendWithSignature
        ? this.$t('CONVERSATION.FOOTER.DISABLE_SIGN_TOOLTIP')
        : this.$t('CONVERSATION.FOOTER.ENABLE_SIGN_TOOLTIP');
    },
  },
  mounted() {
    ActiveStorage.start();
  },
  methods: {
    handleKeyEvents(e) {
      if (hasPressedAltAndWKey(e)) {
        this.toggleFormatMode();
      }
      if (hasPressedAltAndAKey(e)) {
        this.$refs.upload.$children[1].$el.click();
      }
    },
    toggleFormatMode() {
      this.setFormatMode(!this.isFormatMode);
    },
    closeFileRequestModal() {
      this.showFileRequestModal = false;
    },
    closeLinkModal() {
      this.showLinkModal = false;
    },
    openFileRequestModal() {
      this.showFileRequestModal = true;
    },
    openLinkModal() {
      this.showLinkModal = true;
    },
    onSendFileRequest() {
      this.$emit('onSendFileRequest');
      this.closeFileRequestModal();
    },
    toggleMessageSignature() {
      this.updateUISettings({
        send_with_signature: !this.sendWithSignature,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.bottom-box {
  display: flex;
  justify-content: space-between;
  padding: var(--space-slab) var(--space-normal);

  &.is-note-mode {
    background: var(--y-50);
  }
}

.left-wrap .button {
  margin-right: var(--space-small);
}

.left-wrap {
  align-items: center;
  display: flex;
}

.right-wrap {
  display: flex;
}

::v-deep .file-uploads {
  label {
    cursor: pointer;
  }
  &:hover .button {
    background: var(--s-100);
  }
}

.modal-mask {
  color: var(--s-600);
  background: var(--white-transparent);
  flex-direction: column;
}

.page-sub-title {
  color: var(--s-600);
}

.icon {
  font-size: 8rem;
}

.fileRequestModal {
  width: 20rem;
}
</style>
