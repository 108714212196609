<template>
  <div class="container">
    <copilot-structured-content-message :message-data="messageData" />
  </div>
</template>

<script>
import CopilotStructuredContentMessage from './../CopilotStructuredContentMessage.vue';
export default {
  components: {
    CopilotStructuredContentMessage,
  },
  props: {
    messageData: {
      type: Object,
      required: true,
    },
    isSideMenuOpen: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style scoped>
.container {
  padding: 10px;
}
</style>
