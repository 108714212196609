<template>
  <div class="context-menu-container">
    <woot-button
      icon="more-vertical"
      color-scheme="secondary"
      variant="link"
      :data-test-id="'copilot_bot_context_menu'"
      @click="handleContextMenuClick"
    />
    <div
      v-if="isOpen"
      v-on-clickaway="handleContextMenuClick"
      class="dropdown-pane dropdown-pane--open"
      :class="`dropdown-pane--${menuPosition} ${forwardContextOptionLeft} ${forwardContextOptionRight}`"
    >
      <woot-dropdown-menu>
        <woot-dropdown-item>
          <woot-button
            variant="clear"
            size="small"
            :data-test-id="'copilot_bot_context_menu_reset_session'"
            @click="resetSession"
          >
            {{ $t('CONVERSATION_SIDEBAR.COPILOT_BOT.RESET_SESSION') }}
          </woot-button>
        </woot-dropdown-item>
        <woot-dropdown-item>
          <woot-button
            variant="clear"
            size="small"
            :data-test-id="'copilot_bot_context_menu_settings'"
            @click="navigateToSettings"
          >
            {{ $t('CONVERSATION_SIDEBAR.COPILOT_BOT.ASSIST_FLOW_SETTINGS') }}
          </woot-button>
        </woot-dropdown-item>
      </woot-dropdown-menu>
    </div>
  </div>
</template>
<script>
import { mixin as clickaway } from 'vue-clickaway';
import accountMixin from './../../mixins/account.js';
import { mapGetters } from 'vuex';

import WootDropdownItem from 'shared/components/ui/dropdown/DropdownItem.vue';
import WootDropdownMenu from 'shared/components/ui/dropdown/DropdownMenu.vue';

export default {
  components: {
    WootDropdownMenu,
    WootDropdownItem,
  },
  mixins: [clickaway, accountMixin],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    showCopy: {
      type: Boolean,
      default: false,
    },
    menuPosition: {
      type: String,
      default: 'left',
    },
    isPrivate: {
      type: Boolean,
      default: false,
    },
    showForwardContextMenuOption: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
    }),
    forwardContextOptionLeft() {
      return this.showForwardContextMenuOption && this.menuPosition === 'left'
        ? 'forward-context-option-left'
        : '';
    },
    forwardContextOptionRight() {
      return this.showForwardContextMenuOption && this.menuPosition === 'right'
        ? 'forward-context-option-right'
        : '';
    },
    currentInboxId() {
      return this.currentChat.inbox_id;
    },
  },
  methods: {
    handleContextMenuClick() {
      this.$emit('toggle', !this.isOpen);
    },
    navigateToSettings() {
      this.$router.push(
        this.addAccountScoping(`settings/inboxes/${this.currentInboxId}`)
      );
    },
    resetSession() {
      this.$parent.$emit('resetSession');
      this.handleContextMenuClick();
    },
    handleDelete() {
      this.$emit('delete');
    },
    handleSendMessageToAgentCopilotBot() {
      this.$emit('handleSendMessageToAgentCopilotBot');
    },
  },
};
</script>
<style lang="scss" scoped>
.context-menu-container {
  margin-top: 10px;
}

.dropdown-pane--left {
  right: 30px;
}

.forward-context-option-right {
  left: -240px;
}

.forward-context-option-left {
  right: -230px;
}
</style>
