<template>
  <div class="flex-container actions--container">
    <woot-button
      v-if="isAdminOrSupervisor"
      v-tooltip.top="$t('CONVERSATION.HISTORY.DELETE.TITLE')"
      data-test-id="history_delete_button"
      variant="smooth"
      color-scheme="alert"
      size="tiny"
      icon="dismiss-circle"
      class-names="grey-btn"
      :disabled="currentChat.messages.length < 1"
      @click="openCloseChatHistoryModal"
    />
    <woot-button
      v-if="!currentChat.muted"
      v-tooltip="$t('CONTACT_PANEL.MUTE_CONTACT')"
      variant="clear"
      color-scheme="secondary"
      icon="speaker-mute"
      @click="mute"
    />
    <woot-button
      v-else
      v-tooltip.left="$t('CONTACT_PANEL.UNMUTE_CONTACT')"
      variant="clear"
      color-scheme="secondary"
      icon="speaker-1"
      @click="unmute"
    />
    <woot-button
      v-tooltip="$t('CONTACT_PANEL.SEND_TRANSCRIPT')"
      variant="clear"
      color-scheme="secondary"
      icon="share"
      @click="toggleEmailActionsModal"
    />
    <resolve-action
      :conversation-id="currentChat.id"
      :status="currentChat.status"
    />
    <email-transcript-modal
      v-if="showEmailActionsModal"
      :show="showEmailActionsModal"
      :current-chat="currentChat"
      @cancel="toggleEmailActionsModal"
    />
    <woot-confirm-modal
      ref="confirmClearChatHistoryModal"
      :title="confirmClearChatHistoryTitle"
      :description="confirmClearChatHistoryDescription"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { mixin as clickaway } from 'vue-clickaway';
import alertMixin from 'shared/mixins/alertMixin';
import EmailTranscriptModal from './EmailTranscriptModal.vue';
import ResolveAction from '../../buttons/ResolveAction.vue';
import {
  CMD_MUTE_CONVERSATION,
  CMD_SEND_TRANSCRIPT,
  CMD_UNMUTE_CONVERSATION,
} from '../../../routes/dashboard/commands/commandBarBusEvents';
import wootConstants from '../../../constants';
import WootButton from 'components/ui/WootButton.vue';
import WootConfirmModal from 'components/widgets/modal/ConfirmationModal.vue';

export default {
  components: {
    WootConfirmModal,
    WootButton,
    EmailTranscriptModal,
    ResolveAction,
  },
  mixins: [alertMixin, clickaway],
  data() {
    return {
      showEmailActionsModal: false,
      showClearChatHistoryModal: false,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      currentRoles: 'getCurrentRoles',
    }),
    isAdminOrSupervisor() {
      return (
        this.currentRoles.includes('administrator') ||
        this.currentRoles.includes('supervisor')
      );
    },
    confirmClearChatHistoryTitle() {
      return `${this.$t('CONVERSATION.HISTORY.DELETE.TITLE')}`;
    },
    confirmClearChatHistoryDescription() {
      return `${this.$t('CONVERSATION.HISTORY.DELETE.DESCRIPTION')}`;
    },
  },
  mounted() {
    bus.$on(CMD_MUTE_CONVERSATION, this.mute);
    bus.$on(CMD_UNMUTE_CONVERSATION, this.unmute);
    bus.$on(CMD_SEND_TRANSCRIPT, this.toggleEmailActionsModal);
  },
  destroyed() {
    bus.$off(CMD_MUTE_CONVERSATION, this.mute);
    bus.$off(CMD_UNMUTE_CONVERSATION, this.unmute);
    bus.$off(CMD_SEND_TRANSCRIPT, this.toggleEmailActionsModal);
  },
  methods: {
    mute() {
      this.$store.dispatch('muteConversation', this.currentChat.id);
      this.showAlert(this.$t('CONTACT_PANEL.MUTED_SUCCESS'), {
        status: wootConstants.SNACKBAR_TYPE.SUCCESS,
      });
    },
    unmute() {
      this.$store.dispatch('unmuteConversation', this.currentChat.id);
      this.showAlert(this.$t('CONTACT_PANEL.UNMUTED_SUCCESS'), {
        status: wootConstants.SNACKBAR_TYPE.SUCCESS,
      });
    },
    async clearConversationChatHistory() {
      try {
        await this.$store.dispatch(
          'clearConversationChatHistory',
          this.currentChat.id
        );
        this.showAlert(
          this.$t('CONVERSATION.HISTORY.DELETE.API.SUCCESS_MESSAGE'),
          {
            status: wootConstants.SNACKBAR_TYPE.SUCCESS,
          }
        );
      } catch (error) {
        const errorMessage =
          error?.response?.message ||
          this.$t('CONVERSATION.HISTORY.DELETE.API.ERROR_MESSAGE');
        this.showAlert(errorMessage, {
          status: wootConstants.SNACKBAR_TYPE.ERROR,
        });
      }
    },
    async openCloseChatHistoryModal() {
      this.showClearChatHistoryModal = true;

      const ok =
        await this.$refs.confirmClearChatHistoryModal.showConfirmation();

      if (ok) {
        await this.clearConversationChatHistory();
        this.closeClearChatHistoryModal();
      }
    },
    closeClearChatHistoryModal() {
      this.showClearChatHistoryModal = false;
    },
    toggleEmailActionsModal() {
      this.showEmailActionsModal = !this.showEmailActionsModal;
    },
  },
};
</script>
<style scoped lang="scss">
.actions--container {
  align-items: center;

  .resolve-actions {
    margin-left: var(--space-small);
  }
}

.more--button {
  align-items: center;
  display: flex;
  margin-left: var(--space-small);
}

.actions--container {
  position: relative;
}

.dropdown-pane {
  right: var(--space-minus-small);
  top: 48px;
}

.icon {
  margin-right: var(--space-smaller);
  min-width: var(--space-normal);
}
</style>
