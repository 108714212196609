<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { get } from 'lodash';
import BubbleActions from './bubble/Actions.vue';
import { Message } from '../../../store/types/message/message.types';

interface Props {
  messageData: Message;
  readableTime: string;
  storySender?: string | null;
  storyId?: string | null;
  testId?: string;
}

const props = withDefaults(defineProps<Props>(), {
  testId: `cognigy-x-apps-message`,
  storyId: null,
  storySender: null,
});

const content = ref('');

const camelToReadable = (str: string) => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/^./, match => match.toUpperCase());
};

const generateXAppsContent = (
  data: any,
  level: number = 0,
  context: string | undefined = undefined
) => {
  let render = '';

  if (Array.isArray(data) && data.length === 0) {
    render += `${generateXAppsContent(data, level)}`;
  } else if (typeof data === 'object' && data !== null) {
    render += '<ul>';
    Object.keys(data).forEach(key => {
      if (Object.hasOwn(data, key)) {
        const value = data[key];

        if (Array.isArray(value)) {
          render += `<li>${camelToReadable(key)}: ${generateXAppsContent(
            value,
            level,
            'array'
          )}</li>`;
        } else if (typeof value === 'object' && value !== null) {
          if (!Number.isNaN(Number(key)) && context === 'array') {
            render += `${generateXAppsContent(value, level, 'array')}`;
          } else {
            render += `<li>${camelToReadable(key)}: <ul>${generateXAppsContent(
              value,
              level
            )}</ul></li>`;
          }
        } else {
          render += `<li>${camelToReadable(key)}: ${value}</li>`;
        }
      }
    });
    render += '</ul>';
  } else {
    render += `<li>${data}</li>`;
  }

  return render;
};

const renderXAppsContent = () => {
  const xAppsContent = get(
    props?.messageData?.content_attributes?.items?.[0],
    ['data', '_cognigy', '_app', 'payload'],
    {}
  );
  content.value = `<b>X-Apps Input</b>: <br>${generateXAppsContent(
    xAppsContent
  )}`;
};

onMounted(() => {
  renderXAppsContent();
});
</script>

<template>
  <div class="x-apps-content-wrap" :data-test-id="testId">
    <div class="x-apps-content" v-html="content" />
    <bubble-actions
      :id="messageData.id"
      :sender="messageData.sender"
      :story-sender="storySender"
      :story-id="storyId"
      :is-private="messageData.private"
      :message-type="messageData.message_type"
      :readable-time="readableTime"
      :source-id="messageData.source_id"
      :inbox-id="messageData.inbox_id"
      :message-read="false"
    />
  </div>
</template>

<style scoped lang="scss">
.x-apps-content-wrap {
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
}
.x-apps-content {
  margin-top: 8px;
  margin-left: 10px;
  font-size: 13px;
  line-height: 1.5;
  padding: var(--space-medium);
  border: 1px solid var(--s-100);
  border-radius: var(--border-radius-medium);
  background: #e0e4ee;
}
</style>
