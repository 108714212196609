export default str => {
  const profileKeyToPlaceholder = {
    firstname: 'Jane',
    lastname: 'Doe',
    email: 'jane.doe@domain.com',
    profilepic: 'Profile Picture URL',
    age: '25',
    birthday: '31-12-1998',
    gender: 'Female',
    goals: 'TestGoalA,TestGoalB',
    location: 'New York',
    prevent_data_collection: 'true',
    accepted_gdpr: 'Yes',
  };

  return profileKeyToPlaceholder[str] || '';
};
