<template>
  <tr :data-testid="dataTestid">
    <!-- Attachment data -->
    <td>
      <span>
        {{ fileName(attachment) }}
      </span>
    </td>
    <td>
      <router-link
        :data-testid="`inbox-link-${dataTestid}`"
        :to="
          frontendURL(
            `accounts/${attachment.account_id}/inbox/${attachment.inbox_id}`
          )
        "
      >
        {{ getInboxNameById(attachment.inbox_id) }}
      </router-link>
    </td>
    <td>
      <router-link
        :data-testid="`conversation-link-${dataTestid}`"
        :to="
          frontendURL(
            `accounts/${attachment.account_id}/conversations/${attachment.conversation_id}`
          )
        "
      >
        {{ attachment.conversation_id }}
      </router-link>
    </td>
    <td>
      <router-link
        :data-testid="`cognigy-session-id-link-${dataTestid}`"
        :to="
          frontendURL(
            `accounts/${attachment.account_id}/conversations/${attachment.conversation_id}`
          )
        "
      >
        {{ attachment.cognigy_session_id }}
      </router-link>
    </td>
    <td>
      <span>
        {{ format(new Date(attachment.created_at), 'dd-MM-yyyy HH:mm:ss') }}
      </span>
    </td>
    <!-- Actions -->
    <td>
      <woot-button
        v-tooltip.top="$t('ATTACHMENTS_MGMT.DOWNLOAD.BUTTON_TEXT')"
        variant="smooth"
        color-scheme="secondary"
        size="tiny"
        icon="arrow-download"
        class-names="grey-btn"
        :data-testid="`download-button-${dataTestid}`"
        @click="openAttachmentLink(attachment.url)"
      />
      <woot-button
        v-tooltip.top="$t('ATTACHMENTS_MGMT.DELETE.BUTTON_TEXT')"
        variant="smooth"
        color-scheme="alert"
        size="tiny"
        icon="dismiss-circle"
        class-names="grey-btn"
        :is-loading="loading"
        :data-testid="`delete-button-${dataTestid}`"
        @click="openDeletePopup(attachment)"
      />
    </td>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex';
import format from 'date-fns/format';
import { frontendURL } from '../../../../../helper/URLHelper';
import attachmentsMixin from '../mixins/attachmentsMixin';
import WootButton from '../../../../../components/ui/WootButton.vue';

export default {
  components: { WootButton },
  mixins: [attachmentsMixin],
  props: {
    attachment: {
      type: Object,
      required: true,
    },
    openDeletePopup: {
      type: Function,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      inboxesList: 'inboxes/getInboxes',
    }),
    dataTestid() {
      return `attachment-${this.attachment.id}`;
    },
  },
  methods: {
    frontendURL,
    format,
    openAttachmentLink(url) {
      const win = window.open(url, '_blank', 'noopener');
      if (win) win.focus();
    },
    getInboxNameById(id) {
      return this.inboxesList.find(inbox => inbox.id === id)?.name || '';
    },
  },
};
</script>
