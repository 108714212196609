<template>
  <div class="medium-9">
    <label class="medium-9 columns settings-item container">
      {{ $t('INBOX_MGMT.COPILOT_BOT.COPILOT_BOTS') }}
    </label>
    <table>
      <tbody>
        <tr v-for="bot in copilotBots" :key="bot.id">
          <td>{{ bot.name }}</td>
          <td>
            <div class="button-wrapper button-container">
              <woot-button
                v-tooltip.top="$t('INBOX_MGMT.SETTINGS')"
                variant="smooth"
                size="tiny"
                icon="settings"
                color-scheme="secondary"
                class-names="grey-btn"
                :data-test-id="`${bot.name}_settings_button`"
                @click="editCopilotBot(bot)"
              />
              <woot-button
                v-tooltip.top="$t('INBOX_MGMT.DELETE.BUTTON_TEXT')"
                variant="smooth"
                color-scheme="alert"
                size="tiny"
                class-names="grey-btn"
                :data-test-id="`${bot.name}_delete_button`"
                icon="dismiss-circle"
                @click="removeCopilotBot(bot.id)"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <woot-button
      class="button"
      :data-test-id="'add_copilot_bot_button'"
      @click="openCopilotBotConfiguration"
    >
      {{ $t('INBOX_MGMT.COPILOT_BOT.ADD_COPILOT_BOTS') }}
    </woot-button>
    <copilot-bots-configuration-popup
      v-if="showCopilotBotConfiguration"
      :copilot-bot="copilotBotToEdit"
      :is-editing="isEditing"
      :show="showCopilotBotConfiguration"
      @closePopup="closeCopilotBotConfiguration"
    />
    <copilot-bots-delete-popup
      v-if="showCopilotBotDelete"
      :copilot-bot-id="copilotBotToDeleteId"
      :show="showCopilotBotDelete"
      @closePopup="closeCopilotBotDelete"
    />
  </div>
</template>

<script>
import CopilotBotsConfigurationPopup from './CopilotBotsConfigurationPopup.vue';
import CopilotBotsDeletePopup from './CopilotBotsDeletePopup.vue';

export default {
  components: {
    CopilotBotsConfigurationPopup,
    CopilotBotsDeletePopup,
  },
  data() {
    return {
      showCopilotBotConfiguration: false,
      showCopilotBotDelete: false,
      copilotBotToDeleteId: null,
      copilotBotToEdit: null,
      isEditing: false,
    };
  },
  computed: {
    copilotBotsTags() {
      return this.copilotBots.map(bot => ({ text: bot.name, id: bot.id }));
    },
    currentInboxId() {
      return this.$route.params.inboxId;
    },
    copilotBots() {
      return this.$store.getters['inboxCopilotBots/getCopilotBots'](
        this.currentInboxId
      );
    },
  },
  watch: {
    currentInboxId() {
      if (this.currentInboxId) {
        this.fetchInboxCopilotBots();
      }
    },
  },
  mounted() {
    this.fetchInboxCopilotBots();
  },
  methods: {
    removeCopilotBot(copilotBotId) {
      this.copilotBotToDeleteId = copilotBotId;
      this.openCopilotBotDelete();
    },
    editCopilotBot(copilotBot) {
      this.isEditing = true;
      this.copilotBotToEdit = copilotBot;
      this.openCopilotBotConfiguration();
    },
    closeCopilotBotConfiguration() {
      this.copilotBotToEditId = null;
      this.isEditing = false;
      this.showCopilotBotConfiguration = false;
    },
    openCopilotBotConfiguration() {
      this.showCopilotBotConfiguration = true;
    },
    closeCopilotBotDelete() {
      this.copilotBotToDeleteId = null;
      this.showCopilotBotDelete = false;
    },
    openCopilotBotDelete() {
      this.showCopilotBotDelete = true;
    },
    async fetchInboxCopilotBots() {
      await this.$store.dispatch('inboxCopilotBots/fetch', {
        inboxId: this.currentInboxId,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.input {
  border: 1px solid var(--s-75);
  border-radius: var(--border-radius-normal);
  max-width: none;
}

.button {
  width: 100px;
  margin-top: 10px;
  align-self: flex-start;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}
</style>
