<template>
  <div class="file message-text__wrap">
    <div class="icon-wrap">
      <fluent-icon icon="book-contacts" class="file--icon" size="32" />
    </div>
    <div class="meta">
      <h5 class="text-block-title">
        <span v-if="name">{{ `${name}` }}</span>
        <span v-if="lastName">{{ `${lastName}` }}</span>
      </h5>
      <a
        class="download clear link button small"
        data-test-id="contact-details-modal-button"
        @click="showDetails"
      >
        {{ $t('CONVERSATION.ATTACHMENTS.CONTACT.CONTACT_DETAILS') }}
      </a>
    </div>
    <contact-modal
      :data="contactData"
      :show="detailsModalIsOpen"
      @close="onClose"
    />
  </div>
</template>

<script>
import ContactModal from '../../modal/ContactModal.vue';
export default {
  name: 'BubbleContact',
  components: { ContactModal },
  props: {
    contactData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      detailsModalIsOpen: false,
    };
  },
  computed: {
    name() {
      return (
        this.contactData.name?.first_name || this.contactData.name?.firstName
      );
    },
    lastName() {
      return (
        this.contactData.name?.last_name || this.contactData.name?.lastName
      );
    },
  },
  methods: {
    onClose() {
      this.detailsModalIsOpen = false;
    },
    showDetails() {
      this.detailsModalIsOpen = true;
    },
  },
};
</script>
