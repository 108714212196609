import ApiClient, { axiosWithAuth } from './ApiClient';

class Agents extends ApiClient {
  constructor() {
    super('agents', { accountScoped: true });
  }

  get({ page = null, query = null, minified = null } = {}) {
    const params = {};
    if (page !== null) params.page = page;
    if (query !== null) params.query = query;
    if (minified !== null) params.minified = minified;

    return axiosWithAuth.get(this.url, { params });
  }

  getUnmaskedAgents({ page, query, minified }) {
    const finalUrl = `${this.url}?agent_masking_disabled=true`;
    return axiosWithAuth.get(finalUrl, { params: { page, query, minified } });
  }

  updateAgentSkills({ agentId, skills }) {
    return axiosWithAuth.patch(`${this.url}/${agentId}/skills`, {
      skill_ids: skills,
    });
  }
}

export default new Agents();
