import ApiClient, { axiosWithAuth } from './ApiClient';

class AssignableAgents extends ApiClient {
  constructor() {
    super('assignable_agents', { accountScoped: true });
  }

  get(inboxIds?: string[] | number[]) {
    return axiosWithAuth.get(this.url, {
      params: { inbox_ids: inboxIds },
    });
  }
}

export default new AssignableAgents();
