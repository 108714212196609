export const OPERATOR_TYPES_1 = ['equal_to', 'not_equal_to'];

export const OPERATOR_TYPES_2 = [
  'equal_to',
  'not_equal_to',
  'contains',
  'does_not_contain',
];

export const OPERATOR_TYPES_3 = [
  'equal_to',
  'not_equal_to',
  'is_present',
  'is_not_present',
];

export const OPERATOR_TYPES_4 = [
  'equal_to',
  'not_equal_to',
  'is_present',
  'is_not_present',
  'is_greater_than',
  'is_less_than',
];

export const OPERATOR_TYPES_5 = [
  'is_greater_than',
  'is_less_than',
  'days_before',
];
