<template>
  <div>
    <div>
      <input
        :id="`number-input-type-none-${uniqueId}`"
        v-model="customNumberInputOption"
        :data-test-id="`${dataTestId}-number-input-type-none`"
        class="notification--checkbox"
        type="radio"
        value="none"
      />
      <label :for="`number-input-type-none-${uniqueId}`">
        {{ noneInputLabel }}
      </label>
    </div>
    <div>
      <input
        :id="`number-input-type-custom_${uniqueId}`"
        v-model="customNumberInputOption"
        :data-test-id="`${dataTestId}-number-input-type-custom`"
        class="notification--checkbox"
        type="radio"
        value="custom"
      />
      <label :for="`number-input-type-custom_${uniqueId}`">
        {{ customInputLabel }}
      </label>
    </div>
    <div class="custom-number-input">
      <label :class="{ error: $v.customNumberInput.$error }">
        {{ generalLabel }}
        <input
          v-model="customNumberInput"
          :data-test-id="`${dataTestId}-number-input-type-number`"
          type="number"
          :disabled="customNumberInputOption !== 'custom'"
          @blur="$v.customNumberInput.$touch"
        />
        <span
          v-if="
            $v.customNumberInput.$error && customNumberInputOption === 'custom'
          "
          :data-test-id="`${dataTestId}-number-input-error`"
          class="message"
        >
          {{ errorMessage }}
        </span>
      </label>
    </div>
  </div>
</template>

<script>
const validateCustomNumberInput = (_, vm) => {
  return (
    vm.customNumberInput >= vm.lowerGuard &&
    vm.customNumberInput <= vm.upperGuard
  );
};
export default {
  props: {
    dataTestId: {
      type: String,
      default: '',
    },
    noneInputLabel: {
      type: String,
      default: '',
    },
    customInputLabel: {
      type: String,
      default: '',
    },
    generalLabel: {
      type: String,
      default: '',
    },
    errorMessage: {
      type: String,
      default: '',
    },
    value: {
      type: [Number, String],
      default: null,
    },
    lowerGuard: {
      type: Number,
      default: 1,
    },
    upperGuard: {
      type: Number,
      default: 999,
    },
  },
  validations: {
    customNumberInput: { validateCustomNumberInput },
  },
  data() {
    return {
      // unique id is needed for the labels to ensure that they are referring to unique input ids. Otherwise, if there are two elements on the same page, clicking one label could trigger an unintended input
      uniqueId: `${Math.ceil(Math.random() * 100000)}_${Math.ceil(
        Math.random() * 100000
      )}`,
    };
  },
  computed: {
    customNumberInput: {
      get() {
        if (this.value !== null) {
          return this.value;
        }
        return 1;
      },
      set(val) {
        const parsedNumber = Number(val);
        this.$emit('input', parsedNumber);
      },
    },
    customNumberInputOption: {
      get() {
        return this.value !== null ? 'custom' : 'none';
      },
      set(val) {
        if (val === 'custom') {
          this.$emit('input', this.customNumberInput);
        } else {
          this.$emit('input', null);
        }
      },
    },
  },
  methods: {},
};
</script>

<style lang="scss">
@import 'dashboard/assets/scss/variables.scss';
@import 'dashboard/assets/scss/mixins.scss';

.custom-number-input {
  padding: 0.5rem 1.5rem;
}
</style>
