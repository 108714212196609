import WootLogin from './Login.vue';
import LoginOAuth from './LoginOAuth.vue';
import { frontendURL } from '../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('old-login'),
      name: 'login',
      component: WootLogin,
      props: route => ({
        config: route.query.config,
        email: route.query.email,
        ssoAuthToken: route.query.sso_auth_token,
        redirectUrl: route.query.route_url,
        ssoAccountId: route.query.sso_account_id,
      }),
    },
    {
      path: frontendURL('login'),
      name: 'login_oauth',
      component: LoginOAuth,
      props: route => ({
        config: route.query.config,
        email: route.query.email,
        ssoAuthToken: route.query.sso_auth_token,
        redirectUrl: route.query.route_url,
      }),
    },
  ],
};
