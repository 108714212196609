<template>
  <li
    :data-test-id="`message_item_${data.id}`"
    :class="{ 'bot-message': isSentByBot, 'is-audio-message': isAudio }"
    @click="handleMessageClick"
  >
    <div v-if="data.error" class="error-message">
      <fluent-icon icon="warning" size="30" view-box="0 0 40 40" />
      <span>
        {{ data.message.content }}
      </span>
    </div>
    <agent-copilot-bot-cognigy-structured-content-message
      v-if="contentType === 'cognigy' && !data.message.content"
      :content-type="contentType"
      :message-data="data.message"
      @forwardAssistMessagePostback="forwardAssistMessagePostback"
    />
    <div v-else-if="!data.error" class="wrap" :class="alignment">
      <div :class="bubbleClass">
        <bubble-text :message="data.message.content" />
      </div>
    </div>
    <div v-if="isSentByBot" class="icon">
      <fluent-icon icon="forward" size="60" view-box="0 0 40 40" />
    </div>
    <woot-button
      v-tooltip.right="$t('AGENT_ASSIST.RAW_DATA')"
      class="raw-data"
      icon="code"
      @click.stop="showRawData"
    />
  </li>
</template>
<script>
import BubbleText from '../widgets/conversation/bubble/Text.vue';
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import AgentCopilotBotCognigyStructuredContentMessage from './AgentCopilotBotCognigyStructuredContentMessage.vue';
import { BUS_EVENTS } from 'shared/constants/busEvents';

export default {
  components: {
    BubbleText,
    AgentCopilotBotCognigyStructuredContentMessage,
  },
  mixins: [messageFormatterMixin],
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showContextMenu: false,
      hasImageError: false,
      collapsed: false,
      stopForwarding: false,
    };
  },
  computed: {
    contentAttributes() {
      return this.data.content_attributes || {};
    },
    sender() {
      return this.data.message.sender.type || {};
    },

    contentType() {
      return this.data.message.content_type;
    },

    getCognigyTestId() {
      return `cognigy-bubble-${this.data.inbox_id}-${this.data.conversation_id}-${this.data.id}`;
    },

    hasText() {
      return !!this.data.message.content;
    },

    bubbleClass() {
      return {
        'agent-copilot-bot-bubble': true,
        'is-text': this.hasText,
        'is-from-bot': this.isSentByBot,
        'is-from-user': this.isSentByUser,
      };
    },

    isAudio() {
      return (
        // eslint-disable-next-line no-underscore-dangle
        this.data?.message?.content_attributes?.items[0].data?._cognigy
          ?._webchat?.message?.attachment?.type === 'audio' ||
        // eslint-disable-next-line no-underscore-dangle
        this.data?.message?.content_attributes?.items[0].data?._cognigy
          ?._defaultPreview?.message?.attachment?.type === 'audio'
      );
    },

    isSentByBot() {
      return this.sender === 'agent_bot';
    },
    isSentByUser() {
      return this.sender === 'user';
    },

    alignment() {
      return {
        'bot-align': this.isSentByBot,
        'user-align': this.isSentByUser,
      };
    },
  },
  created() {
    bus.$on(BUS_EVENTS.STOP_FORWARDING, () => {
      this.stopForwarding = true;
    });
  },
  methods: {
    handleMessageClick() {
      if (!this.isSentByBot) return;

      if (!this.stopForwarding) {
        bus.$emit(BUS_EVENTS.ADD_MESSAGE_TO_CHAT_INPUT, this.data.message);
      }
      this.stopForwarding = false;
    },
    forwardAssistMessagePostback(message) {
      bus.$emit(BUS_EVENTS.ADD_POSTBACK_TO_CHAT_INPUT, message);
    },
    showRawData() {
      this.$parent.$emit('onClickRawDataButton', this.data.message);
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  flex-direction: column;

  > .agent-copilot-bot-bubble {
    word-break: break-word;
    min-height: 54.3px;
    max-width: 20rem;
    padding: 17px 24px;
    font-size: 1.4rem;
    display: inline-block;
    text-align: left !important;

    &.is-from-bot {
      color: white;
      background: #0b3694;
      border-radius: 16px 16px 16px 0px;
      margin-left: 18px;
    }

    &.is-from-user {
      background: #ffffff;
      border-radius: 16px 16px 0px;
      border: 1px solid var(--s-75);
      margin-right: 8px;
    }
  }
}

.bot-message {
  min-height: 100px;
  position: relative;
  &:hover {
    background: #f1f5f9;
    cursor: pointer;

    .icon {
      visibility: visible;
    }
    .raw-data {
      visibility: visible;
    }
  }

  div[data-test-id^='agent_assist_'] {
    padding-left: 15px !important;
  }
}
.user-align {
  text-align: end;
}
.bot-align {
  text-align: start;
}
.icon {
  visibility: hidden;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
  margin-top: 10px;
  transition: background-color 0.3s ease-out;
  padding-top: 11px;
  padding-right: 13px;
  position: absolute;
  right: 5px;
  top: -8px;
  transform: scale(-1, 1);

  svg {
    z-index: 2;
    color: #0b3694;
  }
}

.error-message {
  font-size: 14px;
  padding: 25px;
  background-color: #f1f5f8;
  display: flex;
  flex-direction: column;
}

.raw-data {
  visibility: hidden;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  width: 30px !important;
  height: 30px;
  margin-top: 10px;
  transition: background-color 0.3s ease-out;
  z-index: 2;
  border-radius: 50%;
  background-color: #e2e2e2 !important;
  right: 15px;
  position: absolute;
  bottom: 15px;

  &:hover {
    background-color: #0b3694 !important;
  }
  svg {
    z-index: 2;
    color: #ffff;
  }
}

.is-audio-message {
  height: 100px;
}
</style>
