import { AxiosError } from 'axios';

export const isAxiosError = (
  error: unknown
): error is AxiosError<{
  message: string;
  attributes: Record<string, string>;
}> =>
  !!(
    error as AxiosError<{
      message: string;
      attributes: Record<string, string>;
    }>
  ).response?.data?.message;
