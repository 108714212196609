import ApiClient, { axiosWithAuth } from './ApiClient';

class AccountAPI extends ApiClient {
  constructor() {
    super('', { accountScoped: true });
  }

  createAccount(data) {
    return axiosWithAuth.post(`${this.apiVersion}/accounts`, data);
  }
}

export default new AccountAPI();
