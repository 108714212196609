import ApiClient, { axiosWithAuth } from './ApiClient';

class NotificationSubscriptions extends ApiClient {
  constructor() {
    super('notification_subscriptions');
  }

  delete(data) {
    return axiosWithAuth.delete(`${this.url}`, { data });
  }
}

export default new NotificationSubscriptions();
