import ApiClient, { axiosWithAuth } from './ApiClient';

class Inboxes extends ApiClient {
  constructor() {
    super('inboxes', { accountScoped: true });
  }

  deleteInboxAvatar(inboxId) {
    return axiosWithAuth.delete(`${this.url}/${inboxId}/avatar`);
  }
}

export default new Inboxes();
