<template>
  <div class="settings--content">
    <settings-section
      :title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_IDENTIFIER')"
      :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_IDENTIFIER_SUB_TEXT')"
    >
      <woot-code
        :script="inbox.inbox_identifier"
        data-test-id="inbox_identifier_field"
      />
    </settings-section>
    <settings-section
      :title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_ID')"
      :sub-title="$t('INBOX_MGMT.SETTINGS_POPUP.INBOX_ID_SUB_TEXT')"
    >
      <woot-code
        :script="inbox.id ? String(inbox.id) : ''"
        data-test-id="inbox_id_field"
      />
    </settings-section>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import SettingsSection from '../../../../../components/SettingsSection.vue';

export default {
  components: {
    SettingsSection,
  },
  mixins: [alertMixin],
  props: {
    inbox: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>
