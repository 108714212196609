/* eslint no-console: 0 */
import ApiClient, { axiosWithAuth } from '../ApiClient';

export const buildCreatePayload = ({
  bccEmails = '',
  ccEmails = '',
  content_type,
  contentAttributes,
  echoId,
  files,
  isPrivate,
  message_type,
  message,
  templateParams,
}) => {
  let payload;
  if (files && files.length !== 0) {
    payload = new FormData();
    if (message) {
      payload.append('content', message);
    }
    files.forEach(file => {
      payload.append('attachments[]', file);
    });
    payload.append('private', isPrivate);
    payload.append('echo_id', echoId);
    payload.append('cc_emails', ccEmails);
    payload.append('bcc_emails', bccEmails);

    if (contentAttributes) {
      payload.append('content_attributes', JSON.stringify(contentAttributes));
    }
  } else {
    payload = {
      bcc_emails: bccEmails,
      cc_emails: ccEmails,
      content_attributes: contentAttributes,
      content_type,
      content: message,
      echo_id: echoId,
      message_type,
      private: isPrivate,
      template_params: templateParams,
    };
  }
  return payload;
};

class MessageApi extends ApiClient {
  constructor() {
    super('conversations', { accountScoped: true });
  }

  create({
    conversationId,
    message,
    private: isPrivate,
    contentAttributes,
    echo_id: echoId,
    content_type,
    files,
    ccEmails = '',
    bccEmails = '',
    message_type,
    templateParams,
  }) {
    return axiosWithAuth.post(
      `${this.url}/${conversationId}/messages`,
      buildCreatePayload({
        message,
        content_type,
        isPrivate,
        contentAttributes,
        echoId,
        files,
        ccEmails,
        bccEmails,
        message_type,
        templateParams,
      })
    );
  }

  delete(conversationID, messageId) {
    return axiosWithAuth.delete(
      `${this.url}/${conversationID}/messages/${messageId}`
    );
  }

  getPreviousMessages({ conversationId, before }) {
    return axiosWithAuth.get(`${this.url}/${conversationId}/messages`, {
      params: { before },
    });
  }

  getNextMessages({ conversationId, after }) {
    return axiosWithAuth.get(`${this.url}/${conversationId}/messages`, {
      params: { after },
    });
  }
}

export default new MessageApi();
