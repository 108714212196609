import getCognigyMessageType from '../helpers/getCognigyMessageType.js';

export default {
  computed: {
    getCognigyTestId() {
      return `cognigy-bubble-${this.messageData.inbox_id}-${this.messageData.conversation_id}-${this.messageData.id}`;
    },
    cognigyMessageType() {
      return getCognigyMessageType(this.messageData);
    },
    contentAttributes() {
      return this.messageData.content_attributes || {};
    },
  },
};
