<template>
  <woot-modal :show="show" :on-close="onCancel">
    <div class="column content-box">
      <woot-modal-header
        :header-title="headerTitle"
        :header-content="headerContent"
      />
      <form @submit.prevent="onSubmit">
        <slot />
        <div class="flex">
          <div>
            <woot-submit-button
              size="medium"
              :button-text="primaryButtonText"
              :data-test-id="'modal_submit_button'"
            />
            <woot-button
              size="medium"
              class="button clear"
              @click.prevent="onCancel"
            >
              {{ secondaryButtonText }}
            </woot-button>
          </div>
        </div>
      </form>
    </div>
  </woot-modal>
</template>

<script>
import WootModal from 'components/Modal.vue';
import WootModalHeader from 'components/ModalHeader.vue';
import WootButton from 'components/ui/WootButton.vue';
import WootSubmitButton from 'components/buttons/FormSubmitButton.vue';

export default {
  components: { WootSubmitButton, WootButton, WootModalHeader, WootModal },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    headerTitle: {
      type: String,
      default: '',
    },
    headerContent: {
      type: String,
      default: '',
    },
    primaryButtonText: {
      type: String,
      default: '',
    },
    secondaryButtonText: {
      type: String,
      default: '',
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel');
    },
    onSubmit() {
      this.$emit('submit');
    },
  },
};
</script>

<style scoped></style>
