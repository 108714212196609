import { removePushNotificationSubscription } from 'dashboard/helper/pushHelper';
import Auth from '../api/auth';

export const logoutMixin = {
  methods: {
    async logout() {
      await this.$store.dispatch('updateAvailability', {
        availability: 'offline',
        account_id: this.accountId,
      });

      removePushNotificationSubscription();

      await Auth.logout();
    },
  },
};

export default logoutMixin;
