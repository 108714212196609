export default {
  computed: {
    getTeamTitleErrorMessage() {
      let errorMessage = '';
      if (!this.$v.title.$error) {
        errorMessage = '';
      } else if (!this.$v.title.required) {
        errorMessage = this.$t('TEAMS_SETTINGS.FORM.NAME.REQUIRED_ERROR');
      } else if (!this.$v.title.minLength) {
        errorMessage = this.$t('TEAMS_SETTINGS.FORM.NAME.MINIMUM_LENGTH_ERROR');
      } else if (!this.$v.title.validNameIsSafe) {
        errorMessage = this.$t('TEAMS_SETTINGS.FORM.NAME.VALIDATION_ERROR');
      }
      return errorMessage;
    },
  },
};
