<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header :header-title="$t('SKILLS_MGMT.ADD.TITLE')" />
      <form class="row" @submit.prevent="addNewSkill()">
        <div class="medium-12 columns">
          <label :class="{ error: $v.name.$error }">
            {{ $t('SKILLS_MGMT.ADD.FORM.NAME.LABEL') }}
            <input
              v-model.trim="name"
              type="text"
              :placeholder="$t('SKILLS_MGMT.ADD.FORM.NAME.PLACEHOLDER')"
              data-test-id="skill-add-name-input"
              @input="$v.name.$touch"
            />
          </label>
        </div>

        <div class="medium-12 columns">
          <label>
            {{ $t('SKILLS_MGMT.ADD.FORM.TYPE.LABEL') }}
            <select v-model="type" data-test-id="skill-add-type-select">
              <option
                v-for="option in options"
                :key="option.key"
                :data-test-id="`skill-add-type-${option.value}`"
                :value="option.value"
              >
                {{ $t(option.label) }}
              </option>
            </select>
          </label>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="$v.name.$invalid || addSkill.showLoading"
              data-test-id="skill-add-submit-button"
              :button-text="$t('SKILLS_MGMT.ADD.FORM.SUBMIT')"
              :loading="addSkill.showLoading"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('SKILLS_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';

import WootSubmitButton from '../../../../components/buttons/FormSubmitButton.vue';
import Modal from '../../../../components/Modal.vue';
import alertMixin from 'shared/mixins/alertMixin';
import wootConstants from '../../../../constants';

export default {
  components: {
    WootSubmitButton,
    Modal,
  },
  mixins: [alertMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      name: '',
      type: 'skill',
      vertical: 'bottom',
      horizontal: 'center',
      addSkill: {
        showLoading: false,
        message: '',
      },
      show: true,
    };
  },
  validations: {
    name: {
      required,
      minLength: minLength(2),
    },
    type: {
      required,
    },
  },
  methods: {
    resetForm() {
      this.name = '';
      this.type = '';
      this.$v.name.$reset();
      this.$v.type.$reset();
    },
    addNewSkill() {
      // Show loading on button
      this.addSkill.showLoading = true;
      // Make API Calls
      this.$store
        .dispatch('skills/createSkill', {
          name: this.name,
          skill_type: this.type,
          id: Date.now().toString(36),
        })
        .then(() => {
          // Reset Form, Show success message
          this.addSkill.showLoading = false;
          this.showAlert(this.$t('SKILLS_MGMT.ADD.API.SUCCESS_MESSAGE'), {
            status: wootConstants.SNACKBAR_TYPE.SUCCESS,
          });
          this.resetForm();
          this.onClose();
        })
        .catch(error => {
          this.addSkill.showLoading = false;
          const errorMessage =
            error.message === 'Name has already been taken'
              ? this.$t('SKILLS_MGMT.ADD.API.ERROR_MESSAGE_NAME_TAKEN')
              : this.$t('SKILLS_MGMT.ADD.API.ERROR_MESSAGE');

          this.showAlert(errorMessage, {
            status: wootConstants.SNACKBAR_TYPE.ERROR,
          });
        });
    },
  },
};
</script>
