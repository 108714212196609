<template>
  <div>
    <label>
      <span v-if="label">{{ label }}</span>
    </label>
    <woot-thumbnail v-if="src" class="thumbnail-image" size="80px" :src="src" />
    <div v-if="src && deleteAvatar" class="avatar-delete-btn">
      <woot-button
        color-scheme="alert"
        variant="hollow"
        size="tiny"
        data-test-id="avatar_delete_btn"
        @click="onAvatarDelete"
      >
        {{ $t('INBOX_MGMT.DELETE.AVATAR_DELETE_BUTTON_TEXT') }}
      </woot-button>
    </div>
    <label>
      <input
        id="file"
        ref="file"
        type="file"
        accept="image/png, image/jpeg, image/gif"
        @change="handleImageUpload"
      />
      <slot />
    </label>
  </div>
</template>

<script>
import WootButton from 'dashboard/components/ui/WootButton.vue';
import WootThumbnail from 'components/widgets/Thumbnail.vue';

export default {
  name: 'WootAvatarUploader',
  components: { WootThumbnail, WootButton },
  props: {
    label: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      default: '',
    },
    deleteAvatar: {
      type: Boolean,
      default: false,
    },
  },
  watch: {},
  methods: {
    handleImageUpload(event) {
      const [file] = event.target.files;

      this.$emit('change', {
        file,
        url: URL.createObjectURL(file),
      });
    },
    onAvatarDelete() {
      this.$refs.file.value = null;
      this.$emit('onAvatarDelete');
    },
    clearFileInput() {
      this.$refs.file.value = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-delete-btn {
  margin-top: var(--space-slab);
  margin-bottom: var(--space-slab);
}
.thumbnail-image {
  margin-top: var(--space-normal);
  margin-bottom: var(--space-two);
}
</style>
