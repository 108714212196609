<template>
  <div v-if="show" class="column">
    <woot-modal-header :header-title="$t('FILTER.ATTACHMENTS.TITLE')">
      <p>{{ $t('FILTER.ATTACHMENTS.SUBTITLE') }}</p>
    </woot-modal-header>
    <div class="row modal-content">
      <div class="medium-12 columns filters-wrap">
        <attachment-filter-input
          v-for="(filter, i) in appliedFilters"
          :key="i"
          v-model="appliedFilters[i]"
          input-type="plain_text"
          :filter-attributes="filterAttributes"
          :operators="getOperators(appliedFilters[i].attribute_key)"
          :show-query-operator="i !== appliedFilters.length - 1"
          :show-user-input="true"
          :show-errors="showErrors"
          :grouped-filters="false"
          @submit="onApplyFilters"
          @resetFilter="resetFilter(i, appliedFilters[i])"
          @removeFilter="removeFilter(i)"
        />
        <div v-if="isAddingMoreFiltersAllowed" class="filter-actions">
          <woot-button
            icon="add"
            color-scheme="success"
            variant="smooth"
            size="small"
            @click="appendNewFilter"
          >
            {{ $t('FILTER.ADD_NEW_FILTER') }}
          </woot-button>
        </div>
      </div>

      <p v-if="areFiltersDuplicated" class="filter-error">
        {{ $t('FILTER.ATTACHMENTS.ERRORS.DUPLICATE_FILTERS') }}
      </p>

      <div class="medium-12 columns">
        <div class="modal-footer justify-content-end w-full">
          <woot-button class="button clear" @click.prevent="onClose">
            {{ $t('FILTER.CANCEL_BUTTON_LABEL') }}
          </woot-button>
          <woot-button
            data-test-id="apply-advanced-filters"
            @click="onApplyFilters"
          >
            {{ $t('FILTER.SUBMIT_BUTTON_LABEL') }}
          </woot-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import filterMixin from 'shared/mixins/filterMixin';
import wootConstants from '../../../../../constants';
import { filterTypes } from './advancedFilterItems';
import AttachmentFilterInput from './FilterInput.vue';

export default {
  components: { AttachmentFilterInput },
  mixins: [alertMixin, filterMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    initialFilterTypes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: true,
      appliedFilters: [],
      filtersFori18n: 'FILTER',
      showQueryOperator: false,
      showErrors: false,
      filterAttributes: filterTypes,
    };
  },
  computed: {
    isAddingMoreFiltersAllowed() {
      return this.filterAttributes.length > this.appliedFilters.length;
    },
    areFiltersDuplicated() {
      const attributes = this.appliedFilters.map(el => el.attribute_key);
      return attributes.some(el => {
        return attributes.indexOf(el) !== attributes.lastIndexOf(el);
      });
    },
  },
  mounted() {
    if (this.initialFilterTypes.length === 0) {
      const [firstFilterType] = filterTypes;
      const { attributeKey, filterOperators } = firstFilterType;
      const initialFilter = {
        attribute_key: attributeKey,
        filter_operator: filterOperators[0],
        values: '',
      };
      this.appliedFilters.push(initialFilter);
    } else {
      this.appliedFilters = this.initialFilterTypes;
    }
  },
  methods: {
    hasErrors() {
      const areFilterValuesEmpty = this.appliedFilters.some(
        filter => !filter.values
      );
      return this.areFiltersDuplicated || areFilterValuesEmpty;
    },
    getOperators(key) {
      const type = this.filterAttributes.find(
        filter => filter.attributeKey === key
      );
      return type.filterOperators;
    },
    appendNewFilter() {
      const appliedKeys = this.appliedFilters.map(el => el.attribute_key);
      const { attributeKey: attribute_key, filterOperators } =
        this.filterAttributes.find(
          attribute => !appliedKeys.includes(attribute.attributeKey)
        );
      this.appliedFilters.push({
        attribute_key,
        filter_operator: filterOperators[0],
        values: '',
      });
    },
    removeFilter(index) {
      if (this.appliedFilters.length <= 1) {
        this.showAlert(this.$t('FILTER.FILTER_DELETE_ERROR'), {
          status: wootConstants.SNACKBAR_TYPE.ERROR,
        });
      } else {
        this.appliedFilters.splice(index, 1);
      }
    },
    submitFilterQuery() {
      this.$emit('applyFilter', this.appliedFilters);
    },
    resetFilter(index, currentFilter) {
      this.appliedFilters[index].filter_operator = this.filterAttributes.find(
        filter => filter.attributeKey === currentFilter.attribute_key
      ).filterOperators[0];
      this.appliedFilters[index].values = '';
    },
    onApplyFilters() {
      if (!this.hasErrors()) {
        this.submitFilterQuery();
        this.onClose();
      } else if (this.showErrors === false) {
        this.showErrors = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.filters-wrap {
  padding: var(--space-normal);
  border-radius: var(--border-radius-large);
  border: 1px solid var(--color-border);
  background: var(--color-background-light);
  margin-bottom: var(--space-normal);
}

.filter-error {
  color: var(--r-500);
  display: block;
  margin: var(--space-smaller) 0;
}

.filter-actions {
  margin-top: var(--space-normal);
}
</style>
