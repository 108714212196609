import { createConsumer } from '@rails/actioncable';
import eventHandler from './../../../events/handler';

// Constants
const CONVERSATION_CHANNEL_NAME = 'ConversationChannel';
const NOTIFICATION_CHANNEL_NAME = 'NotificationChannel';
const INBOX_CHANNEL_NAME = 'InboxChannel';
const PRESENT_CHANNEL_NAME = 'PresentChannel';
export const NAMESPACE = 'websockets';

const PRESENCE_INTERVAL = 20000;

const types = {
  SUBSCRIBE: 'SUBSCRIBE',
  UNSUBSCRIBE: 'UNSUBSCRIBE',
};

const mutations = {
  [types.SUBSCRIBE]: () => {},
  [types.UNSUBSCRIBE]: () => {},
};

export const storeModule = {
  namespaced: true,
  state: {},
  mutations,
};

const subscriptions = new Map();

const mutationHandlers = {
  [`${NAMESPACE}/${types.SUBSCRIBE}`]: (store, mutation, consumer, handler) => {
    const conversation = `conversation:${mutation.payload.chat_id}`;

    if (!subscriptions.has(conversation)) {
      subscriptions.set(
        conversation,
        consumer.subscriptions.create(
          {
            channel: CONVERSATION_CHANNEL_NAME,
            room: 'conversation:' + mutation.payload.chat_id,
          },
          { received: handler }
        )
      );
    }
  },
  [`${NAMESPACE}/${types.UNSUBSCRIBE}`]: (store, mutation) => {
    if (
      store.getters.getSelectedChat.original_id === mutation.payload.chat_id
    ) {
      return;
    }

    const conversation = `conversation:${mutation.payload.chat_id}`;
    if (subscriptions.has(conversation)) {
      subscriptions.get(conversation).unsubscribe();
      subscriptions.delete(conversation);
    }
  },
  SET_CURRENT_CHAT_WINDOW: (store, mutation, consumer, handler) => {
    if (typeof mutation.payload === 'object' && 'id' in mutation.payload) {
      const conversation = `conversation:${mutation.payload.original_id}`;

      if (!subscriptions.has(conversation)) {
        subscriptions.set(
          conversation,
          consumer.subscriptions.create(
            {
              channel: CONVERSATION_CHANNEL_NAME,
              room: 'conversation:' + mutation.payload.original_id,
            },
            { received: handler }
          )
        );
      }
    }
  },
  'accounts/ADD_ACCOUNT': (store, mutation, consumer, handler) => {
    if (subscriptions.has('account')) {
      subscriptions.get('account').unsubscribe();
    }
    if (subscriptions.has('present')) {
      subscriptions.get('present').unsubscribe();
    }

    if (typeof mutation.payload === 'object' && 'id' in mutation.payload) {
      subscriptions.set(
        `account`,
        consumer.subscriptions.create(
          {
            channel: NOTIFICATION_CHANNEL_NAME,
            room: 'notification:' + mutation.payload.id,
          },
          { received: handler }
        )
      );

      // Add the present subscription
      subscriptions.set(
        'present',
        consumer.subscriptions.create(
          {
            channel: PRESENT_CHANNEL_NAME,
            room: 'present:' + mutation.payload.id,
          },
          {
            connected() {
              this.perform('appear');
            },
            received: handler,
            updatePresence() {
              this.perform('appear');
            },
          }
        )
      );

      // Set up the presence interval
      setInterval(() => {
        subscriptions.get('present').updatePresence();
      }, PRESENCE_INTERVAL);
    }
  },
  ['conversationTypingStatus/PROPAGATE_USER_TYPING_ON_CONVERSATION']: (
    store,
    mutation,
    consumer,
    handler
  ) => {
    const channel = `conversation:${mutation.payload.conversationId}`;
    if (subscriptions.has(channel)) {
      subscriptions.get(channel).perform('typing', {
        status: mutation.payload.status,
        conversation_id: mutation.payload.conversationId,
        is_private: mutation.payload.isPrivate,
      });
    }
  },
  SET_ACTIVE_INBOX: (store, mutation, consumer, handler) => {
    if (mutation.payload === 0) {
      store.getters['inboxes/getInboxes'].forEach(inbox => {
        if (!subscriptions.has(`inbox:${inbox.id}`)) {
          subscriptions.set(
            `inbox:${inbox.id}`,
            consumer.subscriptions.create(
              {
                channel: INBOX_CHANNEL_NAME,
                room: 'inbox:' + inbox.id,
              },
              { received: handler }
            )
          );
        }
      });
    } else {
      const inboxKey = `inbox:${mutation.payload}`;

      subscriptions.forEach((subscription, key) => {
        if (key.includes('inbox:') && !key.includes(inboxKey)) {
          subscription.unsubscribe();
          subscriptions.delete(key);
        }
      });

      if (!subscriptions.has(inboxKey)) {
        subscriptions.set(
          inboxKey,
          consumer.subscriptions.create(
            {
              channel: INBOX_CHANNEL_NAME,
              room: 'inbox:' + mutation.payload,
            },
            { received: handler }
          )
        );
      }
    }
  },
};

export const createPlugin = function createWSPlugin() {
  const { websocketURL = '' } = window.chatwootConfig || {};
  const endpoint = websocketURL ? `${websocketURL}/cable` : undefined;
  const consumer = createConsumer(endpoint);

  return store => {
    const handler = eventHandler(store);
    setInterval(() => {
      if (subscriptions.has('present')) {
        subscriptions.get('present').perform('appear');
      }
    }, 20000);

    store.subscribe((mutation, _) => {
      const handlerFunction = mutationHandlers[mutation.type];
      if (handlerFunction) {
        handlerFunction(store, mutation, consumer, handler);
      }
    });
  };
};
