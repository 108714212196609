/* eslint-disable no-underscore-dangle */

import getStructuredMessageType from './getStructuredMessageType';

/**
 * Helper function for getting the message type of a Cognigy message.
 */

export default data => {
  const result = getStructuredMessageType({
    data,
    galleryDescription: 'Gallery',
    listDescription: 'List',
    audioDescription: 'Audio',
    imageDescription: 'Image',
    videoDescription: 'Video',
    adaptiveCardDescription: 'Adaptive Card',
    attachmentsDescription: 'Attachments',
    unknownDescription: 'Unknown',
  });
  return result.messageTypeDescription || result.text;
};
