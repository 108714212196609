export const REPLY_EDITOR_MODES: {
  REPLY: 'REPLY';
  REPLY_BOT: 'REPLY_BOT';
  NOTE: 'NOTE';
} = {
  REPLY: 'REPLY',
  REPLY_BOT: 'REPLY_BOT',
  NOTE: 'NOTE',
};

export const CHAR_LENGTH_WARNING = {
  UNDER_50: 'characters remaining',
  NEGATIVE: 'characters over',
};
