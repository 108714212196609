<template>
  <div class="image message-text__wrap">
    <img
      crossorigin="anonymous"
      :src="url"
      @click="onClick"
      @error="onImgError()"
    />
    <woot-modal :full-width="true" :show.sync="show" :on-close="onClose">
      <img :src="url" class="modal-image" crossorigin="anonymous" />
    </woot-modal>
  </div>
</template>

<script>
import WootModal from 'components/Modal.vue';

export default {
  name: 'BubbleImage',
  components: { WootModal },
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    onClose() {
      this.show = false;
    },
    onClick() {
      this.show = true;
    },
    onImgError() {
      this.$emit('error');
    },
  },
};
</script>
<style lang="scss" scoped>
.modal-mask {
  max-width: 60rem;
  max-height: 60rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
