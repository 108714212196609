<template>
  <modal :show="show" :on-close="onClose">
    <woot-modal-header :header-title="title" :header-content="message" />
    <div class="modal-footer delete-item">
      <button
        class="alert button nice"
        data-test-id="confirm_delete_button"
        @click="onConfirm"
      >
        {{ confirmText }}
      </button>
      <button class="button clear" @click="onClose">
        {{ rejectText }}
      </button>
    </div>
  </modal>
</template>

<script>
import Modal from '../../Modal.vue';
import WootModalHeader from 'components/ModalHeader.vue';

export default {
  name: 'WootDeleteModal',
  components: {
    WootModalHeader,
    Modal,
  },
  props: {
    show: Boolean,
    onClose: { type: Function, default: () => {} },
    onConfirm: { type: Function, default: () => {} },
    title: { type: String, default: '' },
    message: { type: String, default: '' },
    confirmText: { type: String, default: '' },
    rejectText: { type: String, default: '' },
  },
};
</script>
