<template>
  <li
    :class="{
      'tabs-title': true,
      'is-active': active,
    }"
  >
    <a
      :data-test-id="dataTestId"
      :class="{ 'has-badge': showBadge }"
      @click="onTabClick"
    >
      {{ name }}
      <span v-if="showBadge" class="badge">
        {{ getItemCount }}
      </span>
      <!-- we assigned a non visible badge to keep the same line height between tabs and avoid a small disparity in border alignment -->
      <span v-else class="non-visible-badge badge">0</span>
    </a>
  </li>
</template>
<script>
export default {
  name: 'WootTabsItem',
  props: {
    index: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    count: {
      type: Number,
      default: 0,
    },
    showBadge: {
      type: Boolean,
      default: false,
    },
    dataTestId: {
      type: String,
      default: '',
    },
  },

  computed: {
    active() {
      return this.index === this.$parent.index;
    },

    getItemCount() {
      return this.count;
    },
  },

  methods: {
    onTabClick(event) {
      event.preventDefault();
      if (!this.disabled) {
        this.$parent.$emit('change', this.index);
      }
    },
  },
};
</script>
<style scoped>
.non-visible-badge {
  visibility: hidden;
}
</style>
