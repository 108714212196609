export default profile => {
  const updatedContactItem = {};

  Object.keys(profile).forEach(key => {
    if (
      typeof profile[key] === 'object' &&
      profile[key].field &&
      profile[key] !== null
    ) {
      if ('value' in profile[key]) {
        updatedContactItem[key] = profile[key].value;
      } else {
        updatedContactItem[key] = '';
      }
    } else {
      updatedContactItem[key] = profile[key];
    }
  });

  return updatedContactItem;
};
