const addScalingMarginToTextWithButtons = (elementId, payload) => {
  const length = payload.buttons?.length;

  const marginTop = length * 5;
  const marginBottom = length * 5 + 15;

  const element = document.getElementById(`${elementId}`);
  if (element && element.length > 0) {
    element.style.margin = `-${marginTop}px 0px -${marginBottom}px 0px`;
  }
};

const addScalingMarginToLists = (elementId, payload) => {
  const length = payload.elements?.length;

  const hasButton = payload.buttons?.length > 0;

  const hasLargeTopElement = payload.top_element_style === 'large';

  const buttonMargin = hasButton ? 5 : 0;
  const largeTopElementMargin = hasLargeTopElement ? 9 : 0;

  const marginTop = length * 12 - 12 + buttonMargin + largeTopElementMargin;
  const marginBottom = length * 10 + 8 + buttonMargin + largeTopElementMargin;

  const element = document.getElementById(`${elementId}`);
  if (element && element.length > 0) {
    element.style.margin = `-${marginTop}px 0px -${marginBottom}px 0px`;
  }
};

const addScalingMarginToGalleries = (elementId, payload) => {
  let maxCardButtonsLength = -Infinity;
  payload.elements.forEach(element => {
    if (element.buttons.length > maxCardButtonsLength) {
      maxCardButtonsLength = element.buttons.length;
    }
  });

  const marginTop = maxCardButtonsLength * 7 + 23;
  const marginBottom = maxCardButtonsLength * 7 + 30;

  const element = document.getElementById(`${elementId}`);
  if (element && element.length > 0) {
    element.style.margin = `-${marginTop}px 0px -${marginBottom}px 0px`;
  }
};

const addScalingMarginToAdaptiveCards = elementId => {
  const element = document.querySelectorAll(`#${elementId}`);

  if (element && element.length > 0) {
    element[0].style.margin = `-50px 0px -60px 0px`;
  }
};

export const addScalingMarginFix = (
  elementId,
  messageData,
  cognigyMessageType
) => {
  // We add margins dynamically to the list, gallery and text with button structured messages
  // to make them fit after scaling them 70%
  const legacyMessage =
    // eslint-disable-next-line no-underscore-dangle
    messageData.content_attributes.items[0].data?._cognigy?._webchat?.message;
  const message =
    // eslint-disable-next-line no-underscore-dangle
    messageData.content_attributes.items[0].data?._cognigy?._defaultPreview
      ?.message;
  const payload =
    legacyMessage?.attachment?.payload || message?.attachment?.payload;

  switch (cognigyMessageType) {
    case 'button':
      addScalingMarginToTextWithButtons(elementId, payload);

      break;
    case 'list':
      addScalingMarginToLists(elementId, payload, messageData.forwardable);

      break;
    case 'generic':
      addScalingMarginToGalleries(elementId, payload);

      break;
    case 'adaptivecards':
      addScalingMarginToAdaptiveCards(elementId, messageData.forwardable);

      break;

    default:
      break;
  }
};
