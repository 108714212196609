<template>
  <div class="conversation-info--items">
    <div
      v-for="ConversationInfoRow in conversationInfo"
      :key="ConversationInfoRow.value"
      :data-test-id="createDataTestIdFromTitle(ConversationInfoRow.title)"
      class="conversation-info--details"
    >
      <div class="text-truncate">
        <span class="conversation-info--title">
          {{ ConversationInfoRow.title }}:
        </span>
        <span>
          {{ ConversationInfoRow.value }}
        </span>
      </div>
      <woot-button
        v-if="showCopy"
        type="submit"
        variant="link"
        :data-test-id="`${createDataTestIdFromTitle(
          ConversationInfoRow.title
        )}-copy-button`"
        color-scheme="secondary"
        icon="clipboard"
        class-names="icon copy-icon"
        @click="onCopy($event, ConversationInfoRow.value)"
      />
    </div>
  </div>
</template>
<script>
import copy from 'copy-text-to-clipboard';
import alertMixin from 'shared/mixins/alertMixin';
import wootConstants from '../../../constants';
import WootUIKit from 'components';

function showCopyAlert(e, value) {
  e.preventDefault();
  copy(value);
  this.showAlert(this.$t('CONTACT_PANEL.COPY_SUCCESSFUL'), {
    status: wootConstants.SNACKBAR_TYPE.SUCCESS,
  });
}

export default {
  components: { WootButton: WootUIKit.Button },
  mixins: [alertMixin],
  props: {
    conversationInfo: { type: Array, default: () => [] },
    showCopy: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onCopy: showCopyAlert,
    createDataTestIdFromTitle: title =>
      title.toLowerCase().split(' ').join('-'),
  },
};
</script>
<style scoped lang="scss">
.text-truncate {
  width: 400px;
}
.conversation-info--row {
  padding: var(--space-slab) var(--space-normal);
}

.conversation-info--title {
  color: var(--b-500);
  font-weight: 500;
}

.conversation-info--items {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
}

.conversation-info--details {
  display: flex;
  justify-content: space-between;
  color: var(--color-body);
  padding: var(--space-slab) var(--space-normal);

  .copy-icon {
    margin-left: var(--space-smaller);
  }

  &.div {
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
