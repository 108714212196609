<template>
  <li
    v-if="showMessage && !xAppsOverlay"
    :class="alignBubble"
    :data-test-id="`message_item_${data.id}`"
  >
    <cognigy-x-apps-message
      v-if="contentType === 'cognigy' && isXAppsMessage"
      :message-data="data"
      :readable-time="readableTime"
      :test-id="`cognigy-x-apps-message_${data.id}`"
    />
    <cognigy-structured-content-message
      v-if="
        contentType === 'cognigy' && data.message_type !== 5 && !isXAppsMessage
      "
      :content-type="contentType"
      :message-data="data"
      :readable-time="readableTime"
      :is-sent-by-bot="isSentByBot"
      :is-sent-by-user="isSentByUser"
      :tooltip-for-sender="tooltipForSender"
      :sender="sender"
      :sender-name-for-avatar="senderNameForAvatar"
      :is-incoming="isIncoming"
    />
    <copilot
      v-if="isAICopilotMessage"
      :message-data="data"
      :is-collapsed="isCollapsed"
      @collapseGroup="collapseGroup"
    />
    <!-- TODO: moving the logic for regular messages into its own component to remove complexity -->
    <div
      v-if="contentType !== 'cognigy' && data.message_type !== 5"
      :class="wrapClass"
    >
      <div class="messageAvatarContainer">
        <div v-tooltip.top-start="messageToolTip" :class="bubbleClass">
          <bubble-text
            v-if="data.content"
            :message="message"
            :is-email="isEmailContentType"
            :readable-time="readableTime"
            :display-quoted-button="displayQuotedButton"
          />
          <span
            v-if="isPending && hasAttachments"
            class="chat-bubble has-attachment agent"
          >
            {{ $t('CONVERSATION.UPLOADING_ATTACHMENTS') }}
          </span>
          <div v-if="!isPending && (hasAttachments || hasCognigyAttachment)">
            <div v-for="attachment in getAllAttachments" :key="attachment.id">
              <bubble-text
                v-if="['image', 'video', 'document'].includes(attachment.type)"
                :message="attachment.caption || null"
              />
              <bubble-handler
                :attachment="attachment"
                :readable-time="readableTime"
                :has-image-error="hasImageError"
                :on-image-load-error="onImageLoadError"
                :message-type="data.message_type"
              />
            </div>
          </div>
        </div>
        <div v-if="showAvatar" class="sender--info">
          <woot-thumbnail
            v-tooltip.top="tooltipForSender"
            :src="sender.thumbnail"
            :username="senderNameForAvatar"
            :sent-by-bot="isSentByBot"
            size="16px"
          />
        </div>
      </div>
      <bubble-actions
        :id="data.id"
        :sender="data.sender"
        :is-private="data.private"
        :message-type="data.message_type"
        :readable-time="readableTime"
        :source-id="data.source_id"
        :inbox-id="data.inbox_id"
        :message-read="showReadTicks"
      />
      <woot-spinner v-if="isPending" size="tiny" />

      <div v-if="isFailed" class="message-failed--alert">
        <woot-button
          v-tooltip.top-end="$t('CONVERSATION.TRY_AGAIN')"
          size="tiny"
          color-scheme="alert"
          variant="clear"
          icon="arrow-clockwise"
          @click="retrySendMessage"
        />
      </div>
    </div>
    <div v-if="shouldShowContextMenu" class="context-menu-wrap">
      <context-menu
        v-if="
          isBubble &&
          !isMessageDeleted &&
          contentType !== 'cognigy' &&
          (hasText || hasRawData)
        "
        :is-open="showContextMenu"
        :show-copy="hasText"
        :is-private="data.private"
        :menu-position="contextMenuPosition"
        :data-test-id="'message_context_menu'"
        :show-forward-context-menu-option="showForwardContextMenuOption"
        :show-raw-data="hasRawData"
        @toggle="handleContextMenuClick"
        @delete="handleDelete"
        @copy="handleCopy"
        @showRawData="toggleRawData"
        @handleSendMessageToAgentCopilotBot="handleSendMessageToAgentCopilotBot"
      />
    </div>
    <raw-data-modal
      :show="showRawDataModal"
      :raw-data="rawData"
      @close="toggleRawData"
    />
  </li>
</template>
<script>
import copy from 'copy-text-to-clipboard';

import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import timeMixin from '../../../mixins/time';
import BubbleHandler from './bubble/Handler.vue';
import BubbleText from './bubble/Text.vue';
import BubbleActions from './bubble/Actions.vue';
import CognigyStructuredContentMessage from './CognigyStructuredContentMessage.vue';

import ContextMenu from 'dashboard/modules/conversations/components/MessageContextMenu.vue';
import RawDataModal from '../../widgets/modal/RawDataModal.vue';

import alertMixin from 'shared/mixins/alertMixin';
import contentTypeMixin from 'shared/mixins/contentTypeMixin';
import { MESSAGE_TYPE, MESSAGE_STATUS } from 'shared/constants/messages';
import { generateBotMessageContent } from './helpers/botMessageContentHelper';

import { BUS_EVENTS } from 'shared/constants/busEvents';
import Copilot from './Copilot.vue';
import wootConstants from '../../../constants';

import { isEmptyObject } from '../../../helper/commons';
import WootButton from 'dashboard/components/ui/WootButton.vue';
import WootThumbnail from 'components/widgets/Thumbnail.vue';
import WootSpinner from 'shared/components/Spinner.vue';
import CognigyXAppsMessage from './CognigyXAppsMessage.vue';
import detectXAppsMessage from '../../../mixins/detectXAppsMessage';

export default {
  name: 'ConversationMessage',
  components: {
    CognigyXAppsMessage,
    WootSpinner,
    WootThumbnail,
    WootButton,
    BubbleActions,
    BubbleText,
    BubbleHandler,
    ContextMenu,
    CognigyStructuredContentMessage,
    Copilot,
    RawDataModal,
  },
  mixins: [
    alertMixin,
    timeMixin,
    messageFormatterMixin,
    contentTypeMixin,
    detectXAppsMessage,
  ],
  props: {
    data: {
      type: Object,
      required: true,
    },
    hasUserReadMessage: {
      type: Boolean,
      default: false,
    },
    showForwardContextMenuOption: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showContextMenu: false,
      hasImageError: false,
      isCollapsed: false,
      showRawDataModal: false,
      isSanitized: false,
      rawData: {},
    };
  },
  computed: {
    showMessage() {
      return this.isAICopilotMessage
        ? this.showAICopilotGroupMessage
        : this.hasAttachments ||
            this.data.content ||
            this.contentType === 'cognigy' ||
            this.hasCognigyAttachment ||
            this.isEmailContentType;
    },
    isAICopilotMessage() {
      return this.data.message_type === 5;
    },
    isOnBehalfOfUserMessage() {
      return this.data.message_type === 6;
    },
    showAICopilotGroupMessage() {
      return (
        this.data.isTopAICopilotMessage ||
        (!this.data.isTopAICopilotMessage && !this.isCollapsed)
      );
    },
    contentToBeParsed() {
      const {
        html_content: { full: fullHTMLContent } = {},
        text_content: { full: fullTextContent } = {},
      } = this.contentAttributes.email || {};
      return fullHTMLContent || fullTextContent || '';
    },
    displayQuotedButton() {
      if (!this.isIncoming) {
        return false;
      }

      return !!this.contentToBeParsed.includes('<blockquote');
    },
    message() {
      const botMessageContent = generateBotMessageContent(
        this.contentType,
        this.contentAttributes,
        {
          noResponseText: this.$t('CONVERSATION.NO_RESPONSE'),
          csat: {
            ratingTitle: this.$t('CONVERSATION.RATING_TITLE'),
            feedbackTitle: this.$t('CONVERSATION.FEEDBACK_TITLE'),
          },
        }
      );

      const { email: { content_type: contentType = '' } = {} } =
        this.contentAttributes;
      if (this.contentToBeParsed && this.isIncoming) {
        const parsedContent = this.stripStyleCharacters(this.contentToBeParsed);
        if (parsedContent) {
          // This is a temporary fix for line-breaks in text/plain emails
          // Now, It is not rendered properly in the email preview.
          // FIXME: Remove this once we have a better solution for rendering text/plain emails
          return contentType.includes('text/plain')
            ? parsedContent.replace(/\n/g, '<br />')
            : parsedContent;
        }
      }

      if (this.isOnBehalfOfUserMessage) {
        let parsedMessage = this.data.content?.replace('/bot ', '');
        if (parsedMessage) {
          parsedMessage = parsedMessage.replace(/\n/g, '<br />');
        }
        return parsedMessage;
      }
      // Sanitized message, if the message is sanitized completely an error will be shown
      const message = this.isIncoming
        ? this.data.content
        : this.formatMessage(this.data.content);
      if (message.length > 0) {
        return message + botMessageContent;
      }
      this.setSanitizedMessage();
      return `${this.$t('CONVERSATION.SANITIZED')}`;
    },
    contentAttributes() {
      return this.data.content_attributes || {};
    },
    sender() {
      return this.data.sender || {};
    },
    storySender() {
      return this.contentAttributes.story_sender || null;
    },
    storyId() {
      return this.contentAttributes.story_id || null;
    },
    contentType() {
      const {
        data: { content_type: contentType },
      } = this;
      return contentType;
    },
    alignBubble() {
      const { message_type: messageType } = this.data;
      const isCentered =
        messageType === MESSAGE_TYPE.ACTIVITY ||
        messageType === MESSAGE_TYPE.ASSIST;
      const isLeftAligned = messageType === MESSAGE_TYPE.INCOMING;
      const isRightAligned =
        messageType === MESSAGE_TYPE.OUTGOING ||
        messageType === MESSAGE_TYPE.OUTGOING_COGNIGY ||
        messageType === MESSAGE_TYPE.ON_BEHALF_OF_USER ||
        messageType === MESSAGE_TYPE.TEMPLATE;

      return {
        center: isCentered,
        left: isLeftAligned,
        right: isRightAligned,
        'has-context-menu': this.showContextMenu,
      };
    },
    readableTime() {
      return this.messageStamp(
        this.contentAttributes.external_created_at || this.data.created_at,
        'LLL d, h:mm a'
      );
    },
    isBubble() {
      return [0, 1, 3, 4, 6].includes(this.data.message_type);
    },
    isIncoming() {
      return this.data.message_type === MESSAGE_TYPE.INCOMING;
    },
    getCognigyTestId() {
      return `cognigy-bubble-${this.data.inbox_id}-${this.data.conversation_id}-${this.data.id}`;
    },
    isOutgoing() {
      return this.data.message_type === MESSAGE_TYPE.OUTGOING;
    },
    isOnBehalfOfUser() {
      return this.data.message_type === MESSAGE_TYPE.ON_BEHALF_OF_USER;
    },
    showReadTicks() {
      return (
        (this.isOutgoing || this.isTemplate) &&
        this.hasUserReadMessage &&
        !this.data.private
      );
    },
    isTemplate() {
      return this.data.message_type === MESSAGE_TYPE.TEMPLATE;
    },
    emailHeadAttributes() {
      return {
        email: this.contentAttributes.email,
        cc: this.contentAttributes.cc_emails,
        bcc: this.contentAttributes.bcc_emails,
      };
    },
    getAllAttachments() {
      return this.data.attachments || this.getCognigyAttachments;
    },
    getCognigyAttachments() {
      if (this.hasCognigyAttachment) {
        return (
          // eslint-disable-next-line no-underscore-dangle
          this.contentAttributes.items[0].data?._cognigy &&
          // eslint-disable-next-line no-underscore-dangle
          this.contentAttributes.items[0].data?._cognigy._defaultPreview
            .attachments
        );
      }
      return [];
    },
    hasCognigyAttachment() {
      return !!(
        this.contentAttributes.items &&
        // eslint-disable-next-line no-underscore-dangle
        this.contentAttributes.items[0].data?._cognigy &&
        // eslint-disable-next-line no-underscore-dangle
        this.contentAttributes.items[0].data?._cognigy._defaultPreview
          ?.attachments?.length > 0
      );
    },
    hasAttachments() {
      return !!(this.data.attachments && this.data.attachments.length > 0);
    },
    isMessageDeleted() {
      return this.contentAttributes.deleted;
    },
    hasText() {
      return !!this.data.content;
    },
    tooltipForSender() {
      const name = this.senderNameForAvatar;
      const { message_type: messageType } = this.data;
      const showTooltip =
        messageType === MESSAGE_TYPE.OUTGOING ||
        messageType === MESSAGE_TYPE.TEMPLATE ||
        messageType === MESSAGE_TYPE.OUTGOING_COGNIGY ||
        messageType === MESSAGE_TYPE.ON_BEHALF_OF_USER;
      return showTooltip
        ? {
            content: `${this.$t('CONVERSATION.SENT_BY')} ${name}`,
            classes: 'left tooltip-style',
          }
        : false;
    },
    messageToolTip() {
      if (this.isMessageDeleted) {
        return false;
      }
      if (this.isFailed) {
        return this.$t(`CONVERSATION.SEND_FAILED`);
      }
      return false;
    },
    wrapClass() {
      return {
        wrap: this.isBubble,
        'activity-wrap': !this.isBubble,
        'is-pending': this.isPending,
        'is-failed': this.isFailed,
      };
    },
    bubbleClass() {
      return {
        bubble: this.isBubble,
        'is-private': this.data.private,
        'is-image': this.hasMediaAttachment('image'),
        'is-video': this.hasMediaAttachment('video'),
        'is-text': this.hasText,
        'is-from-bot': this.isSentByBot,
        'is-from-user': this.isSentByUser,
        'is-failed': this.isFailed || this.isSanitized,
      };
    },

    isPending() {
      return this.data.status === MESSAGE_STATUS.PROGRESS;
    },
    isFailed() {
      return this.data.status === MESSAGE_STATUS.FAILED;
    },
    isSentByBot() {
      if (this.isPending || this.isFailed) return false;
      return !this.sender.type || this.sender.type === 'agent_bot';
    },
    isSentByUser() {
      return this.sender.type === 'user';
    },
    contextMenuPosition() {
      const { message_type: messageType } = this.data;
      return messageType ? 'right' : 'left';
    },
    shouldShowContextMenu() {
      return !(this.isFailed || this.isPending);
    },
    errorMessage() {
      const { meta } = this.data;
      return meta ? meta.error : '';
    },
    showAvatar() {
      const isNotificationMessage = this.data.message_type === 2;
      const isRightSide =
        this.isOutgoing ||
        this.isTemplate ||
        this.isSentByBot ||
        this.isOnBehalfOfUser;

      if (!isNotificationMessage && isRightSide) {
        return true;
      }
      return false;
    },
    senderNameForAvatar() {
      return this.sender.name || '';
    },
    hasRawData() {
      if (!isEmptyObject(this.contentAttributes)) {
        return this.contentAttributes?.items?.[0]?.data
          ? !isEmptyObject(this.contentAttributes?.items?.[0]?.data)
          : false;
      }
      return false;
    },
  },
  watch: {
    data() {
      this.hasImageError = false;
    },
  },
  created() {
    bus.$on(
      BUS_EVENTS.COLLAPSE_AGENT_ASSIST_MESSAGE,
      lastAICopilotMessageGroupId => {
        if (lastAICopilotMessageGroupId !== this.data.aiCopilotGroupId) {
          this.collapse();
        }
      }
    );

    bus.$on(BUS_EVENTS.COLLAPSE_AGENT_ASSIST_MESSAGE_GROUP, groupId => {
      if (groupId === this.data.aiCopilotGroupId) {
        this.toggleCollapse();
      }
    });
  },
  mounted() {
    this.hasImageError = false;
  },
  methods: {
    hasMediaAttachment(type) {
      if (this.hasAttachments && this.data.attachments.length > 0) {
        const { attachments = [{}] } = this.data;
        const { file_type: fileType } = attachments[0];
        return fileType === type && !this.hasImageError;
      }
      return false;
    },
    handleContextMenuClick() {
      this.showContextMenu = !this.showContextMenu;
    },
    async handleDelete() {
      const { conversation_id: conversationId, id: messageId } = this.data;
      try {
        await this.$store.dispatch('deleteMessage', {
          conversationId,
          messageId,
        });
        this.showAlert(this.$t('CONVERSATION.SUCCESS_DELETE_MESSAGE'), {
          status: wootConstants.SNACKBAR_TYPE.SUCCESS,
        });
        this.showContextMenu = false;
      } catch (error) {
        this.showAlert(this.$t('CONVERSATION.FAIL_DELETE_MESSSAGE'), {
          status: wootConstants.SNACKBAR_TYPE.ERROR,
        });
      }
    },
    handleCopy() {
      copy(this.data.content);
      this.showAlert(this.$t('CONTACT_PANEL.COPY_SUCCESSFUL'), {
        status: wootConstants.SNACKBAR_TYPE.SUCCESS,
      });
      this.showContextMenu = false;
    },
    toggleRawData() {
      this.rawData = this.contentAttributes.items[0].data;
      this.showRawDataModal = !this.showRawDataModal;
      this.showContextMenu = false;
    },
    async retrySendMessage() {
      await this.$store.dispatch('sendMessageWithData', this.data);
    },
    onImageLoadError() {
      this.hasImageError = true;
    },
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    collapseGroup() {
      bus.$emit(
        BUS_EVENTS.COLLAPSE_AGENT_ASSIST_MESSAGE_GROUP,
        this.data.aiCopilotGroupId
      );
    },
    collapse() {
      this.isCollapsed = true;
    },
    handleSendMessageToAgentCopilotBot() {
      bus.$emit(BUS_EVENTS.ADD_MESSAGE_TO_COPILOT_BOT_INPUT, this.data.content);
      this.showContextMenu = false;
    },

    setSanitizedMessage() {
      this.isSanitized = true;
    },
  },
};
</script>
<style lang="scss">
.wrap {
  flex-direction: column;
  > div .bubble {
    &.is-image,
    &.is-video {
      padding: 0;
      overflow: hidden;

      .image,
      .video {
        max-width: 32rem;
        padding: var(--space-micro);

        > img,
        > video {
          border-radius: var(--border-radius-medium);
        }
        > video {
          height: 100%;
          object-fit: cover;
          width: 100%;
        }
      }
      .video {
        height: 18rem;
      }
    }

    &.is-image.is-text > .message-text__wrap {
      max-width: 32rem;
      padding: var(--space-small) var(--space-normal);
    }

    &.is-private a {
      color: var(--w-700);
    }

    &.is-private .file.message-text__wrap {
      .file--icon {
        color: var(--w-400);
      }
      .text-block-title {
        color: #3c4858;
      }
      .download.button {
        color: var(--w-400);
      }
    }

    &.is-private.is-text > .message-text__wrap .link {
      color: var(--w-700);
    }
    &.is-private.is-text > .message-text__wrap .prosemirror-mention-node {
      font-weight: var(--font-weight-black);
      background: none;
      border-radius: var(--border-radius-small);
      padding: 0;
      color: var(--color-body);
      text-decoration: underline;
    }

    &.is-from-bot {
      background: #0b3694;
      .message-text--metadata .time {
        color: var(--v-50);
      }

      &.is-private .message-text--metadata .time {
        color: var(--s-400);
      }
    }

    &.is-from-user {
      background: #0070e0;
      .message-text--metadata .time {
        color: var(--v-50);
      }

      &.is-private .message-text--metadata .time {
        color: var(--s-400);
      }
    }

    &.is-failed {
      background: var(--r-200);

      .message-text--metadata .time {
        color: var(--r-50);
      }
    }
  }

  &.is-pending {
    position: relative;
    opacity: 0.8;

    .spinner {
      position: absolute;
      bottom: var(--space-smaller);
      right: var(--space-smaller);
    }

    > .is-image.is-text.bubble > .message-text__wrap {
      padding: 0;
    }
  }
}

.webchat-media-template-video {
  margin-top: 0px !important;
}

.webchat-media-template-audio {
  margin-top: 0px !important;
}

.webchat-list-template-root {
  margin-top: 0px !important;
}

.webchat-quick-reply-template-root {
  margin-top: 0px !important;
}

.webchat-buttons-template-root {
  margin-top: 0px !important;
}

div[data-test-id='cognigy_generic'] div div {
  margin-top: 0px !important;
}

.messageAvatarContainer {
  display: flex;
}

.thumbnail-structured-content {
  position: absolute !important;
  right: 16px;
  bottom: 27px;
}

.tooltip-style {
  & {
    left: -114px !important;
    top: 22px !important;
    max-width: none;
    min-width: none;
    width: 12rem;
  }
}

.sender--info {
  align-items: flex-end;
  color: var(--b-700);
  display: inline-flex;
  padding-left: 0.8rem;
  .sender--available-name {
    font-size: var(--font-size-mini);
    margin-left: var(--space-smaller);
  }
}

.sender-structured-content {
  align-items: flex-end;
  color: var(--b-700);
  display: inline-flex;
  padding: var(--space-smaller) 0;

  .sender--available-name {
    font-size: var(--font-size-mini);
    margin-left: var(--space-smaller);
  }
}

.cognigy-content-container {
  display: flex;
}

.message-time-container {
  margin-right: 23px;
}

.message-failed--alert {
  color: var(--r-900);
  flex-grow: 1;
  text-align: right;
  margin-top: var(--space-smaller) var(--space-smaller) 0 0;
}

.button--delete-message {
  visibility: hidden;
}

li.left {
  padding-top: 10px;
}

li.right {
  display: flex;
  align-items: flex-end;

  &:hover .button--delete-message {
    visibility: visible;
  }
}

li.left .context-menu-wrap {
  margin-right: auto;
  margin-bottom: 25px;
}

li.right .context-menu-wrap {
  margin-left: auto;
  margin-bottom: 25px;
}

li.right {
  flex-direction: row-reverse;
  justify-content: flex-end;

  .wrap.is-pending {
    margin-left: auto;
  }

  .wrap.is-failed {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    margin-left: auto;
  }
}

.has-context-menu {
  background: var(--color-background);
  .button--delete-message {
    visibility: visible;
  }
}

.context-menu {
  position: relative;
}

.activity-wrap .message-text--metadata {
  margin-top: 2px;
}
</style>
