<template>
  <div class="bulk-action__inboxes">
    <div class="triangle">
      <svg height="12" viewBox="0 0 24 12" width="24">
        <path
          d="M20 12l-8-8-12 12"
          fill="var(--white)"
          fill-rule="evenodd"
          stroke="var(--s-50)"
          stroke-width="1px"
        />
      </svg>
    </div>
    <div class="header flex-between">
      <span>{{ $t('BULK_ACTION.INBOX.SELECT_LABEL') }}</span>
      <woot-button
        size="tiny"
        variant="clear"
        color-scheme="secondary"
        icon="dismiss"
        @click="onClose"
      />
    </div>
    <div class="container">
      <ul v-if="!selectedInbox">
        <li class="search-container">
          <div class="inbox-list-search flex-between">
            <fluent-icon icon="search" class="search-icon" size="16" />
            <input
              ref="search"
              v-model="query"
              type="search"
              placeholder="Search"
              class="inbox--search_input"
            />
          </div>
        </li>
        <li v-for="inbox in filteredInboxes" :key="inbox.id">
          <div class="inbox-list-item" @click="assignInbox(inbox)">
            <span>{{ inbox.name }}</span>
          </div>
        </li>
      </ul>
      <div v-else class="inbox-confirmation-container">
        <p
          v-html="
            $t('BULK_ACTION.INBOX.CHANGE_CONFIRMATION_LABEL', {
              conversationCount,
              conversationLabel,
              inbox: selectedInbox.name,
            })
          "
        />
        <div class="inbox-confirmation-actions">
          <woot-button color-scheme="primary" variant="smooth" @click="goBack">
            {{ $t('BULK_ACTION.GO_BACK_LABEL') }}
          </woot-button>
          <woot-button
            color-scheme="primary"
            variant="flat"
            :is-loading="uiFlags.isUpdating"
            @click="submit"
          >
            {{ $t('BULK_ACTION.ASSIGN_LABEL') }}
          </woot-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="js">
import Vue from 'vue';
import WootButton from 'components/ui/WootButton.vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  name: 'ChangeInbox',
  components: { WootButton },
  props: {
    availableInboxes: {
      type: Array,
      default: () => [],
    },
    conversationCount: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      query: '',
      selectedInbox: null,
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'bulkActions/getUIFlags',
    }),
    filteredInboxes() {
      if (this.query) {
        return this.availableInboxes.filter(inbox =>
          inbox.name.toLowerCase().includes(this.query.toLowerCase())
        );
      }
      return this.availableInboxes;
    },
    conversationLabel() {
      return this.conversationCount > 1
        ? this.$t('BULK_ACTION.MULTIPLE_CONVERSATIONS')
        : this.$t('BULK_ACTION.SINGLE_CONVERSATION');
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    assignInbox(inbox) {
      this.selectedInbox = inbox;
    },
    goBack() {
      this.selectedInbox = null;
    },
    submit() {
      this.$emit('select', this.selectedInbox);
    },
  },
});
</script>

<style scoped lang="scss">
.bulk-action__inboxes {
  background-color: var(--white);
  border-radius: var(--border-radius-large);
  border: 1px solid var(--s-50);
  box-shadow: var(--shadow-dropdown-pane);
  max-width: 75%;
  position: absolute;
  right: var(--space-small);
  top: var(--space-larger);
  transform-origin: top right;
  width: auto;
  z-index: var(--z-index-twenty);
  min-width: var(--space-giga);

  .header {
    padding: var(--space-one);

    span {
      font-size: var(--font-size-small);
      font-weight: var(--font-weight-medium);
    }
  }

  .container {
    max-height: var(--space-giga);
    overflow-y: auto;
    .agent__list-container {
      height: 100%;
    }
    .inbox-list-search {
      padding: 0 var(--space-one);
      border: 1px solid var(--s-100);
      border-radius: var(--border-radius-medium);
      background-color: var(--s-50);
      .search-icon {
        color: var(--s-400);
      }

      .inbox--search_input {
        border: 0;
        font-size: var(--font-size-mini);
        margin: 0;
        background-color: transparent;
        height: unset;
      }
    }
  }
  .triangle {
    display: block;
    z-index: var(--z-index-one);
    position: absolute;
    top: var(--space-minus-slab);
    right: var(--space-micro);
    text-align: left;
  }
}
.search-container {
  padding: 0 var(--space-one);
  position: sticky;
  top: 0;
  z-index: var(--z-index-twenty);
  background-color: var(--white);
}
.inbox-list-item {
  display: flex;
  align-items: center;
  padding: var(--space-one);
  cursor: pointer;
  &:hover {
    background-color: var(--s-50);
  }
  span {
    font-size: var(--font-size-small);
  }
}
.inbox-confirmation-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: var(--space-one);
  p {
    flex-grow: 1;
  }
  .inbox-confirmation-actions {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: var(--space-one);
  }
}

ul {
  margin: 0;
  list-style: none;
}
</style>
