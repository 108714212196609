import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentUserRoles: 'getCurrentRoles',
    }),
    isAdmin() {
      return this.currentUserRoles.includes('administrator');
    },
  },
};
