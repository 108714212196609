import { default as _advancedFilters } from './advancedFilters.json';
import { default as _agentMgmt } from './agentMgmt.json';
import { default as _attachmentsMgmt } from './attachmentsMgmt.json';
import { default as _attributesMgmt } from './attributesMgmt.json';
import { default as _automation } from './automation.json';
import { default as _bulkActions } from './bulkActions.json';
import { default as _cannedMgmt } from './cannedMgmt.json';
import { default as _chatlist } from './chatlist.json';
import { default as _contact } from './contact.json';
import { default as _contactFilters } from './contactFilters.json';
import { default as _conversation } from './conversation.json';
import { default as _csatMgmtMgmt } from './csatMgmt.json';
import { default as _generalSettings } from './generalSettings.json';
import { default as _inboxMgmt } from './inboxMgmt.json';
import { default as _labelsMgmt } from './labelsMgmt.json';
import { default as _login } from './login.json';
import { default as _loginOAuth } from './loginOAuth.json';
import { default as _report } from './report.json';
import { default as _resetPassword } from './resetPassword.json';
import { default as _setNewPassword } from './setNewPassword.json';
import { default as _settings } from './settings.json';
import { default as _signup } from './signup.json';
import { default as _teamsSettings } from './teamsSettings.json';
import { default as _sidePanel } from './sidePanel.json';
import { default as _skillsMgmt } from './skillsMgmt.json';

export default {
  ..._advancedFilters,
  ..._agentMgmt,
  ..._attributesMgmt,
  ..._attachmentsMgmt,
  ..._automation,
  ..._cannedMgmt,
  ..._skillsMgmt,
  ..._chatlist,
  ..._contact,
  ..._contactFilters,
  ..._conversation,
  ..._csatMgmtMgmt,
  ..._generalSettings,
  ..._inboxMgmt,
  ..._labelsMgmt,
  ..._login,
  ..._loginOAuth,
  ..._report,
  ..._resetPassword,
  ..._setNewPassword,
  ..._settings,
  ..._signup,
  ..._sidePanel,
  ..._teamsSettings,
  ..._bulkActions,
};
