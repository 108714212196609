<template>
  <div class="column content-box">
    <woot-modal-header :header-title="pageTitle" />
    <form class="row" @submit.prevent="editLabel">
      <woot-input
        v-model.trim="title"
        :class="{ error: $v.title.$error }"
        class="medium-12 columns"
        :label="$t('LABEL_MGMT.FORM.NAME.LABEL')"
        :placeholder="$t('LABEL_MGMT.FORM.NAME.PLACEHOLDER')"
        :error="getLabelTitleErrorMessage"
        :data-test-id="'label_title_input'"
        @input="$v.title.$touch"
      />
      <woot-input
        v-model.trim="description"
        :class="{ error: $v.description.$error }"
        class="medium-12 columns"
        :label="$t('LABEL_MGMT.FORM.DESCRIPTION.LABEL')"
        :placeholder="$t('LABEL_MGMT.FORM.DESCRIPTION.PLACEHOLDER')"
        :data-test-id="'label_description_input'"
        @input="$v.description.$touch"
      />

      <div class="medium-12">
        <label :class="{ error: $v.ownerType.$error }">
          {{ $t('LABEL_MGMT.FORM.LEVEL.LABEL') }}
        </label>
        <select
          v-model.trim="ownerType"
          :class="{ error: $v.ownerType.$error }"
          :data-test-id="'label_level_select'"
        >
          <option value="Account">
            {{ $t('LABEL_MGMT.FORM.LEVEL.OPTIONS.ACCOUNT') }}
          </option>
          <option value="Team">
            {{ $t('LABEL_MGMT.FORM.LEVEL.OPTIONS.TEAM') }}
          </option>
          <option value="Inbox">
            {{ $t('LABEL_MGMT.FORM.LEVEL.OPTIONS.INBOX') }}
          </option>
        </select>
      </div>

      <div v-if="isTeamOwner" class="medium-12 columns">
        <label for="team">{{ $t('LABEL_MGMT.FORM.TEAM.LABEL') }}</label>
        <select
          id="team"
          v-model.trim="ownerId"
          :data-test-id="'select_edit_team'"
        >
          <option
            v-for="(team, index) in teamsList"
            :key="index"
            :value="team.id"
            :data-test-id="'team_option_' + team.id"
          >
            {{ team.name }}
          </option>
        </select>
      </div>

      <div v-if="isInboxOwner" class="medium-12 columns">
        <label for="inbox">{{ $t('LABEL_MGMT.FORM.INBOX.LABEL') }}</label>
        <select
          id="inbox"
          v-model.trim="ownerId"
          :data-test-id="'select_edit_inbox'"
        >
          <option
            v-for="(inbox, index) in inboxesList"
            :key="index"
            :value="inbox.id"
            :data-test-id="'inbox_option_' + inbox.id"
          >
            {{ inbox.name }}
          </option>
        </select>
      </div>

      <div class="medium-12">
        <label>
          {{ $t('LABEL_MGMT.FORM.COLOR.LABEL') }}
          <woot-color-picker v-model="color" />
        </label>
      </div>
      <div class="medium-12">
        <input v-model="showOnSidebar" type="checkbox" :value="true" />
        <label for="conversation_creation">
          {{ $t('LABEL_MGMT.FORM.SHOW_ON_SIDEBAR.LABEL') }}
        </label>
      </div>
      <div class="modal-footer">
        <div class="medium-12 columns">
          <woot-button
            :is-disabled="
              $v.title.$invalid || uiFlags.isUpdating || $v.ownerType.$invalid
            "
            :is-loading="uiFlags.isUpdating"
            :data-test-id="'edit_label_submit'"
          >
            {{ $t('LABEL_MGMT.FORM.EDIT') }}
          </woot-button>
          <woot-button class="button clear" @click.prevent="onClose">
            {{ $t('LABEL_MGMT.FORM.CANCEL') }}
          </woot-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import validationMixin from './validationMixin';
import validations from './validations';
import wootConstants from '../../../../constants';

export default {
  mixins: [alertMixin, validationMixin],
  props: {
    selectedResponse: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      title: '',
      description: '',
      showOnSidebar: true,
      color: '',
      ownerType: this.ownerType,
      ownerId: this.ownerId,
    };
  },
  validations,
  computed: {
    ...mapGetters({
      uiFlags: 'labels/getUIFlags',
      teamsList: 'teams/getTeams',
      inboxesList: 'inboxes/getInboxes',
    }),
    pageTitle() {
      return `${this.$t('LABEL_MGMT.EDIT.TITLE')} - ${
        this.selectedResponse.title
      }`;
    },
    isTeamOwner() {
      return this.ownerType === 'Team';
    },
    isInboxOwner() {
      return this.ownerType === 'Inbox';
    },
  },
  mounted() {
    this.setFormValues();
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    setFormValues() {
      this.title = this.selectedResponse.title;
      this.description = this.selectedResponse.description;
      this.showOnSidebar = this.selectedResponse.show_on_sidebar;
      this.color = this.selectedResponse.color;
      this.ownerType = this.selectedResponse.owner_type;
      this.ownerId = this.selectedResponse.owner_id;
    },
    editLabel() {
      this.$store
        .dispatch('labels/update', {
          id: this.selectedResponse.id,
          color: this.color,
          description: this.description,
          title: this.title,
          show_on_sidebar: this.showOnSidebar,
          owner_type: this.ownerType,
          owner_id: this.ownerId,
        })
        .then(() => {
          this.showAlert(this.$t('LABEL_MGMT.EDIT.API.SUCCESS_MESSAGE'), {
            status: wootConstants.SNACKBAR_TYPE.SUCCESS,
          });
          setTimeout(() => this.onClose(), 10);
        })
        .catch(error => {
          let errorMessage = '';
          let snackbarType = wootConstants.SNACKBAR_TYPE.ERROR;

          switch (error.message) {
            case 'Title must be unique':
              errorMessage = this.$t(
                'LABEL_MGMT.EDIT.API.ERROR_MESSAGE_TITLE_TAKEN'
              );
              break;
            case 'Inbox ID is required':
              errorMessage = this.$t('LABEL_MGMT.ADD.API.INBOX_ID_REQUIRED');
              break;
            default:
              errorMessage = this.$t('LABEL_MGMT.EDIT.API.ERROR_MESSAGE');
          }

          this.showAlert(errorMessage, { status: snackbarType });
        });
    },
  },
};
</script>
