import { mapGetters } from 'vuex';
export const DEFAULT_CONVERSATION_SIDEBAR_ITEMS_ORDER = [
  { name: 'conversation_actions' },
  { name: 'conversation_info' },
  { name: 'previous_conversation' },
];
export const DEFAULT_CONTACT_SIDEBAR_ITEMS_ORDER = [
  { name: 'previous_conversation' },
];

export const isEditorHotKeyEnabled = (uiSettings, key) => {
  const {
    editor_message_key: editorMessageKey,
    enter_to_send_enabled: enterToSendEnabled,
  } = uiSettings || {};
  if (!editorMessageKey) {
    if (enterToSendEnabled) {
      return key === 'enter';
    }
    return key === 'cmd_enter';
  }
  return editorMessageKey === key;
};

export const uiSettingsMixin = {
  data() {
    return {
      featureUseAICopilotWorkspace:
        window.chatwootConfig?.featureUseAICopilotWorkspace,
      enableConversationPolling:
        window.chatwootConfig?.enableConversationPolling,
      enableNotificationPolling:
        window.chatwootConfig?.enableNotificationPolling,
      pollingInterval: {
        currentConversation:
          window.chatwootConfig?.pollingInterval?.currentConversation,
        latestConversations:
          window.chatwootConfig?.pollingInterval?.latestConversations,
        paginatedConversations:
          window.chatwootConfig?.pollingInterval?.paginatedConversations,
        unreadNotifications:
          window.chatwootConfig?.pollingInterval?.unreadNotifications,
        notifications: window.chatwootConfig?.pollingInterval?.notifications,
      },
    };
  },
  computed: {
    ...mapGetters({
      uiSettings: 'getUISettings',
      currentChat: 'getSelectedChat',
      getAccount: 'accounts/getAccount',
      accountId: 'getCurrentAccountId',
    }),

    contactSidebarItemsOrder() {
      const { contact_sidebar_items_order: itemsOrder } = this.uiSettings;
      return itemsOrder || DEFAULT_CONTACT_SIDEBAR_ITEMS_ORDER;
    },
    cognigy_agent_assist_workspace_url() {
      return this.currentChat.custom_attributes
        ?.cognigy_agent_assist_workspace_url;
    },
    showAICopilotWorkspace() {
      const currentAccount = this.getAccount(this.accountId);
      const isOverridedInAccount = currentAccount?.override_enable_aaw_view;
      const isEnabledInAccount =
        isOverridedInAccount && currentAccount?.enable_aaw_view;
      const userEnabled = this.uiSettings?.enable_aaw_view;

      return (
        this.featureUseAICopilotWorkspace &&
        !!this.cognigy_agent_assist_workspace_url &&
        (isEnabledInAccount || (!isOverridedInAccount && userEnabled))
      );
    },
    conversationSidebarItemsOrder() {
      const { conversation_sidebar_items_order: itemsOrder } = this.uiSettings;
      if (this.showAICopilotWorkspace) {
        return (
          itemsOrder ||
          DEFAULT_CONVERSATION_SIDEBAR_ITEMS_ORDER.filter(
            item => item.name !== 'previous_conversation'
          )
        );
      }
      return itemsOrder || DEFAULT_CONVERSATION_SIDEBAR_ITEMS_ORDER;
    },
  },
  methods: {
    updateUISettings(uiSettings = {}) {
      this.$store.dispatch('updateUISettings', {
        uiSettings: {
          ...this.uiSettings,
          ...uiSettings,
        },
      });
    },
    isContactSidebarItemOpen(key) {
      const { [key]: isOpen } = this.uiSettings;
      return !!isOpen;
    },
    toggleSidebarUIState(key) {
      this.updateUISettings({ [key]: !this.isContactSidebarItemOpen(key) });
    },
  },
};

export default uiSettingsMixin;
