<template>
  <div ref="viewBox" class="view-box fill-height">
    <div class="sidebar-toggle__wrap">
      <woot-button
        v-if="!showAICopilotWorkspace"
        variant="smooth"
        size="tiny"
        color-scheme="secondary"
        class="sidebar-toggle--button"
        :icon="isRightOrLeftIcon"
        @click="onToggleContactPanel"
      />
    </div>

    <ul class="conversation-panel">
      <transition name="slide-up">
        <li class="spinner--container">
          <span v-if="shouldShowSpinner" class="spinner message" />
        </li>
      </transition>
      <conversation-message
        v-for="message in getReadMessages"
        :key="message.id"
        class="message--read"
        :data="message"
        :has-user-read-message="
          hasUserReadMessage(message.created_at, getLastSeenAt)
        "
        :show-forward-context-menu-option="showForwardContextMenuOption"
        @forwardAssistMessage="forwardAssistMessage"
        @forwardAssistMessagePostback="forwardAssistMessagePostback"
        @forwardSuggestedReply="forwardSuggestedReply"
      />
      <li v-show="getUnreadCount != 0" class="unread--toast">
        <span class="text-uppercase">
          {{ getUnreadCount }}
          {{
            getUnreadCount > 1
              ? $t('CONVERSATION.UNREAD_MESSAGES')
              : $t('CONVERSATION.UNREAD_MESSAGE')
          }}
        </span>
      </li>
      <conversation-message
        v-for="message in getUnReadMessages"
        :key="message.id"
        class="message--unread"
        :data="message"
        :has-user-read-message="
          hasUserReadMessage(message.created_at, getLastSeenAt)
        "
        :show-forward-context-menu-option="showForwardContextMenuOption"
        @forwardAssistMessage="forwardAssistMessage"
        @forwardAssistMessagePostback="forwardAssistMessagePostback"
        @forwardSuggestedReply="forwardSuggestedReply"
      />
      <Transition name="appear">
        <woot-button
          v-if="!scrollIsAtBottom"
          class="scroll-to-bottom-button"
          :class="{
            'scroll-to-bottom-open-conversation-panel':
              isConversationsPanelOpen,
            'scroll-to-bottom-open-menu': isSideMenuOpen,
          }"
          icon="chevron-down"
          title="Scroll to bottom"
          @click="onScrollToMessage"
        />
      </Transition>
      <li ref="bottomAnchor" />
    </ul>
    <div
      class="conversation-footer"
      :class="{ 'modal-mask': isPopoutReplyBox }"
    >
      <div v-if="isAnyoneTyping" class="typing-indicator-wrap">
        <div class="typing-indicator">
          {{ typingUserNames }}
          <img class="gif" alt="Someone is typing" :src="typingGif" />
        </div>
      </div>
      <reply-box
        ref="replyBox"
        :conversation-id="currentChat.id"
        :popout-reply-box.sync="isPopoutReplyBox"
        :has-ai-copilot-message="hasAICopilotMessage"
        @click="showPopoutReplyBox"
        @scrollToMessage="scrollToBottom"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import typingGif from '@/dashboard/assets/images/typing.gif';

import ReplyBox from './ReplyBox/ReplyBox.vue';
import conversationMixin from '../../../mixins/conversations';
import { getTypingUsersText } from '../../../helper/commons';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import inboxMixin from 'shared/mixins/inboxMixin';
import { calculateScrollTop } from './helpers/scrollTopCalculationHelper';
import { isEscape } from 'shared/helpers/KeyboardHelpers';
import eventListenerMixins from 'shared/mixins/eventListenerMixins';
import { mixin as clickaway } from 'vue-clickaway';
import getStructuredMessageType from './helpers/getStructuredMessageType';
import uiSettingsMixin from '../../../mixins/uiSettings';
import WootButton from 'components/ui/WootButton.vue';
import ConversationMessage from 'components/widgets/conversation/Message.vue';
import { newMessageNotification } from 'shared/helpers/AudioNotificationHelper';

export default {
  components: {
    ConversationMessage,
    WootButton,
    ReplyBox,
  },
  mixins: [
    conversationMixin,
    inboxMixin,
    eventListenerMixins,
    clickaway,
    uiSettingsMixin,
  ],
  props: {
    isContactPanelOpen: {
      type: Boolean,
      default: false,
    },
    showForwardContextMenuOption: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      typingGif,
      isLoadingPrevious: true,
      heightBeforeLoad: null,
      conversationPanel: null,
      isPopoutReplyBox: false,
      aiCopilotMessage: '',
      hasAICopilotMessage: false,
      aiCopilotGroupId: '',
      scrollIsAtBottom: true,
      showScrollToBottomButton: false,
      fetchNextMessagesInterval: null,
      numberOfMessages: 0,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      allConversations: 'getAllConversations',
      inboxesList: 'inboxes/getInboxes',
      listLoadingStatus: 'getAllMessagesLoaded',
      getUnreadCount: 'getUnreadCount',
      loadingChatList: 'getChatListLoadingStatus',
      conversationLastSeen: 'getConversationLastSeen',
      isSideMenuOpen: 'ui/isSideMenuOpen',
      isConversationsPanelOpen: 'ui/isConversationsPanelOpen',
    }),
    inboxId() {
      return this.currentChat.inbox_id;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.inboxId);
    },
    typingUsersList() {
      return this.$store.getters['conversationTypingStatus/getUserList'](
        this.currentChat.id
      );
    },
    isAnyoneTyping() {
      const userList = this.typingUsersList;
      return userList.length !== 0;
    },
    typingUserNames() {
      const userList = this.typingUsersList;

      if (this.isAnyoneTyping) {
        return getTypingUsersText(userList);
      }

      return '';
    },

    getMessages() {
      let conversation = {};
      const [chat] = this.allConversations.filter(
        c => c.id === this.currentChat.id
      );
      // Use copy instead of reference to avoid rendering loop
      Object.assign(conversation, chat);
      conversation.messages = this.groupAICopilotMessages(chat.messages);
      return conversation;
    },
    getReadMessages() {
      const chat = this.getMessages;
      return chat === undefined ? null : this.readMessages(chat);
    },
    getUnReadMessages() {
      const chat = this.getMessages;
      return chat === undefined ? null : this.unReadMessages(chat);
    },
    shouldShowSpinner() {
      return (
        (this.getMessages && this.getMessages.dataFetched === undefined) ||
        (!this.listLoadingStatus && this.isLoadingPrevious)
      );
    },

    shouldLoadMoreChats() {
      return !this.listLoadingStatus && !this.isLoadingPrevious;
    },

    conversationType() {
      const { additional_attributes: additionalAttributes } = this.currentChat;
      const type = additionalAttributes ? additionalAttributes.type : '';
      return type || '';
    },
    isRightOrLeftIcon() {
      if (this.isContactPanelOpen) {
        return 'arrow-chevron-right';
      }
      return 'arrow-chevron-left';
    },

    getLastSeenAt() {
      if (this.conversationLastSeen) return this.conversationLastSeen;
      const { contact_last_seen_at: contactLastSeenAt } = this.currentChat;
      return contactLastSeenAt;
    },
  },
  watch: {
    scrollIsAtBottom() {
      if (this.scrollIsAtBottom) {
        this.$store.dispatch('ui/setConversationScrollbarAtBottomStatus', true);
        this.makeMessagesRead();
      } else {
        this.$store.dispatch(
          'ui/setConversationScrollbarAtBottomStatus',
          false
        );
      }
    },
    getMessages() {
      if (!this.enableConversationPolling) {
        return;
      }

      this.$nextTick(() => {
        if (this.scrollIsAtBottom) {
          this.scrollToBottom();
          this.makeMessagesRead();
        }
      });
    },
  },
  created() {
    bus.$on(BUS_EVENTS.SCROLL_TO_MESSAGE, this.onScrollToMessage);
    bus.$on(BUS_EVENTS.ADD_MESSAGE_TO_CHAT_INPUT, this.forwardAssistMessage);
    bus.$on(
      BUS_EVENTS.ADD_POSTBACK_TO_CHAT_INPUT,
      this.forwardAssistMessagePostback
    );
  },

  mounted() {
    if (this.enableConversationPolling) {
      this.fetchNextMessagesInterval = setInterval(
        this.fetchNextMessages,
        this.pollingInterval.currentConversation
      );
    }
    this.addScrollListener();
    this.addIntersectionObserver();
  },

  beforeDestroy() {
    if (this.fetchNextMessagesInterval) {
      clearInterval(this.fetchNextMessagesInterval);
    }

    this.removeBusListeners();
    this.removeScrollListener();
    this.removeIntersectionObserver();
  },

  methods: {
    forwardAssistMessage(message) {
      if (message.content) {
        this.setAssistTextMessageInReplyBox(message);
      } else {
        this.sendForwardedMessage(message);
      }
    },
    forwardAssistMessagePostback(payload) {
      this.setTextMessageInReplyBox(payload);
    },
    forwardSuggestedReply(payload) {
      this.setTextMessageInReplyBox(payload);
    },
    groupAICopilotMessages(messages) {
      let previousMessageIsAICopilot = false;

      return messages.map((message, index, messagesArray) => {
        const isAICopilot = message.message_type === 5;
        const nextMessageIsAICopilot =
          messagesArray[index + 1]?.message_type === 5;

        // Return isTopAICopilotMessage if it is the first message in an Copilot group
        // and create the group id, also add isOnlyMessage if it is the only one
        if (
          isAICopilot &&
          !previousMessageIsAICopilot &&
          !nextMessageIsAICopilot
        ) {
          this.aiCopilotGroupId = message.id;
          previousMessageIsAICopilot = isAICopilot;
          return {
            ...message,
            isTopAICopilotMessage: true,
            isOnlyAICopilotMessage: true,
            aiCopilotGroupId: this.aiCopilotGroupId,
          };
        }
        // Return isTopAICopilotMessage if it is the first message in an Copilot group
        // and create the group id
        if (isAICopilot && !previousMessageIsAICopilot) {
          this.aiCopilotGroupId = message.id;
          previousMessageIsAICopilot = isAICopilot;
          return {
            ...message,
            isTopAICopilotMessage: true,
            aiCopilotGroupId: this.aiCopilotGroupId,
          };
        }
        // Return isLastAICopilotMessageInGroup if it is the last message in an Copilot group
        if (isAICopilot && !nextMessageIsAICopilot) {
          previousMessageIsAICopilot = isAICopilot;
          return {
            ...message,
            isLastAICopilotMessageInGroup: true,
            aiCopilotGroupId: this.aiCopilotGroupId,
          };
        }
        // Return normal Copilot message with correspondent group id
        if (isAICopilot) {
          previousMessageIsAICopilot = isAICopilot;
          return {
            ...message,
            aiCopilotGroupId: this.aiCopilotGroupId,
          };
        }

        // Return normal message if no Copilot message
        previousMessageIsAICopilot = isAICopilot;
        return message;
      });
    },
    removeBusListeners() {
      bus.$off(BUS_EVENTS.SCROLL_TO_MESSAGE, this.onScrollToMessage);
      bus.$off('scrollToMessage', this.onScrollToMessage);
    },
    onScrollToMessage() {
      this.$nextTick(() => this.scrollToBottom());
      this.makeMessagesRead();
    },
    showPopoutReplyBox() {
      this.isPopoutReplyBox = !this.isPopoutReplyBox;
    },
    closePopoutReplyBox() {
      this.isPopoutReplyBox = false;
    },
    handleKeyEvents(e) {
      if (isEscape(e)) {
        this.closePopoutReplyBox();
      }
    },
    addIntersectionObserver() {
      this.observer = new IntersectionObserver(
        ([observedElement]) => {
          this.scrollIsAtBottom = observedElement.isIntersecting;
        },
        { root: this.$refs.viewBox }
      );
      this.observer.observe(this.$refs.bottomAnchor);
    },
    removeIntersectionObserver() {
      this.observer.unobserve(this.$refs.bottomAnchor);
    },
    addScrollListener() {
      this.conversationPanel = this.$el.querySelector('.conversation-panel');
      this.setScrollParams();
      this.conversationPanel.addEventListener('scroll', this.handleScroll);
      this.$nextTick(() => this.scrollToBottom());
      this.isLoadingPrevious = false;
    },
    removeScrollListener() {
      this.conversationPanel.removeEventListener('scroll', this.handleScroll);
    },
    scrollToBottom() {
      let relevantMessages = [];
      // TODO: We need to improve how Live Agent defines unread messages, current Chatwoot implementation does not seems very solid,
      // we will let this piece of code here, since it is what Chatwoot was using, the current criteria is if an agent spent more time
      // in a chat than what the last message was created, then it is considered as "read", this solution is not very useful since it
      // is not very accurate, the time is different after around 1 second. This also push back the auto-scroll capability.

      // if (this.getUnreadCount > 0) {
      //   // capturing only the unread messages
      //   relevantMessages =
      //     this.conversationPanel.querySelectorAll('.message--unread');
      // } else {
      //   // capturing last message from the messages list
      //   relevantMessages = Array.from(
      //     this.conversationPanel.querySelectorAll('.message--read')
      //   ).slice(-1);
      // }

      relevantMessages = Array.from(
        this.conversationPanel.querySelectorAll(
          '[data-test-id^="item_message"]'
        )
      ).slice(-1);
      this.conversationPanel.scrollTop = calculateScrollTop(
        this.conversationPanel.scrollHeight,
        this.$el.scrollHeight,
        relevantMessages
      );
    },
    onToggleContactPanel() {
      this.$emit('contact-panel-toggle');
    },

    setScrollParams() {
      this.heightBeforeLoad = this.conversationPanel.scrollHeight;
      this.scrollTopBeforeLoad = this.conversationPanel.scrollTop;
    },
    fetchNextMessages() {
      const lastMessage =
        this.getMessages.messages[this.getMessages.messages.length - 1];
      const afterParam = lastMessage ? lastMessage.id : null;
      if (afterParam) {
        this.$store.dispatch('fetchNextMessages', {
          conversationId: this.currentChat.id,
          after: afterParam,
        });
      }
      // call new message notification when numberOfMessages is different
      if (this.getMessages.messages.length !== this.numberOfMessages) {
        newMessageNotification(lastMessage);
        this.numberOfMessages = this.getMessages.messages.length;
      }
    },

    // TODO: investigate debouncing for scroll event, if necessary
    handleScroll(e) {
      this.setScrollParams();

      const dataFetchCheck =
        this.getMessages.dataFetched === true && this.shouldLoadMoreChats;
      if (
        e.target.scrollTop < 100 &&
        !this.isLoadingPrevious &&
        dataFetchCheck
      ) {
        this.isLoadingPrevious = true;
        this.$store
          .dispatch('fetchPreviousMessages', {
            conversationId: this.currentChat.id,
            before: this.getMessages.messages[0]?.id,
          })
          .then(() => {
            const heightDifference =
              this.conversationPanel.scrollHeight - this.heightBeforeLoad;
            this.conversationPanel.scrollTop =
              this.scrollTopBeforeLoad + heightDifference;
            this.isLoadingPrevious = false;
            this.setScrollParams();
          });
      }
    },

    makeMessagesRead() {
      this.$store.dispatch('markMessagesRead', {
        id: this.currentChat.id,
      });
    },
    setTextMessageInReplyBox(message) {
      this.$refs.replyBox.tags = [];
      this.$refs.replyBox.setAssistMessageInReplyBox(message);
    },
    setAssistTextMessageInReplyBox(message) {
      this.$refs.replyBox.tags = [];
      this.$refs.replyBox.setAssistTextMessageInReplyBox(message);
    },
    sendForwardedMessage(message) {
      const result = getStructuredMessageType({
        data: message,
        buttonWithTextDescription: this.$t(
          'AGENT_ASSIST.REPLY_TAG_MESSAGE.TEXT_WITH_BUTTONS'
        ),
        quickReplyDescription: this.$t(
          'AGENT_ASSIST.REPLY_TAG_MESSAGE.QUICK_REPLY'
        ),
        galleryDescription: this.$t('AGENT_ASSIST.REPLY_TAG_MESSAGE.GALLERY'),
        listDescription: this.$t('AGENT_ASSIST.REPLY_TAG_MESSAGE.LIST'),
        audioDescription: this.$t('AGENT_ASSIST.REPLY_TAG_MESSAGE.AUDIO'),
        imageDescription: this.$t('AGENT_ASSIST.REPLY_TAG_MESSAGE.IMAGE'),
        videoDescription: this.$t('AGENT_ASSIST.REPLY_TAG_MESSAGE.VIDEO'),
        adaptiveCardDescription: this.$t(
          'AGENT_ASSIST.REPLY_TAG_MESSAGE.ADAPTIVE_CARD'
        ),
        unknownDescription: this.$t('AGENT_ASSIST.REPLY_TAG_MESSAGE.UNKNOWN'),
      });

      if (result.messageTypeDescription !== undefined) {
        this.$refs.replyBox.setAssistMessageTagInReplyBox(
          message,
          result.messageTypeDescription
        );
      } else {
        this.$refs.replyBox.setStructuredAssistMessageInReplyBox(
          message,
          result.text
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'dashboard/assets/scss/variables';
@import 'foundation-sites/scss/foundation';

.spinner--container {
  min-height: var(--space-jumbo);
}

.view-box.fill-height {
  height: auto;
  flex-grow: 1;
  min-width: 0;
}

.modal-mask {
  &::v-deep {
    .ProseMirror-woot-style {
      max-height: 40rem;
    }

    .reply-box {
      border: 1px solid var(--color-border);
      max-width: 120rem;
      width: 70%;
    }

    .reply-box .reply-box__top {
      position: relative;
      min-height: 44rem;
    }

    .reply-box__top .input {
      min-height: 44rem;
    }

    .emoji-dialog {
      position: fixed;
      left: unset;
      position: absolute;
    }

    .emoji-dialog::before {
      transform: rotate(0deg);
      left: 5px;
      bottom: var(--space-minus-slab);
    }
  }
}
.sidebar-toggle__wrap {
  display: flex;
  justify-content: flex-end;

  .sidebar-toggle--button {
    position: fixed;

    top: var(--space-mega);
    z-index: var(--z-index-low);

    background: var(--white);

    padding: inherit 0;
    border-top-left-radius: calc(
      var(--space-medium) + 1px
    ); /* 100px of height + 10px of border */
    border-bottom-left-radius: calc(
      var(--space-medium) + 1px
    ); /* 100px of height + 10px of border */
    border: 1px solid var(--color-border-light);
    border-right: 0;
    box-sizing: border-box;
  }
}

.scroll-to-bottom-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: fixed;
  bottom: 200px;
  left: 1em;

  @include breakpoint(1400 up) {
    width: 50px;
    height: 50px;
  }
}
.scroll-to-bottom-button.scroll-to-bottom-open-conversation-panel {
  @include breakpoint(large up) {
    left: 26em;
  }

  @include breakpoint(xlarge up) {
    left: 26em;
  }

  @include breakpoint(1400 up) {
    left: 28em;
    width: 50px;
    height: 50px;
  }

  @include breakpoint(1600 up) {
    left: 34em;
  }
}

.scroll-to-bottom-button.scroll-to-bottom-open-menu {
  @include breakpoint(large up) {
    left: 20em;
  }

  @include breakpoint(xlarge up) {
    left: 20em;
  }

  @include breakpoint(1400 up) {
    left: 20em;
    width: 50px;
    height: 50px;
  }

  @include breakpoint(1600 up) {
    left: 20em;
  }
}

.scroll-to-bottom-button.scroll-to-bottom-open-menu.scroll-to-bottom-open-conversation-panel {
  @include breakpoint(large up) {
    left: 44em !important;
  }

  @include breakpoint(xlarge up) {
    left: 44em !important;
  }

  @include breakpoint(1400 up) {
    left: 46em !important;
    width: 50px !important;
    height: 50px !important;
  }

  @include breakpoint(1600 up) {
    left: 52em !important;
  }
}

.appear-enter-active {
  animation: appear 0.25s;
}
.appear-leave-active {
  animation: appear 0.25s reverse;
}
@keyframes appear {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
</style>
