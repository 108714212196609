<template>
  <div class="container" :class="{ smallContainer: isSideMenuOpen }">
    <p class="title">{{ title }}</p>
    <p v-if="subTitle" class="subtitle">{{ subTitle }}</p>

    <div v-if="image" class="container-image">
      <div class="sub-container">
        <img class="image" :src="image" @click="openImageModal" />
        <div v-if="button" class="action-image">
          <woot-button @click="openUrl">{{ button.title }}</woot-button>
        </div>
      </div>
      <p v-dompurify-html="body" class="body-image">
        {{ body }}
      </p>
    </div>

    <div v-else>
      <p v-dompurify-html="body" class="body">
        {{ body }}
      </p>
      <div v-if="button" class="action">
        <woot-button @click="openUrl">{{ button.title }}</woot-button>
      </div>
    </div>
    <image-modal
      :show="showImageModal"
      :image-url="image"
      @close="closeImageModal"
    />
  </div>
</template>

<script>
import ImageModal from './../../modal/ImageModal.vue';

export default {
  components: {
    ImageModal,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
    isSideMenuOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showImageModal: false,
      imageModalUrl: '',
    };
  },
  computed: {
    title() {
      return this.data[0].text;
    },
    subTitle() {
      return this.data[1].text;
    },
    image() {
      if (this.data.length === 5) {
        return this.data[2].url;
      }
      return null;
    },
    body() {
      if (this.data.length === 5) {
        return this.data[3].text;
      }
      return this.data[2].text;
    },
    button() {
      if (
        this.data.length === 5 &&
        this.data[4].actions[0]?.type === 'Action.OpenUrl'
      ) {
        return this.data[4].actions[0];
      }
      if (
        this.data.length === 4 &&
        this.data[3].actions[0]?.type === 'Action.OpenUrl'
      ) {
        return this.data[3].actions[0];
      }
      return null;
    },
  },
  methods: {
    openUrl() {
      window.open(this.button.url, '_blank');
    },
    openImageModal() {
      this.showImageModal = true;
    },
    closeImageModal() {
      this.showImageModal = false;
    },
  },
};
</script>

<style scoped lang="scss">
@import 'dashboard/assets/scss/woot';

.container {
  display: flex;
  flex-direction: column;
  min-width: 400px;
  width: 400px;
  @include breakpoint(xlargeHD up) {
    width: 550px;
  }
  padding: 15px 15px 15px 0;
}

.smallContainer {
  width: 230px;
  min-width: 230px;
  padding-right: 0;
  padding-left: 10px;
  @include breakpoint(xlargeHD up) {
    width: 550px;
    padding: 15px 15px 15px 0;
  }
}

.sub-container {
  width: 130px;
}
.container-image {
  display: flex;
}
.title {
  font-size: 1.6rem;
  color: #1a48a2;
  @include breakpoint(xlargeHD up) {
    font-size: 2rem;
  }
}
.subtitle {
  font-size: 1.2rem;
  @include breakpoint(xlargeHD up) {
    font-size: 1.2rem;
  }
}
.image {
  width: 130px;
  border-radius: 5px;
  cursor: pointer;
}

.body {
  word-wrap: break-word;
  text-align: justify;
  font-size: 1.3rem;
  white-space: pre-wrap;
  overflow: auto;

  @include breakpoint(xlargeHD up) {
    font-size: 1.5rem;
  }
}

.body-image {
  word-wrap: break-word;
  width: 255px;
  text-align: justify;
  padding-left: 10px;
  font-size: 1.3rem;
  white-space: pre-wrap;
  overflow: auto;

  @include breakpoint(xlargeHD up) {
    font-size: 1.5rem;
    width: 400px;
  }
}

.action-image {
  padding-top: 3px;

  button {
    width: 130px;
  }
}
.action {
  button {
    width: 130px;
  }
}
</style>
