import axios from 'axios';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Auth from '../api/auth';
import { ApiClientOptions, IApiClient } from './types/apiClient.types';

const DEFAULT_API_VERSION = 'v1';

const getAuthHeaders = () => {
  if (!Auth.hasAuthCookie()) return {};

  const {
    'access-token': accessToken,
    'token-type': tokenType,
    client,
    expiry,
    uid,
  } = Auth.getAuthData();

  return {
    'access-token': accessToken,
    'token-type': tokenType,
    client,
    expiry,
    uid,
  };
};

export const axiosWithAuth: Pick<
  typeof axios,
  'get' | 'patch' | 'post' | 'put' | 'delete'
> = {
  get: (url, config) =>
    axios.get(url, {
      ...config,
      headers: { ...getAuthHeaders(), ...config?.headers },
    }),
  patch: (url, data, config) =>
    axios.patch(url, data, {
      ...config,
      headers: { ...getAuthHeaders(), ...config?.headers },
    }),
  post: (url, data, config) =>
    axios.post(url, data, {
      ...config,
      headers: { ...getAuthHeaders(), ...config?.headers },
    }),
  put: (url, data, config) =>
    axios.put(url, data, {
      ...config,
      headers: { ...getAuthHeaders(), ...config?.headers },
    }),
  delete: (url, config) =>
    axios.delete(url, {
      ...config,
      headers: { ...getAuthHeaders(), ...config?.headers },
    }),
};
class ApiClient implements IApiClient {
  apiVersion: string;

  options: ApiClientOptions;

  resource: string;

  client: typeof axiosWithAuth;

  constructor(resource: string, options: ApiClientOptions = {}) {
    this.apiVersion = `/api/${options.apiVersion || DEFAULT_API_VERSION}`;
    this.options = options;
    this.resource = resource;
    this.client = axiosWithAuth;
  }

  get url() {
    return `${this.baseUrl()}/${this.resource}`;
  }

  baseUrl() {
    let url = this.apiVersion;
    if (this.options.accountScoped) {
      const isInsideAccountScopedURLs =
        window.location.pathname.includes('/app/accounts');

      if (isInsideAccountScopedURLs) {
        const accountId = window.location.pathname.split('/')[3];
        url = `${url}/accounts/${accountId}`;
      }
    }

    return url;
  }

  get() {
    return this.client.get(this.url);
  }

  show(id: number | string) {
    return this.client.get(`${this.url}/${id}`);
  }

  create(data: unknown) {
    return this.client.post(this.url, data);
  }

  update(id: number | string, data: unknown) {
    return this.client.patch(`${this.url}/${id}`, data);
  }

  delete(id: number | string) {
    return this.client.delete(`${this.url}/${id}`);
  }
}

export default ApiClient;
