import capitalize from '../../shared/helpers/capitalize';

const MESSAGE_VARIABLES_REGEX = /{{(.*?)}}/g;
export const replaceVariablesInMessage = ({ message, variables }) => {
  return message.replace(MESSAGE_VARIABLES_REGEX, (match, replace) => {
    return variables[replace.trim()] ? variables[replace.trim()] : '';
  });
};

const skipCodeBlocks = str => str.replace(/```(?:.|\n)+?```/g, '');

/**
 * Returns the name of a user
 *
 * @param user
 * @returns {*|undefined}
 */
export const getName = ({ user }) => {
  let currentName = user?.name?.trim();

  if (!currentName) {
    return 'Customer';
  }

  if (currentName.split(' ').length > 1) {
    const parts = currentName.split(' ');
    const firstName = capitalize(parts.shift());
    const lastName = capitalize(parts.pop());

    if (parts.length === 0) {
      currentName = `${firstName} ${lastName}`;
    } else {
      currentName = `${firstName} ${parts.join(' ').trim()} ${lastName}`.trim();
    }
  }

  return currentName;
};

/**
 * Returns the first name of a user
 * It either extracts the first name from the user name or from the user profile.
 * If neither is available, it returns an empty string.
 *
 * @param user
 * @returns {*|string}
 */
export const getFirstName = ({ user }) => {
  let name = user?.profile?.firstname?.trim() || getName({ user });

  if (name !== 'Customer') {
    return capitalize(
      name.split(' ').length > 1 ? name.split(' ').shift() : name
    );
  }

  return name;
};

/**
 * Returns the last name of a user
 * It either extracts the last name from the user name or from the user profile.
 * If neither is available, it returns an empty string.
 *
 * @param user
 * @returns {*|string}
 */
export const getLastName = ({ user }) => {
  let name = user?.profile?.lastname?.trim() || getName({ user });

  if (name !== 'Customer') {
    let lastName = name.split(' ').length > 1 ? name.split(' ').pop() : name;
    return capitalize(lastName);
  }

  return '';
};

/**
 * Returns a list of variables that can be used in a message
 * The list is based on the conversation and the contact of the conversation.
 *
 * @param conversation
 * @param contact
 * @returns {{"contact.birthday": (string|string|string|*), "contact.last_name": (*|string), "conversation.id", "contact.gender": *, "contact.name": (*|undefined), "contact.age": (string|number|string|string|number|*), "contact.first_name": (*|string), "contact.location": *, "contact.accepted_gdpr": (string), "contact.email": *, "agent.name": (*|string), "agent.email": (*|string), "agent.first_name": (*|string), "contact.id": *, "agent.last_name": (*|string)}}
 */
export const getMessageVariables = ({ conversation, contact }) => {
  const {
    meta: { assignee = {} },
    id,
  } = conversation;

  return {
    'contact.name': getName({ user: contact }),
    'contact.first_name': getFirstName({ user: contact }),
    'contact.last_name': getLastName({ user: contact }),
    'contact.email': contact?.email,
    'contact.age': contact?.profile?.age,
    'contact.gender': contact?.profile?.gender,
    'contact.birthday': contact?.profile?.birthday,
    'contact.location': contact?.profile?.location,
    'contact.accepted_gdpr': contact?.profile?.accepted_gdpr
      ? 'accepted'
      : 'not accepted', // this prevents the variable to falsely trigger the undefined variable warning.
    'contact.id': contact?.id,
    'conversation.id': id,
    'agent.name': assignee?.name ? assignee?.name : '',
    'agent.first_name': getFirstName({ user: assignee }),
    'agent.last_name': getLastName({ user: assignee }),
    'agent.email': assignee?.email ?? '',
  };
};

export const getUndefinedVariablesInMessage = ({ message, variables }) => {
  const messageWithOutCodeBlocks = skipCodeBlocks(message);
  const matches = messageWithOutCodeBlocks.match(MESSAGE_VARIABLES_REGEX);
  if (!matches) return [];

  return matches
    .map(match => {
      return match.replace('{{', '').replace('}}', '').trim();
    })
    .filter(variable => {
      return !variables[variable];
    });
};
