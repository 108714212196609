/* eslint arrow-body-style: 0 */
import ConversationView from './ConversationView.vue';
import { frontendURL } from '../../../helper/URLHelper';
import wootConstants from '../../../constants';

const parseExistsParam = existsParam => {
  const existsArray = existsParam?.split(',');
  if (
    existsParam !== undefined &&
    existsArray !== undefined &&
    existsArray.length > 0
  ) {
    const allowedFields = [
      'assignee_id',
      'first_reply_created_at',
      'labels',
      'team_id',
    ];

    return existsArray.filter(el => allowedFields.includes(el));
  }
  return undefined;
};

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/dashboard'),
      name: 'home',
      roles: ['administrator', 'agent', 'supervisor'],
      component: ConversationView,
      props: route => {
        const statusTypes = Object.values(wootConstants.STATUS_TYPE);
        const status = statusTypes.includes(route.query?.status)
          ? route.query?.status
          : undefined;

        const assigneeId = Number(route.query?.assignee_id);

        const filters = {
          status: status,
          assigneeId: Number.isNaN(assigneeId) ? undefined : assigneeId,
          notPresent: parseExistsParam(route.query?.not_present),
        };

        return {
          inboxId: 0,
          filters: filters,
        };
      },
    },
    {
      path: frontendURL('accounts/:accountId/conversations/:conversation_id'),
      name: 'inbox_conversation',
      roles: ['administrator', 'agent', 'supervisor'],
      component: ConversationView,
      props: route => {
        return { inboxId: 0, conversationId: route.params.conversation_id };
      },
    },
    {
      path: frontendURL('accounts/:accountId/inbox/:inbox_id'),
      name: 'inbox_dashboard',
      roles: ['administrator', 'agent', 'supervisor'],
      component: ConversationView,
      props: route => {
        return { inboxId: route.params.inbox_id };
      },
    },
    {
      path: frontendURL(
        'accounts/:accountId/inbox/:inbox_id/conversations/:conversation_id'
      ),
      name: 'conversation_through_inbox',
      roles: ['administrator', 'agent', 'supervisor'],
      component: ConversationView,
      props: route => {
        return {
          conversationId: route.params.conversation_id,
          inboxId: route.params.inbox_id,
        };
      },
    },
    {
      path: frontendURL('accounts/:accountId/label/:label'),
      name: 'label_conversations',
      roles: ['administrator', 'agent', 'supervisor'],
      component: ConversationView,
      props: route => ({ label: route.params.label }),
    },
    {
      path: frontendURL(
        'accounts/:accountId/label/:label/conversations/:conversation_id'
      ),
      name: 'conversations_through_label',
      roles: ['administrator', 'agent', 'supervisor'],
      component: ConversationView,
      props: route => ({
        conversationId: route.params.conversation_id,
        label: route.params.label,
      }),
    },
    {
      path: frontendURL('accounts/:accountId/team/:teamId'),
      name: 'team_conversations',
      roles: ['administrator', 'agent', 'supervisor'],
      component: ConversationView,
      props: route => ({ teamId: route.params.teamId }),
    },
    {
      path: frontendURL(
        'accounts/:accountId/team/:teamId/conversations/:conversationId'
      ),
      name: 'conversations_through_team',
      roles: ['administrator', 'agent', 'supervisor'],
      component: ConversationView,
      props: route => ({
        conversationId: route.params.conversationId,
        teamId: route.params.teamId,
      }),
    },
    {
      path: frontendURL('accounts/:accountId/custom_view/:id'),
      name: 'folder_conversations',
      roles: ['administrator', 'agent', 'supervisor'],
      component: ConversationView,
      props: route => ({ foldersId: route.params.id }),
    },
    {
      path: frontendURL(
        'accounts/:accountId/custom_view/:id/conversations/:conversation_id'
      ),
      name: 'conversations_through_folders',
      roles: ['administrator', 'agent', 'supervisor'],
      component: ConversationView,
      props: route => ({
        conversationId: route.params.conversation_id,
        foldersId: route.params.id,
      }),
    },
    {
      path: frontendURL('accounts/:accountId/mentions/conversations'),
      name: 'conversation_mentions',
      roles: ['administrator', 'agent', 'supervisor'],
      component: ConversationView,
      props: () => ({ conversationType: 'mention' }),
    },
    {
      path: frontendURL(
        'accounts/:accountId/mentions/conversations/:conversationId'
      ),
      name: 'conversation_through_mentions',
      roles: ['administrator', 'agent', 'supervisor'],
      component: ConversationView,
      props: route => ({
        conversationId: route.params.conversationId,
        conversationType: 'mention',
      }),
    },
  ],
};
