import ConversationReports from './Index.vue';
import AgentReports from './AgentReports.vue';
import LabelReports from './LabelReports.vue';
import InboxReports from './InboxReports.vue';
import TeamReports from './TeamReports.vue';
import LiveReports from './LiveReports.vue';
import SettingsContent from '../Wrapper.vue';
import { frontendURL } from '../../../../helper/URLHelper';

const featureDisableReports = window.chatwootConfig.featureDisableReports;

const reportsRoutes = store => [
  {
    path: frontendURL('accounts/:accountId/reports'),
    component: SettingsContent,
    props: {
      headerTitle: 'REPORT.HEADER',
      icon: 'chat',
      keepAlive: false,
    },
    children: [
      {
        path: 'conversation',
        name: 'conversation_reports',
        roles: ['administrator', 'supervisor'],
        component: ConversationReports,
      },
    ],
  },
  {
    path: frontendURL('accounts/:accountId/reports'),
    component: SettingsContent,
    props: {
      headerTitle: 'AGENT_REPORTS.HEADER',
      icon: 'people',
      keepAlive: false,
    },
    children: [
      {
        path: 'agent',
        redirect: () => `agent/${store.getters.getCurrentUserID}`,
      },
      {
        path: 'agent/:agentID',
        name: 'agent_reports',
        roles: ['administrator', 'supervisor'],
        component: AgentReports,
      },
    ],
  },
  {
    path: frontendURL('accounts/:accountId/reports'),
    component: SettingsContent,
    props: {
      headerTitle: 'LABEL_REPORTS.HEADER',
      icon: 'tag',
      keepAlive: false,
    },
    children: [
      {
        path: 'label',
        name: 'label_reports',
        roles: ['administrator', 'supervisor'],
        component: LabelReports,
      },
    ],
  },
  {
    path: frontendURL('accounts/:accountId/reports'),
    component: SettingsContent,
    props: {
      headerTitle: 'INBOX_REPORTS.HEADER',
      icon: 'mail-inbox-all',
      keepAlive: false,
    },
    children: [
      {
        path: 'inboxes',
        name: 'inbox_reports',
        roles: ['administrator', 'supervisor'],
        component: InboxReports,
      },
    ],
  },
  {
    path: frontendURL('accounts/:accountId/reports'),
    component: SettingsContent,
    props: {
      headerTitle: 'TEAM_REPORTS.HEADER',
      icon: 'people-team',
    },
    children: [
      {
        path: 'teams',
        name: 'team_reports',
        roles: ['administrator', 'supervisor'],
        component: TeamReports,
      },
    ],
  },
];

export default store => ({
  routes: [
    {
      path: frontendURL('accounts/:accountId/reports'),
      name: 'reports_home',
      roles: ['administrator', 'supervisor'],
      redirect: () => {
        if (store.getters.getCurrentRoles?.includes('supervisor')) {
          return frontendURL('accounts/:accountId/reports/overview');
        }
        return frontendURL('accounts/:accountId/reports/conversation');
      },
    },
    {
      path: frontendURL('accounts/:accountId/reports'),
      component: SettingsContent,
      props: {
        headerTitle: 'OVERVIEW_REPORTS.HEADER',
        icon: 'arrow-trending-lines',
        keepAlive: false,
      },
      roles: ['supervisor'],
      children: [
        {
          path: 'overview',
          name: 'account_overview_reports',
          roles: ['supervisor'],
          component: LiveReports,
        },
      ],
    },
    ...(featureDisableReports === 'true' ? [] : reportsRoutes(store)),
  ],
});
