import ApiClient, { axiosWithAuth } from './ApiClient';

class CannedResponse extends ApiClient {
  constructor() {
    super('canned_responses', { accountScoped: true });
  }

  get({ searchKey }) {
    const url = searchKey ? `${this.url}?search=${searchKey}` : this.url;
    return axiosWithAuth.get(url);
  }

  upload(file) {
    const formData = new FormData();
    formData.append('file', file.resource.file);
    return axiosWithAuth.post(`${this.url}/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export default new CannedResponse();
