<template>
  <woot-modal
    :show="show"
    :on-close="onClose"
    data-test-id="raw-data-modal"
    size="medium"
  >
    <div class="container">
      <pre class="content">
        <woot-button class="copy-button" @click.stop="copyToClipboard">{{ $t('COMPONENTS.CODE.BUTTON_TEXT') }}</woot-button>{{ rawData }}</pre>
    </div>
  </woot-modal>
</template>

<script>
import wootConstants from '../../../constants';
import copy from 'copy-text-to-clipboard';
import alertMixin from 'shared/mixins/alertMixin';
import WootModal from 'components/Modal.vue';
import WootButton from 'components/ui/WootButton.vue';

export default {
  components: { WootButton, WootModal },
  mixins: [alertMixin],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    rawData: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    copyToClipboard() {
      copy(JSON.stringify(this.rawData, 0, 4));
      this.showAlert(this.$t('COMPONENTS.CODE.COPY_SUCCESSFUL'), {
        status: wootConstants.SNACKBAR_TYPE.SUCCESS,
      });
    },
  },
};
</script>

<style scoped>
.container {
  min-width: 50px;
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
  overflow: hidden;
}

.content {
  background-color: #f1f5f9;
  overflow: auto;
  font-size: 14px;
  max-height: 60rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.copy-button {
  align-self: flex-end;
  margin-right: -32px;
  margin-top: -32px;
  position: fixed;
}
</style>
<style>
/* We make the tooltip a layer further than the modal so it can be seen */
.tooltip {
  z-index: 9991;
}
</style>
