<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('CANNED_MGMT.ADD.TITLE')"
        :header-content="$t('CANNED_MGMT.ADD.DESC')"
      />
      <form class="row" @submit.prevent="addCannedResponse()">
        <div class="medium-12 columns">
          <label :class="{ error: $v.shortCode.$error }">
            {{ $t('CANNED_MGMT.ADD.FORM.SHORT_CODE.LABEL') }}
            <input
              v-model.trim="shortCode"
              type="text"
              :placeholder="$t('CANNED_MGMT.ADD.FORM.SHORT_CODE.PLACEHOLDER')"
              :data-test-id="'short_code_input'"
              @input="$v.shortCode.$touch"
            />
          </label>
        </div>

        <div class="medium-12 columns">
          <label :class="{ error: $v.content.$error }">
            {{ $t('CANNED_MGMT.ADD.FORM.CONTENT.LABEL') }}
          </label>
          <div class="editor-wrap">
            <woot-message-editor
              v-model="content"
              class="message-editor"
              :class="{ editor_warning: $v.content.$error }"
              :enable-variables="true"
              :enable-canned-responses="false"
              :placeholder="$t('CANNED_MGMT.ADD.FORM.CONTENT.PLACEHOLDER')"
              :data-test-id="'content_input'"
              @blur="$v.content.$touch"
            />
          </div>
        </div>

        <div class="medium-12 columns">
          <label :class="{ error: $v.content.$error }" for="scope">{{
            $t('CANNED_MGMT.ADD.FORM.LEVEL.LABEL')
          }}</label>
          <select id="scope" v-model.trim="ownerType">
            <option v-if="!isAgent" value="Account">
              {{ $t('CANNED_MGMT.ADD.FORM.LEVEL.OPTIONS.ACCOUNT') }}
            </option>
            <option value="User">
              {{ $t('CANNED_MGMT.ADD.FORM.LEVEL.OPTIONS.USER') }}
            </option>
            <option v-if="!isAgent" value="Team">
              {{ $t('CANNED_MGMT.ADD.FORM.LEVEL.OPTIONS.TEAM') }}
            </option>
          </select>
        </div>

        <div v-if="isTeamOwner" class="medium-12 columns">
          <label for="team">{{ $t('CANNED_MGMT.ADD.FORM.TEAM.LABEL') }}</label>
          <select id="team" v-model.trim="ownerId">
            <option
              v-for="(team, index) in teamsList"
              :key="index"
              :value="team.id"
            >
              {{ team.name }}
            </option>
          </select>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="
                $v.content.$invalid ||
                $v.shortCode.$invalid ||
                addCanned.showLoading
              "
              :data-test-id="'create_canned_response'"
              :button-text="$t('CANNED_MGMT.ADD.FORM.SUBMIT')"
              :loading="addCanned.showLoading"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('CANNED_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';

import alertMixin from 'shared/mixins/alertMixin';
import wootConstants from '../../../../constants';
import { mapGetters } from 'vuex';
import WootMessageEditor from '../../../../components/widgets/WootWriter/Editor.vue';
import WootUIKit from 'components';

export default {
  components: {
    WootModalHeader: WootUIKit.ModalHeader,
    WootMessageEditor,
    WootSubmitButton: WootUIKit.SubmitButton,
    Modal: WootUIKit.Modal,
  },
  mixins: [alertMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      shortCode: '',
      content: '',
      ownerType: 'User',
      ownerId: null,

      vertical: 'bottom',
      horizontal: 'center',
      addCanned: {
        showLoading: false,
        message: '',
      },
      show: true,
    };
  },
  validations: {
    shortCode: {
      required,
      minLength: minLength(2),
    },
    content: {
      required,
    },
    ownerType: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      teamsList: 'teams/getTeams',
      currentUser: 'getCurrentUser',
      currentRoles: 'getCurrentRoles',
    }),
    isTeamOwner() {
      return this.ownerType === 'Team';
    },
    // TODO: 42861 move to mixin
    isAgent() {
      return (
        this.currentRoles.includes('agent') &&
        !this.currentRoles.includes('administrator') &&
        !this.currentRoles.includes('supervisor')
      );
    },
  },
  created() {
    // Set by default the first team as owner because
    // the API will ignore the owner_id param if it is personal
    // or account level
    this.ownerId = this.teamsList?.[0]?.id || null;
  },
  methods: {
    resetForm() {
      this.shortCode = '';
      this.content = '';
      this.$v.shortCode.$reset();
      this.$v.content.$reset();
    },
    addCannedResponse() {
      // Show loading on button
      this.addCanned.showLoading = true;
      // Make API Calls
      this.$store
        .dispatch('createCannedResponse', {
          short_code: this.shortCode,
          content: this.content,
          owner_type: this.ownerType,
          owner_id: this.ownerId,
        })
        .then(() => {
          // Reset Form, Show success message
          this.addCanned.showLoading = false;
          this.showAlert(this.$t('CANNED_MGMT.ADD.API.SUCCESS_MESSAGE'), {
            status: wootConstants.SNACKBAR_TYPE.SUCCESS,
          });
          this.resetForm();
          this.onClose();
        })
        .catch(error => {
          this.addCanned.showLoading = false;
          const errorMessage =
            error.message === 'Short code has already been taken'
              ? this.$t('CANNED_MGMT.ADD.API.ERROR_MESSAGE_SHORT_CODE_TAKEN')
              : this.$t('CANNED_MGMT.ADD.API.ERROR_MESSAGE');

          this.showAlert(errorMessage, {
            status: wootConstants.SNACKBAR_TYPE.ERROR,
          });
        });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .ProseMirror-menubar {
    display: none;
  }
}
</style>
