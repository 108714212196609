// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import types from '../../mutation-types';
import LabelsAPI from '../../../api/labels';
import { State } from './labels.types';
import { ActionContext } from 'vuex';
import { Label } from '../../types/label.types';
import axios from 'axios';
import { UiFlags } from '../../types/ui.types';

export const state: State = {
  records: [],
  uiFlags: {
    isFetching: false,
    isFetchingItem: false,
    isCreating: false,
    isDeleting: false,
  },
};

export const getters = {
  getLabels(_state: State) {
    return _state.records;
  },
  getUIFlags(_state: State) {
    return _state.uiFlags;
  },
  getLabelsOnSidebar(_state: State) {
    return _state.records
      .filter(record => record.show_on_sidebar)
      .sort((a, b) => a.title.localeCompare(b.title));
  },
};

export const actions = {
  get: async function getLabels({
    commit,
  }: Pick<ActionContext<State, State>, 'commit'>) {
    commit(types.SET_LABEL_UI_FLAG, { isFetching: true });
    try {
      const response = await LabelsAPI.get();
      commit(types.SET_LABELS, response.data.payload);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      commit(types.SET_LABEL_UI_FLAG, { isFetching: false });
    }
  },

  create: async function createLabels(
    { commit }: Pick<ActionContext<State, State>, 'commit'>,
    cannedObj: Label
  ) {
    commit(types.SET_LABEL_UI_FLAG, { isCreating: true });
    try {
      const response = await LabelsAPI.create(cannedObj);
      commit(types.ADD_LABEL, response.data);
    } catch (error) {
      let errorMessage: string;

      if (axios.isAxiosError(error)) {
        errorMessage = error?.response?.data?.message;
      } else {
        errorMessage = error as string;
      }

      throw new Error(errorMessage);
    } finally {
      commit(types.SET_LABEL_UI_FLAG, { isCreating: false });
    }
  },

  update: async function updateLabels(
    { commit }: Pick<ActionContext<State, State>, 'commit'>,
    { id, ...updateObj }: { id: number }
  ) {
    commit(types.SET_LABEL_UI_FLAG, { isUpdating: true });
    try {
      const response = await LabelsAPI.update(id, updateObj);
      commit(types.EDIT_LABEL, response.data);
    } catch (error) {
      let errorMessage: string;

      if (axios.isAxiosError(error)) {
        errorMessage = error?.response?.data?.message;
      } else {
        errorMessage = error as string;
      }

      throw new Error(errorMessage);
    } finally {
      commit(types.SET_LABEL_UI_FLAG, { isUpdating: false });
    }
  },

  delete: async function deleteLabels(
    { commit }: Pick<ActionContext<State, State>, 'commit'>,
    id: number | string
  ) {
    commit(types.SET_LABEL_UI_FLAG, { isDeleting: true });
    try {
      await LabelsAPI.delete(id);
      commit(types.DELETE_LABEL, id);
    } catch (error) {
      throw new Error(error as string);
    } finally {
      commit(types.SET_LABEL_UI_FLAG, { isDeleting: false });
    }
  },
};

export const mutations = {
  [types.SET_LABEL_UI_FLAG](_state: State, data: UiFlags) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.SET_LABELS]: MutationHelpers.set,
  [types.ADD_LABEL]: MutationHelpers.create,
  [types.EDIT_LABEL]: MutationHelpers.update,
  [types.DELETE_LABEL]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
