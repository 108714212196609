import { marked } from 'marked';
import DOMPurify from 'dompurify';
import { afterSanitizeAttributes } from './HTMLSanitizer';

const USER_MENTIONS_REGEX = /mention:\/\/(user|team)\/(\d+)\/(.+)/gm;

class MessageFormatter {
  constructor(message) {
    this.message = message;
    this.marked = marked;

    const renderer = {
      heading(text) {
        return `<strong>${text}</strong>`;
      },
      link(url, title, text) {
        const mentionRegex = new RegExp(USER_MENTIONS_REGEX);
        if (url.match(mentionRegex)) {
          return `<span class="prosemirror-mention-node">${text}</span>`;
        }
        return `<a rel="noreferrer noopener nofollow" href="${url}" class="link" title="${
          title || ''
        }" target="_blank">${text}</a>`;
      },
    };
    this.marked.use({ renderer });
  }

  formatMessage() {
    try {
      DOMPurify.addHook('afterSanitizeAttributes', afterSanitizeAttributes);
      return DOMPurify.sanitize(
        marked.parseInline(this.message, { breaks: true, gfm: true }),
        {
          ADD_TAGS: ['iframe'],
          ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
          FORBID_TAGS: ['input'],
        }
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return this.message;
    }
  }

  get formattedMessage() {
    return this.formatMessage();
  }

  get plainText() {
    const strippedOutHtml = new DOMParser().parseFromString(
      this.formattedMessage,
      'text/html'
    );
    return strippedOutHtml.body.textContent || '';
  }
}

export default MessageFormatter;
