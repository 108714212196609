export const removeCognigyGalleryEventListeners = elementId => {
  const elements = [
    ...document.querySelectorAll(
      `#${elementId} .slider-wrapper.axis-horizontal > .slider > .slide`
    ),
  ];
  // We clone the element to remove al cognigy event listeners and use the ones added in live agent
  if (elements.length > 0) {
    elements.forEach(item => {
      item.replaceWith(item.cloneNode(true));
    });
  }
};
