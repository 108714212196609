<template>
  <div class="message-text--metadata">
    <fluent-icon
      v-if="isOnBehalfOfUser"
      icon="bot"
      type="solid"
      class="bot--icon"
      size="16"
      :data-test-id="`on_behalf_of_user_${id}`"
    />
    <span class="time" :class="{ delivered: messageRead }">{{
      readableTime
    }}</span>
    <fluent-icon
      v-if="messageRead"
      v-tooltip.top-start="$t('CHAT_LIST.MESSAGE_READ')"
      icon="checkmark-double"
      class="action--icon read-tick"
      size="12"
    />
    <fluent-icon
      v-if="isPrivate"
      v-tooltip.top-start="$t('CONVERSATION.VISIBLE_TO_AGENTS')"
      icon="lock-closed"
      class="action--icon lock--icon--private"
      size="16"
      @mouseenter="isHovered = true"
      @mouseleave="isHovered = false"
    />
  </div>
</template>

<script>
import { MESSAGE_TYPE } from 'shared/constants/messages';
import inboxMixin from 'shared/mixins/inboxMixin';

export default {
  name: 'BubbleActions',
  mixins: [inboxMixin],
  props: {
    sender: {
      type: Object,
      default: () => ({}),
    },
    readableTime: {
      type: String,
      default: '',
    },
    storySender: {
      type: String,
      default: '',
    },
    storyId: {
      type: String,
      default: '',
    },
    isPrivate: {
      type: Boolean,
      default: true,
    },
    messageType: {
      type: Number,
      default: 1,
    },
    sourceId: {
      type: String,
      default: '',
    },
    id: {
      type: [String, Number],
      default: '',
    },
    inboxId: {
      type: [String, Number],
      default: 0,
    },
    messageRead: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.inboxId);
    },
    isIncoming() {
      return MESSAGE_TYPE.INCOMING === this.messageType;
    },
    isOutgoing() {
      return MESSAGE_TYPE.OUTGOING === this.messageType;
    },
    isOnBehalfOfUser() {
      return this.messageType === MESSAGE_TYPE.ON_BEHALF_OF_USER;
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'dashboard/assets/scss/woot';

.right {
  .message-text--metadata {
    .action--icon {
      color: var(--white);
    }

    .lock--icon--private {
      color: var(--s-400);
    }

    .action--icon {
      &.read-tick {
        color: var(--v-100);
        margin-top: calc(var(--space-micro) + var(--space-micro) / 2);
      }
      color: var(--white);
    }

    .lock--icon--private {
      color: var(--s-400);
    }
  }
}

.left {
  .message-text--metadata {
    display: flex;
    justify-content: flex-start;
    margin-left: 5px;
    margin-top: 8px;
    // .time {
    //   color: var(--s-400);
    // }
  }
}

.message-text--metadata {
  align-items: flex-start;
  display: flex;
  justify-content: flex-end;
  margin-right: 18px;
  margin-top: 8px;

  .time {
    margin-right: var(--space-small);
    display: block;
    font-size: var(--font-size-micro);
    line-height: 1.8;
  }

  .action--icon {
    margin-right: var(--space-small);
    margin-left: var(--space-small);
    color: var(--s-900);
  }

  .bot--icon {
    margin-right: var(--space-small);
    color: var(--s-600);
  }

  a {
    color: var(--s-900);
  }
}

.activity-wrap {
  .message-text--metadata {
    .time {
      color: var(--s-300);
      display: flex;
      text-align: center;
      font-size: var(--font-size-micro);
      margin-left: 0;

      @include breakpoint(xlarge up) {
        margin-left: var(--space-small);
      }
    }
  }
}

.is-image,
.is-video {
  .message-text--metadata {
    .time {
      bottom: var(--space-smaller);
      color: var(--white);
      position: absolute;
      right: var(--space-small);
      white-space: nowrap;
      &.delivered {
        right: var(--space-medium);
        line-height: 2;
      }
    }
    .read-tick {
      position: absolute;
      bottom: var(--space-small);
      right: var(--space-small);
    }
  }
}

.is-private {
  .message-text--metadata {
    align-items: center;

    .time {
      color: var(--s-400);
    }

    .icon {
      color: var(--s-400);
    }
  }

  &.is-image,
  &.is-video {
    .time {
      position: inherit;
      padding-left: var(--space-one);
    }
  }
}

.delivered-icon {
  margin-left: -var(--space-normal);
}
</style>
