import ApiClient, { axiosWithAuth } from '../ApiClient';
import {
  IAssignAgentPayload,
  IAssignTeamPayload,
  IBasePayload,
  IFilterPayload,
  IGetConversationsPayload,
  IMarkMessageRead,
  IMetaPayload,
  ISearchPayload,
  ISendTranscriptPayload,
  IToggleInboxPayload,
  ITogglePriorityPayload,
  IToggleStatusPayload,
  IToggleTypingPayload,
  IUpdateCustomAttributePayload,
} from '../types/inbox.conversation.types';

class ConversationApi extends ApiClient {
  constructor() {
    super('conversations', { accountScoped: true });
  }

  get(params?: IGetConversationsPayload) {
    const {
      inboxId,
      status,
      assigneeType,
      page,
      labels,
      teamId,
      conversationType,
      sortBy,
    } = params || {};

    return axiosWithAuth.get(this.url, {
      params: {
        inbox_id: inboxId,
        team_id: teamId,
        status,
        assignee_type: assigneeType,
        page,
        labels,
        conversation_type: conversationType,
        sort_by: sortBy,
      },
    });
  }

  filter(payload: IFilterPayload) {
    return axiosWithAuth.post(`${this.url}/filter`, payload.queryData, {
      params: {
        page: payload.page,
      },
    });
  }

  search({ q }: ISearchPayload) {
    return axiosWithAuth.get(`${this.url}/search`, {
      params: {
        q,
        page: 1,
      },
    });
  }

  toggleStatus({
    conversationId,
    status,
    snoozedUntil = null,
    sub_status = null,
  }: IToggleStatusPayload) {
    return axiosWithAuth.post(`${this.url}/${conversationId}/toggle_status`, {
      status,
      snoozed_until: snoozedUntil,
      sub_status,
    });
  }

  togglePriority({ conversationId, priority }: ITogglePriorityPayload) {
    return axiosWithAuth.post(`${this.url}/${conversationId}/toggle_priority`, {
      priority,
    });
  }

  toggleInbox({ conversationId, inboxId }: IToggleInboxPayload) {
    return axiosWithAuth.post(`${this.url}/${conversationId}/toggle_inbox`, {
      inbox_id: inboxId,
    });
  }

  assignAgent({ conversationId, agentId }: IAssignAgentPayload) {
    return axiosWithAuth.post(
      `${this.url}/${conversationId}/assignments?assignee_id=${agentId}`,
      {}
    );
  }

  assignTeam({ conversationId, teamId }: IAssignTeamPayload) {
    const params = { team_id: teamId };
    return axiosWithAuth.post(
      `${this.url}/${conversationId}/assignments`,
      params
    );
  }

  markMessageRead({ id }: IMarkMessageRead) {
    return axiosWithAuth.post(`${this.url}/${id}/update_last_seen`);
  }

  toggleTyping({ conversationId, status, isPrivate }: IToggleTypingPayload) {
    return axiosWithAuth.post(
      `${this.url}/${conversationId}/toggle_typing_status`,
      {
        typing_status: status,
        is_private: isPrivate,
      }
    );
  }

  mute(conversationId: number | string) {
    return axiosWithAuth.post(`${this.url}/${conversationId}/mute`);
  }

  unmute(conversationId: number | string) {
    return axiosWithAuth.post(`${this.url}/${conversationId}/unmute`);
  }

  meta({
    inboxId,
    status,
    assigneeType,
    labels,
    teamId,
    conversationType,
  }: IMetaPayload) {
    return axiosWithAuth.get(`${this.url}/meta`, {
      params: {
        inbox_id: inboxId,
        status,
        assignee_type: assigneeType,
        labels,
        team_id: teamId,
        conversation_type: conversationType,
      },
    });
  }

  sendTranscriptToEmail({ conversationId, email }: ISendTranscriptPayload) {
    return axiosWithAuth.post(`${this.url}/${conversationId}/transcript`, {
      email,
    });
  }

  sendTranscriptToAssigneeEmail({ conversationId }: IBasePayload) {
    return axiosWithAuth.post(`${this.url}/${conversationId}/transcript`, {
      recipient: 'assignee',
    });
  }

  updateCustomAttributes({
    conversationId,
    customAttributes,
  }: IUpdateCustomAttributePayload) {
    return axiosWithAuth.post(
      `${this.url}/${conversationId}/custom_attributes`,
      {
        custom_attributes: customAttributes,
      }
    );
  }

  clearChatHistory(conversationId: number | string) {
    return axiosWithAuth.delete(
      `${this.url}/${conversationId}/clear_chat_history`
    );
  }
}

export default new ConversationApi();
