import { ContactStoreState } from './types/contactStore.types';
import { UiFlags } from '../../types/ui.types';
import { Contact } from './types/contact.types';

export const getters = {
  getContacts($state: ContactStoreState): Contact[] {
    return $state.sortOrder.map(
      (contactId: number) => $state.records[contactId]
    );
  },
  getUIFlags($state: ContactStoreState): UiFlags {
    return $state.uiFlags;
  },
  getContact: ($state: ContactStoreState) => (id: number) => {
    const contact = $state.records[id];
    return contact || {};
  },
  getMeta: ($state: ContactStoreState) => {
    return $state.meta;
  },
  getAppliedContactFilters: ($state: ContactStoreState) => {
    return $state.appliedFilters;
  },
};
