<template>
  <bubble-image
    v-if="(type === 'image' || type === 'sticker') && !hasImageError"
    data-test-id="bubble-image"
    class="bubble-content"
    :url="url"
    :readable-time="readableTime"
    @error="onImageLoadError"
  />
  <audio
    v-else-if="type === 'audio'"
    class="bubble-content"
    data-test-id="bubble-audio"
    controls
    crossorigin="anonymous"
  >
    <source :src="attachment.data_url || attachment.url" />
  </audio>
  <bubble-video
    v-else-if="type === 'video'"
    class="bubble-content"
    data-test-id="bubble-video"
    :url="url"
    :readable-time="readableTime"
  />
  <bubble-location
    v-else-if="type === 'location'"
    class="bubble-content"
    data-test-id="bubble-location"
    :readable-time="readableTime"
    :latitude="attachment.latitude"
    :longitude="attachment.longitude"
    :address="attachment.address"
    :name="attachment.name"
  />
  <bubble-contact
    v-else-if="type === 'contact'"
    class="bubble-content"
    :contact-data="attachment"
    data-test-id="bubble-contact"
  />
  <bubble-document
    v-else-if="type === 'document'"
    :file-name="fileName"
    :url="url"
    class="bubble-content"
    :readable-time="readableTime"
    data-test-id="bubble-document"
  />
  <bubble-file
    v-else
    class="bubble-content"
    :file-name="fileName"
    :url="url"
    :readable-time="readableTime"
    :message-type="messageType"
    data-test-id="bubble-file"
  />
</template>

<script>
import BubbleImage from './Image.vue';
import BubbleVideo from './Video.vue';
import BubbleFile from './File.vue';
import BubbleLocation from './Location.vue';
import BubbleContact from './Contact.vue';
import BubbleDocument from './Document.vue';

export default {
  name: 'BubbleHandler',
  components: {
    BubbleImage,
    BubbleVideo,
    BubbleFile,
    BubbleLocation,
    BubbleContact,
    BubbleDocument,
  },
  props: {
    attachment: {
      type: Object,
      required: true,
    },
    readableTime: {
      type: String,
      required: true,
    },
    hasImageError: {
      type: Boolean,
      required: true,
    },
    onImageLoadError: {
      type: Function,
      required: true,
    },
    messageType: {
      type: Number,
      default: null,
    },
  },
  computed: {
    type() {
      return this.attachment.file_type || this.attachment.type;
    },
    url() {
      return this.attachment.data_url || this.attachment.url;
    },
    fileName() {
      return this.attachment.name;
    },
  },
};
</script>
<style lang="scss">
.bubble-content {
  margin-top: 2rem;
}
</style>
