import ApiClient, { axiosWithAuth } from './ApiClient';

export class SkillAPI extends ApiClient {
  constructor() {
    super('skills', { accountScoped: true });
  }

  getSkills() {
    return axiosWithAuth.get(`${this.url}/skills`);
  }

  createSkill(skillData) {
    return axiosWithAuth.post(`${this.url}/skills`, skillData);
  }

  updateSkill(skillId, skillData) {
    return axiosWithAuth.put(`${this.url}/skills/${skillId}`, skillData);
  }

  deleteSkill(skillId) {
    return axiosWithAuth.delete(`${this.url}/skills/${skillId}`);
  }
}

export default new SkillAPI();
