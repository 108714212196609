/* eslint no-param-reassign: 0 */

import getUuid from 'widget/helpers/uuid';
import { MESSAGE_STATUS, MESSAGE_TYPE } from 'shared/constants/messages';

export default () => {
  if (!Array.prototype.last) {
    Object.assign(Array.prototype, {
      last() {
        return this[this.length - 1];
      },
    });
  }
};

export const isEmptyObject = obj =>
  Object.keys(obj).length === 0 && obj.constructor === Object;

export const isObjectWithEmptyKeys = obj =>
  Object.keys(obj).every(key => obj[key] === '');

export const isJSONValid = value => {
  try {
    JSON.parse(value);
  } catch (e) {
    return false;
  }
  return true;
};

export const getTypingUsersText = (users = []) => {
  const count = users.length;
  if (count === 1) {
    const [user] = users;
    return `${user.name} is typing`;
  }

  if (count === 2) {
    const [first, second] = users;
    return `${first.name} and ${second.name} are typing`;
  }

  const [user] = users;
  const rest = users.length - 1;
  return `${user.name} and ${rest} others are typing`;
};

const getDefaultMessageProps = data => {
  const timestamp = Math.floor(new Date().getTime() / 1000);
  const tempMessageId = getUuid();
  return {
    id: tempMessageId,
    echo_id: tempMessageId,
    status: MESSAGE_STATUS.PROGRESS,
    created_at: timestamp,
    message_type: MESSAGE_TYPE.OUTGOING,
    conversation_id: data.conversationId,
  };
};

export const createPendingMessage = data => {
  const { message, files } = data;
  const defaultProps = getDefaultMessageProps(data);
  const tempAttachments = [{ id: defaultProps.id }];
  return {
    ...defaultProps,
    ...data,
    content: message || null,
    attachments: files ? tempAttachments : null,
  };
};

export const createFileRequestPendingMessage = data => {
  return {
    ...getDefaultMessageProps(data),
    ...data,
    message_type: MESSAGE_TYPE.OUTGOING,
    content_type: 'request_file_upload',
    contentAttributes: {
      items: [
        {
          host: window.chatwootConfig.externalHostURL,
          inboxIdentifier: data.inboxIdentifier,
        },
      ],
    },
  };
};

export const createFileRequestActivityPendingMessage = data => {
  return {
    ...getDefaultMessageProps(data),
    ...data,
    message_type: MESSAGE_TYPE.ACTIVITY,
    content_type: 'request_file_upload',
    contentAttributes: {},
  };
};

export const createPendingAICopilotMessage = data => {
  return {
    ...data.message,
    message_type: MESSAGE_TYPE.OUTGOING,
    content_type: 'cognigy',
    contentAttributes: data.message.content_attributes,
    conversationId: data.conversationId,
    private: data.private,
    ...getDefaultMessageProps(data),
  };
};

export const createPendingAICopilotTextMessage = data => {
  return {
    ...data.message,
    message: data.message.content,
    message_type: MESSAGE_TYPE.OUTGOING,
    content_type: 'text',
    contentAttributes: data.message.content_attributes,
    conversationId: data.conversationId,
    private: data.private,
    ...getDefaultMessageProps(data),
  };
};

export const convertToSlug = text => {
  return text
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '_');
};
