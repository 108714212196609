import ApiClient from './ApiClient';

class AttributeAPI extends ApiClient {
  constructor() {
    super('custom_attribute_definitions', { accountScoped: true });
  }

  // eslint-disable-next-line class-methods-use-this
  getAttributesByModel() {
    // return axiosWithAuth.get(this.url);
    // TODO 55617 remove this API call
    return {
      data: [],
    };
  }
}

export default new AttributeAPI();
