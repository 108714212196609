<template>
  <woot-modal :show="show" :on-close="closeModal">
    <woot-modal-header
      :header-title="title"
      :header-content="message"
      :warning-message="warningMessage"
    />
    <form @submit.prevent="onConfirm">
      <woot-input
        v-model="value"
        type="text"
        :class="{ error: $v.value.$error }"
        :placeholder="confirmPlaceHolderText"
        :data-test-id="'delete_input'"
        @blur="$v.value.$touch"
      />
      <div class="button-wrapper">
        <woot-button
          :is-disabled="$v.value.$invalid"
          color-scheme="alert"
          data-test-id="confirm_delete_button"
        >
          {{ confirmText }}
        </woot-button>
        <woot-button class="clear" @click.prevent="closeModal">
          {{ rejectText }}
        </woot-button>
      </div>
    </form>
  </woot-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import WootModal from 'components/Modal.vue';
import WootModalHeader from 'components/ModalHeader.vue';
import WootButton from 'components/ui/WootButton.vue';
import WootInput from 'components/widgets/forms/Input.vue';

export default {
  name: 'WootConfirmDeleteModal',
  components: { WootInput, WootButton, WootModalHeader, WootModal },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    warningMessage: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: '',
    },
    rejectText: {
      type: String,
      default: '',
    },
    confirmValue: {
      type: String,
      default: '',
    },
    confirmPlaceHolderText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      value: '',
    };
  },
  validations: {
    value: {
      required,
      isEqual(value) {
        return value === this.confirmValue;
      },
    },
  },
  methods: {
    closeModal() {
      this.value = '';
      this.$emit('on-close');
    },
    onConfirm() {
      this.$emit('on-confirm');
    },
  },
};
</script>
