<template>
  <div class="medium-12 column login">
    <div class="text-center medium-12 login__hero align-self-top">
      <img
        :src="globalConfig.logo"
        :alt="globalConfig.installationName"
        class="hero__logo"
      />
      <h2 class="hero__title">
        {{ $t('LOGIN_OAUTH.TITLE') }}
      </h2>
      <h3 class="sign-in-label">
        {{ $t('LOGIN_OAUTH.LABEL') }}
      </h3>
    </div>
    <div class="sign-in-button-container">
      <woot-button
        class="sign-in-button"
        :data-test-id="'sign_in_button'"
        @click="triggerCognigyOAuth"
      >
        {{ $t('LOGIN_OAUTH.SUBMIT') }}
      </woot-button>
    </div>
    <form
      id="cognigy-oauth-form"
      method="post"
      :action="`${window.chatwootConfig.hostURL}/omniauth/cognigy${
        organisationId ? `?organisationId=${organisationId}` : ''
      }`"
    >
      <input
        id="csrf-hidden"
        type="hidden"
        name="authenticity_token"
        value=""
        referrerpolicy="strict-origin"
      />
    </form>
  </div>
</template>
<script>
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import Cookies from 'js-cookie';
import { mapGetters } from 'vuex';
import WootUIKit from 'components';

export default {
  components: {
    WootButton: WootUIKit.Button,
  },
  mixins: [globalConfigMixin],
  data() {
    return {
      window,
    };
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    }),
    organisationId() {
      const urlSearchParams = new URLSearchParams(window.location.search);
      const params = Object.fromEntries(urlSearchParams.entries());

      return params.organisationId;
    },
  },
  methods: {
    triggerCognigyOAuth() {
      const tokenElement = document.getElementsByName('csrf-token')[0];

      let token = '';

      if (tokenElement) {
        token = tokenElement.content;
      }

      this.saveOrganisationId();

      document.getElementById('csrf-hidden').value = token;
      document.getElementById('cognigy-oauth-form').submit();
    },
    saveOrganisationId() {
      if (this.organisationId) {
        Cookies.set('organisationId', this.organisationId, {
          expires: 10,
        });
      }
    },
  },
};
</script>
<style scoped>
.sign-in-button-container {
  display: flex;
  justify-content: center;
}
.sign-in-button {
  width: 300px;
  text-align: center;
  display: flex;
  justify-content: center;
}
.sign-in-label {
  margin-top: 150px;
}
</style>
