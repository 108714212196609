/* eslint no-console: 0 */
/* eslint no-param-reassign: 0 */
/* eslint no-shadow: 0 */
import * as types from '../mutation-types';
import Report from '../../api/reports';
import Vue from 'vue';

import { downloadCsvFile } from '../../helper/downloadHelper';

const state = {
  fetchingStatus: false,
  reportData: [],
  accountReport: {
    isFetching: false,
    data: [],
  },
  accountSummary: {
    avg_first_response_time: 0,
    avg_resolution_time: 0,
    conversations_count: 0,
    incoming_messages_count: 0,
    outgoing_messages_count: 0,
    resolutions_count: 0,
    previous: {},
  },
  overview: {
    uiFlags: {
      isFetchingAccountConversationMetric: false,
      isFetchingAccountConversationTimeseriesMetric: false,
      isFetchingAgentConversationMetric: false,
    },
    accountConversationMetric: {},
    accountConversationTimeseriesMetric: {
      group_timestamps: [],
      incoming_conversation_counts: [],
      conversation_duration_averages: [],
      queued_conversation_counts: [],
    },
    agentConversationMetric: [],
    agentConversationMetricCount: 0,
  },
};

const getters = {
  getAccountReports(_state) {
    return _state.accountReport;
  },
  getAccountSummary(_state) {
    return _state.accountSummary;
  },
  getAccountConversationMetric(_state) {
    return _state.overview.accountConversationMetric;
  },
  getAccountConversationTimeseriesMetric(_state) {
    return _state.overview.accountConversationTimeseriesMetric;
  },
  getAgentConversationMetric(_state) {
    return _state.overview.agentConversationMetric;
  },
  getAgentConversationMetricTotal(_state) {
    return _state.overview.agentConversationMetricCount;
  },
  getOverviewUIFlags($state) {
    return $state.overview.uiFlags;
  },
};

export const actions = {
  fetchAccountReport({ commit }, reportObj) {
    commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, true);
    Report.getReports({
      metric: reportObj.metric,
      since: reportObj.from,
      until: reportObj.to,
      type: reportObj.type,
      id: reportObj.id,
      group_by: reportObj.groupBy,
      business_hours: reportObj.businessHours,
    }).then(accountReport => {
      let { data } = accountReport;
      commit(types.default.SET_ACCOUNT_REPORTS, data);
      commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, false);
    });
  },
  fetchAccountSummary({ commit }, reportObj) {
    Report.getSummary({
      since: reportObj.from,
      until: reportObj.to,
      type: reportObj.type,
      id: reportObj.id,
      group_by: reportObj.groupBy,
      business_hours: reportObj.businessHours,
    })
      .then(accountSummary => {
        commit(types.default.SET_ACCOUNT_SUMMARY, accountSummary.data);
      })
      .catch(() => {
        commit(types.default.TOGGLE_ACCOUNT_REPORT_LOADING, false);
      });
  },
  fetchAccountConversationTimeseries({ commit }, reportObj) {
    commit(
      types.default.TOGGLE_ACCOUNT_CONVERSATION_TIMESERIES_METRIC_LOADING,
      true
    );
    return Report.getConversationMetric({
      ...reportObj,
      type: 'account_timeseries',
    })
      .then(metrics => {
        commit(
          types.default.SET_ACCOUNT_CONVERSATION_TIMESERIES_METRIC,
          metrics.data
        );
        commit(
          types.default.TOGGLE_ACCOUNT_CONVERSATION_TIMESERIES_METRIC_LOADING,
          false
        );
        return metrics.data;
      })
      .catch(() => {
        commit(
          types.default.TOGGLE_ACCOUNT_CONVERSATION_TIMESERIES_METRIC_LOADING,
          false
        );
      });
  },
  fetchAccountConversationMetric({ commit }, reportObj) {
    commit(types.default.TOGGLE_ACCOUNT_CONVERSATION_METRIC_LOADING, true);
    Report.getConversationMetric(reportObj)
      .then(accountConversationMetric => {
        commit(
          types.default.SET_ACCOUNT_CONVERSATION_METRIC,
          accountConversationMetric.data
        );
        commit(types.default.TOGGLE_ACCOUNT_CONVERSATION_METRIC_LOADING, false);
      })
      .catch(() => {
        commit(types.default.TOGGLE_ACCOUNT_CONVERSATION_METRIC_LOADING, false);
      });
  },
  fetchAgentConversationMetric({ commit }, reportObj) {
    commit(types.default.TOGGLE_AGENT_CONVERSATION_METRIC_LOADING, true);
    Report.getConversationMetric(reportObj)
      .then(agentConversationMetric => {
        commit(
          types.default.SET_AGENT_CONVERSATION_METRIC,
          agentConversationMetric.data
        );
        commit(types.default.TOGGLE_AGENT_CONVERSATION_METRIC_LOADING, false);
      })
      .catch(() => {
        commit(types.default.TOGGLE_AGENT_CONVERSATION_METRIC_LOADING, false);
      });
  },
  updateSingleReportAgentStatus({ commit }, data) {
    commit(types.default.UPDATE_SINGLE_REPORT_AGENT_STATUS, data);
  },
  updateAllReportAgentStatuses({ commit }, data) {
    commit(types.default.UPDATE_ALL_REPORT_AGENTS_STATUSES, data);
  },
  downloadAgentReports(_, reportObj) {
    return Report.getAgentReports(reportObj)
      .then(response => {
        downloadCsvFile(reportObj.fileName, response.data);
      })
      .catch(error => {
        console.error(error);
      });
  },
  downloadLabelReports(_, reportObj) {
    return Report.getLabelReports(reportObj)
      .then(response => {
        downloadCsvFile(reportObj.fileName, response.data);
      })
      .catch(error => {
        console.error(error);
      });
  },
  downloadInboxReports(_, reportObj) {
    return Report.getInboxReports(reportObj)
      .then(response => {
        downloadCsvFile(reportObj.fileName, response.data);
      })
      .catch(error => {
        console.error(error);
      });
  },
  downloadTeamReports(_, reportObj) {
    return Report.getTeamReports(reportObj)
      .then(response => {
        downloadCsvFile(reportObj.fileName, response.data);
      })
      .catch(error => {
        console.error(error);
      });
  },
};

const mutations = {
  [types.default.SET_ACCOUNT_REPORTS](_state, accountReport) {
    _state.accountReport.data = accountReport;
  },
  [types.default.TOGGLE_ACCOUNT_REPORT_LOADING](_state, flag) {
    _state.accountReport.isFetching = flag;
  },
  [types.default.SET_ACCOUNT_SUMMARY](_state, summaryData) {
    _state.accountSummary = summaryData;
  },
  [types.default.SET_ACCOUNT_CONVERSATION_METRIC](_state, metricData) {
    _state.overview.accountConversationMetric = metricData;
  },
  [types.default.SET_ACCOUNT_CONVERSATION_TIMESERIES_METRIC](
    _state,
    metricData
  ) {
    _state.overview.accountConversationTimeseriesMetric = metricData;
  },
  [types.default.TOGGLE_ACCOUNT_CONVERSATION_TIMESERIES_METRIC_LOADING](
    _state,
    flag
  ) {
    _state.overview.uiFlags.isFetchingAccountConversationTimeseriesMetric =
      flag;
  },
  [types.default.TOGGLE_ACCOUNT_CONVERSATION_METRIC_LOADING](_state, flag) {
    _state.overview.uiFlags.isFetchingAccountConversationMetric = flag;
  },
  [types.default.SET_AGENT_CONVERSATION_METRIC](_state, { agents, count }) {
    _state.overview.agentConversationMetric = agents;
    _state.overview.agentConversationMetricCount = count;
  },
  [types.default.TOGGLE_AGENT_CONVERSATION_METRIC_LOADING](_state, flag) {
    _state.overview.uiFlags.isFetchingAgentConversationMetric = flag;
  },
  [types.default.UPDATE_SINGLE_REPORT_AGENT_STATUS](_state, data) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < _state.overview.agentConversationMetric.length; i++) {
      const currentAgentMetric = _state.overview.agentConversationMetric[i];
      if (currentAgentMetric.id === data.id) {
        Vue.set(
          _state.overview.agentConversationMetric[i],
          'availability',
          data.status
        );
        break;
      }
    }
  },
  [types.default.UPDATE_ALL_REPORT_AGENTS_STATUSES](_state, data) {
    _state.overview.agentConversationMetric.forEach((element, index) => {
      const availabilityStatus = data[element.id];
      Vue.set(
        _state.overview.agentConversationMetric[index],
        'availability',
        availabilityStatus || 'offline'
      );
    });
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
