<template>
  <div class="logo">
    <img :alt="name" :src="cognigyLogo" />
  </div>
</template>
<script>
import cognigyLogo from '@/dashboard/assets/images/cognigy_logo.png';
import { frontendURL } from 'dashboard/helper/URLHelper';
import { mapGetters } from 'vuex';

export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    accountId: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    cognigyLogo,
  }),
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
    }),
    dashboardPath() {
      return frontendURL(`accounts/${this.accountId}/dashboard`);
    },
  },
};
</script>
<style lang="scss" scoped>
.logo {
  padding: var(--space-normal);

  img {
    width: 9px;
    height: 10px;
    object-fit: fill;
    object-position: center center;
    max-width: none;
    margin-right: 1px;
    margin-top: 1px;
  }
}
</style>
