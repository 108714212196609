import { DraftContent, DraftMessages, ReplyType } from './draftStore.types';
import { DRAFT_EXPIRATION_TIME, STORAGE_COLLECTION_KEY } from './constants';

export class DraftManager {
  /**
   * build the message key for draft messages collection
   * @param conversationId
   * @param replyType
   * @returns string
   */
  static buildKey(conversationId: string, replyType: ReplyType): string {
    return `draft-${conversationId}-${replyType}`;
  }

  /**
   * build the content for draft messages collection.
   * It contains the content and the timestamp of the creation
   * @param content
   * @returns DraftContent
   */
  static buildContent(content: string): DraftContent {
    return {
      content: content,
      timestamp: new Date().getTime(),
    };
  }

  /**
   * get the draft messages collection from local storage
   */
  static getDrafts(): DraftMessages {
    const draftsJson: string | null = localStorage.getItem(
      STORAGE_COLLECTION_KEY
    );
    return draftsJson ? JSON.parse(draftsJson) : {};
  }

  /**
   * update the draft messages collection in local storage
   * @param drafts
   */
  static saveDrafts(drafts: DraftMessages): void {
    localStorage.setItem(STORAGE_COLLECTION_KEY, JSON.stringify(drafts));
  }

  /**
   * save draft message to draft messages collection
   * @param conversationId
   * @param replyType
   * @param content
   */
  static saveDraft(
    conversationId: string,
    replyType: ReplyType,
    content: string
  ): void {
    const drafts: DraftMessages = this.getDrafts();
    const key: string = this.buildKey(conversationId, replyType);
    this.saveDrafts({
      ...drafts,
      [key]: this.buildContent(content),
    });
  }

  /**
   * get draft message from local storage
   * @param conversationId
   * @param replyType
   * @returns DraftContent | null
   */
  static getDraft(
    conversationId: string,
    replyType: ReplyType
  ): DraftContent | null {
    const drafts: DraftMessages = this.getDrafts();
    const key: string = this.buildKey(conversationId, replyType);
    return drafts[key] ?? null;
  }

  /**
   * delete draft message from local storage
   * @param conversationId
   * @param replyType
   */
  static deleteDraft(conversationId: string, replyType: ReplyType): void {
    const drafts: DraftMessages = this.getDrafts();
    const key: string = this.buildKey(conversationId, replyType);
    delete drafts[key];
    this.saveDrafts(drafts);
  }

  static deleteDraftsByTTL(): void {
    const drafts: DraftMessages = this.getDrafts();
    const expirationTime: number = new Date().getTime() - DRAFT_EXPIRATION_TIME;
    const filteredDrafts: DraftMessages = Object.entries(
      drafts
    ).reduce<DraftMessages>(
      (acc: DraftMessages, [key, value]: [string, DraftContent]) => {
        if (value.timestamp >= expirationTime) {
          acc[key] = value;
        }

        return acc;
      },
      {}
    );
    this.saveDrafts(filteredDrafts);
  }

  /**
   * Delete all drafts
   */
  static deleteAllDrafts(): void {
    localStorage.removeItem(STORAGE_COLLECTION_KEY);
  }
}

export default DraftManager;
