/* eslint arrow-body-style: 0 */
import SettingsContent from '../Wrapper.vue';
import Settings from './Settings.vue';
import InboxHome from './Index.vue';
import InboxCreationFlow from './InboxCreationFlow.vue';
import NotFound from '../../../NotFound.vue';
import { isEmptyObject } from '../../../../helper/commons';

import SettingsInboxesPageChannel from './channels/Api.vue';
import AddAgents from './AddAgents.vue';
import FinishSetup from './FinishSetup.vue';
import { frontendURL } from '../../../../helper/URLHelper';

const isValidResource = store => async (to, from, next) => {
  await store.dispatch('inboxes/get');

  const isResourceEmpty = isEmptyObject(
    store.getters['inboxes/getInbox'](to.params.inboxId)
  );

  if (isResourceEmpty) {
    next({
      name: 'inbox_not_found',
      params: { accountId: to.params.accountId },
    });
  } else {
    next();
  }
};

export default store => ({
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/inboxes'),
      component: SettingsContent,
      props: params => {
        const showBackButton = params.name !== 'settings_inbox_list';
        return {
          headerTitle: 'INBOX_MGMT.HEADER',
          headerButtonText: 'SETTINGS.INBOXES.NEW_INBOX',
          icon: 'mail-inbox-all',
          newButtonRoutes: ['settings_inbox_list'],
          showBackButton,
        };
      },
      children: [
        {
          path: '',
          name: 'settings_inbox',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'settings_inbox_list',
          component: InboxHome,
          roles: ['administrator', 'supervisor'],
        },
        {
          path: 'new',
          component: InboxCreationFlow,
          children: [
            {
              path: ':inbox_id/finish',
              name: 'settings_inbox_finish',
              component: FinishSetup,
              roles: ['administrator', 'supervisor'],
            },
            {
              path: '',
              name: 'settings_inboxes_page_channel',
              component: SettingsInboxesPageChannel,
              roles: ['administrator', 'supervisor'],
            },
            {
              path: ':inbox_id/agents',
              name: 'settings_inboxes_add_agents',
              roles: ['administrator', 'supervisor'],
              component: AddAgents,
            },
          ],
        },
        {
          path: 'not_found',
          name: 'inbox_not_found',
          roles: ['administrator'],
          component: NotFound,
        },
        {
          path: ':inboxId',
          name: 'settings_inbox_show',
          component: Settings,
          roles: ['administrator'],
          beforeEnter: isValidResource(store),
        },
      ],
    },
  ],
});
