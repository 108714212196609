import MessageFormatter from '../helpers/MessageFormatter';
import DOMPurify from 'dompurify';

export default {
  methods: {
    formatMessage(message) {
      const messageFormatter = new MessageFormatter(message);
      return messageFormatter.formattedMessage;
    },
    getPlainText(message) {
      const messageFormatter = new MessageFormatter(message);
      return messageFormatter.plainText;
    },
    stripStyleCharacters(message) {
      return DOMPurify.sanitize(message, {
        FORBID_TAGS: ['style'],
        FORBID_ATTR: [
          'id',
          'class',
          'style',
          'bgcolor',
          'valign',
          'width',
          'face',
          'color',
          'height',
          'lang',
          'align',
          'size',
          'border',
        ],
      });
    },
  },
};
