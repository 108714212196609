const filterOnlineUsersFirst = user => {
  return user.availability_status === 'online' ? -1 : 1;
};

export const filterAgentsForAssignability = (
  initialAgents,
  selectedAgent,
  shouldIncludeUnassigned
) => {
  const unassignedAgent = {
    confirmed: true,
    name: 'Unassigned',
    id: null,
    role: 'agent',
    account_id: 0,
    email: 'Unassigned',
  };
  const allAgents =
    selectedAgent?.id !== unassignedAgent.id && shouldIncludeUnassigned
      ? [unassignedAgent, ...initialAgents]
      : [...initialAgents];
  return allAgents.sort(filterOnlineUsersFirst);
};
