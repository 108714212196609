export const BUS_EVENTS = {
  SET_TWEET_REPLY: 'SET_TWEET_REPLY',
  SHOW_ALERT: 'SHOW_ALERT',
  START_NEW_CONVERSATION: 'START_NEW_CONVERSATION',
  FOCUS_CUSTOM_ATTRIBUTE: 'FOCUS_CUSTOM_ATTRIBUTE',
  SCROLL_TO_MESSAGE: 'SCROLL_TO_MESSAGE',
  TOGGLE_SIDEMENU: 'TOGLE_SIDEMENU',
  OPEN_PANELS: 'OPEN_PANELS',
  CLOSE_PANELS: 'CLOSE_PANELS',
  TOGGLE_CONVERSATION_PANEL: 'TOGGLE_CONVERSATION_PANEL',
  COLLAPSE_AGENT_ASSIST_MESSAGE: 'COLLAPSE_AGENT_ASSIST_MESSAGE',
  COLLAPSE_AGENT_ASSIST_MESSAGE_GROUP: 'COLLAPSE_AGENT_ASSIST_MESSAGE_GROUP',
  WEBSOCKET_DISCONNECT: 'WEBSOCKET_DISCONNECT',
  STOP_FORWARDING: 'STOP_FORWARDING',
  ADD_MESSAGE_TO_COPILOT_BOT_INPUT: 'ADD_MESSAGE_TO_COPILOT_BOT_INPUT',
  ADD_MESSAGE_TO_CHAT_INPUT: 'ADD_MESSAGE_TO_CHAT_INPUT',
  ADD_POSTBACK_TO_CHAT_INPUT: 'ADD_POSTBACK_TO_CHAT_INPUT',
  CLOSE_STATUS_MODAL: 'CLOSE_STATUS_MODAL',
};
