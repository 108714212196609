import ApiClient, { axiosWithAuth } from './ApiClient';

class Agents extends ApiClient {
  constructor() {
    super('attachments', { accountScoped: true });
  }

  get({ page, sortBy, cognigy_session_id, cognigy_user_id, filename }) {
    return axiosWithAuth.get(`${this.url}`, {
      params: {
        page,
        sort_by: sortBy,
        filename_containing: filename,
        cognigy_session_id,
        cognigy_user_id,
      },
    });
  }
}

export default new Agents();
