<template>
  <div class="flex-container flex-dir-column medium-flex-dir-row">
    <div v-if="type === 'agent'" class="small-12 medium-3 pull-right">
      <p aria-hidden="true" class="hide">
        {{ $t('AGENT_REPORTS.FILTER_DROPDOWN_LABEL') }}
      </p>
      <multi-select
        v-model="currentSelectedFilter"
        :data-test-id="`${type}_select`"
        :placeholder="multiselectLabel"
        label="name"
        track-by="id"
        :options="filterItemsList"
        :option-height="24"
        :show-labels="false"
        @input="changeFilterSelection"
      >
        <template slot="singleLabel" slot-scope="props">
          <div class="reports-option__wrap">
            <WootThumbnail
              :src="props.option.thumbnail"
              :username="props.option.name"
              size="22px"
              class="margin-right-small"
            />
            <span class="reports-option__desc">
              <span class="reports-option__title">{{ props.option.name }}</span>
            </span>
          </div>
        </template>
        <template slot="option" slot-scope="props">
          <div class="reports-option__wrap">
            <WootThumbnail
              :src="props.option.thumbnail"
              :username="props.option.name"
              size="22px"
              class="margin-right-small"
            />
            <p>{{ props.option.name }}</p>
          </div>
        </template>
      </multi-select>
    </div>
    <div v-else-if="type === 'label'" class="small-12 medium-3 pull-right">
      <p aria-hidden="true" class="hide">
        {{ $t('LABEL_REPORTS.FILTER_DROPDOWN_LABEL') }}
      </p>
      <multi-select
        v-model="currentSelectedFilter"
        :placeholder="multiselectLabel"
        label="title"
        track-by="id"
        :options="filterItemsList"
        :option-height="24"
        :show-labels="false"
        @input="changeFilterSelection"
      >
        <template slot="singleLabel" slot-scope="props">
          <div class="reports-option__wrap">
            <div
              :style="{ backgroundColor: props.option.color }"
              class="reports-option__rounded--item margin-right-small"
            />
            <span class="reports-option__desc">
              <span class="reports-option__title">
                {{ props.option.title }}
              </span>
            </span>
          </div>
        </template>
        <template slot="option" slot-scope="props">
          <div class="reports-option__wrap">
            <div
              :style="{ backgroundColor: props.option.color }"
              class="reports-option__rounded--item reports-option__item reports-option__label--swatch"
            />
            <span class="reports-option__desc">
              <span class="reports-option__title">
                {{ props.option.title }}
              </span>
            </span>
          </div>
        </template>
      </multi-select>
    </div>
    <div v-else class="small-12 medium-3 pull-right">
      <p aria-hidden="true" class="hide">
        {{ $t('INBOX_REPORTS.FILTER_DROPDOWN_LABEL') }}
      </p>
      <multi-select
        v-model="currentSelectedFilter"
        track-by="id"
        label="name"
        :placeholder="multiselectLabel"
        selected-label
        :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
        deselect-label=""
        :options="filterItemsList"
        :searchable="true"
        :allow-empty="shouldFiltersBeFlexible"
        @input="changeFilterSelection"
      />
    </div>
    <div
      v-if="shouldDateRangeBeShown"
      class="small-12 medium-3 pull-right margin-left-small"
    >
      <multi-select
        v-model="currentDateRangeSelection"
        track-by="name"
        label="name"
        :placeholder="$t('FORMS.MULTISELECT.SELECT_ONE')"
        selected-label
        :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
        deselect-label=""
        :options="dateRange"
        :searchable="false"
        :allow-empty="false"
        @select="changeDateSelection"
      />
    </div>
    <woot-date-range-picker
      v-if="isDateRangeSelected"
      show-range
      :value="customDateRange"
      :confirm-text="$t('REPORT.CUSTOM_DATE_RANGE.CONFIRM')"
      :placeholder="$t('REPORT.CUSTOM_DATE_RANGE.PLACEHOLDER')"
      @change="onChange"
    />
    <div
      v-if="notLast7Days"
      class="small-12 medium-3 pull-right margin-left-small"
    >
      <p aria-hidden="true" class="hide">
        {{ $t('REPORT.GROUP_BY_FILTER_DROPDOWN_LABEL') }}
      </p>
      <multi-select
        v-model="currentSelectedGroupByFilter"
        track-by="id"
        label="groupBy"
        :placeholder="$t('REPORT.GROUP_BY_FILTER_DROPDOWN_LABEL')"
        :options="groupByFilterItemsList"
        :allow-empty="false"
        :show-labels="false"
        @input="changeGroupByFilterSelection"
      />
    </div>
    <!-- <div class="small-12 medium-3 business-hours">
      <span class="business-hours-text margin-right-small">
        {{ $t('REPORT.BUSINESS_HOURS') }}
      </span>
      <span>
        <woot-switch v-model="businessHoursSelected" />
      </span>
    </div> -->
  </div>
</template>
<script>
import { endOfDay, getUnixTime, startOfDay, subDays } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import WootDateRangePicker from 'dashboard/components/ui/DateRangePicker.vue';
import { mapGetters } from 'vuex';

import { frontendURL } from 'dashboard/helper/URLHelper';
import { GROUP_BY_FILTER, DEFAULT_TIMEZONE } from '../constants';
import WootThumbnail from 'components/widgets/Thumbnail.vue';
const CUSTOM_DATE_RANGE_ID = 5;

export default {
  components: {
    WootThumbnail,
    WootDateRangePicker,
  },
  props: {
    shouldFiltersBeFlexible: {
      type: Boolean,
      default: false,
    },
    shouldDateRangeBeShown: {
      type: Boolean,
      default: true,
    },
    filterItemsList: {
      type: Array,
      default: () => [],
    },
    groupByFilterItemsList: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'agent',
    },
    selectedGroupByFilter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentSelectedFilter: null,
      currentDateRangeSelection: this.$t('REPORT.DATE_RANGE')[0],
      dateRange: this.$t('REPORT.DATE_RANGE'),
      customDateRange: [new Date(), new Date()],
      currentSelectedGroupByFilter: null,
      businessHoursSelected: false,
    };
  },
  computed: {
    ...mapGetters({
      accountId: 'getCurrentAccountId',
    }),
    isDateRangeSelected() {
      return this.currentDateRangeSelection.id === CUSTOM_DATE_RANGE_ID;
    },
    to() {
      if (this.isDateRangeSelected) {
        return this.toCustomDate(this.customDateRange[1]);
      }
      return this.toCustomDate(new Date());
    },
    from() {
      if (this.isDateRangeSelected) {
        return this.fromCustomDate(this.customDateRange[0]);
      }
      const dateRange = {
        0: 6,
        1: 29,
        2: 89,
        3: 179,
        4: 364,
      };
      const diff = dateRange[this.currentDateRangeSelection.id];
      const fromDate = subDays(new Date(), diff);
      return this.fromCustomDate(fromDate);
    },
    multiselectLabel() {
      const typeLabels = {
        agent: this.$t('AGENT_REPORTS.FILTER_DROPDOWN_LABEL'),
        label: this.$t('LABEL_REPORTS.FILTER_DROPDOWN_LABEL'),
        inbox: this.$t('INBOX_REPORTS.FILTER_DROPDOWN_LABEL'),
        team: this.$t('TEAM_REPORTS.FILTER_DROPDOWN_LABEL'),
      };
      return typeLabels[this.type] || this.$t('FORMS.MULTISELECT.SELECT_ONE');
    },
    groupBy() {
      if (this.isDateRangeSelected) {
        return GROUP_BY_FILTER[4].period;
      }
      const groupRange = {
        0: GROUP_BY_FILTER[1].period,
        1: GROUP_BY_FILTER[2].period,
        2: GROUP_BY_FILTER[3].period,
        3: GROUP_BY_FILTER[3].period,
        4: GROUP_BY_FILTER[3].period,
      };
      return groupRange[this.currentDateRangeSelection.id];
    },
    notLast7Days() {
      return this.groupBy !== GROUP_BY_FILTER[1].period;
    },
  },
  watch: {
    filterItemsList(val) {
      if (this.shouldFiltersBeFlexible) {
        this.currentSelectedFilter = null;
        this.changeFilterSelection();
      } else if (this.type === 'agent') {
        this.setAgentRoute(val);
      } else {
        this.currentSelectedFilter = val[0];
        this.changeFilterSelection();
      }
    },
    groupByFilterItemsList() {
      this.currentSelectedGroupByFilter = this.selectedGroupByFilter;
    },
    businessHoursSelected() {
      this.$emit('business-hours-toggle', this.businessHoursSelected);
    },
  },
  mounted() {
    this.onDateRangeChange();
  },
  methods: {
    setAgentRoute(val) {
      if (this.$route.params.agentID !== 'null') {
        this.currentSelectedFilter = this.getAgent(this.$route.params.agentID);
        this.changeFilterSelection();
      } else {
        this.$router.push(
          `/app/accounts/${this.accountId}/reports/agent/${val[0].id}`
        );
      }
    },
    onDateRangeChange() {
      this.$emit('date-range-change', {
        from: this.from,
        to: this.to,
        groupBy: this.groupBy,
      });
    },
    fromCustomDate(date) {
      const dayStart = zonedTimeToUtc(startOfDay(date), DEFAULT_TIMEZONE);
      return getUnixTime(dayStart);
    },
    toCustomDate(date) {
      const dayEnd = zonedTimeToUtc(endOfDay(date), DEFAULT_TIMEZONE);
      return getUnixTime(dayEnd);
    },
    changeDateSelection(selectedRange) {
      this.currentDateRangeSelection = selectedRange;
      this.onDateRangeChange();
    },
    changeFilterSelection() {
      this.$emit('filter-change', this.currentSelectedFilter);
      if (this.type === 'agent') {
        const url = `accounts/${this.accountId}/reports/agent/${this.currentSelectedFilter.id}`;
        // eslint-disable-next-line no-unused-vars
        this.$router.push(frontendURL(url)).catch(err => {});
      }
    },
    onChange(value) {
      this.customDateRange = value;
      this.onDateRangeChange();
    },
    getAgent(agentID) {
      return this.filterItemsList.find(item => item.id === Number(agentID));
    },
    changeGroupByFilterSelection() {
      this.$emit('group-by-filter-change', this.currentSelectedGroupByFilter);
    },
  },
};
</script>

<style lang="scss">
@import 'dashboard/assets/scss/widgets/_reports';
</style>
