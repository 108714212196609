import ApiClient from './ApiClient';
import {
  AddAgentsParams,
  GetAgentsParams,
  ITeamClient,
  UpdateAgentsParams,
} from './types/teamsClient.types';

export class TeamsAPI extends ApiClient implements ITeamClient {
  constructor() {
    super('teams', { accountScoped: true });
  }

  getAgents({ teamId }: GetAgentsParams) {
    return this.client.get(`${this.url}/${teamId}/team_members`);
  }

  addAgents({ teamId, agentsList }: AddAgentsParams) {
    return this.client.post(`${this.url}/${teamId}/team_members`, {
      user_ids: agentsList,
    });
  }

  updateAgents({ teamId, agentsList }: UpdateAgentsParams) {
    return this.client.patch(`${this.url}/${teamId}/team_members`, {
      user_ids: agentsList,
    });
  }
}

export default new TeamsAPI();
