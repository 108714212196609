<template>
  <div class="row content-box full-height">
    <woot-wizard class="small-3 columns" :items="items" />
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'EditStepWrap',
  computed: {
    items() {
      return this.$t('TEAMS_SETTINGS.EDIT_FLOW.WIZARD');
    },
  },
};
</script>
