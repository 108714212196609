/* eslint-disable prettier/prettier */
import getCognigyMessageTypePreview from './getCognigyMessageTypePreview';

/**
 * Helper function for getting the message preview of a Cognigy message.
 */
export default message => {
  let preview = null;

  if (
    message?.content_type === 'cognigy' ||
    (message?.content_type === 'cognigy_attachments' &&
      message?.content_attributes.items.length > 0)
  ) {
    const cognigyMessagePreview = getCognigyMessageTypePreview(message);
    preview = `${
      cognigyMessagePreview.charAt(0).toUpperCase() +
      cognigyMessagePreview.slice(1).replace('_', ' ')
    }`;
  }
  const mapToTranslation = {
    Gallery: 'AGENT_ASSIST.PREVIEW_MESSAGE.GALLERY',
    List: 'AGENT_ASSIST.PREVIEW_MESSAGE.LIST',
    Audio: 'AGENT_ASSIST.PREVIEW_MESSAGE.AUDIO',
    Image: 'AGENT_ASSIST.PREVIEW_MESSAGE.IMAGE',
    Video: 'AGENT_ASSIST.PREVIEW_MESSAGE.VIDEO',
    'Adaptive Card': 'AGENT_ASSIST.PREVIEW_MESSAGE.ADAPTIVE_CARD',
    Attachments: 'AGENT_ASSIST.PREVIEW_MESSAGE.ATTACHMENTS',
    Unknown: 'AGENT_ASSIST.PREVIEW_MESSAGE.UNKNOWN',
  };

  return mapToTranslation[preview] ? mapToTranslation[preview] : `${preview}`;
};
