import { TeamStoreState } from './types/teamsStore.types';
import { Team } from './types/team.types';

export const getters = {
  getTeams($state: TeamStoreState) {
    return Object.values($state.records).sort((a, b) => a.id - b.id);
  },
  getMyTeams($state: TeamStoreState) {
    const currentTeams = getters.getTeams($state);
    return currentTeams.filter((team: Team) => {
      const { is_member: isMember } = team;
      return isMember;
    });
  },
  getUIFlags($state: TeamStoreState) {
    return $state.uiFlags;
  },
  getTeam: ($state: TeamStoreState) => (id: number) => {
    const team = $state.records[id];
    return team || {};
  },
};
