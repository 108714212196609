import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      skillTypeOptions: [
        { label: 'SKILLS_MGMT.OPTIONS.SKILL', value: 'skill' },
        { label: 'SKILLS_MGMT.OPTIONS.LANGUAGE', value: 'language' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      records: 'skills/getSkills',
    }),
  },
  methods: {
    getTranslatedSkillType(type) {
      const types = {
        skill: 'SKILLS_MGMT.OPTIONS.SKILL',
        language: 'SKILLS_MGMT.OPTIONS.LANGUAGE',
      };

      return types[type];
    },
  },
};
