export const INBOX_TYPES = {
  API: 'Channel::Api',
};

export default {
  computed: {
    channelType() {
      return this.inbox.channel_type;
    },
    inboxBadge() {
      return this.channelType;
    },
  },
};
