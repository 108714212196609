<template>
  <div class="content">
    <ul class="wrapper">
      <agent-copilot-bot-message
        v-for="message in chatHistory"
        ref="chat"
        :key="message.id"
        :data="message"
      />
    </ul>
  </div>
</template>

<script>
import AgentCopilotBotMessage from './AgentCopilotBotMessage.vue';
export default {
  components: { AgentCopilotBotMessage },
  props: {
    sessionId: {
      type: String,
      default: '',
    },
    chatHistory: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    chatHistory() {
      this.setCount();
      // We wait for the new message to render and then we scroll to it
      this.$nextTick(() => {
        if (this.chatHistory.length > 0) {
          this.scrollToBottom();
        }
      });
    },
  },
  mounted() {
    this.setCount();
  },
  methods: {
    setCount() {
      this.$parent.$emit('setMessagesCount', this.chatHistory.length);
    },
    scrollToBottom() {
      const lastPosition = this.chatHistory.length - 1;
      const el = this.$refs.chat[lastPosition];
      if (el) {
        el.$el.scrollIntoView();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  border-bottom: 1px solid var(--color-border);
  border-left: 1px solid var(--color-border);
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1;

  ul {
    list-style-type: none;
    margin-left: 0;

    li {
      margin-top: 10px;
    }
  }
}
</style>
