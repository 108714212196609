import ApiClient, { axiosWithAuth } from './ApiClient';

class ConversationApi extends ApiClient {
  constructor() {
    super('conversations', { accountScoped: true });
  }

  getLabels(conversationID: string | number) {
    return axiosWithAuth.get(`${this.url}/${conversationID}/labels`);
  }

  updateLabels(conversationID: string | number, labels: string[]) {
    return axiosWithAuth.post(`${this.url}/${conversationID}/labels`, {
      labels,
    });
  }
}

export default new ConversationApi();
