<template>
  <modal :show.sync="show" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header :header-title="pageTitle" />
      <form class="row medium-8" @submit.prevent="editCannedResponse()">
        <div class="medium-12 columns">
          <label :class="{ error: $v.shortCode.$error }">
            {{ $t('CANNED_MGMT.EDIT.FORM.SHORT_CODE.LABEL') }}
            <input
              v-model.trim="shortCode"
              type="text"
              :placeholder="$t('CANNED_MGMT.EDIT.FORM.SHORT_CODE.PLACEHOLDER')"
              :data-test-id="'short_code_input'"
              @input="$v.shortCode.$touch"
            />
          </label>
        </div>

        <div class="medium-12 columns">
          <label :class="{ error: $v.content.$error }">
            {{ $t('CANNED_MGMT.EDIT.FORM.CONTENT.LABEL') }}
          </label>
          <div
            class="editor-wrap"
            :class="{ editor_warning: $v.content.$error }"
          >
            <woot-message-editor
              v-model="content"
              class="message-editor"
              :enable-variables="true"
              :enable-canned-responses="false"
              :placeholder="$t('CANNED_MGMT.EDIT.FORM.CONTENT.PLACEHOLDER')"
              :data-test-id="'content_input'"
              @blur="$v.content.$touch"
            />
          </div>
          <label :class="{ error: $v.content.$error }" for="scope">{{
            $t('CANNED_MGMT.EDIT.FORM.LEVEL.LABEL')
          }}</label>
          <select
            id="scope"
            v-model.trim="ownerType"
            data-test-id="level_select"
          >
            <option v-if="!isAgent" value="Account">
              {{ $t('CANNED_MGMT.EDIT.FORM.LEVEL.OPTIONS.ACCOUNT') }}
            </option>
            <option value="User">
              {{ $t('CANNED_MGMT.EDIT.FORM.LEVEL.OPTIONS.USER') }}
            </option>
            <option v-if="!isAgent" value="Team">
              {{ $t('CANNED_MGMT.EDIT.FORM.LEVEL.OPTIONS.TEAM') }}
            </option>
          </select>
        </div>

        <div v-if="isTeamOwner" class="medium-12 columns">
          <label for="team">Team:</label>
          <select id="team" v-model.trim="ownerId" data-test-id="team_select">
            <option
              v-for="(team, index) in teamsList"
              :key="index"
              :value="team.id"
            >
              {{ team.name }}
            </option>
          </select>
        </div>

        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :disabled="
                $v.content.$invalid ||
                $v.shortCode.$invalid ||
                editCanned.showLoading
              "
              :data-test-id="'submit_edit_canned_response'"
              :button-text="$t('CANNED_MGMT.EDIT.FORM.SUBMIT')"
              :loading="editCanned.showLoading"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('CANNED_MGMT.EDIT.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
/* eslint no-console: 0 */
import { required, minLength } from 'vuelidate/lib/validators';

import WootSubmitButton from '../../../../components/buttons/FormSubmitButton.vue';
import Modal from '../../../../components/Modal.vue';
import wootConstants from '../../../../constants';
import { mapGetters } from 'vuex';
import WootMessageEditor from '../../../../components/widgets/WootWriter/Editor.vue';

export default {
  components: {
    WootMessageEditor,
    WootSubmitButton,
    Modal,
  },
  props: {
    id: { type: Number, default: null },
    edcontent: { type: String, default: '' },
    edshortCode: { type: String, default: '' },
    edownerType: { type: String, default: '' },
    edownerId: { type: Number, default: null },
    onClose: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      editCanned: {
        showAlert: false,
        showLoading: false,
        message: '',
      },
      shortCode: this.edshortCode,
      content: this.edcontent,
      ownerType: this.edownerType,
      ownerId: this.edownerId,
      show: true,
    };
  },
  validations: {
    shortCode: {
      required,
      minLength: minLength(2),
    },
    content: {
      required,
    },
    ownerType: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      teamsList: 'teams/getTeams',
      currentRoles: 'getCurrentRoles',
    }),
    // TODO: 42861 move to mixin
    isAgent() {
      return (
        this.currentRoles.includes('agent') &&
        !this.currentRoles.includes('administrator') &&
        !this.currentRoles.includes('supervisor')
      );
    },
    isTeamOwner() {
      return this.ownerType === 'Team';
    },
    pageTitle() {
      return `${this.$t('CANNED_MGMT.EDIT.TITLE')} - ${this.edshortCode}`;
    },
  },
  created() {
    if (this.edownerType === 'Team') {
      this.ownerId = this.teamsList?.find(
        team => team.id === this.edownerId
      )?.id;
    }
  },
  methods: {
    setPageName({ name }) {
      this.$v.content.$touch();
      this.content = name;
    },
    showAlert() {
      bus.$emit(
        'newToastMessage',
        this.editCanned.message,
        this.editCanned.action
      );
    },
    resetForm() {
      this.shortCode = '';
      this.content = '';
      this.$v.shortCode.$reset();
      this.$v.content.$reset();
    },
    editCannedResponse() {
      // Show loading on button
      this.editCanned.showLoading = true;
      // Make API Calls
      this.$store
        .dispatch('updateCannedResponse', {
          id: this.id,
          short_code: this.shortCode,
          content: this.content,
          owner_type: this.ownerType,
          owner_id: this.ownerId,
        })
        .then(() => {
          // Reset Form, Show success message
          this.editCanned.showLoading = false;
          this.editCanned.message = this.$t(
            'CANNED_MGMT.EDIT.API.SUCCESS_MESSAGE'
          );
          this.editCanned.action = {
            status: wootConstants.SNACKBAR_TYPE.SUCCESS,
          };
          this.showAlert();
          this.resetForm();
          setTimeout(() => {
            this.onClose();
          }, 10);
        })
        .catch(error => {
          this.editCanned.showLoading = false;
          const errorMessage =
            error.message === 'Short code has already been taken'
              ? this.$t('CANNED_MGMT.EDIT.API.ERROR_MESSAGE_SHORT_CODE_TAKEN')
              : this.$t('CANNED_MGMT.EDIT.API.ERROR_MESSAGE');

          this.editCanned.message = errorMessage;
          this.editCanned.action = {
            status: wootConstants.SNACKBAR_TYPE.ERROR,
          };

          this.showAlert();
        });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep {
  .ProseMirror-menubar {
    display: none;
  }
}
</style>
