import Cookies from 'js-cookie';
import { frontendURL } from '../helper/URLHelper';

export const getOrganisationId = () => Cookies.getJSON('organisationId');

export const setOrganisationId = organisationId => {
  Cookies.set('organisationId', organisationId, {
    expires: 10,
  });
};

export const getActiveAccountId = ({ store = {}, user }) => {
  let defaultAccountId = null;
  let currentUser = user;

  if (!currentUser) {
    currentUser = store?.getters?.getCurrentUser;
  }

  defaultAccountId = currentUser?.account_id;

  return Cookies.getJSON('activeAccountId') || defaultAccountId;
};

export const setActiveAccountId = ({
  user,
  organisationId = getOrganisationId(),
  redirect = false,
  store = {},
}) => {
  if (organisationId) {
    let currentUser = user;

    setOrganisationId(organisationId);

    const currentActiveAccountId = getActiveAccountId({ store });

    if (!currentUser) {
      currentUser = store?.getters?.getCurrentUser;
    }

    const activeAccount = currentUser?.accounts?.find(
      account =>
        account.cognigy_organisation_id === organisationId &&
        account.id !== currentActiveAccountId
    );

    if (activeAccount) {
      Cookies.set('activeAccountId', activeAccount.id);

      if (
        redirect &&
        window.location.pathname.indexOf(`/accounts/${activeAccount.id}`) === -1
      ) {
        window.location = frontendURL(`accounts/${activeAccount.id}/dashboard`);
      }
    }
  }
};
