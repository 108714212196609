import ApiClient, { axiosWithAuth } from './ApiClient';

class InboxCopilotBots extends ApiClient {
  constructor() {
    super('inboxes', { accountScoped: true });
  }

  getCopilotBots(inboxId) {
    return axiosWithAuth.get(`${this.url}/${inboxId}/copilot_bots`);
  }

  addCopilotBot(inboxId, copilotBot) {
    return axiosWithAuth.post(
      `${this.url}/${inboxId}/copilot_bots`,
      copilotBot
    );
  }

  editCopilotBot(inboxId, copilotBot) {
    return axiosWithAuth.patch(
      `${this.url}/${inboxId}/copilot_bots/${copilotBot.id}`,
      copilotBot
    );
  }

  removeCopilotBot(inboxId, copilotBotId) {
    return axiosWithAuth.delete(
      `${this.url}/${inboxId}/copilot_bots/${copilotBotId}`
    );
  }
}

export default new InboxCopilotBots();
