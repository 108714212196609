<template>
  <div class="column content-box audit-log--settings">
    <!-- List Audit Logs -->
    <div>
      <div>
        <p
          v-if="!uiFlags.fetchingList && !records.length"
          class="no-items-error-message"
        >
          {{ $t('AUDIT_LOGS.LIST.404') }}
        </p>
        <woot-loading-state
          v-if="uiFlags.fetchingList"
          :message="$t('AUDIT_LOGS.LOADING')"
        />

        <table
          v-if="!uiFlags.fetchingList && records.length"
          class="woot-table width-100"
        >
          <colgroup>
            <col class="column-activity" />
            <col />
            <col />
          </colgroup>
          <thead>
            <!-- Header -->
            <th
              v-for="thHeader in $t('AUDIT_LOGS.LIST.TABLE_HEADER')"
              :key="thHeader"
            >
              {{ thHeader }}
            </th>
          </thead>
          <tbody>
            <tr
              v-for="currentAuditLogItem in records"
              :key="currentAuditLogItem.id"
              class="log-row"
              @click="onLogClick(currentAuditLogItem)"
            >
              <td class="wrap-break-words">
                {{ currentAuditLogItem.username }}
              </td>
              <td
                class="wrap-break-words"
                :data-test-id="`audit-log-action-${currentAuditLogItem.id}`"
              >
                {{ generateLogText(currentAuditLogItem) }}
              </td>
              <td class="wrap-break-words">
                {{
                  messageTimestamp(
                    currentAuditLogItem.created_at,
                    'MMM dd, yyyy hh:mm a'
                  )
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <table-footer
      :current-page="Number(meta.currentPage)"
      :total-count="meta.totalEntries"
      :page-size="meta.perPage"
      @page-change="onPageChange"
    />
    <audit-log-modal
      :show="showAuditLogModal"
      :audit-log-item="auditLogItem"
      :generate-log-text="generateLogText"
      @close="onModalClose"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import TableFooter from 'dashboard/components/widgets/TableFooter.vue';
import timeMixin from 'dashboard/mixins/time';
import alertMixin from 'shared/mixins/alertMixin';
import AuditLogModal from './AuditLogModal.vue';

export default {
  name: 'AuditLogsHome',
  components: {
    TableFooter,
    AuditLogModal,
  },
  mixins: [alertMixin, timeMixin],
  data() {
    return {
      loading: {},
      showAuditLogModal: false,
      auditLogItem: {},
    };
  },
  computed: {
    ...mapGetters({
      records: 'auditlogs/getAuditLogs',
      uiFlags: 'auditlogs/getUIFlags',
      meta: 'auditlogs/getMeta',
    }),
  },
  mounted() {
    this.$store.dispatch('agents/get', { minified: true });
  },
  methods: {
    generateLogText(auditLogItem) {
      const auditableType = auditLogItem.auditable_type.toLowerCase();
      const action = auditLogItem.action.toLowerCase();
      const auditId = auditLogItem.auditable_id;
      const logActionKey = `${auditableType}:${action}`;

      const translationPayload = {
        id: auditId,
      };

      const translationKeys = {
        'automationrule:create': `AUDIT_LOGS.AUTOMATION_RULE.ADD`,
        'automationrule:update': `AUDIT_LOGS.AUTOMATION_RULE.EDIT`,
        'automationrule:destroy': `AUDIT_LOGS.AUTOMATION_RULE.DELETE`,
        'webhook:create': `AUDIT_LOGS.WEBHOOK.ADD`,
        'webhook:update': `AUDIT_LOGS.WEBHOOK.EDIT`,
        'webhook:destroy': `AUDIT_LOGS.WEBHOOK.DELETE`,
        'inbox:create': `AUDIT_LOGS.INBOX.ADD`,
        'inbox:update': `AUDIT_LOGS.INBOX.EDIT`,
        'inbox:destroy': `AUDIT_LOGS.INBOX.DELETE`,
        'user:sign_in': `AUDIT_LOGS.USER_ACTION.SIGN_IN`,
        'user:sign_out': `AUDIT_LOGS.USER_ACTION.SIGN_OUT`,
        'team:create': `AUDIT_LOGS.TEAM.ADD`,
        'team:update': `AUDIT_LOGS.TEAM.EDIT`,
        'team:destroy': `AUDIT_LOGS.TEAM.DELETE`,
        'copilotbot:create': `AUDIT_LOGS.COPILOT_BOT.ADD`,
        'copilotbot:update': `AUDIT_LOGS.COPILOT_BOT.EDIT`,
        'copilotbot:destroy': `AUDIT_LOGS.COPILOT_BOT.DELETE`,
        // Backward compatibility with AssistBot old logs
        'assistbot:create': `AUDIT_LOGS.ASSIST_BOT.ADD`,
        'assistbot:update': `AUDIT_LOGS.ASSIST_BOT.EDIT`,
        'assistbot:destroy': `AUDIT_LOGS.ASSIST_BOT.DELETE`,
        'inboxmember:create': `AUDIT_LOGS.INBOX_MEMBER.ADD`,
        'inboxmember:destroy': `AUDIT_LOGS.INBOX_MEMBER.DELETE`,
        'channel::api:create': `AUDIT_LOGS.WEBHOOK.ADD`,
        'channel::api:update': `AUDIT_LOGS.WEBHOOK.EDIT`,
        'channel::api:destroy': `AUDIT_LOGS.WEBHOOK.DELETE`,
      };

      return this.$t(translationKeys[logActionKey] || '', translationPayload);
    },
    onPageChange(page) {
      window.history.pushState({}, null, `${this.$route.path}?page=${page}`);
      try {
        this.$store.dispatch('auditlogs/fetch', { page });
      } catch (error) {
        const errorMessage =
          error?.message || this.$t('AUDIT_LOGS.API.ERROR_MESSAGE');
        this.showAlert(errorMessage);
      }
    },
    onLogClick(auditLogItem) {
      this.auditLogItem = auditLogItem;
      this.showAuditLogModal = true;
    },
    onModalClose() {
      this.showAuditLogModal = false;
    },
  },
};
</script>
<style scoped lang="scss">
.wrap-break-words {
  word-break: break-all;
  white-space: normal;
}

.log-row {
  cursor: pointer;
  &:hover {
    background-color: #e4ebf1;
  }
}
</style>
