<template>
  <div class="container" :class="{ smallContainer: isSideMenuOpen }">
    <p class="title">{{ title }}</p>
    <p class="subTitle">{{ subTitle }}</p>
    <div class="table">
      <div v-for="(fact, index) in table.facts" :key="index" class="tr">
        <div class="td key" :class="{ header: index === 0 }">
          {{ fact.title }}
        </div>
        <div class="td" :class="{ header: index === 0 }">
          {{ fact.value }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    isSideMenuOpen: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    title() {
      return this.data[0].text;
    },
    subTitle() {
      return this.data[1].text;
    },
    table() {
      return this.data[2];
    },
  },
};
</script>

<style scoped lang="scss">
@import 'dashboard/assets/scss/woot';

.container {
  display: flex;
  flex-direction: column;
  width: 400px;
  padding-top: 15px;

  @include breakpoint(xlargeHD up) {
    width: 550px;
  }
}

.smallContainer {
  width: 220px;
  min-width: 220px;
  padding-right: 0;
  padding-left: 10px;
  @include breakpoint(xlargeHD up) {
    width: 550px;
    padding-left: 0px;
  }
}
.title {
  font-size: 1.6rem;
  color: #1a48a2;
  @include breakpoint(xlargeHD up) {
    font-size: 2rem;
  }
}

.subtitle {
  font-size: 1.2rem;
  @include breakpoint(xlargeHD up) {
    font-size: 1.2rem;
  }
}

.table {
  display: table;
  border: 1px solid #c0cde4;
  border-radius: 5px;
}
.tr {
  display: table-row;
}
.td {
  display: table-cell;
  font-size: 1.2rem;
  padding: 2px;
  border-top: 1px solid #c0cde4;
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @include breakpoint(xlargeHD up) {
    font-size: 1.5rem;
    padding: 5px;
  }
}
.key {
  border-right: 1px solid #c0cde4;
  font-weight: bold;
}

.header {
  border-top: none;
}
.key {
  display: table-cell;
}

.value {
  margin-left: 5px;
}
</style>
