<template>
  <div
    :id="getCognigyTestId"
    :data-test-id="'agent_copilot_bot_' + cognigyMessageType"
  />
</template>

<script>
import { renderCognigyMessage } from '../widgets/conversation/helpers/renderCognigyMessage.js';
import { MESSAGE_STATUS } from 'shared/constants/messages';
import cognigyStructuredContentMixin from 'shared/mixins/cognigyStructuredContentMixin';
import { BUS_EVENTS } from 'shared/constants/busEvents';
import {
  addEventListenersForActionElements,
  addEventListenersToStopGalleryArrowPropagation,
} from '../widgets/conversation/helpers/addEventListeners.js';

export default {
  mixins: [cognigyStructuredContentMixin],
  props: {
    messageData: {
      type: Object,
      required: true,
    },
    contentType: {
      type: String,
      default: '',
    },
  },
  mounted() {
    const isIdAvailable =
      !!this.messageData.inbox_id &&
      !!this.messageData.conversation_id &&
      !!this.messageData.id;

    const isCognigyMessage = isIdAvailable && this.contentType === 'cognigy';
    const messageStatusIsNotProgress =
      this.messageData.status !== MESSAGE_STATUS.PROGRESS;

    if (isCognigyMessage && messageStatusIsNotProgress) {
      this.renderCognigyMessage();
    }
  },
  methods: {
    renderCognigyMessage() {
      const elementId = this.getCognigyTestId;

      renderCognigyMessage(
        elementId,
        this.contentAttributes,
        '#0b3694',
        'incoming'
      );

      this.addEventListeners(elementId);
    },

    addEventListeners(elementId) {
      addEventListenersForActionElements({
        elementId,
        onForwardAssistMessagePostback: this.onForwardAssistMessagePostback,
        messageData: this.messageData,
        type: this.cognigyMessageType,
      });

      if (this.cognigyMessageType === 'generic') {
        addEventListenersToStopGalleryArrowPropagation(
          elementId,
          this.emitStopForwarding
        );
      }
    },
    onForwardAssistMessagePostback(payload) {
      this.$emit('forwardAssistMessagePostback', payload);
    },
    emitStopForwarding() {
      bus.$emit(BUS_EVENTS.STOP_FORWARDING);
    },
  },
};
</script>

<style lang="scss">
@import 'foundation-sites/scss/foundation';

.cognigy-content-container {
  display: flex;
}

.message-time-container {
  margin-right: 23px;
}

.thumbnail-structured-content {
  position: absolute !important;
  right: 16px;
  bottom: 27px;
}

.sender--info {
  align-items: flex-end;
  color: var(--b-700);
  display: inline-flex;
  padding-left: 0.8rem;
  .sender--available-name {
    font-size: var(--font-size-mini);
    margin-left: var(--space-smaller);
  }
}

.sender-structured-content {
  align-items: flex-end;
  color: var(--b-700);
  display: inline-flex;
  padding: var(--space-smaller) 0;

  .sender--available-name {
    font-size: var(--font-size-mini);
    margin-left: var(--space-smaller);
  }
}

div[data-test-id^='agent_copilot_bot_'] {
  @include breakpoint(1600 down) {
    width: 80%;
  }
}

div[data-test-id='agent_copilot_bot_generic'] .slider {
  background-color: transparent !important;
  height: 100% !important;
}

div[data-test-id='agent_copilot_bot_generic'] {
  width: 350px;

  @include breakpoint(1600 down) {
    width: 270px;
  }
}

div[data-test-id='agent_copilot_bot_adaptivecards'] .adaptivecard-wrapper {
  max-width: 400px;
}

div[data-test-id='agent_copilot_bot_generic'] .carousel.carousel-slider {
  width: 93% !important;
}

.webchat-quick-reply-template-header-message {
  font-size: 1.4rem;
  color: white !important;
  font-family: 'Inter' !important;
}

.webchat-buttons-template-header {
  font-size: 1.4rem;
  color: white !important;
  font-family: 'Inter' !important;
}
</style>
