<template>
  <div>
    <basic-info-card
      v-if="isBasicInfoCard"
      :data="adaptiveCardBody"
      :is-side-menu-open="isSideMenuOpen"
      data-test-id="basic-card"
    />
    <table-info-card
      v-if="isTableInfoCard"
      :data="adaptiveCardBody"
      :is-side-menu-open="isSideMenuOpen"
      data-test-id="table-card"
    />
    <suggested-reply-card
      v-if="isSuggestedReplyCard"
      :data="adaptiveCardBody"
      :is-side-menu-open="isSideMenuOpen"
      data-test-id="suggest-card"
    />
    <custom-card
      v-if="isCustomAdaptiveCard"
      :message-data="adaptiveCardMessageData"
      :is-side-menu-open="isSideMenuOpen"
      data-test-id="custom-card"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BasicInfoCard from './BasicInfoCard.vue';
import TableInfoCard from './TableInfoCard.vue';
import SuggestedReplyCard from './SuggestedReplyCard.vue';
import CustomCard from './CustomCard.vue';

export default {
  components: {
    BasicInfoCard,
    TableInfoCard,
    SuggestedReplyCard,
    CustomCard,
  },
  props: {
    messageData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      isSideMenuOpen: 'ui/isSideMenuOpen',
    }),
    infoCardData() {
      // eslint-disable-next-line no-underscore-dangle
      return this.messageData.content_attributes.items[0].data?._cognigy
        ._whisperAssist;
    },
    infoCardType() {
      return this.infoCardData.cardType;
    },
    adaptiveCardBody() {
      // eslint-disable-next-line no-underscore-dangle
      return this.infoCardData.adaptiveCard.body;
    },
    isBasicInfoCard() {
      return this.infoCardType === 'basic';
    },
    isTableInfoCard() {
      return this.infoCardType === 'table';
    },
    isSuggestedReplyCard() {
      return this.infoCardType === 'suggested';
    },
    isCustomAdaptiveCard() {
      return this.infoCardType === 'custom';
    },
    adaptiveCardMessageData() {
      return {
        ...this.messageData,
        content_attributes: {
          items: [
            {
              text: 'UNSUPPORTED_DATA_MESSAGE',
              data: {
                _plugin: {
                  type: 'adaptivecards',
                  payload: {
                    // eslint-disable-next-line no-underscore-dangle
                    ...this.messageData.content_attributes.items[0].data
                      ._cognigy._whisperAssist.adaptiveCard,
                  },
                },
              },
            },
          ],
        },
      };
    },
  },
};
</script>
