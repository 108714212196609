import fromUnixTime from 'date-fns/fromUnixTime';
import differenceInDays from 'date-fns/differenceInDays';
import Cookies from 'js-cookie';
import { setActiveAccountId } from '../../api/activeAccountId';
import DraftManager from 'dashboard/store/modules/drafts/draftManager';

Cookies.defaults = { sameSite: 'Lax' };

export const getLoadingStatus = state => state.fetchAPIloadingStatus;
export const setLoadingStatus = (state, status) => {
  state.fetchAPIloadingStatus = status;
};

export const setUser = user => {
  // Set active account ID again in case there is a
  // new account and organisation ID is different
  setActiveAccountId({ user, redirect: true });
};

export const getHeaderExpiry = response =>
  fromUnixTime(response.headers.expiry);

export const setAuthCredentials = response => {
  const expiryDate = getHeaderExpiry(response);
  Cookies.set('cw_d_session_info', response.headers, {
    expires: differenceInDays(expiryDate, new Date()),
  });
  setUser(response.data.data, expiryDate);
};

export const clearBrowserSessionCookies = () => {
  Cookies.remove('cw_d_session_info');
  Cookies.remove('auth_data');
  Cookies.remove('user');
  Cookies.remove('activeAccountId');
  Cookies.remove('organisationId');
};

export const clearCookiesOnLogout = organisationId => {
  const existingOrganisationId = Cookies.get('organisationId');
  const selectedOrganisationId = organisationId || existingOrganisationId;

  clearBrowserSessionCookies();
  DraftManager.deleteAllDrafts();
  const globalConfig = window.globalConfig || {};
  const logoutRedirectLink = globalConfig.LOGOUT_REDIRECT_LINK || '/';
  window.location = selectedOrganisationId
    ? `${logoutRedirectLink}/?organisationId=${selectedOrganisationId}
  `
    : logoutRedirectLink;
};

export const parseAPIErrorResponse = error => {
  if (error?.response?.data?.message) {
    return error?.response?.data?.message;
  }
  if (error?.response?.data?.error) {
    return error?.response?.data?.error;
  }
  return error;
};

export const throwErrorMessage = error => {
  const errorMessage = parseAPIErrorResponse(error);
  throw new Error(errorMessage);
};
