import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import SkillAPI from '../../api/skills';

export const state = {
  records: [],
  uiFlags: {
    fetchingList: false,
    fetchingItem: false,
    creatingItem: false,
    updatingItem: false,
    deletingItem: false,
  },
};

const getters = {
  getSkills(_state) {
    return _state.records;
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
};

const actions = {
  get: async function getSkills({ commit }, { searchKey } = {}) {
    commit(types.default.SET_SKILL_UI_FLAG, { fetchingList: true });
    try {
      const response = await SkillAPI.get({ searchKey });
      commit(types.default.SET_SKILL, response.data);
      commit(types.default.SET_SKILL_UI_FLAG, { fetchingList: false });
    } catch (error) {
      commit(types.default.SET_SKILL_UI_FLAG, { fetchingList: false });
    }
  },

  createSkill: async function createSkill({ commit }, skillObj) {
    commit(types.default.SET_SKILL_UI_FLAG, { creatingItem: true });
    try {
      const response = await SkillAPI.create(skillObj);
      commit(types.default.ADD_SKILL, response.data);
      commit(types.default.SET_SKILL_UI_FLAG, { creatingItem: false });
    } catch (error) {
      commit(types.default.SET_SKILL_UI_FLAG, { creatingItem: false });
      throw new Error(error.response.data.message);
    }
  },

  updateSkill: async function updateSkill({ commit }, { id, ...updateObj }) {
    commit(types.default.SET_SKILL_UI_FLAG, { updatingItem: true });
    try {
      const response = await SkillAPI.update(id, updateObj);
      commit(types.default.EDIT_SKILL, response.data);
      commit(types.default.SET_SKILL_UI_FLAG, { updatingItem: false });
    } catch (error) {
      commit(types.default.SET_SKILL_UI_FLAG, { updatingItem: false });
      throw new Error(error.response.data.message);
    }
  },

  deleteSkill: async function deleteSkill({ commit }, id) {
    commit(types.default.SET_SKILL_UI_FLAG, { deletingItem: true });
    try {
      await SkillAPI.delete(id);
      commit(types.default.DELETE_SKILL, id);
      commit(types.default.SET_SKILL_UI_FLAG, { deletingItem: true });
    } catch (error) {
      commit(types.default.SET_SKILL_UI_FLAG, { deletingItem: true });
    }
  },
};

const mutations = {
  [types.default.SET_SKILL_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },

  [types.default.SET_SKILL]: MutationHelpers.set,
  [types.default.ADD_SKILL]: MutationHelpers.create,
  [types.default.EDIT_SKILL]: MutationHelpers.update,
  [types.default.DELETE_SKILL]: MutationHelpers.destroy,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
