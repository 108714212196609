export const addClassToCognigyGalleryInCopilot = (elementId, messageData) => {
  // We use this function to re-style the galleries in Copilot
  // depending on the quantity of cards
  const elementRoot = document.querySelectorAll(`#${elementId}`);

  const element = document.querySelectorAll(
    `#${elementId} .webchat-message-row`
  );
  /* eslint-disable no-underscore-dangle */
  const legacyMessageElements =
    messageData.content_attributes.items[0].data?._cognigy?._webchat?.message
      ?.attachment?.payload?.elements;
  const messageElements =
    messageData.content_attributes.items[0].data?._cognigy?._defaultPreview
      ?.message?.attachment?.payload?.elements;
  const length = legacyMessageElements?.length || messageElements.length;

  if (element.length > 0) {
    if (length === 1) {
      elementRoot[0].classList.add('one-card-ai-copilot-gallery-root');
    }
    if (length === 2) {
      element[0].classList.add('two-cards-ai-copilot-gallery');
      elementRoot[0].classList.add('two-cards-ai-copilot-gallery-root');
    }
    if (length === 3) {
      element[0].classList.add('three-cards-ai-copilot-gallery');
      elementRoot[0].classList.add('three-cards-ai-copilot-gallery-root');
    }
    if (length > 3) {
      element[0].classList.add('multiple-cards-ai-copilot-gallery');
      elementRoot[0].classList.add('multiple-cards-gallery-root');
    }
  }
};

export const addSideBarOpenClass = ({
  isSideMenuOpen,
  elementId,
  messageData,
  galleryNextArrowClicks,
}) => {
  const elementRoot = document.querySelectorAll(`#${elementId}`);

  const element = document.querySelectorAll(
    `#${elementId} .webchat-message-row`
  );
  const nextArrow = document.querySelectorAll(
    `#${elementId} .control-arrow.control-next`
  );

  const legacyMessageElements =
    messageData.content_attributes.items[0].data?._cognigy?._webchat?.message
      ?.attachment?.payload?.elements;
  const messageElements =
    messageData.content_attributes.items[0].data?._cognigy?._defaultPreview
      ?.message?.attachment?.payload?.elements;
  const length = legacyMessageElements?.length || messageElements.length;

  if (element.length > 0) {
    if (isSideMenuOpen && length > 1) {
      element[0].classList.add('gallery-side-menu-open');
    } else {
      element[0].classList.remove('gallery-side-menu-open');
      element[0].classList.remove('one-card-gallery-side-menu-open');
      element[0].classList.remove(
        'two-cards-ai-copilot-gallery-side-menu-open'
      );
      element[0].classList.remove(
        'three-cards-ai-copilot-gallery-side-menu-open'
      );
      element[0].classList.remove(
        'multiple-cards-ai-copilot-gallery-side-menu-open'
      );
    }

    if (isSideMenuOpen && length === 1) {
      elementRoot[0].classList.add('one-card-gallery-side-menu-open-root');
      element[0].classList.add('one-card-gallery-side-menu-open');
    }
    if (isSideMenuOpen && length === 2) {
      element[0].classList.add('two-cards-ai-copilot-gallery-side-menu-open');
      elementRoot[0].classList.add(
        'two-cards-ai-copilot-gallery-side-menu-open-root'
      );
    }
    if (isSideMenuOpen && length === 3) {
      element[0].classList.add('three-cards-ai-copilot-gallery-side-menu-open');
      elementRoot[0].classList.add(
        'three-cards-ai-copilot-gallery-side-menu-open-root'
      );
    }
    if (isSideMenuOpen && length > 3) {
      element[0].classList.add(
        'multiple-cards-ai-copilot-gallery-side-menu-open'
      );
      elementRoot[0].classList.add(
        'multiple-cards-ai-copilot-gallery-side-menu-openroot'
      );
    }
  }

  if (nextArrow.length > 0) {
    if (isSideMenuOpen && galleryNextArrowClicks === length - 3) {
      nextArrow[0].classList.add('arrow-side-menu-open');
    } else {
      nextArrow[0].classList.remove('arrow-side-menu-open');
    }
  }
};

export const addClassToGalleryNextArrowButton = (
  elementId,
  galleryNextArrowClicks,
  messageData
) => {
  const nextArrow = document.querySelectorAll(
    `#${elementId} .control-arrow.control-next`
  );

  const legacyMessageElements =
    messageData.content_attributes.items[0].data?._cognigy?._webchat?.message
      ?.attachment?.payload?.elements;
  const messageElements =
    messageData.content_attributes.items[0].data?._cognigy?._defaultPreview
      ?.message?.attachment?.payload?.elements;
  const length = legacyMessageElements?.length || messageElements.length;

  if (nextArrow.length > 0) {
    if (galleryNextArrowClicks === length - 3) {
      nextArrow[0].classList.add('hide-control-arrow');
    } else {
      nextArrow[0].classList.remove('hide-control-arrow');
    }
  }
};
