import { get } from 'lodash';
import { ContentAttributes } from '../store/types/message/message.types';

/**
 * Mixin to detect xApps messages
 */
export default {
  computed: {
    /**
     * Check if the current message is an xApps message to be displayed in the chat
     *
     * @returns boolean
     */
    isXAppsMessage(this: { contentAttributes: ContentAttributes }): boolean {
      if (Object.hasOwn(this.contentAttributes, 'items')) {
        const xAppsContent: any = get(this.contentAttributes?.items?.[0], [
          'data',
          '_cognigy',
          '_app',
        ]);

        if (!xAppsContent) {
          return false;
        }

        return xAppsContent.type === 'submit';
      }

      return false;
    },
    /**
     * Check if the message is an xApps overlay message
     * This message is not a regular message with text content but a message
     * with xApps configuration settings.For now, it is not displayed in the
     * chat since it does not contain chat-relevant data.
     *
     * @returns boolean
     */
    xAppsOverlay(this: { contentAttributes: ContentAttributes }): boolean {
      if (Object.hasOwn(this.contentAttributes, 'items')) {
        const xAppsOverlayMessage: any = get(
          this.contentAttributes?.items?.[0],
          ['data', '_cognigy', '_app', 'overlaySettings']
        );

        return xAppsOverlayMessage !== undefined;
      }

      return false;
    },
  },
};
