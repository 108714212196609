/* eslint no-console: 0 */
/* eslint-env browser */
/* eslint-disable no-new */
/* Vue Core */

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueRouter from 'vue-router';
// Global Components
import hljs from 'highlight.js';
import MultiSelect from 'vue-multiselect';
import VueFormulate from '@braid/vue-formulate';
import WootSwitch from 'components/ui/Switch.vue';
import WootWizard from 'components/ui/Wizard.vue';
import { sync } from 'vuex-router-sync';
import Vuelidate from 'vuelidate';
import VTooltip from 'v-tooltip';
import WootUiKit from '../dashboard/components';
import App from '../dashboard/App.vue';
import i18n from '../dashboard/i18n';
import commonHelpers, { isJSONValid } from '../dashboard/helper/commons';
import {
  getAlertAudio,
  initOnEvents,
} from 'shared/helpers/AudioNotificationHelper';
import { initFaviconSwitcher } from 'shared/helpers/faviconHelper';
import { createRouter, initalizeRouter } from 'dashboard/routes';
import store from '../dashboard/store';
import constants from '../dashboard/constants';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import {
  verifyServiceWorkerExistence,
  registerSubscription,
} from 'dashboard/helper/pushHelper';
import 'vue-easytable/libs/theme-default/index.css';
import FluentIcon from 'shared/components/FluentIcon/DashboardIcon.vue';
import VueDOMPurifyHTML from 'vue-dompurify-html';
import { domPurifyConfig } from 'shared/helpers/HTMLSanitizer';
import GmapVue from 'gmap-vue';

Vue.config.env = process.env;

if (
  window.errorLoggingConfig &&
  window.errorLoggingConfig.tracingEnabled === 'true'
) {
  Sentry.init({
    Vue,
    dsn: window.errorLoggingConfig.tracingBaseUrlWithProtocol,
    denyUrls: [
      // Chrome extensions
      /^chrome:\/\//i,
      /chrome-extension:/i,
      /extensions\//i,

      // Locally saved copies
      /file:\/\//i,

      // Safari extensions.
      /safari-web-extension:/i,
      /safari-extension:/i,
    ],
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: parseFloat(window.errorLoggingConfig.tracingSampleRate),
    environment: window.errorLoggingConfig.environment ?? process.env.NODE_ENV,
  });
}

Vue.use(VueDOMPurifyHTML, domPurifyConfig);
Vue.use(VueRouter);
Vue.use(VueI18n);
Vue.use(WootUiKit);
Vue.use(Vuelidate);
Vue.use(VueFormulate, {
  rules: {
    JSON: ({ value }) => isJSONValid(value),
  },
});
Vue.use(VTooltip, {
  defaultHtml: false,
});
Vue.use(hljs.vuePlugin);

Vue.use(GmapVue, {
  load: {
    key: window.chatwootConfig.googleMapsToken,
    libraries: 'places',
  },
  installComponents: true,
});

Vue.component('MultiSelect', MultiSelect);
Vue.component('WootSwitch', WootSwitch);
Vue.component('WootWizard', WootWizard);
Vue.component('FluentIcon', FluentIcon);

export const i18nVueInstance = new VueI18n({
  locale: 'en',
  messages: i18n,
});

// load common helpers into js
commonHelpers();
const router = createRouter(store);

sync(store, router);
window.WootConstants = constants;
window.bus = new Vue();
initalizeRouter({ store, router });

window.onload = () => {
  window.WOOT = new Vue({
    router,
    store,
    i18n: i18nVueInstance,
    components: { App },
    template: '<App/>',
  }).$mount('#app');
};

const setupAudioListeners = () => {
  getAlertAudio().then(() => {
    initOnEvents.forEach(event => {
      document.removeEventListener(event, setupAudioListeners, false);
    });
  });
};

window.addEventListener('load', () => {
  verifyServiceWorkerExistence(registration =>
    registration.pushManager.getSubscription().then(subscription => {
      if (subscription) {
        registerSubscription();
      } else if (Notification.permission === 'granted') {
        // automatically enable push notifications if permissions were granted
        registerSubscription();
      }
    })
  );

  window.playAudioAlert = () => {};
  initOnEvents.forEach(e => {
    document.addEventListener(e, setupAudioListeners, false);
  });
  initFaviconSwitcher();
});
