<template>
  <div class="basicContent">
    <div class="basicMessage">
      {{ messageData.content }}
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  mixins: [],
  props: {
    messageData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
.basicContent {
  margin-top: 10px;
  margin-right: 10px;
  :hover > & {
    transform: scale(120%) !important;
    transition: transform 0.3s ease-out;
  }
}
.basicMessage {
  font-size: 0.98rem;
  background-color: #154194;
  min-width: 70px;
  max-width: 500px;
  min-height: 21px;
  color: white;
  display: flex;
  overflow-wrap: anywhere;
  border-radius: 11.2px 11.2px 0px;
  min-height: 38px;
  padding: 11.9px 16.8px;
}
</style>
