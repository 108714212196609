import de from './locale/de';
import en from './locale/en';
import es from './locale/es';
import fr from './locale/fr';
import ja from './locale/ja';
import ko from './locale/ko';

export default {
  de,
  en,
  es,
  fr,
  ja,
  ko,
};
