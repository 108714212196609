<template>
  <basic-modal
    :show="show"
    :header-title="$t('INBOX_MGMT.COPILOT_BOT.REMOVE_COPILOT_BOT')"
    :header-content="$t('INBOX_MGMT.COPILOT_BOT.REMOVE_COPILOT_BOT_HEADER')"
    :primary-button-text="
      $t('INBOX_MGMT.COPILOT_BOT.REMOVE_COPILOT_BOT_BUTTON')
    "
    :secondary-button-text="$t('INBOX_MGMT.COPILOT_BOT.CANCEL_BUTTON')"
    @cancel="closeCopilotBotDelete"
    @submit="onRemoveBots"
  />
</template>

<script>
import BasicModal from '../../../../../components/widgets/modal/BasicModal.vue';
export default {
  components: { BasicModal },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    copilotBotId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      name: '',
      endpointUrl: '',
    };
  },
  computed: {
    currentInboxId() {
      return this.$route.params.inboxId;
    },
  },
  methods: {
    async onRemoveBots() {
      await this.$store.dispatch('inboxCopilotBots/removeCopilotBot', {
        inboxId: this.currentInboxId,
        copilotBotId: this.copilotBotId,
      });
      this.closeCopilotBotDelete();
    },
    closeCopilotBotDelete() {
      this.$emit('closePopup');
    },
  },
};
</script>
